import React, { useEffect, useState, Dispatch } from 'react'
import { Link, useParams } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { WorkOrderClient, WorkOrderQueryParams } from '../../../infrastracture/api/WorkOrderClient'
import { TenantTypeIds } from '../../../common/constants/tenantTypeIds'
import { connect, shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import AttachmentsList from '../../attachments/components/AttachmentsList'
import QuotesList from '../../quotes/components/QuotesList'
import NotesList from '../../notes/components/NotesList'
import ReviewsList from '../../reviews/components/ReviewsList'
import ParticipantsList from '../../participants/components/ParticipantsList'
import AssignToModal from './AssignToModal'
import CheckInsList from '../../check-ins/components/CheckInsList'
import Moment from 'react-moment'
import moment from 'moment'
import NoticeSitesList from '../../notice-sites/components/NoticeSitesList'
import WorkOrderHistoryList from '../../work-order-history/components/WorkOrderHistoryList'

import ChargesList from '../../charges/components/ChargesList'
import { toMoneyFormat } from '../../../common/helpers/stringHelper'
import WorkOrderStatusUpdateSelector from '../../../common/components/selectors/WorkOrderStatusUpdateSelector'
import {
    CategoryWorkScopesClient,
    CategoryWorkScopesQueryParams,
} from '../../../infrastracture/api/CategoryWorkScopesClient'
import { OverlayTrigger, Popover, Spinner, Tooltip } from 'react-bootstrap-v5'
import { SweetAlertRenderProps } from 'react-bootstrap-sweetalert/dist/types'
import SiteCheckList from './SiteCheckList'
import {
    WorkOrderStatusQueryParams,
    WorkOrderStatusClient,
} from '../../../infrastracture/api/WorkOrderStatusClient'
import toast, { Toaster } from 'react-hot-toast'
import WorkOrderChildren from './WorkOrderChildren'
import WorkOrderAssetsList from './WorkOrderAssetsList'
import { Actions } from '../redux/WorkOrderRedux'
import { SweetAlertWithProp } from '../../../../SweetAlertWithProp'
import WorkOrderMessageModal from './WorkOrderMessageModal'
import { Permissions } from '../../../common/constants/permissions'
import MessagesList from '../../messages/components/MessagesList'
import { TenantsClient } from '../../../infrastracture/api/TenantsClient'
import QuotesModal from '../../quotes/components/QuotesModal'
import {
    ClientPrioritySettingsClient,
    ClientPrioritySettingsQueryParams,
} from '../../../infrastracture/api/ClientPrioritySettingsClient'
import WorkOrderProjectCostModal from './WorkOrderProjectCostModal'
import QuotesForParentWorkOrderList from '../../quotes/components/QuotesForParentWorkOrderList'
import { ProjectsClient } from '../../../infrastracture/api/ProjectsClient'
import AsyncSelect from 'react-select/async';
import ReadyIndicator from './widgets/ReadyIndicator'

interface Props {
    dispatch: Dispatch<any>
    workorder: any
}

export enum NavItems {
    Notes,
    Quotes,
    Attachments,
    Assignments,
    Reviews,
    Participants,
    CheckIns,
    Notices,
    Charges,
    WorkOrderHistoryList,
    CheckLists,
    Children,
    Assets,
}

const WorkOrderDetails: React.FC<Props> = ({ dispatch, workorder }) => {
    const params: any = useParams()

    const user: any = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as any

    const permissions: any = useSelector<RootState>(
        ({ auth }) => auth.userPermission,
        shallowEqual
    ) as any

    const [supplierStatus, setSupplierStatus] = useState<any>({});
    const [supplierStatusLoading, setSupplierStatusLoading] = useState<boolean>(false);

    const [externalId, setExternalId] = useState<any>({});
    const [workOrder, setWorkOrder] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [categoryWorkScope, setCategoryWorkScope] = useState<any>(null)
    const [WorkOrderStatus, setWorkOrderStatus] = useState<Array<any>>([])
    const [selectedWorkOrderStatusId, setSelectedWorkOrderStatusId] = useState('')
    const [onChangeFlag, setOnChangeFlag] = useState<boolean>(false)
    const [selectedWorkorderStatusDescription, setSelectedWorkorderStatusDescription] = useState('')
    const [prevWorkORderStatus, setPrevWorkOrderStatus] = useState<any>({})
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalRecords: 1,
        totalPages: 1,
        skip: 0,
        take: 50,
        reset: true,
        sort: 'name',
        direction: 'asc',
    })
    const [projectCost, setProjectCost] = useState(0)
    const [imageLogo, setImageLogo] = useState('')
    const [refreshQuotesList, setRefreshQuotesList] = useState(false)
    const [showProjectCostModal, setShowProjectCostModal] = useState(false)
    const [showAssignToProjectModal, setShowAssignToProjectModal] = useState(false)

    const { filter } = workorder
    const { selectedTab } = filter

    useEffect(() => {
        fetchData()
        fetchWorkOrderStatusData()
    }, [params])

    const sourceFriendlyName = (name: string) => {
        if (name === "error")
            return "error";
        if (name === "none")
            return "none";
        if (name === "tenant")
            return "client";
        if (name === "onBehalfOfTenant")
            return "on behalf of";
        if (name === "department")
            return "department";
        if (name === "workOrder")
            return "override";
        if (name === "existingSyncRelationship")
            return "pinned";

        return "unknown";
    }

    const updateProjectId = async (values: any) => {
        var response = await ProjectsClient.GetProjectId(values.tenantId, values.onBehalfOfTenantId, values.departmentId, values.externalMYOBProjectId);
        console.log(response);
        if (response.successful) {
            setExternalId(response.data);
        } else {
            setExternalId({ source: "error" });
        }
    };

    const loadOptions = async (inputValue: string, callback: (options: any) => void) => {

        var params = new WorkOrderQueryParams()
            .WithIgnoreCompleteOnly(true)
            .Search(inputValue)
            .WithTenantId(workOrder.tenant.id)
            .IsParent();

        var projectWorkOrders = await WorkOrderClient.List(params);

        var options = projectWorkOrders.data.data.map((item: any) => {
            return {
                value: item.id,
                item: item,
                label: "WO-" + item.workOrderNumberId
            };
        });

        callback(options);

        return options;
    };

    const formatOptionLabel = ({ value, label, item }: any) => (
        <div>
            <div><strong>{label}</strong></div>
            <div>
                {item.description}
            </div>
        </div>
    );

    const fetchData = async () => {
        setLoading(true)
        var response = await WorkOrderClient.Get(params.id)

        if (response.successful) {
            const { data } = response

            updateProjectId(data);

            if (data.workOrderCategoryId && data.workScopeId) {
                getCategoryWorkScope(data.workOrderCategoryId, data.workScopeId)
            }
            setWorkOrder(data);
            setPrevWorkOrderStatus(data.workOrderStatus)
            setSelectedWorkorderStatusDescription(data.workOrderStatus?.description)

            var totalProjectCost = data.cost || 0

            if (data.childrenWorkOrder.length > 0) {
                const childrenCost = data.childrenWorkOrder.reduce(
                    (n: any, { cost }: any) => n + (cost ? cost : 0),
                    0
                )
                totalProjectCost += childrenCost
            }
            setLoading(false)
            setProjectCost(totalProjectCost)

            setImageLogo(data.tenant?.logo)

            console.log("Checking WO");
            console.log(data);
            await checkSupplierStatus(data);

        }
        setLoading(false)

    }

    const checkSupplierStatus = async (data: any) => {

        console.log(data.assignedToTenant);

        if (data.assignedToTenant) {
            setSupplierStatusLoading(true);
            if (!data.assignedToTenant.externalId || data.assignedToTenant.externalId.length === 0) {
                setSupplierStatus({ isAvailableForUse: false, message: "No MYOB Supplier ID specified" });
            } else {
                var supplierResponse = await WorkOrderClient.CheckSupplierStatusInMyob(data.assignedToTenant.externalId);
                if (supplierResponse.successful) {
                    setSupplierStatus(supplierResponse.data);
                } else {
                    setSupplierStatus({ isAvailableForUse: false, message: "Error checking MYOB Supplier ID" });
                }
            }
            setSupplierStatusLoading(false);
        }
    }

    const fetchWorkOrderStatusData = async () => {
        var query = new WorkOrderStatusQueryParams()
        var response = await WorkOrderStatusClient.List(
            query
                .Paginate(pagination.skip, pagination.take)
                .Search('')
                .WithActive(true)
                .Sort(pagination.sort, pagination.direction)
        )

        if (!response.authenticated) {
        } else {
            const { data } = response
            setWorkOrderStatus(data?.data)
        }
    }

    const getCategoryWorkScope = async (categoryId: any, workScopeId: any) => {
        if (categoryId && workScopeId) {
            var query = new CategoryWorkScopesQueryParams()
            var response = await CategoryWorkScopesClient.List(
                query
                    .Paginate(0, 1)
                    .WithActive(true)
                    .Sort('id', 'desc')
                    .WithWorkOrderCategoryId(categoryId)
                    .WithWorkScopeId(workScopeId)
            )

            if (response.successful) {
                const { data } = response.data
                if (data.length > 0) setCategoryWorkScope(data[0])
            }
        }
    }

    const UpdateStatus = async (newWorkOrderStatusId: string) => {
        var selected = WorkOrderStatus.find((c) => c.id === newWorkOrderStatusId)
        toast(selected.description)

        if (selected) {
            setSelectedWorkorderStatusDescription(selected.description)
            setworkOrderStatusDescriptionSuccess(true)
        }
        setSelectedWorkOrderStatusId(newWorkOrderStatusId)

        setWorkOrder({
            ...workOrder,
            workOrderStatusId: newWorkOrderStatusId,
            workOrderStatus: { name: selected.name },
        })
    }

    const SyncWorkOrderToMYOB = async () => {
        setLoading(true)

        var wo = { ...workOrder }
        const { id } = wo

        var response = await WorkOrderClient.ForceSync(id)

        if (!response.successful) {
            //show error
        } else {
            fetchData()
            setLoading(false)
        }
    }

    const UpdateWorkOrderStatus = async () => {
        setLoading(true)

        await WorkOrderClient.SetStatus(workOrder.id, selectedWorkOrderStatusId);

        fetchData();
        setLoading(false);

        setOnChangeFlag(false);
    }

    const UpdateExpectedOnSite = async (newExpectedOnSite: string) => {
        setLoading(true)

        await WorkOrderClient.UpdateField(workOrder.id, 'expectedOnsite', new Date(newExpectedOnSite).toISOString());

        fetchData();
    }

    const updateLocalOnly = async (localOnly: boolean) => {
        setLoading(true)

        await WorkOrderClient.UpdateField(workOrder.id, 'localOnly', localOnly)
        fetchData();
    }

    const updateDueDate = async (newDueOnSite: string) => {
        setLoading(true);
        await WorkOrderClient.UpdateField(workOrder.id, 'dueDate', new Date(newDueOnSite).toISOString());
        fetchData();
    }

    const updateCost = async (newCost: any) => {
        setCostLoading(true)

        const tenantRequest = await TenantsClient.Get(workOrder?.tenantId)
        const tenant = tenantRequest.data

        var priorityQuery = await ClientPrioritySettingsClient.List(
            new ClientPrioritySettingsQueryParams()
                .Paginate(0, 10)
                .WithClientId(tenant.id)
                .WithPriorityId(workOrder.priorityId)
                .Sort('id', 'asc')
        )

        var priority = priorityQuery?.data?.data[0] ?? null

        var priorityFee = priority ? priority.fee : 0

        var percentageFee = tenant.percentageFee != null ? tenant.percentageFee / 100 : 0

        var estimate = parseFloat(newCost)

        var percentageFeeAmount = percentageFee > 0 ? estimate * percentageFee : 0

        var baseCost = (estimate ?? 0) + percentageFeeAmount + priorityFee
        var adminFee = tenant.adminFee ?? 0

        var tenantThreshold = tenant.thresholdMoney ?? 0

        if (baseCost > tenantThreshold - adminFee) {
            setCostError('The entered cost requires a quote')
        } else {

            var response = await WorkOrderClient.UpdateField(workOrder.id, 'costEstimate', newCost);

            if (!response.successful) {
                setCostError(response.data.errorMessage)
                return false
            } else {
                fetchData()
                return true
            }
        }
    }

    const renderItems = () => {
        switch (selectedTab) {
            case NavItems.Notes:
                return <NotesList workOrderId={params.id} refreshWO={fetchData} />

            case NavItems.Quotes:
                if (workOrder.isParent)
                    return (
                        <QuotesForParentWorkOrderList
                            workOrderId={params.id}
                            refreshWO={fetchData}
                            refreshData={() => {
                                setRefreshQuotesList(!refreshQuotesList)
                            }}
                            workOrderNumber={workOrder.workOrderNumberId}
                        />
                    )
                else
                    return (
                        <QuotesList
                            workOrderId={params.id}
                            workOrder={workOrder}
                            refreshWO={fetchData}
                            refreshList={refreshQuotesList}
                            isParentWorkOrder={workOrder.isParent}
                        />
                    )

            case NavItems.Attachments:
                return <AttachmentsList workOrderId={params.id} refreshParentList={fetchData} />

            case NavItems.Assignments:
                return <p>Assignments</p>

            case NavItems.Reviews:
                return <ReviewsList workOrderId={params.id} refreshWO={fetchData} />

            case NavItems.Participants:
                return <ParticipantsList workOrder={workOrder} refreshWO={fetchData} />

            case NavItems.CheckIns:
                return (
                    <CheckInsList
                        workOrder={workOrder}
                        siteId={workOrder.siteId}
                        workOrderId={params.id}
                        refreshWorkOrder={() => fetchData()}
                        getAll
                    />
                )

            case NavItems.Notices:
                return <NoticeSitesList siteId={workOrder.siteId} />

            case NavItems.Charges:
                return (
                    <ChargesList
                        workOrderId={params.id}
                        refreshWorkOrder={() => fetchData()}
                        refreshList={refreshQuotesList}
                    />
                )

            case NavItems.WorkOrderHistoryList:
                return <WorkOrderHistoryList workOrderId={params.id} />

            case NavItems.CheckLists:
                return (
                    <SiteCheckList
                        siteId={workOrder.siteId}
                        workOrderId={params.id}
                        refreshWorkOrder={() => fetchData()}
                    />
                )
            case NavItems.Children:
                return (
                    <WorkOrderChildren
                        parentWorkOrderId={params.id}
                        childrenWorkOrders={workOrder?.childrenWorkOrder || []}
                    />
                )

            case NavItems.Assets:
                return <WorkOrderAssetsList workOrderId={params.id} siteId={workOrder?.siteId} />
        }
    }

    const defaultDueDate = moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm:00')
    const [showDueOnSite, setShowDueOnSite] = useState(false)

    const [dueOnSite, setDueOnSite] = useState(defaultDueDate)
    const [dueOnSiteLoading, setDueOnSiteLoading] = useState(false)
    const [dueOnSiteSuccess, setDueOnSiteSuccess] = useState(false)

    const [showDueDate, setShowDueDate] = useState(false)

    const [dueDate, setDueDate] = useState(defaultDueDate)
    const [dueDateLoading, setDueDateLoading] = useState(false)
    const [dueDateSuccess, setDueDateSuccess] = useState(false)
    const [workOrderStatusDescriptionSuccess, setworkOrderStatusDescriptionSuccess] = useState(false)

    const [showCost, setShowCost] = useState(false)
    const [cost, setCost] = useState(0)
    const [costLoading, setCostLoading] = useState(false)
    const [costError, setCostError] = useState<any>(null)
    const [costSuccess, setCostSuccess] = useState(false)
    const [syncToMYOB, setSyncToMYOB] = useState(false)
    const [showMessageModal, setShowMessageModal] = useState(false)
    const [showWorkFlowModal, setShowWorkFlowModal] = useState(false)
    const [showReviewModal, setShowReviewModal] = useState(false)
    const [showMessageListModal, setShowMessageListModal] = useState(false)
    const [showQuoteModal, setShowQuoteModal] = useState(false)

    const renderModals = () => (
        <>
            <QuotesModal
                mode={'Add'}
                workOrderId={params.id}
                isClient={(user.tenant.tenantTypeId.toUpperCase() === TenantTypeIds.Client || user.tenant.tenantTypeId.toUpperCase() === TenantTypeIds.Owner)}
                handleOk={() => {
                    setRefreshQuotesList(!refreshQuotesList)
                    fetchData()
                    setShowQuoteModal(false)
                }}
                handleClose={() => {
                    setShowQuoteModal(false)
                }}
                thresholdMoney={workOrder?.tenant?.thresholdMoney ?? 0}
                selectedData={undefined}
                defaultAmount={cost}
                show={showQuoteModal}
            />
            <WorkOrderMessageModal
                handleOk={() => {
                    setShowMessageModal(false)
                }}
                handleClose={() => {
                    setShowMessageModal(false)
                }}
                show={showMessageModal}
                workOrder={workOrder}
            />
            <SweetAlertWithProp
                show={showWorkFlowModal}
                title='Work Flow'
                onCancel={() => {
                    setShowWorkFlowModal(false)
                }}
                showCancel={false}
                showConfirm={false}
                cancelBtnText='Close'
                style={{ width: '1000px' }}
                allowEscape
            >
                <WorkOrderHistoryList workOrderId={params.id} />
            </SweetAlertWithProp>

            <SweetAlertWithProp
                show={showReviewModal}
                title='Reviews'
                onCancel={() => {
                    setShowReviewModal(false)
                }}
                showCancel={false}
                showConfirm={false}
                cancelBtnText='Close'
                style={{ width: '1000px' }}
                allowEscape
            >
                <ReviewsList workOrderId={params.id} refreshWO={fetchData} />
            </SweetAlertWithProp>

            <SweetAlertWithProp
                show={showMessageListModal}
                title='Messages'
                onCancel={() => {
                    setShowMessageListModal(false)
                }}
                showCancel={false}
                showConfirm={false}
                cancelBtnText='Close'
                style={{ width: '1000px' }}
                allowEscape
            >
                <MessagesList workOrderId={params.id} />
            </SweetAlertWithProp>

            <SweetAlertWithProp
                show={syncToMYOB}
                title='Sync Information'
                onConfirm={() => {
                    setSyncToMYOB(false)
                    SyncWorkOrderToMYOB()
                }}
                showCancel={false}
            >
                <p>
                    Cost will only be updated in MYOB if the PO is on hold. If you want to update the cost in
                    MYOB, please put the PO on hold first or re-sync later once the PO is on hold.
                </p>
            </SweetAlertWithProp>

            <SweetAlertWithProp
                id="assignToProjectModal"
                customClass={"assignToProject"}
                show={showAssignToProjectModal}
                title='Assign WO to Project'
                onCancel={() => setShowAssignToProjectModal(false)}
                showCancel={true}
                showConfirm={false}
            >
                <p style={{ zIndex: "2" }}>
                    <div style={{ zIndex: "2" }}><AsyncSelect loadOptions={loadOptions} formatOptionLabel={formatOptionLabel} onChange={async (e) => {

                        setShowAssignToProjectModal(false);
                        await WorkOrderClient.AssignToProject(workOrder.id, e.value);
                        await fetchData();

                    }} /></div>
                </p>
            </SweetAlertWithProp>

            <AssignToModal workOrder={workOrder} handleOk={() => fetchData()} />
            <SweetAlertWithProp
                show={dueOnSiteSuccess}
                success
                title='Success'
                onConfirm={() => setDueOnSiteSuccess(false)}
                showCancel={false}
            >
                Date on Site has been set!
            </SweetAlertWithProp>
            <SweetAlertWithProp
                title={'Set Date on Site'}
                onConfirm={() => { }}
                onCancel={() => { }}
                type={'controlled'}
                dependencies={[showDueOnSite]}
                show={showDueOnSite}
                required
                showCancel
                closeOnClickOutside={false}
                customButtons={
                    <React.Fragment>
                        <button
                            onClick={() => {
                                setDueOnSite(defaultDueDate)
                                setShowDueOnSite(false)
                            }}
                            className='btn btn-lg btn-light'
                        >
                            Cancel
                        </button>
                        <span style={{ paddingRight: '5px' }}></span>
                        {dueOnSite && (
                            <button
                                onClick={async () => {
                                    setDueOnSiteLoading(true)
                                    await UpdateExpectedOnSite(dueOnSite)

                                    setDueOnSiteLoading(false)
                                    setDueOnSite(defaultDueDate)
                                    setShowDueOnSite(false)
                                    setDueOnSiteSuccess(true)
                                }}
                                className='btn btn-lg btn-primary'
                                disabled={dueOnSiteLoading}
                            >
                                Confirm
                                <span style={{ paddingRight: '3px' }}></span>
                                {dueOnSiteLoading && (
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                )}
                            </button>
                        )}
                    </React.Fragment>
                }
            >
                {(renderProps: SweetAlertRenderProps) => (
                    <form>
                        <input
                            type='datetime-local'
                            name='expectedOnSite'
                            placeholder=''
                            className='form-control form-control-solid'
                            id='expectedOnSite'
                            value={dueOnSite}
                            defaultValue={dueOnSite}
                            onChange={(e) => {
                                var value = e.target.value
                                setDueOnSite(value)
                            }}
                        />
                    </form>
                )}
            </SweetAlertWithProp>
            <SweetAlertWithProp
                show={dueDateSuccess}
                success
                title='Success'
                onConfirm={() => setDueDateSuccess(false)}
                showCancel={false}
            >
                Due Date has been set!
            </SweetAlertWithProp>
            <SweetAlertWithProp
                title={'Set Due Date'}
                onConfirm={() => { }}
                onCancel={() => { }}
                type={'controlled'}
                dependencies={[showDueDate]}
                show={showDueDate}
                required
                showCancel
                closeOnClickOutside={false}
                customButtons={
                    <React.Fragment>
                        <button
                            onClick={() => {
                                setDueDate(defaultDueDate)
                                setShowDueDate(false)
                            }}
                            className='btn btn-lg btn-light'
                        >
                            Cancel
                        </button>
                        <span style={{ paddingRight: '5px' }}></span>
                        {dueDate && (
                            <button
                                onClick={async () => {
                                    setDueDateLoading(true)
                                    await updateDueDate(dueDate)

                                    setDueDateLoading(false)
                                    setDueDate(dueDate)
                                    setShowDueDate(false)
                                    setDueDateSuccess(true)
                                }}
                                className='btn btn-lg btn-primary'
                                disabled={dueDateLoading}
                            >
                                Confirm
                                <span style={{ paddingRight: '3px' }}></span>
                                {dueDateLoading && (
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                )}
                            </button>
                        )}
                    </React.Fragment>
                }
            >
                {(renderProps: SweetAlertRenderProps) => (
                    <form>
                        <input
                            type='datetime-local'
                            name='dueDate'
                            placeholder=''
                            className='form-control form-control-solid'
                            id='dueDate'
                            value={dueDate}
                            defaultValue={dueDate}
                            onChange={(e) => {
                                var value = e.target.value
                                setDueDate(value)
                            }}
                        />
                    </form>
                )}
            </SweetAlertWithProp>

            <SweetAlertWithProp
                show={costSuccess}
                success
                title='Success'
                onConfirm={() => {
                    setCostSuccess(false)
                    setCostLoading(false)
                }}
                showCancel={false}
            >
                Cost Estimate has been set!
            </SweetAlertWithProp>
            <SweetAlertWithProp
                title={'Set Cost Estimate'}
                onConfirm={() => { }}
                onCancel={() => { }}
                type={'controlled'}
                dependencies={[showCost]}
                show={showCost}
                required
                showCancel
                closeOnClickOutside={false}
                customButtons={
                    <React.Fragment>
                        <button
                            onClick={() => {
                                setCostError(null)
                                setShowCost(false)
                            }}
                            className='btn btn-lg btn-light'
                        >
                            Cancel
                        </button>
                        <span style={{ paddingRight: '5px' }}></span>
                        {cost > 0 && !costError && (
                            <button
                                onClick={async () => {
                                    setCostError(null)
                                    setCostLoading(true)
                                    const res = await updateCost(cost)

                                    setCostLoading(false)
                                    if (res) {
                                        setDueDateLoading(false)
                                        setShowCost(false)
                                        setCostSuccess(true)
                                    } else {
                                    }
                                }}
                                className='btn btn-lg btn-primary'
                                disabled={costLoading}
                            >
                                Confirm
                                <span style={{ paddingRight: '3px' }}></span>
                                {costLoading && (
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                )}
                            </button>
                        )}

                        {cost > 0 && costError && (
                            <button
                                onClick={async () => {
                                    setShowCost(false)
                                    setShowQuoteModal(true)
                                }}
                                className='btn btn-lg btn-primary'
                                disabled={costLoading}
                            >
                                Confirm
                                <span style={{ paddingRight: '3px' }}></span>
                                {costLoading && (
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                )}
                            </button>
                        )}
                    </React.Fragment>
                }
            >
                {(renderProps: SweetAlertRenderProps) => (
                    <form>
                        {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner &&
                            workOrder?.dataProvider !== 'none' && (
                                <p>
                                    Cost will only be updated in MYOB if the PO is on hold. If you want to update the
                                    cost in MYOB, please put the PO on hold first or re-sync later once the PO is on
                                    hold.
                                </p>
                            )}

                        <input
                            type='number'
                            defaultValue={cost}
                            value={cost}
                            onChange={(e: any) => {
                                var value = e.target.value
                                value =
                                    value.indexOf('.') >= 0
                                        ? value.substr(0, value.indexOf('.')) + value.substr(value.indexOf('.'), 3)
                                        : value

                                setCost(value)
                            }}
                            className='form-control form-control-solid'
                            name='costEstimate'
                            data-kt-element='price'
                            disabled={costLoading}
                        />
                        {costError && <p className='text-danger text-center'>{costError}</p>}
                    </form>
                )}
            </SweetAlertWithProp>
            {workOrder?.isParent && (
                <WorkOrderProjectCostModal
                    handleClose={() => {
                        setShowProjectCostModal(false)
                    }}
                    show={showProjectCostModal}
                    parentWorkOrderId={workOrder?.id}
                    updateParentProjectCost={(sum) => {
                        if (workOrder?.isParent) setProjectCost(sum)
                    }}
                    refreshData={refreshQuotesList}
                />
            )}
        </>
    )

    const userPopover = () => {
        return (
            <Popover id='userPopover' style={{ maxWidth: '900px' }}>
                <Popover.Content>
                    <table>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>
                                <div className='fs-6'>
                                    <div className='fw-bolder mt-1'>Mobile</div>
                                    <div className='text-gray-600'>{workOrder.createdBy?.mobile}</div>
                                </div>
                            </td>
                            <td>
                                <div className='fs-6'>
                                    <div className='fw-bolder mt-1'>Email</div>
                                    <div className='text-gray-600'>{workOrder.createdBy?.email}</div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </Popover.Content>
            </Popover>
        )
    }

    const sitePopover = () => {
        return (
            <Popover id='sitePopover' style={{ minWidth: '300px' }}>
                <Popover.Content>
                    <table>
                        <tr>
                            <td>
                                <div className='fw-bolder mt-1'>Site Address</div>
                                <div className='text-gray-600'>
                                    {workOrder.site?.address1}, {workOrder.site?.city}, {workOrder.site?.country}
                                </div>
                            </td>
                        </tr>
                    </table>
                </Popover.Content>
            </Popover>
        )
    }

    const sideMenuPopOver = () => {
        return (
            <Popover
                id='sideMenuPopOver'
                style={{ minWidth: '150px', left: '-120px', top: '-10px' }}
                onClick={() => document.body.click()} //to close the popover when clicking inside
            >
                <Popover.Content style={{ padding: 0 }}>
                    <table style={{ borderCollapse: 'separate', borderSpacing: '20px' }}>
                        <tr>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setShowWorkFlowModal(true)
                                }}
                            >
                                <div className='nav-item fs-5 fw-bolder' style={{ cursor: 'pointer' }}>
                                    Work Flow
                                    {/*  ({workOrder.workOrderStats?.workFlowCount ?? 0}) */}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setShowReviewModal(true)
                                }}
                            >
                                <div className='nav-item fs-5 fw-bolder' style={{ cursor: 'pointer' }}>
                                    Reviews{/*  ({workOrder.workOrderStats?.reviewsCount ?? 0}) */}
                                </div>
                            </td>
                        </tr>
                        {permissions.includes(Permissions.MANAGE_WORKORDER_MESSAGE) && (
                            <tr>
                                <td
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setShowMessageListModal(true)
                                    }}
                                >
                                    <div className='nav-item fs-5 fw-bolder' style={{ cursor: 'pointer' }}>
                                        Messages
                                    </div>
                                </td>
                            </tr>
                        )}
                    </table>
                </Popover.Content>
            </Popover>
        )
    }

    return (
        <>
            {renderModals()}

            <div className='post d-flex flex-column-fluid' id='kt_post'>
                <div id='kt_content_container' className='container' style={{ padding: '0' }}>
                    {!loading &&
                        workOrder.workOrderId &&
                        user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                            <div className='card mb-2'>
                                <div className='card-body d-flex justify-content-between' style={{ padding: '1.5rem 2rem' }}>

                                    <div>
                                        <KTSVG
                                            path='/media/icons/duotune/abstract/abs026.svg'
                                            className='svg-icon-dark svg-icon-1'
                                        />
                                        <b style={{ paddingLeft: '5px' }}>Project Work Order:</b>{' '}
                                        <Link to={`/work-orders/details/` + workOrder?.parentWorkOrder?.id}>
                                            {`WO-${workOrder?.parentWorkOrder?.workOrderNumberId}`}
                                        </Link>
                                    </div>

                                    <div className="d-flex align-items-center">

                                        <ReadyIndicator isReady={workOrder.isReady} statusFlowId={workOrder.statusFlowId} mode="child" />
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-primary me-5'
                                            disabled={loading}
                                            onClick={async () => {
                                                setLoading(true)
                                                await WorkOrderClient.RemoveFromProject(workOrder.id)
                                                fetchData()
                                            }}
                                        >
                                            Remove from project
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    {!loading &&
                        workOrder.isParent &&
                        user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                            <div className='card mb-2'>
                                <div className='card-body' style={{ padding: '1.5rem 2rem', display: 'flex' }}>

                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <div>
                                            <KTSVG
                                                path='/media/icons/duotune/abstract/abs026.svg'
                                                className='svg-icon-dark svg-icon-1'
                                            />
                                            <b style={{ paddingLeft: '5px' }}>Project Work Order</b>

                                            {workOrder?.childrenThatHasSubmittedQuote &&
                                                workOrder.childrenThatHasSubmittedQuote.length > 0 &&
                                                user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                                                    <div style={{ paddingLeft: '5px', color: 'red' }}>
                                                        Submitted Quotes:
                                                        {workOrder.childrenThatHasSubmittedQuote.map((wo: any) => (
                                                            <Link
                                                                to={`/work-orders/details/` + wo.id}
                                                                style={{ marginLeft: '5px', color: 'red' }}
                                                            >
                                                                {`WO-${wo?.workOrderNumberId}`}
                                                            </Link>
                                                        ))}
                                                    </div>
                                                )}
                                        </div>
                                        <div>
                                            <ReadyIndicator isReady={workOrder.isReady} statusFlowId={workOrder.statusFlowId} mode="parent" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    <div className='card mb-5 mb-xxl-8'>
                        <div className='card-body pb-0'>
                            <div className='d-flex flex-wrap flex-sm-nowrap'>
                                <div className='me-5 mb-4' style={{ minWidth: '12%' }}>
                                    <div className='d-flex flex-center flex-column py-5'>
                                        <div className='symbol symbol-150px'>
                                            {imageLogo && (
                                                <img alt="logo"
                                                    className='h-75 align-self-end pb-2'
                                                    src={imageLogo}
                                                    onError={({ currentTarget }) => {
                                                        setImageLogo('')
                                                    }}
                                                ></img>
                                            )}
                                        </div>
                                        {(user.id === workOrder?.createdById ||
                                            user.tenantId === workOrder?.createdBy?.tenantId ||
                                            user.tenantId === workOrder?.tenantId ||
                                            user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner) && (
                                                <Link
                                                    className='btn btn-sm btn-light-primary'
                                                    to={`/work-orders/edit/${params.id}`}
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr075.svg'
                                                        className='svg-icon-3'
                                                    />
                                                    Edit
                                                </Link>
                                            )}

                                        {workOrder.department && (
                                            <div className='fs-6'>
                                                <div className='fw-bolder mt-5'>Department</div>
                                                <div className='text-gray-600'>{workOrder?.department?.name}</div>
                                            </div>
                                        )}
                                        {workOrder.ocManaged && (
                                            <span className='badge badge-info' style={{ marginTop: '5px' }}>
                                                OC Managed
                                            </span>
                                        )}
                                        <div className='fs-6'>
                                            <div className='fw-bolder mt-5'>Client</div>
                                            <div className='text-gray-600'>{workOrder.tenant?.name}</div>
                                            {permissions.includes(Permissions.MANAGE_WORKORDER_MESSAGE) && (
                                                <div className='mt-2'>
                                                    <button
                                                        className='btn btn-sm btn-light-primary'
                                                        onClick={() => {
                                                            setShowMessageModal(true)
                                                        }}
                                                    >
                                                        <KTSVG
                                                            path='/media/icons/duotune/communication/com010.svg'
                                                            className='svg-icon-3'
                                                        />
                                                        <span className='ml-1'>Message</span>
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='flex-grow-1'>
                                    <div className='d-flex justify-content-between align-items-start flex-wrap'>
                                        <div className='d-flex'>
                                            {!loading && (
                                                <span className='text-gray-900 fw-bolder'>
                                                    <span style={{ paddingRight: '5px', fontSize: '2rem' }}>
                                                        {`WO-${workOrder.workOrderNumberId}`}
                                                    </span>

                                                    {workOrder.assignedToTenant && (
                                                        <>
                                                            <span style={{ paddingRight: '10px', fontSize: '1.5rem' }}>
                                                                {`: ${workOrder.assignedToTenant?.name}`}{' '}
                                                                {workOrder.workOrderStatus?.name?.toLowerCase() !== 'cancelled' &&
                                                                    workOrder.workOrderStatus?.name?.toLowerCase() !== 'complete' &&
                                                                    workOrder.workOrderStatus?.name?.toLowerCase() !== 'pending' &&
                                                                    !workOrder.hasActiveCheckIns &&
                                                                    user.tenant?.tenantTypeId?.toUpperCase() ===
                                                                    TenantTypeIds.Owner && (
                                                                        <span
                                                                            style={{ cursor: 'pointer' }}
                                                                            data-bs-toggle='modal'
                                                                            data-bs-target='#wo_assign_to_modal'
                                                                        >
                                                                            <KTSVG
                                                                                path='/media/icons/duotune/general/gen055.svg'
                                                                                className='svg-icon-3'
                                                                            />
                                                                        </span>
                                                                    )}
                                                            </span>

                                                            {supplierStatusLoading && <span className="me-2"><Spinner
                                                                as='span'
                                                                animation='border'
                                                                size='sm'
                                                                role='status'
                                                                aria-hidden='true'
                                                            /></span>}

                                                            {!supplierStatusLoading && supplierStatus.isAvailableForUse && (
                                                                <OverlayTrigger placement='bottom' overlay={<Tooltip id="supplier-good">Supplier set up correctly & active</Tooltip>}>
                                                                    <i className="bi bi-check2-circle me-2" style={{ color: "green" }}></i>
                                                                </OverlayTrigger>
                                                            )}

                                                            {!supplierStatusLoading && !supplierStatus.isAvailableForUse && (
                                                                <OverlayTrigger placement='bottom' overlay={<Tooltip id="supplier-bad">{supplierStatus.message}</Tooltip>}>
                                                                    <i className="bi bi-slash-circle-fill me-2" style={{ color: "red" }}></i>
                                                                </OverlayTrigger>
                                                            )}


                                                            {!workOrder.isParent &&
                                                                workOrder.assignedToTenantId &&
                                                                !workOrder.costEstimate &&
                                                                user.tenant?.tenantTypeId?.toUpperCase() !==
                                                                TenantTypeIds.Client && (
                                                                    <button
                                                                        type='button'
                                                                        className='btn btn-sm btn-primary me-5 ms-2'
                                                                        disabled={loading}
                                                                        onClick={() => {
                                                                            if (workOrder.workOrderId || workOrder.isParent) {
                                                                                setShowQuoteModal(true)
                                                                            } else {
                                                                                if (workOrder.workScope?.name?.toLowerCase() === 'minor') {
                                                                                    setCostError(null)
                                                                                    setCost(0)
                                                                                    setShowCost(true)
                                                                                    if (workOrder.costEstimate)
                                                                                        setCost(workOrder.costEstimate)
                                                                                } else if (
                                                                                    workOrder.workScope?.name?.toLowerCase() === 'major'
                                                                                ) {
                                                                                    setShowQuoteModal(true)
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        Add Cost
                                                                    </button>
                                                                )}

                                                        </>
                                                    )}

                                                    {workOrder.hasActiveCheckIns && (
                                                        <span
                                                            className='badge badge-success'
                                                            style={{
                                                                verticalAlign: 'super',
                                                                boxShadow: '0px 0px 20px 1px darkturquoise',
                                                            }}
                                                        >
                                                            Active Check In
                                                        </span>
                                                    )}
                                                </span>
                                            )}
                                            {loading && (
                                                <div className='d-flex align-items-center'>
                                                    <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                                                </div>
                                            )}
                                        </div>
                                        <div className='d-flex'>
                                            {user.tenant?.tenantTypeId?.toUpperCase() !==
                                                TenantTypeIds.OperationalClient &&
                                                workOrder.ocManaged === false &&
                                                !workOrder.assignedToTenantId &&
                                                workOrder.workOrderStatus?.name?.toLowerCase() !== 'pending' &&
                                                user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                                                    <button
                                                        type='button'
                                                        className='btn btn-sm btn-primary me-5'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#wo_assign_to_modal'
                                                        disabled={loading}
                                                    >
                                                        <KTSVG
                                                            path='/media/icons/duotune/ecommerce/ecm004.svg'
                                                            className='svg-icon-3'
                                                        />
                                                        Assign to
                                                    </button>
                                                )}

                                            {user.tenant?.tenantTypeId?.toUpperCase() ===
                                                TenantTypeIds.OperationalClient &&
                                                workOrder.ocManaged === true &&
                                                !workOrder.assignedToTenantId &&
                                                workOrder.workOrderStatus?.name?.toLowerCase() !== 'pending' &&
                                                user.tenantId === workOrder?.tenantId && (
                                                    <button
                                                        type='button'
                                                        className='btn btn-sm btn-primary me-5'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#wo_assign_to_modal'
                                                        disabled={loading}
                                                    >
                                                        <KTSVG
                                                            path='/media/icons/duotune/ecommerce/ecm004.svg'
                                                            className='svg-icon-3'
                                                        />
                                                        Assign to
                                                    </button>
                                                )}
                                            {onChangeFlag && (
                                                <button
                                                    onClick={() => UpdateWorkOrderStatus()}
                                                    type='button'
                                                    className='btn btn-sm btn-primary me-5'
                                                >
                                                    Save
                                                </button>
                                            )}

                                            {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && workOrder.canBecomeProject && (
                                                <button
                                                    type='button'
                                                    className='btn btn-sm btn-primary me-5'
                                                    disabled={loading}
                                                    onClick={async () => {
                                                        setLoading(true)
                                                        await WorkOrderClient.SetParent(workOrder.id)
                                                        fetchData()
                                                    }}
                                                >
                                                    Create project
                                                </button>
                                            )}

                                            {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && workOrder.canAssignToProject && (
                                                <button
                                                    type='button'
                                                    className='btn btn-sm btn-primary me-5'
                                                    disabled={loading}
                                                    onClick={async () => {
                                                        setShowAssignToProjectModal(true);
                                                    }}
                                                >
                                                    Assign to project
                                                </button>
                                            )}

                                            <WorkOrderStatusUpdateSelector
                                                workOrderId={workOrder.id}
                                                defaultValue={{
                                                    value: workOrder.workOrderStatusId,
                                                    label: workOrder.workOrderStatus?.name,
                                                }}
                                                disabled={loading}
                                                onChange={async (value) => {
                                                    setOnChangeFlag(true)
                                                    await UpdateStatus(value)
                                                }}
                                                statusFlowId={workOrder.statusFlowId}
                                            />

                                            <OverlayTrigger
                                                rootClose
                                                overlay={<Tooltip id='sideMenu-tooltip'>{sideMenuPopOver()}</Tooltip>}
                                                placement='left'
                                                trigger='click'
                                            >
                                                <div
                                                    style={{
                                                        marginLeft: '5px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen053.svg'
                                                        className='svg-icon-1 svg-icon-gray-600'
                                                    />
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>

                                    <table>
                                        <tr>
                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>
                                                        Site
                                                        <OverlayTrigger
                                                            overlay={<Tooltip id='user-tooltip'>{sitePopover()}</Tooltip>}
                                                            placement='right'
                                                            trigger='hover'
                                                        >
                                                            <span className='d-inline-block' style={{ paddingLeft: '5px' }}>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/general/gen045.svg'
                                                                    className='svg-icon-3'
                                                                />
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className='text-gray-600'>{workOrder.site?.name}</div>
                                                </div>
                                            </td>
                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>Type</div>
                                                    <div className='text-gray-600'>{workOrder.workOrderType?.name}</div>
                                                </div>
                                            </td>
                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>Category</div>
                                                    <div className='text-gray-600'>{workOrder.workOrderCategory?.name}</div>
                                                </div>
                                            </td>
                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>Status</div>
                                                    <div className='text-gray-600'>
                                                        {onChangeFlag && prevWorkORderStatus?.name}
                                                        {!onChangeFlag && workOrder?.workOrderStatus?.name}
                                                    </div>
                                                </div>
                                            </td>

                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>
                                                        Work Scope
                                                        {categoryWorkScope && (
                                                            <OverlayTrigger
                                                                overlay={
                                                                    <Tooltip id='tooltip-disabled'>
                                                                        {categoryWorkScope?.description}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span className='d-inline-block' style={{ paddingLeft: '5px' }}>
                                                                    <KTSVG
                                                                        path='/media/icons/duotune/general/gen045.svg'
                                                                        className='svg-icon-3'
                                                                    />
                                                                </span>
                                                            </OverlayTrigger>
                                                        )}
                                                    </div>
                                                    <div className='text-gray-600'>{workOrder.workScope?.name}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>Date Created</div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.created && (
                                                            <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                                                {workOrder.created}
                                                            </Moment>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>

                                            <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>
                                                        Due Date
                                                        {user.tenant?.tenantTypeId?.toUpperCase() !==
                                                            TenantTypeIds.Contractor && (
                                                                <label htmlFor='expectedOnSite'>
                                                                    <span
                                                                        className='d-inline-block'
                                                                        style={{ paddingLeft: '5px', cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            setShowDueDate(true)
                                                                        }}
                                                                    >
                                                                        <KTSVG
                                                                            path='/media/icons/duotune/general/gen014.svg'
                                                                            className='svg-icon-3'
                                                                        />
                                                                    </span>
                                                                </label>
                                                            )}
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.dueDate && (
                                                            <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                                                {workOrder.dueDate}
                                                            </Moment>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>
                                                        Date on Site{' '}
                                                        <label htmlFor='expectedOnSite'>
                                                            <span
                                                                className='d-inline-block'
                                                                style={{ paddingLeft: '5px', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    setShowDueOnSite(true)
                                                                }}
                                                            >
                                                                <KTSVG
                                                                    path='/media/icons/duotune/general/gen014.svg'
                                                                    className='svg-icon-3'
                                                                />
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.expectedOnSite && (
                                                            <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                                                {workOrder.expectedOnSite}
                                                            </Moment>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>

                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>Priority</div>
                                                    <div className='text-gray-600'>{workOrder?.priority?.name}</div>
                                                </div>
                                            </td>

                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>
                                                        <span
                                                            style={
                                                                !workOrder.cost &&
                                                                    workOrder.workScope?.name?.toLowerCase() === 'minor'
                                                                    ? { color: 'red' }
                                                                    : {}
                                                            }
                                                        >
                                                            Cost (excl gst)
                                                        </span>
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.cost ? toMoneyFormat(workOrder.cost) : '$'}
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ paddingRight: '20px' }}>
                                                {workOrder.isParent &&
                                                    user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                                                        <div className='fs-6'>
                                                            <div className='fw-bolder mt-5'>
                                                                <span>Project Cost (excl gst)</span>
                                                            </div>
                                                            <div
                                                                style={
                                                                    workOrder.isParent &&
                                                                        workOrder?.childrenThatHasSubmittedQuote &&
                                                                        workOrder.childrenThatHasSubmittedQuote.length > 0 &&
                                                                        user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner
                                                                        ? { color: 'red', cursor: 'pointer' }
                                                                        : { color: '#7e8299' }
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        workOrder?.isParent &&
                                                                        user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner
                                                                    ) {
                                                                        setShowProjectCostModal(true)
                                                                    }
                                                                }}
                                                            >
                                                                {projectCost ? toMoneyFormat(projectCost) : '$'}
                                                            </div>
                                                        </div>
                                                    )}
                                            </td>
                                        </tr>

                                        <tr>
                                            {user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Client && (
                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>Contractor PO</div>
                                                        <div className='text-gray-600'>{workOrder?.statusFlow?.myobStatusFlowType === "purchaseOrder" && !workOrder.isParent && workOrder?.purchaseOrder}</div>
                                                        <div className='text-gray-600'>{(workOrder?.statusFlow?.myobStatusFlowType !== "purchaseOrder" || workOrder.isParent) && <i>n/a</i>}</div>
                                                    </div>
                                                </td>
                                            )}
                                            {user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>Client PO </div>
                                                        <div className='text-gray-600'>{workOrder?.statusFlow?.myobStatusFlowType === "purchaseOrder" && workOrder?.suppliedPurchaseOrder}</div>
                                                        <div className='text-gray-600'>{workOrder?.statusFlow?.myobStatusFlowType !== "purchaseOrder" && <i>n/a</i>}</div>
                                                    </div>
                                                </td>
                                            )}
                                            {user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>Sales Order </div>
                                                        <div className='text-gray-600'>{!workOrder.isParent && workOrder?.statusFlow?.myobStatusFlowType === "salesOrder" && workOrder?.salesOrder}</div>
                                                        <div className='text-gray-600'>{(workOrder.isParent || workOrder?.statusFlow?.myobStatusFlowType !== "salesOrder") && <i>n/a</i>}</div>
                                                    </div>
                                                </td>
                                            )}

                                            {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>MYOB PJ </div> <span> {!externalId?.source && <i>Unknown</i>}</span>
                                                        <span> {externalId?.source && <b>{externalId?.externalProjectId} ({sourceFriendlyName(externalId?.source)})</b>} {!externalId.isValid && externalId?.source !== "none" && "Warning Project Id is not valid and will not sync to MYOB"}</span>
                                                    </div>
                                                </td>
                                            )}
                                            {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>Manufacturing Multiplier</div>
                                                        <div className='text-gray-600'>{!workOrder.isParent && workOrder?.statusFlow?.myobStatusFlowType === "salesOrder" && (workOrder?.manufacturingPercentageFee ? (1 + (workOrder?.manufacturingPercentageFee / 100)) : 1.55) + " (" + ((1 - (1 / (1 + ((workOrder?.manufacturingPercentageFee ?? 55) / 100)))) * 100).toFixed(2) + "% margin)"}</div>
                                                        <div className='text-gray-600'>{(workOrder.isParent || workOrder?.statusFlow?.myobStatusFlowType !== "salesOrder") && <i>n/a</i>}</div>
                                                    </div>
                                                </td>
                                            )}
                                            {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>Shipping Multiplier</div>
                                                        <div className='text-gray-600'>{!workOrder.isParent && workOrder?.statusFlow?.myobStatusFlowType === "salesOrder" && (workOrder?.shippingPercentageFee ? (1 + (workOrder?.shippingPercentageFee / 100)) : 1.55) + " (" + ((1 - (1 / (1 + ((workOrder?.shippingPercentageFee ?? 15) / 100)))) * 100).toFixed(2) + "% margin)"}</div>
                                                        <div className='text-gray-600'>{(workOrder.isParent || workOrder?.statusFlow?.myobStatusFlowType !== "salesOrder") && <i>n/a</i>}</div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>

                                        <tr>
                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>
                                                        Created By{' '}
                                                        <OverlayTrigger
                                                            overlay={<Tooltip id='user-tooltip'>{userPopover()}</Tooltip>}
                                                            placement='right'
                                                            trigger='hover'
                                                        >
                                                            <span className='d-inline-block' style={{ paddingLeft: '5px' }}>
                                                                <KTSVG
                                                                    path='/media/icons/duotune/general/gen045.svg'
                                                                    className='svg-icon-3'
                                                                />
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.createdBy?.name} {workOrder.createdBy?.surname} -{' '}
                                                        {workOrder.createdBy?.tenant?.name}
                                                    </div>
                                                </div>
                                            </td>

                                            <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>
                                                        Shipping
                                                    </div>
                                                    {workOrder?.statusFlow?.myobStatusFlowType === "salesOrder" && <div className='text-gray-600'>
                                                        {workOrder.shippingType && workOrder.shippingType}
                                                        {!workOrder.shippingType && "Other"}
                                                    </div>}
                                                    {workOrder?.statusFlow?.myobStatusFlowType !== "salesOrder" && <div className='text-gray-600'>
                                                        n/a
                                                    </div>}
                                                </div>
                                            </td>

                                            {workOrder?.statusFlow?.myobStatusFlowType === "salesOrder" && <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>
                                                        Address
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.addressLine1}
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.addressLine2}
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.city} {workOrder.postalCode}
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.country}
                                                    </div>
                                                </div>
                                            </td>}

                                            {workOrder?.statusFlow?.myobStatusFlowType === "salesOrder" && <td style={{ paddingRight: '20px' }}>
                                                <div className='fs-6'>
                                                    <div className='fw-bolder mt-5'>
                                                        Contact
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.shipToContact}
                                                    </div>
                                                    <div className='text-gray-600'>
                                                        {workOrder.shipToContactEmail}
                                                    </div>
                                                </div>
                                            </td>}

                                            {workOrder.onBehalfOfTenantId && (
                                                <td style={{ paddingRight: '20px' }}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>Created On Behalf Of</div>
                                                        <div className='text-gray-600'>{workOrder.onBehalfOfTenant?.name}</div>
                                                    </div>
                                                </td>
                                            )}
                                            {workOrder.schedule && (
                                                <td style={{ paddingRight: '20px' }}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>Schedule</div>
                                                        <div className='text-gray-600'>
                                                            <Link to={`/schedules/details/${workOrder.schedule?.id}`}>
                                                                {workOrder.schedule?.name}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                            {workOrder.completeDate && (
                                                <td style={{ paddingRight: '20px' }}>
                                                    <div className='fs-6'>
                                                        <div className='fw-bolder mt-5'>Completed Date</div>
                                                        <div className='text-gray-600'>
                                                            <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                                                {workOrder.completeDate}
                                                            </Moment>
                                                        </div>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    </table>

                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <td>
                                                <div className='fw-bolder mt-5'>Description</div>

                                                <textarea
                                                    name='description'
                                                    placeholder=''
                                                    className='form-control form-control-solid'
                                                    id='description'
                                                    disabled
                                                    rows={3}
                                                    style={{ width: '100%' }}
                                                    defaultValue={workOrder.description}
                                                ></textarea>
                                            </td>
                                        </tr>
                                        {user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Client && (
                                            <tr>
                                                <td>
                                                    <div className='fw-bolder mt-5'>Contractor Notes</div>

                                                    <textarea
                                                        name='contractorNotes'
                                                        placeholder=''
                                                        className='form-control form-control-solid'
                                                        id='contractorNotes'
                                                        disabled
                                                        rows={3}
                                                        style={{ width: '100%' }}
                                                        defaultValue={workOrder.contractorNotes}
                                                    ></textarea>
                                                </td>
                                            </tr>
                                        )}
                                    </table>

                                    <table style={{ width: '100%' }}>
                                        <tr>
                                            <td>
                                                <div className='fw-bolder mt-5'>WO Contact Name</div>

                                                <input
                                                    type='text'
                                                    name='contact'
                                                    placeholder=''
                                                    className='form-control form-control-solid'
                                                    id='contact'
                                                    disabled
                                                    defaultValue={workOrder.contact}
                                                />
                                            </td>
                                            <td>
                                                <div className='fw-bolder mt-5'>WO Contact Number</div>

                                                <input
                                                    type='text'
                                                    name='contactNumber'
                                                    placeholder=''
                                                    className='form-control form-control-solid'
                                                    id='contactNumber'
                                                    disabled
                                                    defaultValue={workOrder.contactNumber}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='fw-bolder mt-5'>Site Contact Name</div>

                                                <input
                                                    type='text'
                                                    name='siteContact'
                                                    placeholder=''
                                                    className='form-control form-control-solid'
                                                    id='siteContact'
                                                    disabled
                                                    defaultValue={workOrder.siteContact}
                                                />
                                            </td>
                                            <td>
                                                <div className='fw-bolder mt-5'>Site Contact Number</div>

                                                <input
                                                    type='text'
                                                    name='siteContactNumber'
                                                    placeholder=''
                                                    className='form-control form-control-solid'
                                                    id='siteContactNumber'
                                                    disabled
                                                    defaultValue={workOrder.siteContactNumber}
                                                />
                                            </td>
                                        </tr>
                                        {workOrder.onBehalfOfTenantId && (
                                            <tr>
                                                <td>
                                                    <div className='fw-bolder mt-5'>Client Contact</div>

                                                    <input
                                                        type='text'
                                                        name='clientContact'
                                                        placeholder=''
                                                        className='form-control form-control-solid'
                                                        id='clientContact'
                                                        disabled
                                                        defaultValue={workOrder.clientContact}
                                                    />
                                                </td>
                                                <td></td>
                                            </tr>
                                        )}
                                    </table>

                                    {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                                        <div style={{ marginTop: '40px', marginBottom: '20px' }}>
                                            <h5>Integration Settings</h5>
                                            <table>
                                                <tr>
                                                    <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                        <div className='fs-6'>
                                                            <div className='fw-bolder mt-5'>Sync Disabled</div>
                                                            <div className='text-gray-600'>
                                                                <input
                                                                    type='checkbox'
                                                                    name='externalSyncDisabled'
                                                                    value='True'
                                                                    checked={workOrder?.localOnly}
                                                                    onClick={async () => {
                                                                        await updateLocalOnly(!workOrder.localOnly)
                                                                        await fetchData()
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                        <div className='fs-6'>
                                                            <div className='fw-bolder mt-5'>Data provider</div>
                                                            <div className='text-gray-600'>
                                                                <span className='badge badge-light-success'>
                                                                    {workOrder?.dataProvider === 'none' ? 'RD Maintain' : 'MYOB'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    {!workOrder.localOnly && (
                                                        <>
                                                            {workOrder.externalMYOBProjectTaskId && (
                                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                                    <div className='fs-6'>
                                                                        <div className='fw-bolder mt-5'>Project Task ID</div>
                                                                        <div className='text-gray-600'>Created</div>
                                                                    </div>
                                                                </td>
                                                            )}

                                                            {workOrder.externalMYOBProjectRevenueBudgetId && (
                                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                                    <div className='fs-6'>
                                                                        <div className='fw-bolder mt-5'>Revenue Budget ID</div>
                                                                        <div className='text-gray-600'>Created</div>
                                                                    </div>
                                                                </td>
                                                            )}

                                                            {workOrder.externalMYOBProjectCostBudgetId && (
                                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                                    <div className='fs-6'>
                                                                        <div className='fw-bolder mt-5'>Revenue Cost ID</div>
                                                                        <div className='text-gray-600'>Created</div>
                                                                    </div>
                                                                </td>
                                                            )}

                                                            {workOrder.externalMYOBProjectPOId && (
                                                                <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                                    <div className='fs-6'>
                                                                        <div className='fw-bolder mt-5'>Project PO ID</div>
                                                                        <div className='text-gray-600'>Created</div>
                                                                    </div>
                                                                </td>
                                                            )}

                                                            {workOrder.ownerForMyobUser && (<td style={{ paddingRight: '20px', verticalAlign: 'top' }}>
                                                                <div className='fs-6'>
                                                                    <div className='fw-bolder mt-5'>Owner</div>
                                                                    <div className='text-gray-600'>{workOrder.ownerForMyobUser.name}</div>
                                                                </div>
                                                            </td>)}

                                                            <td>
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-sm btn-primary me-5'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-target='#wo_sync_to_myob_modal'
                                                                    disabled={loading}
                                                                    onClick={() => setSyncToMYOB(true)}
                                                                >
                                                                    Sync to MYOB
                                                                </button>
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            </table>

                                            <table style={{ width: '100%' }}>
                                                <tr>
                                                    <td>
                                                        <div className='fw-bolder mt-5'>Invoice Note (MYOB)</div>

                                                        <textarea
                                                            name='invoiceNote'
                                                            placeholder=''
                                                            className='form-control form-control-solid'
                                                            id='invoiceNote'
                                                            disabled
                                                            rows={3}
                                                            style={{ width: '100%' }}
                                                            defaultValue={workOrder.invoiceNote}
                                                        ></textarea>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <ul
                                className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder'
                                style={loading ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
                            >
                                <li
                                    className='nav-item mt-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch(Actions.setFilter({ ...workorder.filter, selectedTab: NavItems.Notes }))
                                    }}
                                >
                                    <span
                                        className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.Notes ? 'active' : ''
                                            }`}
                                    >
                                        Notes ({workOrder.workOrderStats?.notesCount ?? 0})
                                    </span>
                                </li>
                                <li
                                    className='nav-item mt-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch(
                                            Actions.setFilter({ ...workorder.filter, selectedTab: NavItems.Attachments })
                                        )
                                    }}
                                >
                                    <span
                                        className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.Attachments ? 'active' : ''
                                            }`}
                                    >
                                        Files ({workOrder.workOrderStats?.attachmentsCount ?? 0})
                                    </span>
                                </li>
                                <li
                                    className='nav-item mt-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch(
                                            Actions.setFilter({ ...workorder.filter, selectedTab: NavItems.Notices })
                                        )
                                    }}
                                >
                                    <span
                                        className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.Notices ? 'active' : ''
                                            }`}
                                    >
                                        Notices ({workOrder.workOrderStats?.noticesCount ?? 0})
                                    </span>
                                </li>
                                <li
                                    className='nav-item mt-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch(
                                            Actions.setFilter({ ...workorder.filter, selectedTab: NavItems.CheckLists })
                                        )
                                    }}
                                >
                                    <span
                                        className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.CheckLists ? 'active' : ''
                                            }`}
                                    >
                                        Check Lists ({workOrder.workOrderStats?.checkListCount ?? 0})
                                    </span>
                                </li>
                                <li
                                    className='nav-item mt-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch(
                                            Actions.setFilter({ ...workorder.filter, selectedTab: NavItems.Participants })
                                        )
                                    }}
                                >
                                    <span
                                        className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.Participants ? 'active' : ''
                                            }`}
                                    >
                                        Participants ({workOrder.workOrderStats?.participantsCount ?? 0})
                                    </span>
                                </li>
                                <li
                                    className='nav-item mt-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch(
                                            Actions.setFilter({ ...workorder.filter, selectedTab: NavItems.CheckIns })
                                        )
                                    }}
                                >
                                    <span
                                        className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.CheckIns ? 'active' : ''
                                            }`}
                                    >
                                        Check Ins ({workOrder.workOrderStats?.checkInsCount ?? 0})
                                    </span>
                                </li>
                                <li
                                    className='nav-item mt-2'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        dispatch(Actions.setFilter({ ...workorder.filter, selectedTab: NavItems.Quotes }))
                                    }}
                                >
                                    <span
                                        className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.Quotes ? 'active' : ''
                                            }`}
                                    >
                                        Quotes ({workOrder.workOrderStats?.quotesCount ?? 0})
                                    </span>
                                </li>
                                {user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Client &&
                                    user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                                        <li
                                            className='nav-item mt-2'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                dispatch(
                                                    Actions.setFilter({ ...workorder.filter, selectedTab: NavItems.Charges })
                                                )
                                            }}
                                        >
                                            <span
                                                className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.Charges ? 'active' : ''
                                                    }`}
                                            >
                                                Charges ({workOrder.workOrderStats?.chargesCount ?? 0})
                                            </span>
                                        </li>
                                    )}
                                {/* <li
                  className='nav-item mt-2'
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    dispatch(
                      Actions.setFilter({
                        ...workorder.filter,
                        selectedTab: NavItems.WorkOrderHistoryList,
                      })
                    )
                  }}
                >
                  <span
                    className={`nav-link text-active-primary ms-0 me-5 py-5 ${
                      selectedTab === NavItems.WorkOrderHistoryList ? 'active' : ''
                    }`}
                  >
                    Work Flow ({workOrder.workOrderStats?.workFlowCount ?? 0})
                  </span>
                </li> */}
                                {workOrder.isParent &&
                                    user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                                        <li
                                            className='nav-item mt-2'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                dispatch(
                                                    Actions.setFilter({
                                                        ...workorder.filter,
                                                        selectedTab: NavItems.Children,
                                                    })
                                                )
                                            }}
                                        >
                                            <span
                                                className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.Children ? 'active' : ''
                                                    }`}
                                            >
                                                Project ({workOrder.childrenWorkOrder?.length ?? 0})
                                            </span>
                                        </li>
                                    )}{' '}
                                {permissions?.includes('asset.view.all') && (
                                    <li
                                        className='nav-item mt-2'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            dispatch(
                                                Actions.setFilter({
                                                    ...workorder.filter,
                                                    selectedTab: NavItems.Assets,
                                                })
                                            )
                                        }}
                                    >
                                        <span
                                            className={`nav-link text-active-primary ms-0 me-5 py-5 ${selectedTab === NavItems.Assets ? 'active' : ''
                                                }`}
                                        >
                                            Assets ({workOrder.assets?.length ?? 0})
                                        </span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <Toaster
                        position='top-right'
                        toastOptions={{
                            className: '',
                            style: {
                                color: '#009ef7',
                                background: '#eff2f5',
                                maxHeight: '100px',
                                overflow: 'scroll',
                            },
                        }}
                    />
                    <div className='row g-5 g-xxl-8'>
                        <div className='col-xl-12'>{renderItems()}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(({ workorder }: RootState) => ({
    workorder,
}))(WorkOrderDetails)
