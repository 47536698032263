import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'
import { GetAPIDomain } from '../Helper'

export class SiteQueryParams extends ListQueryParams {
    WithUserId(value: string) {
        this.WithParam('userId', value)
        return this
    }
    WithSiteTypeId(value: string) {
        this.WithParam('siteTypeId', value)
        return this
    }
    WithEnabled(value: boolean) {
        this.WithParam('enabled', value)
        return this
    }
    WithTenantId(value: string) {
        this.WithParam('tenantId', value)
        return this
    }
    WithContractId(value: string) {
        this.WithParam('contractId', value)
        return this
    }
}

export class SitesClient {
    static async List(params: SiteQueryParams) {
        return await ApiClient.Get('/sites' + params.GenerateQueryString());
    }

    static async CheckLists(id: string) {
        return await ApiClient.Get('/sites/' + id + '/checklist-templates');
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/sites/' + id);
    }

    static async Get(id: string) {
        return await ApiClient.Get('/sites/' + id);
    }

    static async BulkCreate(file: any, tenantId: string, siteTypeId: string, enabled: boolean) {
        let formData = new FormData()
        formData.append('file', file)
        formData.append('tenantId', tenantId)
        formData.append('siteTypeId', siteTypeId)
        formData.append('enabled', enabled ? 'yes' : 'no')

        var accessToken = localStorage.getItem('acess-token')

        const response = await fetch((await GetAPIDomain()) + '/sites/bulk', {
            method: 'post',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
            body: formData,
        })

        return await response.json()
    }

    static async Create(
        name: string,
        latitude: string,
        longitude: string,
        placeId: string,
        address1: string,
        address2: string,
        address3: string,
        city: string,
        state: string,
        country: string,
        postcode: string,
        enabled: boolean,
        siteTypeId?: string,
        tenantId?: string
    ) {

        return await ApiClient.Post('/sites/', {
            name: name,
            latitude: latitude,
            longitude: longitude,
            placeId: placeId,
            address1: address1,
            address2: address2,
            address3: address3,
            city: city,
            state: state,
            country: country,
            postcode: postcode,
            enabled: enabled,
            siteTypeId: siteTypeId,
            tenantId: tenantId,
        });
    }

    static async Update(
        id: string,
        name: string,
        latitude: string,
        longitude: string,
        placeId: string,
        address1: string,
        address2: string,
        address3: string,
        city: string,
        state: string,
        country: string,
        postcode: string,
        enabled: boolean,
        siteTypeId?: string,
        tenantId?: string
    ) {

        return await ApiClient.Patch('/sites/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'latitude',
                value: latitude,
            },
            {
                op: 'replace',
                path: 'longitude',
                value: longitude,
            },
            {
                op: 'replace',
                path: 'placeId',
                value: placeId,
            },
            {
                op: 'replace',
                path: 'address1',
                value: address1,
            },
            {
                op: 'replace',
                path: 'address2',
                value: address2,
            },
            {
                op: 'replace',
                path: 'address3',
                value: address3,
            },
            {
                op: 'replace',
                path: 'city',
                value: city,
            },
            {
                op: 'replace',
                path: 'state',
                value: state,
            },
            {
                op: 'replace',
                path: 'country',
                value: country,
            },
            {
                op: 'replace',
                path: 'postcode',
                value: postcode,
            },
            {
                op: 'replace',
                path: 'enabled',
                value: enabled,
            },
            {
                op: 'replace',
                path: 'siteTypeId',
                value: siteTypeId,
            },
            {
                op: 'replace',
                path: 'tenantId',
                value: tenantId,
            },
        ]);
    }
}
