import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserQueryParams, UsersClient} from '../../../infrastracture/api/UsersClient'
import {MessageQueryParams, MessagesClient} from '../../../infrastracture/api/MessagesClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import MessagesModal from './MessagesModal'
import Moment from 'react-moment'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
const MessagesBreadCrumbs: Array<PageLink> = []

interface Props {
  workOrderId?: string
}
const MessagesList: React.FC<Props> = (props) => {
  const {workOrderId} = props

  const [Messages, setMessages] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState('')
  const [viewOnly, setViewOnly] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'created',
    direction: 'asc',
  })

  const [loading, setLoading] = useState(false)

  const permissions: any = useSelector<RootState>(
    ({auth}) => auth.userPermission,
    shallowEqual
  ) as any

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  async function fetchData() {
    setLoading(true)
    try {
      var query = new MessageQueryParams()

      if (workOrderId) query.WithWorkOrderId(workOrderId)

      var response = await MessagesClient.List(
        query
          .Paginate(pagination.skip, pagination.take)
          .Search(search)
          .Sort(pagination.sort, pagination.direction)
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setMessages(data?.data)

        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const renderModals = () => {
    return (
      <MessagesModal
        viewOnly={viewOnly}
        mode={mode}
        selectedData={selectedData}
        handleOk={() => fetchData()}
      />
    )
  }

  return (
    <>
      {renderModals()}
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
              </span>
              <input
                type='text'
                data-kt-notice-type-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Messages'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                onKeyPress={(e) => e.key === 'Enter' && fetchData()}
              />
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <span className='badge badge-square badge-primary me-3'>{pagination.totalRecords}</span>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {/* <button
                type='button'
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#messages_modal'
                onMouseOver={() => {
                  setMode('Add')
                  setViewOnly(false)
                }}
                onClick={() => {
                  setMode('Add')
                  setViewOnly(false)
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Message
              </button> */}
              {permissions?.includes('message.modify') && !workOrderId && (
                <Link className='btn btn-sm btn-light-primary me-3' to={`/messages/add`}>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  Add Message
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'Title', sort: 'title', width: '15'},
                  {name: 'Created By', sort: 'createdBy.Name', width: '10'},
                  {name: 'Created', sort: 'created', width: '10'},
                  {name: 'Scheduled', sort: 'scheduled', width: '10'},
                  {name: 'Recipients', static: true, width: '10'},
                  {name: '', sort: '', static: true, width: '10'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    Messages.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.title && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>{item.title}</a>
                          )}
                          {!item.title && <i>Not specified</i>}
                        </td>

                        <td>
                          {item.createdBy && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.createdBy && item.createdBy.name + ' ' + item.createdBy.surname}
                            </a>
                          )}
                          {!item.createdBy && <i>Not specified</i>}
                        </td>
                        <td>
                          <a className='text-dark fw-bolder  d-block mb-1 fs-6'>
                            {item.created && (
                              <Moment utc local format='DD MMM, YYYY h:mm a'>
                                {item.created}
                              </Moment>
                            )}
                          </a>
                        </td>
                        <td>
                          <a className='text-dark fw-bolder  d-block mb-1 fs-6'>
                            {item.scheduled && (
                              <Moment utc local format='DD MMM, YYYY h:mm a'>
                                {item.scheduled}
                              </Moment>
                            )}
                          </a>
                        </td>
                        <td>
                          <a className='text-dark fw-bolder  d-block mb-1 fs-6'>
                            {item.recipients && item.recipients.length}
                          </a>
                        </td>
                        <td className='text-end'>
                          {/* <button
                            type='button'
                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            data-bs-toggle='modal'
                            data-bs-target='#messages_modal'
                            onMouseOver={() => {
                              setSelectedData(item)
                              setViewOnly(false)
                              setMode('Edit')
                            }}
                            onClick={() => {
                              setSelectedData(item)
                              setViewOnly(false)
                              setMode('Edit')
                            }}
                          >
                            Edit
                          </button> */}
                          {permissions?.includes('messages.view.all') && (
                            <Link
                              className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                              to={`/messages/details/${item.id}`}
                            >
                              Details
                            </Link>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-notice-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MessagesList
