/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {Dropdown1} from '../../../../_metronic/partials/content/dropdown/Dropdown1'
import clsx from 'clsx'
import {DashboardClient, DashboardQueryParams} from '../../../infrastracture/api/DashboardClient'
import Moment from 'react-moment'
import moment from 'moment'
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap-v5'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'
import {Link} from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import {CheckInsClient} from '../../../infrastracture/api/CheckInsClient'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

type Props = {
  tenantId: string
}

const CheckInsRealtime: React.FC<Props> = ({tenantId}) => {
  const [itemsList, setItemsList] = useState([])
  const [loading, setLoading] = useState(false)
  const [showCheckOutModal, setShowCheckOutModal] = useState(false)
  const [selectedData, setSelectedData] = useState<any>({})

  const today = moment()

  useEffect(() => {
    if (tenantId) fetchData()

    //fetch data every 2 mins
    const interval = setInterval(() => {
      fetchData()
    }, 120000)

    return () => clearInterval(interval)
  }, [tenantId])

  const fetchData = async () => {
    setLoading(true)

    const todayF = moment(today).format('YYYY-MM-DD')

    var query = new DashboardQueryParams()
    var response: any

    if (tenantId !== 'all') {
      response = await DashboardClient.GetCheckInRealtime(
        query.Paginate(0, 99999).WithAssignedTo(tenantId).WithTenantId(tenantId)
      )
    } else {
      response = await DashboardClient.GetCheckInRealtime(query.Paginate(0, 99999))
    }

    if (!response.authenticated) {
    } else {
      const {data} = response
      setItemsList(data?.data)
    }
    setLoading(false)
  }

  const addDefaultSrc = (ev: any) => {
    ev.target.src = toAbsoluteUrl('/media/avatars/blank.png')
  }

  const addSiteDefaultSrc = (ev: any) => {
    ev.target.src = toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm004.svg')
  }

  const getDatePercent = (start: Date, end: Date) => {
    var today = new Date().valueOf()
    var startVal = new Date(start).valueOf()
    var endVal = new Date(end).valueOf()
    var p = Math.round(((today - startVal) / (endVal - startVal)) * 100)

    if (p >= 100) return 100

    return p
  }

  const workOrderPopover = (workOrder: any) => {
    return (
      <Popover id='workOrderPopover' style={{maxWidth: '900px'}}>
        <Popover.Title
          as='h3'
          style={{width: '900px'}}
        >{`WO-${workOrder?.workOrderNumberId}`}</Popover.Title>
        <Popover.Content>
          <table>
            <tr>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Site</div>
                  <div className='text-gray-600'>{workOrder.site?.name}</div>
                </div>
              </td>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Type</div>
                  <div className='text-gray-600'>{workOrder.workOrderType?.name ?? 'N/A'}</div>
                </div>
              </td>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Category</div>
                  <div className='text-gray-600'>{workOrder.workOrderCategory?.name ?? 'N/A'}</div>
                </div>
              </td>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Status</div>
                  <div className='text-gray-600'>{workOrder.workOrderStatus?.name ?? 'N/A'}</div>
                </div>
              </td>

              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Work Scope</div>
                  <div className='text-gray-600'>{workOrder.workScope?.name ?? 'N/A'}</div>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Date Created</div>
                  <div className='text-gray-600'>
                    <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                      {workOrder.createdDate}
                    </Moment>
                  </div>
                </div>
              </td>

              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Due Date</div>
                  <div className='text-gray-600'>
                    <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                      {workOrder.dueDate}
                    </Moment>
                  </div>
                </div>
              </td>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Date on Site</div>
                  <div className='text-gray-600'>
                    <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                      {workOrder.expectedOnSite}
                    </Moment>
                  </div>
                </div>
              </td>

              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Priority</div>
                  <div className='text-gray-600'>{workOrder?.priority?.name ?? 'N/A'}</div>
                </div>
              </td>

              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Cost</div>
                  <div className='text-gray-600'>
                    {workOrder.costConfirmed ? toMoneyFormat(workOrder.costConfirmed) : '$'}
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <table style={{width: '100%'}}>
            <tr>
              <td>
                <div className='fw-bolder mt-5'>Description</div>

                <textarea
                  name='description'
                  placeholder=''
                  className='form-control form-control-solid'
                  id='description'
                  disabled
                  rows={3}
                  style={{width: '100%'}}
                  defaultValue={workOrder.description}
                ></textarea>
              </td>
            </tr>
          </table>

          <table style={{width: '100%'}}>
            <tr>
              <td>
                <div className='fw-bolder mt-5'>Contact</div>

                <input
                  type='text'
                  name='contact'
                  placeholder=''
                  className='form-control form-control-solid'
                  id='contact'
                  disabled
                  defaultValue={workOrder.contact}
                />
              </td>
            </tr>
          </table>
        </Popover.Content>
      </Popover>
    )
  }

  const userPopover = (user: any) => {
    return (
      <Popover id='userPopover' style={{maxWidth: '500px'}}>
        <Popover.Title as='h3' style={{width: '500px'}}>
          {user?.tenant?.name}
        </Popover.Title>
        <Popover.Content>
          <table>
            <tr>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>First Name</div>
                  <div className='text-gray-600'>{user?.name}</div>
                </div>
              </td>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Last Name</div>
                  <div className='text-gray-600'>{user?.surname}</div>
                </div>
              </td>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Email</div>
                  <div className='text-gray-600'>{user?.email ?? 'N/A'}</div>
                </div>
              </td>
              <td style={{paddingRight: '20px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Mobile</div>
                  <div className='text-gray-600'>{user?.mobile ?? 'N/A'}</div>
                </div>
              </td>
            </tr>
          </table>
        </Popover.Content>
      </Popover>
    )
  }

  const getUniqSitesCount = () => {
    var unique = Array.from(new Set(itemsList.map((item: any) => item.workOrder?.siteId)))
    return unique.length ?? 0
  }

  const formatDate = (date: any) => {
    if (moment(moment(date)).isSame(today, 'day')) {
      return `Today ${moment(date).format('h:mm a')}`
    } else if (moment(moment(date)).isAfter(today, 'day')) {
      return `Tomorrow ${moment(date).format('h:mm a')}`
    } else {
      return moment(date).format('ddd DD MMM, YYYY h:mm a')
    }
  }

  const checkOut = async () => {
    setShowCheckOutModal(false)
    await CheckInsClient.CheckOut(selectedData.id, false, null)
    fetchData()
  }

  const checkOutModal = () => {
    return (
      <SweetAlertWithProp
        info
        showCancel
        title='Are you sure you want to check out?'
        onConfirm={async () => {
          await checkOut()
        }}
        onCancel={() => {
          setShowCheckOutModal(false)
        }}
        focusCancelBtn
        cancelBtnBsStyle='light'
        show={showCheckOutModal}
        confirmBtnText='Yes'
        cancelBtnText='No'
      ></SweetAlertWithProp>
    )
  }

  return (
    <>
      {checkOutModal()}
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Check Ins</span>
          </h3>

          <div className='card-toolbar'>
            {itemsList.filter(
              (item: any) =>
                moment(item.expectedCheckOut).isSameOrBefore(moment().local()) && !item.checkOut
            ).length > 0 && (
              <>
                <span className='badge badge-warning'>
                  <KTSVG
                    path='/media/icons/duotune/communication/com006.svg'
                    className='svg-icon-white'
                  />
                  {itemsList.filter(
                    (item: any) =>
                      moment(item.expectedCheckOut).isSameOrBefore(moment().local()) &&
                      !item.checkOut
                  ).length ?? 0}
                </span>
                <span style={{paddingRight: '5px'}}></span>
              </>
            )}
            <span className='badge badge-primary'>
              <KTSVG path='/media/icons/duotune/general/gen001.svg' className='svg-icon-white' />{' '}
              {getUniqSitesCount()}
            </span>
            <span style={{paddingRight: '5px'}}></span>
            <span className='badge badge-primary'>
              <KTSVG
                path='/media/icons/duotune/communication/com006.svg'
                className='svg-icon-white'
              />
              {itemsList.length ?? 0}
            </span>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th>WO #</th>
                  <th>User</th>
                  <th>Site</th>
                  <th>In</th>
                  <th>Expected Out</th>
                  {/* <th>Progress</th> */}
                  <th>Out</th>
                  <th></th>
                </tr>
              </thead>
              {!loading && !itemsList.length && (
                <tbody>
                  <tr>
                    <td colSpan={8}>
                      <div className='fw-bolder text-gray-800 ps-3 text-center'>No Data</div>
                    </td>
                  </tr>
                </tbody>
              )}
              {!loading &&
                itemsList.length > 0 &&
                itemsList.map((item: any) => (
                  <tbody>
                    <tr
                      style={
                        moment(item.expectedCheckOut).isSameOrBefore(moment().local()) &&
                        !item.checkOut
                          ? {background: '#FFC107'}
                          : {}
                      }
                    >
                      <td>
                        <div>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id='tooltip-disabled'>
                                {workOrderPopover(item.workOrder)}
                              </Tooltip>
                            }
                            placement='right'
                            trigger='hover'
                          >
                            <Link
                              className='fw-bolder text-dark ps-3'
                              to={`/work-orders/details/` + item.workOrder?.id}
                            >
                              {`WO-${item.workOrder?.workOrderNumberId}`}
                            </Link>
                          </OverlayTrigger>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id='tooltip-disabled'>{userPopover(item.user)}</Tooltip>
                              }
                              placement='right'
                              trigger='hover'
                            >
                              <Link
                                className='symbol symbol-45px me-5'
                                to={`/users/details/` + item.user?.id}
                              >
                                <img
                                  src={`${item.user?.profilePhoto}` + '?' + new Date().getTime()}
                                  onError={addDefaultSrc}
                                ></img>
                              </Link>
                            </OverlayTrigger>
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bolder text-hover-primary fs-6'>
                              {item.user?.name} {item.user?.surname}
                            </span>
                            <span className='text-dark fw-bold d-block fs-7'>
                              {item.user?.tenant?.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img
                              src={item.workOrder?.site?.tenant?.logo}
                              onError={addSiteDefaultSrc}
                            ></img>
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <span className='text-dark fw-bold d-block fs-7'>
                              {item.workOrder?.site?.name}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td>
                        <span className='text-dark fw-bold d-block fs-7'>
                          {formatDate(item.checkedIn)}
                        </span>
                      </td>

                      <td>
                        <span className='text-dark fw-bold d-block fs-7'>
                          {formatDate(item.expectedCheckOut)}
                        </span>
                      </td>

                      {/* <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-bold'>
                            {getDatePercent(item.checkedIn, item.expectedCheckOut)}%
                          </span>
                        </div>
                        <div className='progress h-6px w-100'>
                          <div
                            className='progress-bar bg-primary'
                            role='progressbar'
                            style={{
                              width: `${getDatePercent(item.checkedIn, item.expectedCheckOut)}%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </td> */}

                      <td>
                        <span className='text-dark fw-bold d-block fs-7'>
                          {item.checkOut && (
                            <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                              {item.checkOut}
                            </Moment>
                          )}
                        </span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          {/* <a href='' className='btn btn-icon btn-primary btn-sm me-1'>
                            <KTSVG
                              path='/media/icons/duotune/maps/map002.svg'
                              className='svg-icon-3'
                            />
                          </a> */}
                          {!item.checkOut && (
                            <button
                              type='button'
                              className='btn btn-icon btn-primary btn-sm me-1'
                              onMouseOver={() => {
                                setSelectedData(item)
                              }}
                              onClick={() => {
                                setSelectedData(item)
                                setShowCheckOutModal(true)
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr042.svg'
                                className='svg-icon-3'
                              />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckInsRealtime
