/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import ReactMarkdown from 'react-markdown'
import {Col, Form, Row} from 'react-bootstrap-v5'

import {CheckInNoticesClient} from '../../../../infrastracture/api/CheckInNoticesClient'

interface Props {
  notices: any[]
  checkIn: any
  handleOk: () => void
}

const Step1: React.FC<Props> = (props) => {
  const {notices, handleOk, checkIn} = props
  const [currentIndex, setCurrentIndex] = useState(0)
  const [acknowledgedIds, setAcknowledgeIds] = useState<any>([])
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setCurrentIndex(0)
    setAcknowledgeIds([])
  }, [checkIn])

  return (
    <div className='w-100'>
      <div>
        <span>
          <h2 className='pb-2'>Site Notices</h2>
          {notices.length > 0 && (
            <p>
              {currentIndex + 1} of {notices.length}
            </p>
          )}
        </span>
        {notices.length === 0 && (
          <div className='d-flex flex-column'>
            <div className='text-dark '>No notices available for this site</div>
          </div>
        )}
        {notices.length > 0 &&
          notices.map((item, index) => {
            return (
              index === currentIndex && (
                <div key={index}>
                  <div className='card shadow-sm'>
                    <div className='card-header'>
                      <h3 className='card-title'>
                        <span style={{paddingRight: '5px'}}>
                          {item.notice?.noticeType?.name.toUpperCase()}
                        </span>
                        {acknowledgedIds.includes(item.notice?.id) && (
                          <KTSVG
                            path='/media/icons/duotune/general/gen043.svg'
                            className='svg-icon-4 svg-icon-success '
                          />
                        )}
                      </h3>
                    </div>
                    <div className='card-body card-scroll h-250px'>
                      <h3>{item.notice?.name.toUpperCase()}</h3>

                      <ReactMarkdown>{item.notice.body}</ReactMarkdown>
                      <span className='text-danger mt-2'>
                        <h3 className='text-danger'>IMPORTANT</h3>
                        <ReactMarkdown>{item.notice.warning}</ReactMarkdown>
                      </span>

                      <Form>
                        <Form.Group as={Row} style={{paddingTop: '15px'}}>
                          <Col sm='6'>
                            <div className='d-flex flex-column'>
                              {currentIndex !== 0 && (
                                <button
                                  type='button'
                                  className='align-self-start btn btn-sm btn-light-primary'
                                  onClick={() => {
                                    setCurrentIndex(currentIndex - 1)
                                  }}
                                >
                                  Go Back
                                </button>
                              )}
                            </div>
                          </Col>
                          <Col sm='6'>
                            <div className='d-flex flex-column'>
                              <button
                                type='button'
                                disabled={submitting}
                                className='align-self-end btn btn-sm btn-light-primary'
                                onClick={async () => {
                                  setSubmitting(true)
                                  if (!acknowledgedIds.includes(item.notice?.id)) {
                                    var response = await CheckInNoticesClient.Create(
                                      checkIn.id,
                                      item.notice?.id
                                    )
                                    setAcknowledgeIds([...acknowledgedIds, item.notice?.id])
                                  }

                                  if (currentIndex + 1 === notices.length) {
                                    handleOk()
                                  } else {
                                    setCurrentIndex(currentIndex + 1)
                                  }
                                  setSubmitting(false)
                                }}
                              >
                                {!submitting && (
                                  <span className='indicator-label'>I Acknowledge</span>
                                )}
                                {submitting && (
                                  <span className='indicator-progress' style={{display: 'block'}}>
                                    Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                            </div>
                          </Col>
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                </div>
              )
            )
          })}
        {/* <div className='accordion' id='kt_accordion_1'>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='kt_accordion_1_header_2'>
              <button
                className='accordion-button fs-4 fw-bold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_1_body_2'
                aria-expanded='false'
                aria-controls='kt_accordion_1_body_2'
              >
                Heights
              </button>
            </h2>
            <div
              id='kt_accordion_1_body_2'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_2'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <p>1 of 2</p>
                <h3>Heights</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                <span className='text-danger'>
                  <h3 className='text-danger'>IMPORTANT</h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                </span>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_accordion_1_body_2'
                  aria-expanded='true'
                  aria-controls='kt_accordion_1_body_2'
                >
                  I Acknowledge
                </button>
              </div>
            </div>
          </div>

          <div className='accordion-item'>
            <h2 className='accordion-header' id='kt_accordion_1_header_3'>
              <button
                className='accordion-button fs-4 fw-bold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_1_body_3'
                aria-expanded='false'
                aria-controls='kt_accordion_1_body_3'
              >
                Other
              </button>
            </h2>
            <div
              id='kt_accordion_1_body_3'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_3'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <p>2 of 2</p>
                <h3>Others</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                <span className='text-danger'>
                  <h3 className='text-danger'>IMPORTANT</h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                </span>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary'
                  data-bs-toggle='collapse'
                  data-bs-target='#kt_accordion_1_body_3'
                  aria-expanded='true'
                  aria-controls='kt_accordion_1_body_3'
                >
                  I Acknowledge
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export {Step1}
