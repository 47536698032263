import { ApiClient, PublicApiClient } from '../../../setup/axios/SetupAxios';
import { ListQueryParams } from '../ApiClient';

export class IncidentTypeQueryParams extends ListQueryParams {
  WithEnabled(value: boolean) {
    this.WithParam('active', value)
    return this
  }
}

export class IncidentTypeClient {
    static async List(params: IncidentTypeQueryParams) {
        return await ApiClient.Get('/incident-type' + params.GenerateQueryString());
  }

    static async PublicList(params: IncidentTypeQueryParams) {
        return await PublicApiClient.Get('/incident-type/public-list' + params.GenerateQueryString());
  }

    static async Delete(id: string) {
        return await ApiClient.Delete('/incident-type/' + id);
  }

    static async Get(id: string) {
        return await ApiClient.Get('/incident-type/' + id);
  }

    static async Create(name: string, color: string, active: boolean) {
        return await ApiClient.Post('/incident-type/', {
            name,
            color,
            active,
        });
  }

    static async Update(id: string, name: string, color: string, active: boolean) {
        return await ApiClient.Patch('/incident-type/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'color',
                value: color,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
        ]);
  }
}
