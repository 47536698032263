import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetFilter: '[Exclusion SetFilter] Action',
    ResetFilter: '[Exclusion ResetFilter] Action',
}

const initialExclusionState: IExclusionState = {
    filter: {
        status: false,
        active: true,
    },
}

export interface IExclusionState {
    filter?: {
        status: boolean
        active: boolean
    }
}

export const reducer = persistReducer(
    { storage, key: 'v100-Exclusion', whitelist: ['filter'] },
    (state: IExclusionState = initialExclusionState, action: ActionWithPayload<IExclusionState>) => {
        switch (action.type) {
            case actionTypes.ResetFilter: {
                return initialExclusionState
            }

            case actionTypes.SetFilter: {
                const filter = action.payload?.filter
                return { ...state, filter }
            }

            default:
                return state
        }
    }
)

export const Actions = {
    resetFilter: () => ({ type: actionTypes.ResetFilter }),
    setFilter: (filter: object) => ({
        type: actionTypes.SetFilter,
        payload: { filter },
    }),
}

export function* saga() { }
