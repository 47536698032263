import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class CheckListTemplateItemQueryParams extends ListQueryParams {
    WithCheckListTemplateId(value: string) {
        this.WithParam('checkListTemplateId', value)
        return this
    }
}

export class CheckListTemplateItemClient {
    static async List(params: CheckListTemplateItemQueryParams) {
        return await ApiClient.Get('/checklist-template-item' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/checklist-template-item/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/checklist-template-item/' + id);
    }

    static async Create(
        checkListTemplateId: string,
        itemType: any,
        itemText: string,
        options: string[],
        control?: string,
        allowPhoto?: boolean,
        allowComment?: boolean,
        heading?: string
    ) {
        return await ApiClient.Post('/checklist-template-item/', {
            checkListTemplateId,
            itemType,
            itemText,
            options,
            control,
            allowPhoto,
            allowComment,
            heading,
        });
    }

    static async ReOrder(items: any[]) {
        return await ApiClient.Post('/checklist-template-item/re-order', items);
    }

    static async Update(id: string, itemText: string) {
        return await ApiClient.Patch('/checklist-template-item/' + id, [
            {
                op: 'replace',
                path: 'itemText',
                value: itemText,
            },
        ]);
    }
}
