import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class QuoteQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithWorkOrderId(value: string) {
        this.WithParam('workOrderId', value)
        return this
    }
}

export class QuotesClient {
    static async List(params: QuoteQueryParams) {
        return await ApiClient.Get('/Quotes' + params.GenerateQueryString());
    }

    static async GetQuotesForParentWorkOrder(workOrderId: string) {
        return await ApiClient.Get('/Quotes/project-work-order/' + workOrderId);
    }

    static async Get(id: string) {
        return await ApiClient.Get('/Quotes/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/Quotes/' + id);
    }

    static async Create(
        workOrderId: string,
        name: string,
        description: string,
        referenceNumber: string,
        estimate: string
    ) {

        return await ApiClient.Post('/Quotes/', {
            workOrderId: workOrderId,
            name: name,
            description: description,
            referenceNumber: referenceNumber,
            estimate: estimate,
        });

    }

    static async Update(
        id: string,
        name: string,
        description: string,
        referenceNumber: string,
        estimate: string,
        quoteStatusId: string
    ) {

        return await ApiClient.Patch('/Quotes/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'referenceNumber',
                value: referenceNumber,
            },
            {
                op: 'replace',
                path: 'estimate',
                value: estimate,
            },
            {
                op: 'replace',
                path: 'quoteStatusId',
                value: quoteStatusId,
            },
        ]);
    }
}
