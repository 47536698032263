import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {QuotesClient, QuoteQueryParams} from '../../../infrastracture/api/QuotesClient'
import QuoteStatusSelector from '../../../common/components/selectors/QuoteStatusSelector'
import {
  AttachmentsClient,
  AttachmentsQueryParams,
} from '../../../infrastracture/api/AttachmentsClient'

import {Col, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Moment from 'react-moment'
import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import SweetAlert from 'react-bootstrap-sweetalert'
import QuotesList from './QuotesList'
import {Rating} from 'react-simple-star-rating'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {ChargesClient, ChargesQueryParams} from '../../../infrastracture/api/ChargesClient'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'
import {TenantsClient} from '../../../infrastracture/api/TenantsClient'
import {WorkOrderClient} from '../../../infrastracture/api/WorkOrderClient'
import _ from 'lodash'
interface Props {
  handleOk: () => void
  handleClose: () => void
  selectedData: any
  workOrderId: string
  show: boolean
  workOrderNumber: string
  viewOnly?: boolean
  defaultAmount?: any
}

const QuotesForParentWorkOrderModal: React.FC<Props> = (props) => {
  const {
    handleOk,
    selectedData,
    viewOnly,
    workOrderId,
    workOrderNumber,
    show,
    defaultAmount,
    handleClose,
  } = props

  const [loading, setLoading] = useState(false)
  const [quote, setQuote] = useState<any>({})
  const [mappedCharges, setMappedCharges] = useState<any>([])
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const validationSchema = Yup.object().shape({})

  const [initialValues, setInitialValues] = useState<any>({})

  useEffect(() => {
    if (defaultAmount && defaultAmount > 0) {
      setInitialValues({
        ...initialValues,
        estimate: defaultAmount,
      })
    }
  }, [defaultAmount])

  useEffect(() => {
    setLoading(true)

    if (selectedData) {
      var quotesDetails = {...selectedData}

      if (selectedData?.quoteStatus) {
        const quoteStatus = selectedData.quoteStatus
        quotesDetails = {
          ...quotesDetails,
          quoteStatus: {value: quoteStatus.id, label: quoteStatus.name},
          quoteStatusId: quoteStatus.id,
        }
      }

      setInitialValues(quotesDetails)
      setQuote(selectedData)

      if (selectedData.charges && selectedData.charges.length > 0) {
        var parentCharges = selectedData.charges.filter(
          (x: any) => x.workOrderNumberId === workOrderNumber
        )

        var childCharges = selectedData.charges.filter(
          (x: any) => x.workOrderNumberId !== workOrderNumber
        )

        if (childCharges && childCharges.length > 0) {
          var grouped = _(childCharges)
            .groupBy('workOrderNumberId')
            .map(function (items, workOrderNumberId) {
              return {
                workOrderNumberId: workOrderNumberId,
                items: items,
              }
            })
            .value()

          var mapped = grouped.map((charge) => {
            return {
              workOrderNumberId: charge.workOrderNumberId,
              value: _.sumBy(charge.items, 'value'),
            }
          })
          setMappedCharges([...parentCharges, ...mapped])
        } else {
          setMappedCharges(parentCharges)
        }
      }
    }

    setLoading(false)
  }, [selectedData])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          var response: any

          if (quote?.submittedQuotes && quote?.submittedQuotes.length > 0) {
            for (let index = 0; index < quote?.submittedQuotes.length; index++) {
              const _quote = quote?.submittedQuotes[index]
              await QuotesClient.Update(
                _quote.id,
                _quote.name,
                _quote.description,
                _quote.referenceNumber,
                _quote.estimate,
                values.quoteStatusId
              )
            }
          }

          setSubmitting(false)
          handleOk()
        }}
        enableReinitialize
      >
        {({errors, touched, isSubmitting, values, setFieldValue, resetForm}: any) => (
          <>
            <>
              <Modal isOpen={show} onHide={() => {}} zIndex={1000} size='lg'>
                <Form>
                  <ModalHeader closeButton>
                    <h5 className='modal-title'>View Quote</h5>
                  </ModalHeader>
                  <ModalBody>
                    {' '}
                    <table>
                      {(user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Client ||
                        user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner) &&
                        quote?.quoteStatus?.name !== 'Approved' && (
                          <tr>
                            <td colSpan={2}>
                              <label className='required form-label'>Quote Status</label>
                              <QuoteStatusSelector
                                placeholder='Select Quote Status'
                                defaultValue={values.quoteStatus}
                                onChange={setFieldValue}
                              />

                              {touched.quoteStatusId && errors.quoteStatusId && (
                                <div className='fv-plugins-message-container'>
                                  <span className='text-danger' role='alert'>
                                    {errors.quoteStatusId}
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                        )}
                    </table>
                    <table>
                      <tr>
                        <td style={{paddingRight: '152px', verticalAlign: 'top'}}>
                          <div className='fs-6'>
                            <div className='fw-bolder mt-5'>Amount</div>
                            <div className='text-gray-600'>${quote?.estimate}</div>
                          </div>
                        </td>
                        {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                          <td>
                            <div className='fs-6'>
                              <div className='fw-bolder mt-5'>Breakdown</div>
                              {mappedCharges && mappedCharges.length > 0 && (
                                <>
                                  <ul>
                                    {mappedCharges.map((charge: any) => {
                                      return (
                                        <li>
                                          <span className='text-dark fw-bolder'>
                                            WO-{charge.workOrderNumberId}:{' '}
                                          </span>
                                          <span className='text-gray-600'>
                                            {toMoneyFormat(charge.value)}

                                            {charge.chargeType?.name && (
                                              <span> - {charge?.chargeType?.name}</span>
                                            )}
                                          </span>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                      {user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor && (
                        <tr>
                          <td colSpan={2}>
                            <div>
                              <div className='fw-bolder mt-5 fs-6'>Total</div>
                              <div className='text-dark-600 fw-bolder fs-5'>
                                {toMoneyFormat(quote?.estimate)}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </table>
                  </ModalBody>
                  <ModalFooter>
                    <button
                      type='button'
                      onClick={() => {
                        resetForm()
                        handleClose()
                      }}
                      className='btn btn-light'
                      data-bs-dismiss='modal'
                      id='rd_Quotes_modal_dismiss'
                    >
                      Close
                    </button>
                                      {(user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Client || user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner) &&
                      quote?.quoteStatus?.name !== 'Approved' && (
                        <button
                          type='submit'
                          id='rd_create_tenant_submit'
                          className='btn btn-lg btn-primary'
                          disabled={isSubmitting}
                        >
                          {!isSubmitting && <span className='indicator-label'>Save</span>}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                  </ModalFooter>
                </Form>
              </Modal>
            </>
          </>
        )}
      </Formik>
    </>
  )
}

export default QuotesForParentWorkOrderModal
