import React, {useState, useEffect} from 'react'
import {Col, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Moment from 'react-moment'
import * as Yup from 'yup'
import SweetAlert from 'react-bootstrap-sweetalert'
import Select from 'react-select'
import moment from 'moment'
import {CheckInsClient, CheckInsQueryParams} from '../../../infrastracture/api/CheckInsClient'
import customStyles from '../../../common/components/selectors/customStyles'
import {checkInTimeOptions} from './options'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

interface Props {
  handleOk: () => void
  handleClose: () => void
  show: boolean
  checkIn: any
}

const ExtendCheckinModal: React.FC<Props> = (props) => {
  const {handleClose, handleOk, show, checkIn} = props
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const [minutes, setMinutes] = useState<any>(30)

  useEffect(() => {
    setMinutes(30)
  }, [show, checkIn])

  const submit = async () => {
    setLoading(true)
    var response = await CheckInsClient.Extend(checkIn.id, minutes)

    if (!response.successful) {
      //show error
    } else {
      setSuccess(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal isOpen={show} onHide={() => {}} zIndex={9999}>
        <ModalHeader closeButton>
          <h5 className='modal-title'>Extend Check In</h5>
        </ModalHeader>
        <ModalBody>
          <SweetAlertWithProp
            show={success}
            success
            title='Success'
            onConfirm={() => {
              setSuccess(false)
              handleOk()
            }}
            showCancel={false}
          >
            Check In extended success!
          </SweetAlertWithProp>
          <Select
            onChange={(values: any) => {
              const {label, value} = values
              setMinutes(value)
            }}
            defaultValue={{value: 30, label: '30 minutes'}}
            options={checkInTimeOptions}
            placeholder={'Time On Site'}
            styles={customStyles}
            menuPortalTarget={document.body}
          />
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            onClick={() => {
              handleClose()
            }}
            className='btn btn-light'
            data-bs-dismiss='modal'
            id='rd_Library_modal_dismiss'
          >
            Close
          </button>

          <button
            type='submit'
            id='rd_create_tenant_submit'
            className='btn btn-lg btn-primary'
            disabled={loading}
            onClick={() => {
              submit()
            }}
          >
            {!loading && <span className='indicator-label'>Save</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ExtendCheckinModal
