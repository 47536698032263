export const enum Modes {
  Add = 'Add',
  Edit = 'Edit',
  View = 'View',
}

export const shippingTypeOptions = [
    {
        value: 'pickUp',
        label: 'PickUp'
    },
    {
        value: 'site',
        label: 'Site',
    },
    {
        value: 'contractor',
        label: 'Contractor'
    },
    {
        value: 'client',
        label: 'Client'
    },
    {
        value: 'other',
        label: 'Other'
    },
]