import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {
  QuoteStatusQueryParams,
  QuoteStatusClient,
} from '../../../infrastracture/api/QuoteStatusClient'
import customStyles from './customStyles'

interface Props {
  name?: string
  defaultValue?: any
  placeholder?: string
  disabled?: boolean
  onChange?: (name: any, value: any) => void
  id?: string
}

const QuoteStatuselector: React.FC<Props> = (props: any) => {
  const {id, defaultValue, placeholder, disabled, name, onChange} = props

  const [options, setOptions] = useState([])
  const [value, setValue] = useState<any>({})

  const defaultId = 'quoteStatusId'

  useEffect(() => {
    populateData()
  }, [])

  useEffect(() => {
    if (value != defaultValue) setValue(defaultValue)
  }, [defaultValue])

  const populateData = async () => {
    var query = new QuoteStatusQueryParams()

    //TODO: SUPPORT SEARCH FOR PERFORMANCE AGAINST LARGE COLLECTIONS
    var data = (
      await QuoteStatusClient.List(query.Paginate(0, 10000).Sort('name', 'asc').WithActive(true))
    ).data

    if (data) {
      setOptions(
        data.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isDisabled: item.disabled,
          }
        })
      )
    }
  }

  const handleChange = (values: any) => {
    const {onChange} = props
    const {label, value} = values
    setValue(values)
    onChange(defaultId, value)
  }

  return (
    <Select
      onChange={handleChange}
      value={value}
      options={options}
      placeholder={placeholder}
      isDisabled={disabled}
      id={name ? name : defaultId}
      name={name ? name : defaultId}
      styles={customStyles}
      menuPortalTarget={document.body}
    />
  )
}

export default QuoteStatuselector
