export enum CheckListModes {
  Add = 'Add',
  Edit = 'Edit',
  View = 'View',
}

export enum CheckListItemTypes {
  TickBox = 1,
  DropList = 2,
  TextInput = 3,
  Radio = 4,
  Range = 5,
  SectionHeader = 6,
}

export const CheckListItemTypesLabel = new Map<number, string>([
  [CheckListItemTypes.TickBox, 'Tick Box'],
  [CheckListItemTypes.DropList, 'Drop List'],
  [CheckListItemTypes.TextInput, 'Text Input'],
  [CheckListItemTypes.Radio, 'Radio'],
  [CheckListItemTypes.Range, 'Range'],
  [CheckListItemTypes.SectionHeader, 'Section Header'],
])
