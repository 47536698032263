import { ApiClient } from '../../../setup/axios/SetupAxios';
import { ListQueryParams } from '../ApiClient';

export class LeaveSiteReasonsQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
}

export class LeaveSiteReasonsClient {
    static async List(params: LeaveSiteReasonsQueryParams) {
        return await ApiClient.Get('/leave-site-reason' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/leave-site-reason/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/leave-site-reason/' + id);
    }

    static async Create(name: string, description: string, active: boolean) {
        return await ApiClient.Post('/leave-site-reason/', {
            name: name,
            description: description,
            active: active,
        });
    }

    static async Update(id: string, name: string, description: string, active: boolean) {
        return await ApiClient.Patch('/leave-site-reason/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
        ]);
    }
}
