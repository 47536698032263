import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import Select from 'react-select'
import {RootState} from '../../../../setup'
import {
  LeaveSiteReasonsClient,
  LeaveSiteReasonsQueryParams,
} from '../../../infrastracture/api/LeaveSiteReasonsClient'

interface Props {
  name?: string
  defaultValue?: any
  placeholder?: string
  disabled?: boolean
  onChange?: (name: any, value: any, label: any) => void
  id?: string
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    background: '#f5f8fa',
    borderRadius: '5px',
    zIndex: 5601,
  }),
  indicatorSeparator: (_: any) => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
  }),
  option: (styles: any) => {
    return {
      ...styles,
    }
  },
  menuPortal: (base: any) => ({...base, zIndex: 5601}),
  singleValue: (provided: any) => ({...provided, background: '#f5f8fa'}),
  placeholder: (defaultStyles: any) => ({...defaultStyles, background: '#f5f8fa'}),
}

const LeaveSiteReasonSelector: React.FC<Props> = (props: any) => {
  const {id, defaultValue, placeholder, disabled, name} = props
  const [options, setOptions] = useState([])
  const [value, setValue] = useState<any>({})

  const defaultId = id || 'leaveSiteReasonId'

  useEffect(() => {
    populateData()
  }, [])

  useEffect(() => {
    if (value != defaultValue) setValue(defaultValue)
  }, [defaultValue])

  const populateData = async () => {
    var query = new LeaveSiteReasonsQueryParams()

    var data = (
      await LeaveSiteReasonsClient.List(
        query.Paginate(0, 10000).Sort('name', 'asc').WithActive(true)
      )
    ).data

    if (data) {
      setOptions(
        data.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          }
        })
      )
    }
  }

  const handleChange = (values: any) => {
    const {onChange} = props
    const {label, value} = values
    setValue(values)
    onChange(defaultId, value, label)
  }

  return (
    <Select
      onChange={handleChange}
      value={value}
      options={options}
      placeholder={placeholder}
      isDisabled={disabled}
      id={name ? name : defaultId}
      name={name ? name : defaultId}
      styles={customStyles}
      menuPortalTarget={document.body}
    />
  )
}

export default LeaveSiteReasonSelector
