import { ApiClient, PublicApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class HazardsQueryParams extends ListQueryParams {
  WithEnabled(value: boolean) {
    this.WithParam('active', value)
    return this
  }
  WithStatusId(value: string) {
    this.WithParam('statusId', value)
    return this
  }
  WithTypeId(value: string) {
    this.WithParam('typeId', value)
    return this
  }
  WithClientId(value: string) {
    this.WithParam('clientId', value)
    return this
  }
  WithSiteId(value: string) {
    this.WithParam('siteId', value)
    return this
  }
}

export class HazardsClient {
    static async List(params: HazardsQueryParams) {
        return await ApiClient.Get('/hazards' + params.GenerateQueryString());
  }

    static async Delete(id: string) {
        return await ApiClient.Delete('/hazards/' + id);
  }

    static async Get(id: string) {
        return await ApiClient.Get('/hazards/' + id);
  }

    static async GetHazardMobileInfo(userId: string, workOrderId: string) {
        return await PublicApiClient.Get(`/hazards/stand-alone-info?userId=${userId}&workOrderId=${workOrderId}`);
  }

  static async Create(
    siteId: string,
    clientId: string,
    closedOn: any,
    closedNote: string,
    typeId: string,
    statusId: string,
    description: string,
    location: string,
    actionTaken: string,
    stillPresent: boolean
  ) {
      return await ApiClient.Post('/hazards/', {
          siteId,
          clientId,
          closedOn,
          closedNote,
          typeId,
          statusId,
          description,
          location,
          actionTaken,
          stillPresent,
      });
  }

  static async CreateStandAlone(
    siteId: string,
    clientId: string,
    closedOn: any,
    closedNote: string,
    typeId: string,
    statusId: string,
    description: string,
    location: string,
    actionTaken: string,
    stillPresent: boolean,
    createdById: string
  ) {
      return await PublicApiClient.Post('/hazards/stand-alone', {
          siteId,
          clientId,
          closedOn,
          closedNote,
          typeId,
          statusId,
          description,
          location,
          actionTaken,
          stillPresent,
          createdById,
      });

  }

  static async Update(
    id: string,
    siteId: string,
    clientId: string,
    closedNote: string,
    typeId: string,
    statusId: string,
    description: string,
    location: string,
    actionTaken: string,
    stillPresent: boolean
  ) {

      return await ApiClient.Patch('/hazards/' + id, [
          {
              op: 'replace',
              path: 'siteId',
              value: siteId,
          },
          {
              op: 'replace',
              path: 'clientId',
              value: clientId,
          },
          {
              op: 'replace',
              path: 'closedNote',
              value: closedNote,
          },
          {
              op: 'replace',
              path: 'typeId',
              value: typeId,
          },
          {
              op: 'replace',
              path: 'statusId',
              value: statusId,
          },
          {
              op: 'replace',
              path: 'description',
              value: description,
          },
          {
              op: 'replace',
              path: 'location',
              value: location,
          },
          {
              op: 'replace',
              path: 'actionTaken',
              value: actionTaken,
          },
          {
              op: 'replace',
              path: 'stillPresent',
              value: stillPresent,
          },
      ]);
  }
}
