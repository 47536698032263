import { ApiClient, PublicApiClient } from '../../../setup/axios/SetupAxios';
import { ListQueryParams } from '../ApiClient';

export class CheckListItemQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
}

export class CheckListItemClient {
    static async List(params: CheckListItemQueryParams) {
        return await ApiClient.Get('/checklist-item' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await PublicApiClient.Get('/checklist-item/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/checklist-item/' + id);
    }

    static async Create(
        checkListId: any,
        checkListTemplateItemId: any,
        itemType: any,
        itemText: string,
        value: any,
        comment: any
    ) {
        return await ApiClient.Post('/checklist-item/', {
            checkListId,
            checkListTemplateItemId,
            itemType,
            itemText,
            value,
            comment,
        });
    }

    static async Answer(checkListItemId: string, value: any, comment: any, userId?: string) {
        const url = userId
            ? `/checklist-item/answer?userId=${userId}`
            : '/checklist-item/answer'

        return await PublicApiClient.Post(url, {
            checkListItemId,
            value,
            comment,
        });
    }

    static async ListCheckListSite(id: string) {
        return await ApiClient.Get(`/checklist-item/${id}/sites`);
    }

    static async UpdateSites(id: string, assignedSites: any) {
        return await ApiClient.Post(`/checklist-item/${id}/sites`, assignedSites);
    }
}
