import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthCRUD'
import {UsersClient} from '../../../infrastracture/api/UsersClient'

const initialValues = {
  newPassword: '',
}

const validationScheme = Yup.object().shape({
  newPassword: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{4,})/,
      'The password must contain at least one uppercase, one lowercase, one digit, and one symbol'
    ),
})

export function ForgotPasswordComplete() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<any>('')
  const [success, setSuccess] = useState(false)

  const params: any = useParams()

  useEffect(() => {
    setSuccess(false)
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: validationScheme,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      const response: any = await UsersClient.CompletePasswordReset(params.code, values.newPassword)

      if (response.successful) {
        setHasErrors(false)
        setLoading(false)
        setSuccess(true)
      } else {
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setSuccess(false)
        setErrorMessage(response?.message)
      }
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Choose your new password</h1>
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{errorMessage}</div>
          </div>
        )}
        {/* 
        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )} */}
        {/* end::Title */}

        {success && (
          <>
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info text-center'>
                Password reset successful. <br /> You can now login with the new password
              </div>
            </div>

            {/* <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <Link to='/login'>
                <button type='button' className='btn btn-lg btn-light-primary fw-bolder'>
                  Proceed
                </button>
              </Link>{' '}
            </div> */}
          </>
        )}

        {/* begin::Form group */}
        {!success && (
          <>
            <div className='fv-row mb-10'>
              <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('newPassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.newPassword && formik.errors.newPassword},
                  {
                    'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                  }
                )}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className='fv-plugins-message-container text-danger'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.newPassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4'
              >
                {!formik.isSubmitting && <span className='indicator-label'>Submit</span>}
                {formik.isSubmitting && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Cancel
                </button>
              </Link>{' '}
            </div>
          </>
        )}
        {/* end::Form group */}
      </form>
    </>
  )
}
