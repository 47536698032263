import { ApiClient, PublicApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class PersonAffectedQueryParams extends ListQueryParams {
    WithEnabled(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithIncidentId(value: string) {
        this.WithParam('incidentId', value)
        return this
    }
}

export class PersonAffectedClient {
    static async List(params: PersonAffectedQueryParams) {
        return await ApiClient.Get('/incidents/person-affected' + params.GenerateQueryString());
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/incidents/person-affected/' + id);
    }

    static async Get(id: string) {
        return await ApiClient.Get('/incidents/person-affected/' + id);
    }

    static async Create(name: string, note: string, incidentId: string) {
        return await ApiClient.Post('/incidents/person-affected/', {
            name,
            note,
            incidentId,
        });
    }

    static async CreateStandAlone(
        name: string,
        note: string,
        incidentId: string,
        createdById: string
    ) {
        return await PublicApiClient.Post('/incidents/person-affected/stand-alone', {
            name,
            note,
            incidentId,
            createdById,
        });
    }

    static async Update(id: string, name: string, note: string) {
        return await ApiClient.Patch('/incidents/person-affected/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'note',
                value: note,
            },
        ]);
    }
}
