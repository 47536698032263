const customStyles = {
  control: (provided: any) => ({
    ...provided,
    background: '#f5f8fa',
    borderRadius: '5px',
    zIndex: 98,
  }),
  indicatorSeparator: (_: any) => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
  }),
  option: (styles: any) => {
    return {
      ...styles,
    }
  },
  menuPortal: (base: any) => ({...base, zIndex: 2000}),
  singleValue: (provided: any) => ({...provided, background: '#f5f8fa'}),
  placeholder: (defaultStyles: any) => ({...defaultStyles, background: '#f5f8fa'}),
}

export default customStyles
