import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserQueryParams, UsersClient} from '../../../infrastracture/api/UsersClient'
import {QuoteQueryParams, QuotesClient} from '../../../infrastracture/api/QuotesClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import QuotesModal from './QuotesModal'
import Moment from 'react-moment'
import {Rating, RatingView} from 'react-simple-star-rating'
import SweetAlert from 'react-bootstrap-sweetalert'
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap-v5'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {TenantsClient} from '../../../infrastracture/api/TenantsClient'
import {WorkOrderClient} from '../../../infrastracture/api/WorkOrderClient'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'
import {getCharges} from '../helper/quotesHelper'

const QuotesBreadCrumbs: Array<PageLink> = []
interface Props {
  workOrderId: string
  title?: any
  workOrder: any
  refreshWO: () => void
  refreshList?: boolean
  isParentWorkOrder?: boolean
}
const QuotesList: React.FC<Props> = (props) => {
  const {workOrderId, workOrder, refreshWO, refreshList, isParentWorkOrder} = props
  const [Quotes, setQuotes] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState('')
  const [viewOnly, setViewOnly] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [showQuoteModal, setShowQuoteModal] = useState(false)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'name',
    direction: 'desc',
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [workOrderId, refreshList])

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  async function fetchData() {
    setLoading(true)

    try {
      var query = new QuoteQueryParams()

      var response = await QuotesClient.List(
        query
          .Paginate(pagination.skip, pagination.take)
          .Search(search)
          .WithActive(active)
          .WithWorkOrderId(workOrderId)
          .Sort(pagination.sort, pagination.direction)
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        var quotes = data?.data
        var mapped = []
        if (quotes) {
          const woRequest = await WorkOrderClient.Get(workOrderId)
          const wo = woRequest.data

          const tenantRequest = await TenantsClient.Get(wo?.tenantId)

          //calculate fees on the fly
          for (var i = 0; i < quotes.length; i++) {
            mapped.push(await getCharges(quotes[i], wo, tenantRequest.data, user))
          }
        }

        setQuotes(mapped)
        setIsClient(data?.isClient)
        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const renderModals = () => {
    return (
      <QuotesModal
        viewOnly={viewOnly}
        mode={mode}
        selectedData={selectedData}
        workOrderId={workOrderId}
        isClient={isClient}
        handleOk={() => {
          fetchData()
          refreshWO()
          setShowQuoteModal(false)
        }}
        handleClose={() => {
          setShowQuoteModal(false)
        }}
        show={showQuoteModal}
        thresholdMoney={workOrder?.tenant?.thresholdMoney ?? 0}
        isFromChildWorkOrder={workOrder?.workOrderId ? true : false}
      />
    )
  }

  const deleteModals = () => {
    return (
      <SweetAlertWithProp
        danger
        title='Warning'
        onConfirm={async () => {
          var response = await QuotesClient.Delete(selectedData.id)

          fetchData()
          setShowDelete(false)
          refreshWO()
        }}
        onCancel={() => setShowDelete(false)}
        confirmBtnText={'Confirm'}
        cancelBtnBsStyle='light'
        show={showDelete}
        showCancel={true}
        showCloseButton
      >
        <span>
          <p>Are you sure you want to delete quote?</p>
        </span>
      </SweetAlertWithProp>
    )
  }
  const quotePopover = (quote: any) => {
    return (
      <Popover id='quotePopover' style={{maxWidth: '900px'}}>
        <Popover.Title
          as='h3'
          style={{width: '900px'}}
        >{`QO-${quote?.quoteNumberId}`}</Popover.Title>
        <Popover.Content>
          <table>
            <tr>
              <td style={{paddingRight: '175px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>QO #</div>
                  <div className='text-gray-600'>QO-{quote?.quoteNumberId}</div>
                </div>
              </td>
              <td style={{paddingRight: '71px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Ref #</div>
                  <div className='text-gray-600'>{quote?.referenceNumber}</div>
                </div>
              </td>
              <td style={{paddingRight: '152px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Amount</div>
                  <div className='text-gray-600'>$ {quote?.estimate}</div>
                </div>
              </td>
              <td style={{paddingRight: '45px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Description</div>
                  <div className='text-gray-600'>{quote?.description}</div>
                </div>
              </td>
            </tr>
          </table>

          <table>
            <tr>
              <td style={{paddingRight: '45px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Created On</div>
                  <div className='text-gray-600'>
                    <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                      {quote?.created}
                    </Moment>
                  </div>
                </div>
              </td>
              <td style={{paddingRight: '45px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Created By</div>
                  <div className='text-gray-600'>
                    {quote?.createdBy?.name} {quote?.createdBy?.surname}
                  </div>
                </div>
              </td>
              <td style={{paddingRight: '45px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Processed On</div>
                  <div className='text-gray-600'>
                    {quote.processedOn && (
                      <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                        {quote?.processedOn}
                      </Moment>
                    )}
                  </div>
                </div>
              </td>
              <td style={{paddingRight: '45px'}}>
                <div className='fs-6'>
                  <div className='fw-bolder mt-5'>Processed By</div>
                  <div className='text-gray-600'>
                    {quote?.processedBy?.name} {quote?.processedBy?.surname}
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </Popover.Content>
      </Popover>
    )
  }

  return (
    <>
      {deleteModals()}
      {renderModals()}
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
              </span>
              <input
                type='text'
                data-kt-notice-type-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Quotes'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                {!isParentWorkOrder &&
                  workOrder?.assignedToTenantId &&
                  user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Client &&
                  ((user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Contractor &&
                    Quotes.length > 0) ||
                    user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor) && (
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      onMouseOver={() => {
                        setMode('Add')
                        setViewOnly(false)
                      }}
                      onClick={() => {
                        setMode('Add')
                        setViewOnly(false)
                        setShowQuoteModal(true)
                      }}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                      Add Quote
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'QO #', sort: 'quoteNumberId', width: '10'},
                  {name: 'Created', sort: 'created', width: '15'},
                  {name: 'Created By', sort: 'createdBy', width: '10'},
                  // {name: 'Description', sort: 'description', width: '10'},
                  {name: 'Amount', sort: 'estimate', width: '10'},
                  {name: 'Status', sort: 'status', width: '10'},
                  {name: '', sort: '', static: true, width: '20'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    Quotes.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <OverlayTrigger
                            overlay={<Tooltip id='tooltip-disabled'>{quotePopover(item)}</Tooltip>}
                            placement='right'
                            trigger='hover'
                          >
                            <Link
                              className='fw-bolder text-dark ps-3'
                              to={`/work-orders/details/` + item?.id}
                            >
                              {`QO-${item?.quoteNumberId}`}
                            </Link>
                          </OverlayTrigger>
                          {!item.quoteNumberId && <i>Not specified</i>}
                        </td>
                        <td>
                          {item.created && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                {item.created}
                              </Moment>
                            </a>
                          )}
                          {!item.created && <i>Not specified</i>}
                        </td>
                        <td>
                          {item.createdBy && (
                            <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.createdBy?.name} {item.createdBy.surname}
                            </span>
                          )}
                          {!item.createdBy && <i>Not specified</i>}
                        </td>

                        {/* <td>
                          {item.description && (
                            <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.description.length >= 150 && (
                                <span>{item.description.substring(0, 150)}...</span>
                              )}
                              {item.description.length < 150 && item.description}
                            </span>
                          )}
                          {!item.description && <i>Not specified</i>}
                        </td> */}

                        <td>
                          {user?.tenant?.tenantTypeId?.toUpperCase() !==
                            TenantTypeIds.Contractor && (
                            <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {toMoneyFormat(item.totalCost)}
                            </span>
                          )}

                          {user?.tenant?.tenantTypeId?.toUpperCase() ===
                            TenantTypeIds.Contractor && (
                            <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {toMoneyFormat(item.estimate)}
                            </span>
                          )}
                        </td>

                        <td>
                          <div className='d-flex align-items-center rounded p-5'>
                            {item.quoteStatus?.color && (
                              <span
                                className={`bullet bullet-vertical h-30px `}
                                style={
                                  item.quoteStatus?.color && {
                                    background: item.quoteStatus?.color,
                                  }
                                }
                              ></span>
                            )}{' '}
                            <div
                              className='flex-grow-1 me-2'
                              style={
                                item.quoteStatus?.color && {
                                  paddingLeft: '5px',
                                }
                              }
                            >
                              <span className='fw-bolder text-gray-800 fs-6'>
                                {item.quoteStatus?.name}
                              </span>
                              {/* <span class='text-muted fw-bold d-block'>Due in 5 Days</span> */}
                            </div>
                          </div>
                          {/* <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                            {item.workOrderStatus?.name}{' '}
                            <i
                              className='fa fa-genderless fs-1'
                              style={{color: item.workOrderStatus?.color}}
                            ></i>
                          </span> */}
                        </td>

                        <td className='text-end'>
                          {item.quoteStatus?.name === 'Submitted' && item.hasDeleteAccess && (
                            <button
                              type='button'
                              className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                              onMouseOver={() => {
                                setSelectedData(item)
                              }}
                              onClick={() => {
                                setSelectedData(item)
                                setShowDelete(true)
                              }}
                            >
                              Delete
                            </button>
                          )}
                          {item.quoteStatus?.name === 'Submitted' &&
                            user.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Client && (
                              <button
                                type='button'
                                className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                onMouseOver={() => {
                                  setSelectedData(item)
                                  setViewOnly(false)
                                  setMode('Edit')
                                }}
                                onClick={() => {
                                  setSelectedData(item)
                                  setViewOnly(false)
                                  setMode('Edit')
                                  setShowQuoteModal(true)
                                }}
                              >
                                Edit
                              </button>
                            )}
                          <button
                            type='button'
                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            onMouseOver={() => {
                              setSelectedData(item)
                              setViewOnly(true)
                              setMode('View')
                            }}
                            onClick={() => {
                              setSelectedData(item)
                              setViewOnly(true)
                              setMode('View')
                              setShowQuoteModal(true)
                            }}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-notice-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuotesList
