import React, {useState, useEffect, useRef} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Col} from 'reactstrap'
import Select from 'react-select'
import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import _ from 'lodash'
import HazardTypeSelector from '../../../common/components/selectors/HazardTypeSelector'
import HazardStatusSelector from '../../../common/components/selectors/HazardStatusSelector'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import SiteSelector from '../../../common/components/selectors/SiteSelector'
import qs from 'query-string'
import {HazardsClient} from '../../../infrastracture/api/HazardsClient'
import {StepperComponent} from '../../../../_metronic/assets/ts/components/_StepperComponent'
import {AttachmentsClient} from '../../../infrastracture/api/AttachmentsClient'

interface Props {
  viewOnly?: boolean
}

const MobileHazardForm: React.FC<Props> = (props) => {
  const {viewOnly} = props
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>(false)
  const history = useHistory()
  const params = useParams()
  const location = useLocation()

  const validationSchema = Yup.object().shape({
    typeId: Yup.string().required('This field is required'),
    statusId: Yup.string().required('This field is required'),
    description: Yup.string().required('This field is required'),
    actionTaken: Yup.string().required('This field is required'),
  })

  const initialState = {
    siteId: '',
    site: {} as any,
    clientId: '',
    client: {} as any,
    closedOn: null,
    closedNote: '',
    typeId: '',
    type: {} as any,
    statusId: '',
    status: {} as any,
    description: '',
    location: '',
    actionTaken: '',
    stillPresent: false,
    photos: [],
  }

  const [initialValues, setInitialValues] = useState(initialState)
  const [message, setMessage] = useState<any>({})

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null) as any

  const [currentStep, setCurrentStep] = useState(1)
  const [files, setFiles] = useState<any>([])
  const [success, setSuccess] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    //check screen width on load
    handleResize()
  }, [])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    setCurrentStep(currentStep - 1)
    stepper.current.goPrev()
  }

  useEffect(() => {
    setLoading(true)
    const value = qs.parse(location.search)
    const {userId, workOrderId} = value

    if (userId && workOrderId) {
      getDetails(userId, workOrderId)
    } else {
      history.push('/hazard-mobile-error')
      setLoading(false)
    }
  }, [location])

  const getDetails = async (userId: any, workOrderId: any) => {
    var response = await HazardsClient.GetHazardMobileInfo(userId, workOrderId)
    const {data} = response

    if (data?.user && data?.workOrder) {
      setData({...data, userId: userId, workOrderId: workOrderId})
      setInitialValues({
        ...initialValues,
        site: {value: data?.workOrder?.site?.id, label: data?.workOrder?.site?.name},
        siteId: data?.workOrder?.site?.id,
        client: {value: data?.workOrder?.tenant?.id, label: data?.workOrder?.tenant?.name},
        clientId: data?.workOrder?.tenant?.id,
      })
      setLoading(false)
    } else {
      history.push('/hazard-mobile-error')
      setLoading(false)
    }
  }

  const inputElement = React.useRef<any>()

  return (
    <>
      {loading && (
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-lg-600px p-10 p-lg-15 mx-auto'>
              <div className='text-center d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
                Loading...
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={
          !loading
            ? {}
            : {
                display: 'none',
              }
        }
      >
        <div className='d-flex flex-center flex-column flex-column-fluid'>
          <div
            className='bg-white rounded shadow-sm'
            style={isMobile ? {width: '95%', margin: '10px'} : {width: '600px', margin: '20px'}}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
                var response: any

                const {
                  siteId,
                  site,
                  closedOn,
                  closedNote,
                  typeId,
                  type,
                  statusId,
                  status,
                  description,
                  location,
                  actionTaken,
                  stillPresent,
                  clientId,
                } = values

                response = await HazardsClient.CreateStandAlone(
                  siteId,
                  clientId,
                  closedOn,
                  closedNote,
                  typeId,
                  statusId,
                  description,
                  location,
                  actionTaken,
                  stillPresent,
                  data.userId
                )
                if (!response.successful) {
                  //show error
                } else {
                  if (files.length > 0) {
                    files.forEach(async (file: any) => {
                      await AttachmentsClient.CreateStandAlone(
                        response.data.id,
                        null,
                        file.name,
                        file,
                        data.userId
                      )
                    })
                  }
                  setFiles([])
                  setSubmitting(false)

                  history.push('/hazard-mobile-success')
                }
              }}
              enableReinitialize
            >
              {({
                errors,
                touched,
                isSubmitting,
                values,
                setFieldValue,
                resetForm,
                submitForm,
                validateForm,
                setFieldError,
              }: any) => (
                <>
                  <Form>
                    <div className='card shadow-sm'>
                      <div className='card-header'>
                        <h3 className='card-title'>
                          Add Hazard
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </h3>
                        <div className='card-toolbar'></div>
                      </div>
                      <div className='card-body py-5'>
                        <div
                          ref={stepperRef}
                          className='stepper stepper-pills'
                          id='kt_hazard_form_stepper'
                        >
                          <div
                            className={`stepper-nav stepper-nav mb-10 ${
                              isMobile ? 'flex-wrap' : 'flex-center flex-wrap'
                            }`}
                          >
                            <div className='stepper-item current' data-kt-stepper-element='nav'>
                              <div className='stepper-line w-40px'></div>

                              <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>1</span>
                              </div>

                              <div className='stepper-label'>
                                <h3 className='stepper-title'>Details</h3>
                              </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                              <div className='stepper-line w-40px'></div>

                              <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>2</span>
                              </div>

                              <div className='stepper-label'>
                                <h3 className='stepper-title'>Photos</h3>
                              </div>
                            </div>
                          </div>

                          <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
                            <Form className='py-1 w-100 px-1' noValidate id='kt_hazard_form_form'>
                              <div className='current' data-kt-stepper-element='content'>
                                <table className='scroll-y table table-row-dashed gy-5'>
                                  <tbody className='fw-bold text-gray-600 w-100'>
                                    <tr>
                                      <td>
                                        <div>
                                          <div className='form-label'>Client</div>
                                          <div className='text-dark-600 fw-bolder fs-5'>
                                            {values.client?.label}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div>
                                          <div className='form-label'>Site</div>
                                          <div className='text-dark-600 fw-bolder fs-5'>
                                            {values.site?.label}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label className='form-label'> Type </label>
                                        <HazardTypeSelector
                                          isPublic
                                          placeholder='Select Hazard type'
                                          defaultValue={values.type}
                                          onChange={(name, value, label) => {
                                            setFieldValue('typeId', value)
                                            setFieldValue('type', {value, label})
                                          }}
                                          disabled={viewOnly}
                                        />
                                        {errors.type && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.type}
                                            </span>
                                          </div>
                                        )}
                                      </td>{' '}
                                    </tr>
                                    <tr>
                                      <td>
                                        <label className='form-label'> Status </label>
                                        <HazardStatusSelector
                                          isPublic
                                          placeholder='Select Hazard Status'
                                          defaultValue={values.status}
                                          onChange={(name, value, label) => {
                                            setFieldValue('statusId', value)
                                            setFieldValue('status', {value, label})
                                          }}
                                          setDefault={(name, value, label) => {
                                            setFieldValue('statusId', value)
                                            setFieldValue('status', {value, label})
                                          }}
                                          disabled={viewOnly}
                                        />
                                        {errors.status && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.status}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <label className='required form-label'>Description</label>

                                        <textarea
                                          name='description'
                                          placeholder=''
                                          className='form-control form-control-solid'
                                          id='description'
                                          onChange={(e) => {
                                            var value = e.target.value
                                            setFieldValue('description', value)
                                          }}
                                          rows={5}
                                          defaultValue={values.description || ''}
                                        ></textarea>
                                        {errors.description && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.description}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>

                                    {/* <tr>
                                      <td>
                                        <label className='required form-label'>Closed Note</label>

                                        <textarea
                                          name='closedNote'
                                          placeholder=''
                                          className='form-control form-control-solid'
                                          id='closedNote'
                                          disabled={viewOnly}
                                          onChange={(e) => {
                                            var value = e.target.value
                                            setFieldValue('closedNote', value)
                                          }}
                                          rows={3}
                                          defaultValue={values.closedNote || ''}
                                        ></textarea>
                                        {errors.closedNote && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.closedNote}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr> */}
                                    <tr>
                                      <td>
                                        <label className='form-label'> Location</label>
                                        <Field
                                          name='location'
                                          placeholder=''
                                          type='text'
                                          className='form-control form-control-solid'
                                          id='location'
                                        />
                                        {errors.location && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.location}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <label className='required form-label'>Action Taken</label>

                                        <textarea
                                          name='actionTaken'
                                          placeholder=''
                                          className='form-control form-control-solid'
                                          id='actionTaken'
                                          disabled={viewOnly}
                                          onChange={(e) => {
                                            var value = e.target.value
                                            setFieldValue('actionTaken', value)
                                          }}
                                          rows={3}
                                          defaultValue={values.actionTaken || ''}
                                        ></textarea>
                                        {errors.actionTaken && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.actionTaken}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className='form-check form-switch form-check-custom form-check-solid'>
                                          <label className='form-check-label pe-2'>
                                            Still present
                                          </label>
                                          <Field
                                            className='form-check-input pe-2'
                                            type='checkbox'
                                            value=''
                                            id='stillPresent'
                                            name='stillPresent'
                                            checked={values.stillPresent}
                                          />
                                          <span className='ps-2'>
                                            {values.stillPresent ? 'Yes' : 'No'}
                                          </span>
                                        </div>
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div data-kt-stepper-element='content'>
                                <table className='scroll-y table table-row-dashed gy-5'>
                                  <tbody className='fw-bold text-gray-600 w-100'>
                                    <tr>
                                      <td>
                                        <input
                                          type='button'
                                          value='Add file'
                                          className='form-control form-control-solid'
                                          onClick={() => {
                                            inputElement.current.click()
                                          }}
                                        />
                                        <input
                                          type='file'
                                          id='selectedFile'
                                          className='form-control form-control-solid'
                                          onChange={(e) => {
                                            if (!e.target.files) return

                                            setFiles([...files, e.target.files[0]])
                                          }}
                                          accept={'image/*'}
                                          ref={inputElement}
                                          style={{display: 'none'}}
                                        />
                                        {touched.file && errors.file && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.file}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                    {files.length > 0 && (
                                      <tr>
                                        <td>
                                          <ul>
                                            {files.map((file: any) => (
                                              <li>{file.name}</li>
                                            ))}
                                          </ul>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div className='d-flex flex-stack pt-10'>
                                <div className='mr-2'>
                                  <button
                                    onClick={prevStep}
                                    type='button'
                                    className='btn btn-lg btn-light-primary me-3'
                                    data-kt-stepper-action='previous'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr063.svg'
                                      className='svg-icon-4 me-1'
                                    />
                                    Back
                                  </button>
                                </div>
                                <div>
                                  <button
                                    type='submit'
                                    className='btn btn-lg btn-primary me-3'
                                    disabled={isSubmitting}
                                    onClick={async (e) => {
                                      e.preventDefault()

                                      var isValid = await validateForm()

                                      const {
                                        siteId,
                                        site,
                                        closedOn,
                                        closedNote,
                                        typeId,
                                        type,
                                        statusId,
                                        status,
                                        description,
                                        location,
                                        actionTaken,
                                        stillPresent,
                                        clientId,
                                      } = values

                                      if (!typeId) {
                                        setFieldError('type', 'This field is required')
                                        return
                                      }
                                      if (!statusId) {
                                        setFieldError('status', 'This field is required')
                                        return
                                      }
                                      if (!description) {
                                        setFieldError('description', 'This field is required')
                                        return
                                      }
                                      // if (!closedNote) {
                                      //   setFieldError('closedNote', 'This field is required')
                                      //   return
                                      // }
                                      if (!actionTaken) {
                                        setFieldError('actionTaken', 'This field is required')
                                        return
                                      }
                                      if (currentStep !== 2) {
                                        setCurrentStep(currentStep + 1)
                                        stepper.current.goNext()
                                      } else {
                                        submitForm()
                                      }
                                    }}
                                  >
                                    {isSubmitting ? (
                                      <span
                                        className='indicator-progress'
                                        style={{display: 'block'}}
                                      >
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    ) : currentStep !== 2 ? (
                                      'Next'
                                    ) : (
                                      'Save'
                                    )}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                      <div className='card-footer'>
                        {/* <button
              type='button'
              onClick={() => {
                resetForm()
                history.goBack()
              }}
              className='btn btn-light'
              data-bs-dismiss='modal'
              id='rd_workOrder_modal_dismiss'
            >
              Discard
            </button>  */}
                        {/* {!viewOnly && (
              <button
                type='submit'
                id='rd_create_workOrder_submit'
                className='btn btn-lg btn-primary'
                disabled={isSubmitting}
              >
                {!isSubmitting && <span className='indicator-label'>Save</span>}
                {isSubmitting && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )} */}
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileHazardForm
