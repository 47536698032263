import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Schedules SetFilter] Action',
  ResetFilter: '[Schedules ResetFilter] Action',
}

const initialSchedulesState: ISchedulesState = {
  filter: {
    clientId: '',
    statusId: '',
    siteId: '',
    expiresInThreeMonths: false,
    expiresInSixMonths: false,
    frequencyTypeId: '',
  },
}

export interface ISchedulesState {
  filter?: {
    clientId: string
    statusId: string
    siteId: string
    expiresInThreeMonths: boolean
    expiresInSixMonths: boolean
    frequencyTypeId: any
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Schedules', whitelist: ['filter']},
  (state: ISchedulesState = initialSchedulesState, action: ActionWithPayload<ISchedulesState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialSchedulesState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
