import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserQueryParams, UsersClient} from '../../../infrastracture/api/UsersClient'
import {WorkOrderQueryParams, WorkOrderClient} from '../../../infrastracture/api/WorkOrderClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import Moment from 'react-moment'

interface Props {
  workOrderId: string
}

const WorkOrderHistoryList: React.FC<Props> = (props) => {
  const {workOrderId} = props

  const [workOrder, setWorkOrder] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState('')
  const [viewOnly, setViewOnly] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'name',
    direction: 'asc',
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  useEffect(() => {
    fetchData()
  }, [workOrderId])

  async function fetchData() {
    setLoading(true)
    try {
      var query = new WorkOrderQueryParams()

      var response = await WorkOrderClient.GetHistory(
        workOrderId,
        query
          .Paginate(pagination.skip, pagination.take)
          .Search(search)
          .WithActive(active)
          .WithStatusChanged(true)
          .Sort(pagination.sort, pagination.direction)
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setWorkOrder(data?.data)

        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <h2 className='text-gray-600'>Work Order History</h2>
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'></div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'Time Stamp', sort: 'created', width: '15'},
                  {name: 'Status', sort: 'workOrderStatus.name', width: '15'},
                  {name: 'Updated By', sort: 'active', width: '15'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    workOrder.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.created && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                {item.created}
                              </Moment>
                            </a>
                          )}
                          {!item.created && <i>Not specified</i>}
                        </td>

                        <td>
                          {item.workOrderStatus && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.workOrderStatus?.name}
                            </a>
                          )}
                          {!item.workOrderStatus && <i>Not specified</i>}
                        </td>

                        <td>
                          {item.updatedBy && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.updatedBy?.name} {item.updatedBy?.surname}
                            </a>
                          )}
                          {!item.updatedBy && <i>Not specified</i>}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-notice-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkOrderHistoryList
