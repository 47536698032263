import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class ChargeTypesQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithFeesOnly(value: boolean) {
        this.WithParam('feesOnly', value)
        return this
    }
    WithNonFeesOnly(value: boolean) {
        this.WithParam('nonFeesOnly', value)
        return this
    }
}

export class ChargeTypesClient {
    static async List(params: ChargeTypesQueryParams) {

        return await ApiClient.Get('/charge-types' + params.GenerateQueryString());

    }

    static async Get(id: string) {
        return await ApiClient.Get('/charge-types/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/charge-types/' + id);
    }

    static async Create(
        name: string,
        description: string,
        active: boolean,
        singleOnly: boolean,
        fee: boolean
    ) {
        return await ApiClient.Post('/charge-types/', {
            name,
            description,
            active,
            singleOnly,
            fee,
        });
    }

    static async Update(
        id: string,
        name: string,
        description: string,
        active: boolean,
        singleOnly: boolean,
        fee: boolean
    ) {

        return await ApiClient.Patch('/charge-types/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
            {
                op: 'replace',
                path: 'singleOnly',
                value: singleOnly,
            },
            {
                op: 'replace',
                path: 'fee',
                value: fee,
            },
        ]);
    }
}
