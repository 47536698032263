import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class ParticipantQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithUserId(value: string) {
        this.WithParam('userId', value)
        return this
    }
    WithWorkOrderId(value: string) {
        this.WithParam('workOrderId', value)
        return this
    }
}

export class ParticipantsClient {
    static async List(params: ParticipantQueryParams) {
        return await ApiClient.Get('/participants' + params.GenerateQueryString());
    }

    static async ListAvailableParticipants(params: ParticipantQueryParams) {
        return await ApiClient.Get('/participants/available' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/participants/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/participants/' + id);
    }

    static async Create(workOrderId: string, userIds: string[]) {
        return await ApiClient.Post('/participants/', {
            workOrderId: workOrderId,
            userIds: userIds,
        });
    }

    static async Update(id: string, workOrderId: string, userId: string) {
        return await ApiClient.Patch('/participants/' + id, [
            {
                op: 'replace',
                path: 'workOrderId',
                value: workOrderId,
            },
            {
                op: 'replace',
                path: 'userId',
                value: userId,
            },
        ]);
    }
}
