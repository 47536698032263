import {Action} from '@reduxjs/toolkit'
import moment from 'moment'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[CheckIn SetFilter] Action',
  ResetFilter: '[CheckIn ResetFilter] Action',
}

const initialCheckInState: ICheckInState = {
  filter: {
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    siteId: '',
    clientId: '',
    assignedToId: '',
    current: true,
  },
}

export interface ICheckInState {
  filter?: {
    siteId: string
    clientId: string
    assignedToId: string
    current: boolean
    startDate: any
    endDate: any
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-CheckIn', whitelist: ['filter']},
  (state: ICheckInState = initialCheckInState, action: ActionWithPayload<ICheckInState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialCheckInState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
