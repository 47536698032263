import * as React from 'react'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { NotesQueryParams, NotesClient } from '../../../infrastracture/api/NotesClient'
import { TableHeader } from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import NotesModal from './NotesModal'
import Moment from 'react-moment'
import { Button } from 'reactstrap'
import { SweetAlertWithProp } from '../../../../SweetAlertWithProp'

interface Props {
    workOrderId: string
    title?: any
    refreshWO: () => void
}
const NotesList: React.FC<Props> = (props) => {
    const { workOrderId, refreshWO } = props
    const [Notes, setNotes] = useState<Array<any>>([])
    const [search, setSearch] = useState('')
    const [active, setActive] = useState(true)
    const [selectedData, setSelectedData] = useState<any>({})
    const [mode, setMode] = useState('')
    const [viewOnly, setViewOnly] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalRecords: 1,
        totalPages: 1,
        skip: 0,
        take: 50,
        reset: true,
        sort: 'created',
        direction: 'desc',
    })

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (pagination.reset) fetchData()
    }, [search, pagination])

    useEffect(() => {
        if (workOrderId) fetchData()
    }, [workOrderId])

    async function fetchData() {
        setLoading(true)
        try {
            var query = new NotesQueryParams()

            var response = await NotesClient.List(
                query
                    .Paginate(pagination.skip, pagination.take)
                    .Search(search)
                    .WithActive(active)
                    .WithWorkOrderId(workOrderId)
                    .Sort(pagination.sort, pagination.direction)
            )

            if (!response.authenticated) {
            } else {
                const { data } = response
                setNotes(data?.data)

                const totalPages = Math.ceil(data.total / pagination.take)

                setPagination({
                    ...pagination,
                    totalPages,
                    totalRecords: data.total,
                    reset: false,
                })
            }
            setLoading(false)
        } catch (e) {
            console.error(e)
        }
    }

    const renderModals = () => {
        return (<>
            <NotesModal
                viewOnly={viewOnly}
                mode={mode}
                selectedData={selectedData}
                workOrderId={workOrderId}
                handleOk={() => {
                    fetchData()
                    refreshWO()
                }}
            />
            <SweetAlertWithProp
                danger
                title='Warning'
                onConfirm={async () => {
                    setShowDelete(false)
                    await NotesClient.Delete(selectedData.id)
                    await fetchData()
                }}
                onCancel={() => setShowDelete(false)}
                confirmBtnText={'Confirm'}
                cancelBtnBsStyle='light'
                show={showDelete}
                showCancel={true}
                showCloseButton
            >
                <span>
                    <p>
                        Are you sure you want to delete the note?
                    </p>
                </span>
            </SweetAlertWithProp>
        </>)
    }

    const permissions: any = useSelector<RootState>(
        ({ auth }) => auth.userPermission,
        shallowEqual
    ) as any

    return (
        <>
            {renderModals()}
            <div className={`card`}>
                <div className='card-header'>
                    <div className='card-title'>
                        <div className='d-flex align-items-center position-relative my-1'>
                            <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
                            </span>
                            <input
                                type='text'
                                data-kt-notice-type-table-filter='search'
                                className='form-control form-control-solid w-250px ps-14'
                                placeholder='Search Notes'
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                            {loading && (
                                <div className='d-flex align-items-center'>
                                    <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-light-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#notes_modal'
                                    onMouseOver={() => {
                                        setMode('Add')
                                        setViewOnly(false)
                                    }}
                                    onClick={() => {
                                        setMode('Add')
                                        setViewOnly(false)
                                    }}
                                >
                                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                                    Add Note
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table align-middle gs-0 gy-4'>
                            <TableHeader
                                sort={pagination.sort}
                                direction={pagination.direction}
                                columns={[
                                    { name: 'Created', sort: 'created', width: '20' },
                                    { name: 'Created By', sort: 'createdBy', width: '15' },
                                    { name: 'Description', sort: 'description', width: '50' },
                                    { name: '', sort: '', static: true },
                                ]}
                                onUpdate={(sort: any, direction: any) => {
                                    setPagination({
                                        ...pagination,
                                        sort,
                                        direction,
                                        reset: true,
                                    })
                                }}
                            />
                            {/* end::Table head */}
                            {/* begin::Table body */}

                            {!loading && (
                                <tbody>
                                    {!loading &&
                                        Notes.map((item) => (
                                            <tr key={item.id}>
                                                <td>
                                                    {item.created && (
                                                        <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                                                            <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                                                {item.created}
                                                            </Moment>
                                                        </a>
                                                    )}
                                                    {!item.created && <i>Not specified</i>}
                                                </td>
                                                <td>
                                                    {item.createdBy && (
                                                        <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                                                            {item.createdBy.name} {item.createdBy.surname}
                                                        </a>
                                                    )}
                                                    {!item.createdBy && <i>Not specified</i>}
                                                </td>

                                                <td
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#notes_modal'
                                                    onMouseOver={() => {
                                                        setMode('View')
                                                        setViewOnly(true)
                                                        setSelectedData(item)
                                                    }}
                                                    onClick={() => {
                                                        setMode('View')
                                                        setViewOnly(true)
                                                        setSelectedData(item)
                                                    }}
                                                >
                                                    {item.description && (
                                                        <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                                                            {item.description.length > 150
                                                                ? `${item.description.substring(0, 150)}...`
                                                                : item.description}
                                                        </a>
                                                    )}
                                                    {!item.description && <i>Not specified</i>}
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    {permissions.includes('notes.modify') && (
                                                        <Button className='btn btn-sm btn-light-primary me-3' data-bs-toggle='modal' data-bs-target='#notes_modal' onClick={() => {
                                                            setSelectedData(item)
                                                            setMode('Edit')
                                                            setViewOnly(false)
                                                        }}>Edit</Button>
                                                    )}

                                                    {permissions.includes('notes.delete') && (
                                                        <Button className='btn btn-sm btn-light-primary me-3' onClick={() => {
                                                            setShowDelete(true);
                                                        }}>Delete</Button>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            )}
                        </table>
                    </div>

                    <div className='card-footer px-1 py-4'>
                        <div className='d-flex justify-content-between'>
                            <div style={{ minWidth: '200px' }}>
                                <RecordPerPageSelector
                                    placeholder='Rows per page'
                                    onChange={(name, value) => {
                                        setPagination({
                                            ...pagination,
                                            take: value,
                                            reset: true,
                                            currentPage: 1,
                                        })
                                    }}
                                />
                            </div>
                            <div data-kt-notice-type-table-toolbar='base'>
                                <Pagination
                                    current={pagination.currentPage}
                                    total={pagination.totalPages}
                                    onPageChange={(page) => {
                                        setPagination({
                                            ...pagination,
                                            currentPage: page,
                                            skip: page === 1 ? 0 : (page - 1) * pagination.take,
                                            reset: true,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotesList
