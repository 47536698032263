import { ApiClient, PublicApiClient } from '../../../setup/axios/SetupAxios';
import { ListQueryParams } from '../ApiClient';

export class IncidentCauseQueryParams extends ListQueryParams {
    WithEnabled(value: boolean) {
        this.WithParam('active', value)
        return this
    }
}

export class IncidentCauseClient {
    static async List(params: IncidentCauseQueryParams) {
        return await ApiClient.Get('/incident-cause' + params.GenerateQueryString());
    }

    static async PublicList(params: IncidentCauseQueryParams) {
        return await PublicApiClient.Get('/incident-cause/public-list' + params.GenerateQueryString());
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/incident-cause/' + id);
    }

    static async Get(id: string) {
        return await ApiClient.Get('/incident-cause/' + id);
    }

    static async Create(name: string, color: string, active: boolean) {
        return await ApiClient.Post('/incident-cause/', {
            name,
            color,
            active,
        });
    }

    static async Update(id: string, name: string, color: string, active: boolean) {
        return await ApiClient.Patch('/incident-cause/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'color',
                value: color,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
        ]);
    }
}
