import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {NavItems} from '../components/WorkOrderDetails'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[WorkOrder SetFilter] Action',
  ResetFilter: '[WorkOrder ResetFilter] Action',
}

const initialWorkOrderState: IWorkOrderState = {
  filter: {
    siteId: '',
    site: null,
    client: null,
    clientId: '',
    workOrderType: null,
    workOrderTypeId: '',
    workOrderCategory: null,
    workOrderCategoryId: '',
    priority: null,
    priorityId: '',
    workOrderStatus: null,
    workOrderStatusId: '',
    assignedToTenant: null,
    assignedToTenantId: '',
    ignoreComplete: true,
    showOperationalClients: false,
    pagination: {
      currentPage: 1,
      totalRecords: 1,
      totalPages: 1,
      skip: 0,
      take: 50,
      reset: true,
      sort: 'workOrderNumberId',
      direction: 'desc',
    },
    startCreateDate: '',
    endCreateDate: '',
    startCompletedDate: '',
    endCompletedDate: '',
    selectedTab: NavItems.Notes,
    onBehalfOfTenant: null,
    onBehalfOfTenantId: '',
    department: null,
    departmentId: '',
    createdBy: null,
    createdById: '',
    workOrderFilterType: {label: 'All', value: 'all'},
    workOrderFilterTypeId: 'all',
  },
}

export interface IWorkOrderState {
  filter?: {
    site: any
    siteId: string
    client: any
    clientId: string
    workOrderType: any
    workOrderTypeId: string
    workOrderCategory: any
    workOrderCategoryId: string
    priority: any
    priorityId: string
    workOrderStatus: any
    workOrderStatusId: string
    assignedToTenant: any
    assignedToTenantId: string
    ignoreComplete: boolean
    showOperationalClients: boolean
    pagination: any
    startCreateDate: any
    endCreateDate: any
    startCompletedDate: any
    endCompletedDate: any
    selectedTab: any
    onBehalfOfTenant: any
    onBehalfOfTenantId: any
    department: any
    departmentId: any
    createdBy: any
    createdById: string
    workOrderFilterType: any
    workOrderFilterTypeId: string
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-WorkOrder', whitelist: ['filter']},
  (state: IWorkOrderState = initialWorkOrderState, action: ActionWithPayload<IWorkOrderState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialWorkOrderState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
