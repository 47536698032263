import React, {useState, useEffect} from 'react'
import {Col, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Moment from 'react-moment'
import * as Yup from 'yup'
import SweetAlert from 'react-bootstrap-sweetalert'
import Select from 'react-select'
import moment from 'moment'
import {CheckInsClient, CheckInsQueryParams} from '../../../infrastracture/api/CheckInsClient'
import customStyles from '../../../common/components/selectors/customStyles'
import {checkInTimeOptions} from './options'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'
import {minutesToHourFormat} from '../../../common/helpers/stringHelper'

interface Props {
  handleOk: () => void
  handleClose: () => void
  show: boolean
  checkIn: any
}

const EditCheckinModal: React.FC<Props> = (props) => {
  const {handleClose, handleOk, show, checkIn} = props
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const [checkInTime, setCheckInTime] = useState<any>()
  const [checkOutTime, setCheckOutTime] = useState<any>()

  useEffect(() => {
    if (checkIn.checkedIn) {
      setCheckInTime(moment(checkIn.checkedIn).format('YYYY-MM-DDTHH:mm'))
    } else {
      setCheckInTime(null)
    }

    if (checkIn.checkOut) {
      setCheckOutTime(moment(checkIn.checkOut).format('YYYY-MM-DDTHH:mm'))
    } else {
      setCheckOutTime(null)
    }
  }, [checkIn])

  const submit = async () => {
    setLoading(true)

    //NOTE: Refer to CheckInsModal on how to update check ins

    var new_checkIn = new Date(moment(checkInTime).format('ddd DD MMM, YYYY h:mm a')).toISOString()
    var new_checkOut = checkOutTime
      ? new Date(moment(checkOutTime).format('ddd DD MMM, YYYY h:mm a')).toISOString()
      : null
    var diff = moment.duration(moment(checkOutTime).diff(moment(checkInTime)))
    var new_duration = diff.asMinutes()
    var response = await CheckInsClient.Update(
      checkIn.id,
      new_checkIn,
      new_checkOut ? new_duration : checkIn.timeOnSite,
      null, //coord.lat == 0 ? null : coord.lat,
      null, //coord.long == 0 ? null : coord.long,
      new_checkOut,
      new_checkOut ? new_checkOut : null,
      checkIn.workOrderComplete,
      checkIn.leaveSiteReasonId,
      checkIn.comment,
      true
    )

    if (!response.successful) {
      //show error
    } else {
      handleOk()
      setSuccess(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal isOpen={show} onHide={() => {}} zIndex={9999}>
        <ModalHeader closeButton>
          <h5 className='modal-title'>Edit Check In</h5>
        </ModalHeader>
        <ModalBody>
          <table className='scroll-y table table-row-dashed gy-5'>
            <tbody className='fw-bold text-gray-600 w-100'>
              <tr>
                <td>
                  <label className='required form-label'>Check In</label>

                  <input
                    type='datetime-local'
                    name='dateTime'
                    placeholder=''
                    className='form-control form-control-solid'
                    id='dateTime'
                    value={checkInTime}
                    defaultValue={checkInTime}
                    onChange={(e) => {
                      var value = e.target.value

                      setCheckInTime(moment(value).format('YYYY-MM-DDTHH:mm'))
                    }}
                  />

                  {/* {touched.dateTime && errors.dateTime && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger' role='alert'>
                        {errors.dateTime}
                      </span>
                    </div>
                  )} */}
                </td>
              </tr>
              <tr>
                <td>
                  <label className='required form-label'>Check Out</label>

                  <input
                    type='datetime-local'
                    name='dateTime'
                    placeholder=''
                    className='form-control form-control-solid'
                    id='dateTime'
                    value={checkOutTime}
                    defaultValue={checkOutTime}
                    onChange={(e) => {
                      var value = e.target.value

                      setCheckOutTime(moment(value).format('YYYY-MM-DDTHH:mm'))
                    }}
                  />

                  {/* {touched.dateTime && errors.dateTime && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger' role='alert'>
                        {errors.dateTime}
                      </span>
                    </div>
                  )} */}
                </td>
              </tr>
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            onClick={() => {
              handleClose()
            }}
            className='btn btn-light'
            data-bs-dismiss='modal'
            id='rd_Library_modal_dismiss'
          >
            Close
          </button>

          <button
            type='submit'
            id='rd_create_tenant_submit'
            className='btn btn-lg btn-primary'
            disabled={loading}
            onClick={() => {
              submit()
            }}
          >
            {!loading && <span className='indicator-label'>Save</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </ModalFooter>
        {checkIn.checkInAudits && checkIn.checkInAudits.length > 0 && (
          <div style={{marginBottom: '15px'}}>
            {checkIn.checkInAudits.map((checkInAudit: any) => (
              <div style={{fontSize: '12px', padding: '5px 10px'}}>
              {checkInAudit?.updatedBy?.name} {checkInAudit?.updatedBy?.surname} -{' '}
              <Moment utc local format='DD MMM, YYYY h:mm a'>
                {checkInAudit.created}
              </Moment>{' '}
              (
              <Moment utc local format='DD MMM, YYYY h:mm a'>
                {checkInAudit.checkedIn}
              </Moment>{' '}
              -{' '}
              <Moment utc local format='DD MMM, YYYY h:mm a'>
                {checkInAudit.checkOut}
              </Moment>
                )
            </div>
          ))}
          </div>
        )}
      </Modal>
    </>
  )
}

export default EditCheckinModal
