import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import {connect, shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {AssetsClient, AssetsQueryParams} from '../../../infrastracture/api/AssetsClient'
import {Modes} from '../enum'
import Moment from 'react-moment'
import {Link} from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import {SchedulesClient} from '../../../infrastracture/api/SchedulesClient'
import {WorkOrderClient} from '../../../infrastracture/api/WorkOrderClient'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

interface Props {
  workOrderId: any
  siteId: any
  hideActions?: boolean
}

const WorkOrderAssetsList: React.FC<Props> = ({hideActions, workOrderId, siteId}) => {
  const [data, setData] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState(Modes.Add)
  const [viewOnly, setViewOnly] = useState(false)
  const [resetFilter, setResetFilter] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 0,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'name',
    direction: 'asc',
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  useEffect(() => {
    if (resetFilter) fetchData()
  }, [resetFilter])

  async function fetchData() {
    setLoading(true)
    try {
      var query = new AssetsQueryParams()

      if (workOrderId) query.WithWorkOrderId(workOrderId)

      var response = await AssetsClient.List(
        query
          .Paginate(pagination.skip, pagination.take)
          .Search(search)
          // .WithEnabled(active)
          .Sort(pagination.sort, pagination.direction)
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setData(data?.data)

        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })
      }
      setLoading(false)
      setResetFilter(false)
    } catch (e) {
      console.error(e)
    }
  }

  const [openLinkModal, setOpenLinkModal] = useState(false)

  const renderModals = () => {
    return (
      <>
        <SweetAlertWithProp
          danger
          title='Warning'
          onConfirm={async () => {
            var response = await WorkOrderClient.DeleteAsset(workOrderId, selectedData.id)
            fetchData()
            setShowDelete(false)
          }}
          onCancel={() => setShowDelete(false)}
          confirmBtnText={'Confirm'}
          cancelBtnBsStyle='light'
          show={showDelete}
          showCancel={true}
          showCloseButton
        >
          <span>
            <p>
              Are you sure you want to delete <b>{selectedData.name}</b>?
            </p>
          </span>
        </SweetAlertWithProp>
      </>
    )
  }

  const permissions: any = useSelector<RootState>(
    ({auth}) => auth.userPermission,
    shallowEqual
  ) as any

  async function refresh() {
    setResetFilter(true)
  }

  async function reset() {
    setResetFilter(true)
  }
  return (
    <>
      {renderModals()}
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
              </span>
              <input
                type='text'
                data-kt-site-type-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                onKeyPress={(e) => e.key === 'Enter' && fetchData()}
              />
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <span className='badge badge-square badge-primary me-3'>{pagination.totalRecords}</span>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {/* <button
                className='btn btn-sm btn-light-primary me-3'
                onClick={() => {
                  setOpenLinkModal(true)
                }}
              >
                Link Asset
              </button>  */}
              <Link
                className='btn btn-sm btn-light-primary me-3'
                to={`/work-orders/link-asset/${workOrderId}?siteId=${siteId}`}
              >
                Manage Assets
              </Link>
            </div>
            {/* <button
              type='button'
              className='btn btn-sm btn-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <span className='svg-icon svg-icon-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <path
                    d='M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z'
                    fill='black'
                  ></path>
                </svg>
              </span>
            </button>
            <SiteTypeFilters refresh={refresh} reset={reset} /> */}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'Name', sort: 'name'},
                  {name: 'Category', sort: 'assetCategory.name'},
                  {name: 'Type', sort: 'assetType.name'},
                  {name: 'Site', sort: 'site.name'},
                  {name: 'PPM', sort: 'ppm'},
                  {name: 'Expiry date', sort: 'expiryDate'},
                  // {name: '', sort: '', static: true, width: '15'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    data.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.name && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>{item.name}</a>
                          )}
                          {!item.name && <i>Not specified</i>}
                        </td>
                        <td>
                          {item.assetCategory && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.assetCategory?.name}
                            </a>
                          )}
                          {!item.assetCategory && <i>Not specified</i>}
                        </td>
                        <td>
                          {item.assetType && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.assetType?.name}
                            </a>
                          )}
                          {!item.assetType && <i>Not specified</i>}
                        </td>
                        <td>
                          {item.site && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.site?.name}
                            </a>
                          )}
                          {!item.site && <i>Not specified</i>}
                        </td>
                        <td>
                          <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                            {item.ppm ? 'Yes' : 'No'}
                          </a>
                        </td>
                        <td>
                          {item.expiryDate && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              <Moment utc local format='ddd DD MMM, YYYY'>
                                {item.expiryDate}
                              </Moment>
                            </a>
                          )}
                          {!item.expiryDate && <i>Not specified</i>}
                        </td>

                        <td className='text-end'>
                          {/* <Link
                            className='btn btn-sm btn-light-primary'
                            to={`/assets/details/${item.id}`}
                          >
                            Details
                          </Link> */}
                          {/* <button
                            type='button'
                            className='btn btn-danger btn-sm'
                            onMouseOver={() => {
                              setSelectedData(item)
                            }}
                            onClick={() => {
                              setSelectedData(item)
                              setShowDelete(true)
                            }}
                          >
                            Delete
                          </button> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-site-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorkOrderAssetsList
