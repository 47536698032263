import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
    SetFilter: '[ProposalSections SetFilter] Action',
    ResetFilter: '[ProposalSections ResetFilter] Action',
}

const initialProposalSectionsState: IProposalSectionsState = {
  filter: {
        includeInactive: false,
        search: undefined
  },
}

export interface IProposalSectionsState {
  filter?: {
    search?: string
    includeInactive: boolean
  }
}

export const reducer = persistReducer(
    { storage, key: 'v100-ProposalSections', whitelist: ['filter']},
  (
      state: IProposalSectionsState = initialProposalSectionsState,
      action: ActionWithPayload<IProposalSectionsState>
  ) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
            return initialProposalSectionsState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
