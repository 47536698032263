import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {LibraryClient, LibraryQueryParams} from '../../../infrastracture/api/LibraryClient'
import {Col, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Moment from 'react-moment'
import * as Yup from 'yup'
import SweetAlert from 'react-bootstrap-sweetalert'
import DocumentTypeSelector from '../../../common/components/selectors/DocumentTypeSelector'
import LibraryStatusSelector from '../../../common/components/selectors/LibraryStatusSelector'

import {LibraryModes, LibraryStatus, LibraryStatusLabel} from '../enums'
import moment from 'moment'
import {AttachmentsClient} from '../../../infrastracture/api/AttachmentsClient'

interface Props {
  handleOk: () => void
  handleClose: () => void
  show: boolean
  workOrderId: string
  uploadFile: (item: any) => void
  workSafeOnly: boolean
  checkInId?: string
  user?: any
}

const UploadFromLibraryModal: React.FC<Props> = (props) => {
  const {handleClose, handleOk, show, workOrderId, checkInId, uploadFile, workSafeOnly, user} =
    props
  const [createSuccess, setCreateSuccess] = useState(false)
  const params: any = useParams()
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [submittingIndex, setSubmittingIndex] = useState<any>(null)
  const [libraryList, setLibraryList] = useState<Array<any>>([])

  const initialState = {}

  const fetchData = async () => {
    setLoading(true)
    try {
      var query = new LibraryQueryParams()

      if (user) {
        query.WithForUserId(user.id)
      }

      var response = await LibraryClient.List(
        query
          .Paginate(0, 99999)
          .WithExcludeUploadedFromWorkOrderId(workOrderId)
          .WithWorkSafeOnly(workSafeOnly)
          .WithStatus(LibraryStatus.Active)
          .Sort('name', 'asc')
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setLibraryList(data?.data)
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [user])

  return (
    <>
      <Modal isOpen={show} onHide={() => {}} size='lg' zIndex={9999}>
        <ModalHeader closeButton>
          <h5 className='modal-title'>
            Available From Library
            {loading && (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            )}
          </h5>
        </ModalHeader>
        <ModalBody>
          <div
            className='scroll-y me-n7 pe-7'
            id='kt_modal_add_customer_scroll'
            data-kt-scroll='true'
            style={{maxHeight: '450px'}}
          >
            <table
              className='scroll-y table table-row-dashed gy-5'
              id='kt_table_users_login_session'
            >
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Created</th>
                  <th>Expires</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='fs-6 fw-bold text-gray-600'>
                {!loading &&
                  libraryList &&
                  libraryList.map((item: any, index: number) => (
                    <tr key={item.id}>
                      <td>
                        {item.name && (
                          <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 pl-1'>
                            {item.name}
                          </a>
                        )}
                        {!item.name && <i>Not specified</i>}
                      </td>

                      <td>
                        {item.documentType && (
                          <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                            {item.documentType?.name}
                          </a>
                        )}
                        {!item.documentType && <i>Not specified</i>}
                      </td>

                      <td>
                        <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                            {item.created}
                          </Moment>
                        </a>
                      </td>

                      <td>
                        <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                          <Moment utc local format='ddd DD MMM, YYYY'>
                            {item.expireDate}
                          </Moment>
                        </a>
                      </td>
                      <td className='text-end'>
                        <button
                          className='btn btn-lg btn-primary w-100'
                          disabled={isSubmitting}
                          onClick={async () => {
                            setSubmitting(true)
                            setSubmittingIndex(index)

                            await uploadFile(item)
                            fetchData()
                            setSubmitting(false)
                            setSubmittingIndex(null)
                          }}
                        >
                          {(!isSubmitting || submittingIndex !== index) && (
                            <span className='indicator-label'>Add</span>
                          )}
                          {isSubmitting && submittingIndex === index && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Adding...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            onClick={() => {
              handleClose()
            }}
            className='btn btn-light'
            data-bs-dismiss='modal'
            id='rd_Library_modal_dismiss'
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
      {/* <div className='modal fade' tabIndex={-1} id='library_modal'>
              <Form>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      
                    </div>

                    <div className='modal-body'>
                      
                    </div>
                    <div className='modal-footer'>
                    </div>
                  </div>
                </div>
              </Form>
            </div> */}
    </>
  )
}

export default UploadFromLibraryModal
