export const checkInTimeOptions = [
  {value: 30, label: '30 minutes'},
  {value: 60, label: '1 hour'},
  {value: 120, label: '2 hours'},
  {value: 180, label: '3 hours'},
  {value: 240, label: '4 hours'},
  {value: 300, label: '5 hours'},
  {value: 360, label: '6 hours'},
  {value: 420, label: '7 hours'},
  {value: 480, label: '8 hours'},
]
