import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {WorkOrderClient} from '../../../infrastracture/api/WorkOrderClient'
import {Col} from 'reactstrap'

import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import SweetAlert from 'react-bootstrap-sweetalert'
import TenantSelector from '../../../common/components/selectors/TenantSelector'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

interface Props {
  workOrder: any
  handleOk: () => void
}

const AssignToModal: React.FC<Props> = (props) => {
  const {workOrder, handleOk} = props
  const [createSuccess, setCreateSuccess] = useState(false)
  const params: any = useParams()

  const validationSchema = Yup.object().shape({})

  const initialState = {
    tenantId: '',
    tenant: {} as any,
  }

  const [initialValues, setInitialValues] = useState<any>(initialState)

  useEffect(() => {
    if (workOrder.assignedToTenant)
      setInitialValues({
          ...initialState,
          tenantId: workOrder.assignedToTenant?.id,
        tenant: {label: workOrder.assignedToTenant?.name, value: workOrder.assignedToTenant?.id},
      })
  }, [workOrder])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          var response: any

          if (workOrder.assignedToTenantId) {
            response = await WorkOrderClient.ChangeContractor(workOrder.id, values.tenantId)
          } else {
            response = await WorkOrderClient.AssignToTenant(workOrder.id, values.tenantId)
          }

          if (!response) {
            //show error
          } else {
            setSubmitting(false)
            document.getElementById('wo_assign_to_modal_dismiss')!.click()
            setCreateSuccess(true)
          }
        }}
        enableReinitialize
      >
        {({errors, touched, isSubmitting, values, setFieldValue, resetForm}: any) => (
          <>
            <SweetAlertWithProp
              success
              title='Success'
              onConfirm={() => {
                setCreateSuccess(false)
                handleOk()
              }}
              confirmBtnText='Confirm'
              cancelBtnBsStyle='light'
              show={createSuccess}
              showCancel={false}
              showCloseButton
            >
              Assigned to success
            </SweetAlertWithProp>
            <div className='modal fade' tabIndex={-1} id='wo_assign_to_modal'>
              <Form>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>Assign To</h5>

                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <span className='svg-icon svg-icon-2x'></span>
                      </div>
                    </div>

                    <div className='modal-body'>
                      <div
                        className='scroll-y me-n7 pe-7'
                        id='kt_modal_add_customer_scroll'
                        data-kt-scroll='true'
                        style={{maxHeight: '450px'}}
                      >
                        <table
                          className='scroll-y table table-row-dashed gy-5'
                          id='kt_table_users_login_session'
                        >
                          <tbody className='fs-6 fw-bold text-gray-600'>
                            <tr>
                              <td>
                                <label className='required form-label'>Contractor</label>

                                <TenantSelector
                                  placeholder='Select Tenant'
                                  defaultValue={values.tenant}
                                  onChange={setFieldValue}
                                  id='tenantId'
                                  removeClients
                                />

                                {touched.tenant && errors.tenant && (
                                  <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                      {errors.tenant}
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        onClick={() => {
                          resetForm()
                        }}
                        className='btn btn-light'
                        data-bs-dismiss='modal'
                        id='wo_assign_to_modal_dismiss'
                      >
                        Close
                      </button>

                      <button
                        type='submit'
                        id='wo_assign_to_modal_submit'
                        className='btn btn-lg btn-primary'
                        disabled={isSubmitting}
                      >
                        {!isSubmitting && <span className='indicator-label'>Save</span>}
                        {isSubmitting && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </Formik>
    </>
  )
}

export default AssignToModal
