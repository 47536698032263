import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    AttachmentsClient
} from '../../../infrastracture/api/AttachmentsClient'

import DocumentTypeSelector from '../../../common/components/selectors/DocumentTypeSelector'
import Dropzone from 'react-dropzone'

interface Props {
    handleOk: () => void
    workOrderId: any
    proposalId: any
    assetId: any
    contractId: any
    hazardId: any
    incidentId: any
    incidentActionId: any
    viewOnly?: boolean
    imagesOnly?: boolean
    tenantId?: any
}

const AttachmentsMultiModal: React.FC<Props> = (props) => {
    const {
        handleOk,
        workOrderId,
        proposalId,
        assetId,
        contractId,
        hazardId,
        incidentId,
        incidentActionId,
        imagesOnly,
        tenantId
    } = props

    const [createSuccess, setCreateSuccess] = useState(false)
    const params: any = useParams()

    const [files, setFiles] = useState<any[]>([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isInvalid, setIsInvalid] = useState(false)

    const handleDrop = (acceptedFiles: any) => {

        var additional = acceptedFiles.map((file: any) => {
            return {
                file,
                name: file.name,
                type: null,
                error: null
            };
        });

        setFiles([...files, ...additional])
    }

    const resetForm = () => {
        setFiles([])
    }

    const submitForm = async () => {
        setIsSubmitting(true);

        var invalid = false;
        setIsInvalid(false);


        for (var j = 0; j < files.length; j++) {
            if (!files[j].type || !files[j].type.value) {
                files[j].error = true;
                invalid = true;
                setIsInvalid(true);
            }
        }

        if (invalid) {
            setIsSubmitting(false);
            return;
        }

        for (var i = 0; i < files.length; i++) {
            await AttachmentsClient.Create(
                workOrderId,
                assetId,
                contractId,
                hazardId,
                incidentId,
                incidentActionId,
                proposalId,
                files[i].name,
                files[i].file,
                null,
                null,
                false,
                files[i].type.value,
                null,
                null
            )
        }

        resetForm();
        setIsSubmitting(false);
        document.getElementById('rd_attachment_modal_add_dismiss')!.click();
        handleOk();

    }

    return (
        <>
            <div className='modal fade' tabIndex={-1} id='attachment_add_modal'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>

                        <div className='modal-header'>
                            <h5 className='modal-title'>Upload Files</h5>

                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'>
                                <span className='svg-icon svg-icon-2x'></span>
                            </div>
                        </div>


                        <div className='modal-body'>
                            <div
                                className='scroll-y me-n7 pe-7'
                                id='kt_modal_add_files_scroll'
                                data-kt-scroll='true'
                                style={{ maxHeight: '550px' }}>

                                <Dropzone multiple={true} onDrop={handleDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: files.length > 0 ? 'dropzone p-2' : 'dropzone d-flex justify-content-center align-items-center p-2' })}>
                                            <input {...getInputProps()} />
                                            {files.length === 0 && <p> Drag 'n' drop some files here, or click to select files</p>}
                                            {files.length > 0 && <>

                                                <div className="alert alert-danger" style={{ display: isInvalid ? 'block' : 'none' }}>
                                                    Please select document type for all files.
                                                </div>

                                                <table className="table clickable">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ fontWeight: "bold" }}>File Name</th><th style={{ fontWeight: "bold" }}>Type</th><th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {files.map((file) => {
                                                            return (<tr>
                                                                <td width="50%">{file.name}</td>
                                                                <td width="40%" onClick={async (e) => {
                                                                    e.stopPropagation();
                                                                    return false;
                                                                }}>
                                                                    <div className={isInvalid && file.error ? 'is-invalid' : ''}>
                                                                        <DocumentTypeSelector
                                                                            setValueSetter={mtd => file.configureType = mtd}
                                                                            placeholder='Document Type'
                                                                            tenantId={tenantId}
                                                                            onChange={(name: any, value: any) => {
                                                                                file.type = value;
                                                                                file.error = null;

                                                                                var invalid = false;
                                                                                for (var j = 0; j < files.length; j++) {
                                                                                    if (!files[j].type || !files[j].type.value) {
                                                                                        if (files[j].configureType) {
                                                                                            files[j].configureType(value);
                                                                                        }
                                                                                    }
                                                                                }
                                                                                setIsInvalid(invalid);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="invalid-feedback">
                                                                        Please select document type
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button className="btn btn-outline btn-sm" onClick={async (e) => {

                                                                        e.stopPropagation();
                                                                        setFiles([...files.filter((f) => f !== file)])
                                                                        return false;
                                                                    }}><i className="bi bi-trash3-fill"></i></button>
                                                                </td>
                                                            </tr>);
                                                        })}
                                                    </tbody>
                                                </table></>}
                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                        </div>

                        <div className='modal-footer'>
                            <button
                                type='button'
                                onClick={() => {
                                    resetForm()
                                }}
                                className='btn btn-light'
                                data-bs-dismiss='modal'
                                id='rd_attachment_modal_add_dismiss'
                            >
                                Close
                            </button>
                            <button
                                type='submit'
                                id='rd_create_tenant_submit'
                                className='btn btn-lg btn-primary'
                                disabled={isSubmitting}
                                onClick={submitForm}
                            >
                                {!isSubmitting && <span className='indicator-label'>Save</span>}
                                {isSubmitting && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AttachmentsMultiModal
