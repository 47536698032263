import moment from 'moment'
import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'
import { GetAPIDomain } from '../Helper'

export class CheckInsQueryParams extends ListQueryParams {
    WithWorkOrderId(value: any) {
        this.WithParam('workOrderId', value)
        return this
    }
    WithUserId(value: string) {
        this.WithParam('userId', value)
        return this
    }
    WithActiveOnly(value: boolean) {
        this.WithParam('activeOnly', value)
        return this
    }

    WithCommentsOnly(value: boolean) {
        this.WithParam('hasCommentsOnly', value)
        return this
    }
}

export class CheckInsClient {
    static async List(params: CheckInsQueryParams) {
        return await ApiClient.Get('/check-ins' + params.GenerateQueryString());
    }

    static async ExportCheckins(userId: string) {
        var date = moment().toISOString()
        var url = (await GetAPIDomain()) + '/check-ins/export/' + userId + '/' + date
        window.open(url, '_blank')
    }

    static async Get(id: string) {
        return await ApiClient.Get('/check-ins/' + id);
    }

    static async GetSafetyConsiderations(id: string) {
        return await ApiClient.Get('/check-ins/' + id + '/safety-considerations');
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/check-ins/' + id);
    }

    static async Create(
        workOrderId: any,
        timeStamp: any,
        timeOnsite: any,
        checkedInLat: any,
        checkedInLong: any,
        expectedCheckOut: any,
        userId: any
    ) {
        return await ApiClient.Post('/check-ins/', {
            workOrderId,
            timeStamp,
            timeOnsite,
            checkedInLat,
            checkedInLong,
            expectedCheckOut,
            userId,
        });
    }

    static async Extend(id: any, time: number) {
        return await ApiClient.Post('/check-ins/' + id + '/extend', time + "");
    }

    static async Update(
        id: string,
        checkedIn: string,
        timeOnSite: number,
        checkedInLat: number | null,
        checkedInLong: number | null,
        expectedCheckOut: string | null,
        checkOut: string | null,
        workOrderComplete: boolean | null,
        leaveSite: string,
        comment: string,
        isAudit: boolean = false
    ) {

        return await ApiClient.Patch('/check-ins/' + id + `?isAudit=${isAudit}`, [
            {
                op: 'replace',
                path: 'checkedIn',
                value: checkedIn,
            },
            {
                op: 'replace',
                path: 'timeOnSite',
                value: timeOnSite,
            },
            {
                op: 'replace',
                path: 'checkedInLat',
                value: checkedInLat,
            },
            {
                op: 'replace',
                path: 'checkedInLong',
                value: checkedInLong,
            },
            {
                op: 'replace',
                path: 'expectedCheckOut',
                value: expectedCheckOut,
            },
            {
                op: 'replace',
                path: 'checkOut',
                value: checkOut,
            },
            {
                op: 'replace',
                path: 'workOrderComplete',
                value: workOrderComplete,
            },
            {
                op: 'replace',
                path: 'leaveSite',
                value: leaveSite,
            },
            {
                op: 'replace',
                path: 'comment',
                value: comment,
            },
        ]);
    }

    static async UploadSWMS(
        checkInId: any,
        workOrderId: string,
        name: string,
        file: any,
        onUploadProgress: any
    ) {
        let formData = new FormData()
        formData.append('name', name)
        formData.append('workOrderId', workOrderId)
        formData.append('file', file)
        formData.append('checkInId', checkInId)


        var accessToken = localStorage.getItem('acess-token')

        const response = await fetch((await GetAPIDomain()) + '/check-ins/' + checkInId + '/upload-attachment', {
            method: 'post',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
            body: formData,
        })


        return response
    }

    static async CheckOut(id: any, workOrderComplete: boolean, leaveSiteReasonId: any) {
        return await ApiClient.Post('/check-ins/' + id + '/check-out', {
            id,
            workOrderComplete,
            leaveSiteReasonId,
        });
    }

    static async UploadLibraryToCheckIn(id: string, libraryId: string, workOrderId: string) {
        return await ApiClient.Post('/check-ins/' + id + '/upload-attachment-from-library/', {
            libraryId,
            workOrderId,
        });
    }

    static async GetWorkOrderCheckInTotalTime(workOrderId: string) {

        return await ApiClient.Get('/check-ins/total-time?workOrderId=' + workOrderId);

    }
}
