import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {OverlayTrigger, Popover, Spinner, Tooltip} from 'react-bootstrap-v5'
import {
  StatusFlowsClient,
  StatusFlowsQueryParams,
} from '../../../../infrastracture/api/StatusFlowsClient'

interface Props {
  isReady?: boolean
  statusFlowId?: string
  mode?: string
}

const ReadyIndicator: React.FC<Props> = (props: any) => {
  const {isReady, statusFlowId, mode} = props
  const [isLoading, setIsLoading] = useState(false)

  const [parentReadyStatus, setParentReadyStatus] = useState<any>(null)
  const [childReadyStatus, setChildReadyStatus] = useState<any>(null)
  const [statusFlow, setStatusFlow] = useState<any>(null)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)

    var statusFlow = await StatusFlowsClient.Get(statusFlowId)
    setStatusFlow(statusFlow.data)
    setParentReadyStatus(statusFlow.data.clientReadyStatusFlow)

    var query = new StatusFlowsQueryParams()
    var statusFlowStatuses = await StatusFlowsClient.ListStatus(
      query.Paginate(0, 100).WithStatusFlowId(statusFlowId)
    )

    var statusFlows = statusFlowStatuses.data.data.filter((s: any) => s.childReady)
    if (statusFlows.length > 0) {
      setChildReadyStatus(statusFlows)
    }

    setIsLoading(false)
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          as='span'
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
          className='me-2 pe-2'
        />
      ) : (
        <>
          <OverlayTrigger
            placement='left'
            overlay={
              <Tooltip id='ready-status-message'>
                <div className='text-left'>
                  <>
                    {mode === 'parent' && (
                      <>
                        {!isReady && (
                          <>
                            {!childReadyStatus && (
                              <p>
                                <strong>{statusFlow?.name}</strong> never marks children as ready,
                                this Project WO will never be marked as ready
                              </p>
                            )}
                            {childReadyStatus && (
                              <>
                                <p>
                                  This <strong>has not</strong> been marked as ready yet.
                                </p>
                                <p>
                                  Marked as ready when all children child enters one of:{' '}
                                  {childReadyStatus.map((a: any, i: number) => {
                                    return (
                                      <strong>
                                        {a.workOrderStatus.name}
                                        {i < childReadyStatus.length - 1 && <span>, </span>}
                                      </strong>
                                    )
                                  })}{' '}
                                </p>
                                {parentReadyStatus && (
                                  <p>
                                    Once this is marked as ready its status will be updated to{' '}
                                    <strong>{parentReadyStatus.name}</strong>
                                  </p>
                                )}
                                {!parentReadyStatus && (
                                  <p>
                                    Once this is marked as ready it will remain in its current
                                    status
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {isReady && (
                          <>
                            <p>
                              This <strong>has</strong> been marked as ready.
                            </p>
                            {parentReadyStatus && (
                              <p>
                                With current settings the status was changed to{' '}
                                <strong>{parentReadyStatus.name}</strong>.
                              </p>
                            )}
                            {parentReadyStatus && (
                              <p>
                                That was a one time event, the status could have been changed
                                subsequently
                              </p>
                            )}
                            {!parentReadyStatus && <p>No action was taken when this happened</p>}
                          </>
                        )}
                      </>
                    )}

                    {mode === 'child' && (
                      <>
                        {!isReady && (
                          <>
                            {!childReadyStatus && (
                              <p>
                                <strong>{statusFlow?.name}</strong> never marks this work order as
                                ready
                              </p>
                            )}
                            {childReadyStatus && (
                              <>
                                <p>
                                  This <strong>has not</strong> been marked as ready yet.
                                </p>
                                <p>
                                  Marked as ready when this enters one of:{' '}
                                  {childReadyStatus.map((a: any, i: number) => {
                                    return (
                                      <strong>
                                        {a.workOrderStatus.name}
                                        {i < childReadyStatus.length - 1 && <span>, </span>}
                                      </strong>
                                    )
                                  })}{' '}
                                </p>
                                {parentReadyStatus && (
                                  <p>
                                    Once this and all other child work orders are ready, the PWO
                                    will be updated to <strong>{parentReadyStatus.name}</strong>
                                  </p>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {isReady && (
                          <>
                            <p>
                              This <strong>has</strong> been marked as ready.
                            </p>
                            {parentReadyStatus && (
                              <p>
                                Once all other child work orders are ready, the PWO will be updated
                                to <strong>{parentReadyStatus.name}</strong>.
                              </p>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                </div>
              </Tooltip>
            }
          >
            <i
              className={`bi  me-2 fs-2 pe-2 ${isReady ? 'bi-check-circle-fill' : 'bi-circle'}`}
              style={{color: isReady ? 'green' : 'grey'}}
            ></i>
          </OverlayTrigger>
        </>
      )}
    </>
  )
}

export default ReadyIndicator
