import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import {
    DocumentTypesClient,
    DocumentTypesQueryParams,
} from '../../../infrastracture/api/DocumentTypesClient'
import customStyles from './customStyles'

interface Props {
    name?: string
    defaultValue?: any
    placeholder?: string
    disabled?: boolean
    tenantId?: string
    onChange?: (name: any, value: any) => void
    setValueSetter?: (value: Function) => void
}

const DocumentTypeSelector: React.FC<Props> = (props: any) => {
    const { defaultValue, placeholder, disabled, name, onChange, tenantId } = props

    const [options, setOptions] = useState<any>([])
    const [value, setValue] = useState<any>({})

    const defaultId = 'documentType'

    useEffect(() => {
        if (props.setValueSetter) {
            props.setValueSetter(setValueMtd)
        }
        populateData()
    }, [])

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    const setValueMtd = (value: any) => {
        setValue(value);
        handleChange(value);
    }

    const populateData = async () => {
        var query = new DocumentTypesQueryParams()

if(tenantId)
    query = query.WithTenantId(tenantId)

        //TODO: SUPPORT SEARCH FOR PERFORMANCE AGAINST LARGE COLLECTIONS
        var data = (
            await DocumentTypesClient.List(query.Paginate(0, 10000).Sort('name', 'asc').WithActive(true))
        ).data

        if (data) {
            setOptions(
                data.data.map((item: any) => {
                    return {
                        value: item.id,
                        label: item.name,
                    }
                })
            )
        }
    }

    const handleChange = (values: any) => {
        const { label, value } = values
        setValue(values)
        onChange(defaultId, { value, label })
    }

    return (
        <Select
            onChange={handleChange}
            value={value}
            options={options}
            placeholder={placeholder}
            isDisabled={disabled}
            id={name ? name : defaultId}
            name={name ? name : defaultId}
            styles={customStyles}
            menuPortalTarget={document.body}
        />
    )
}

export default DocumentTypeSelector
