import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserQueryParams, UsersClient} from '../../../infrastracture/api/UsersClient'
import {
  ParticipantQueryParams,
  ParticipantsClient,
} from '../../../infrastracture/api/ParticipantsClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import ParticipantsModal from './ParticipantsModal'
import Moment from 'react-moment'
import {Rating, RatingView} from 'react-simple-star-rating'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from 'moment'
import {CheckInsClient, CheckInsQueryParams} from '../../../infrastracture/api/CheckInsClient'
import CheckInsModal from '../../check-ins/components/CheckInsModal'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'

const ParticipantsBreadCrumbs: Array<PageLink> = []
interface Props {
  workOrder: any
  title?: any
  refreshWO: () => void
}
const ParticipantsList: React.FC<Props> = (props) => {
  const {workOrder, refreshWO} = props
  const {id: workOrderId, assignedToTenantId, siteId} = workOrder
  const [Participants, setParticipants] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState('')
  const [viewOnly, setViewOnly] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [checkIn, setCheckIn] = useState<any>('')
  const [userActiveCheckIns, setUserActiveCheckIns] = useState<Array<any>>([])
  const [showCheckinModal, setShowCheckinModal] = useState(false)

  const [loading, setLoading] = useState(false)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'created',
    direction: 'desc',
  })

  useEffect(() => {
    fetchData()
  }, [workOrderId])

  const getuserActiveCheckIns = async () => {
    try {
      var query = new CheckInsQueryParams()

      var response = await CheckInsClient.List(
        query
          .Paginate(0, 99999)
          .Search(search)
          .WithWorkOrderId(workOrderId)
          .WithActiveOnly(true)
          .Sort('created', 'desc')
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setUserActiveCheckIns(data?.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  useEffect(() => {
    if (workOrderId) fetchData()
  }, [workOrderId])

  async function fetchData() {
    setLoading(true)
    getuserActiveCheckIns()
    try {
      var query = new ParticipantQueryParams()

      var response = await ParticipantsClient.List(
        query
          .Paginate(pagination.skip, pagination.take)
          .Search(search)
          .WithActive(active)
          .WithWorkOrderId(workOrderId)
          .Sort(pagination.sort, pagination.direction)
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setParticipants(data?.data)

        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const renderModals = () => {
    return (
      <>
        <ParticipantsModal
          viewOnly={viewOnly}
          mode={mode}
          selectedData={selectedData}
          workOrderId={workOrderId}
          handleOk={() => {
            fetchData()
            refreshWO()
          }}
        />
        <CheckInsModal
          workOrderId={workOrderId}
          siteId={siteId}
          viewOnly={viewOnly}
          workOrder={workOrder}
          checkIn={checkIn}
          handleOk={() => {
            fetchData()
            refreshWO()
            setShowCheckinModal(false)
          }}
          // handleClose={() => {
          //   setShowCheckinModal(false)
          // }}
          loading={modalLoading}
          user={selectedData.user}
          // show={showCheckinModal}
        />
      </>
    )
  }

  const deleteModals = () => {
    return (
      <SweetAlertWithProp
        danger
        title='Warning'
        onConfirm={async () => {
          var response = await ParticipantsClient.Delete(selectedData.id)

          fetchData()
          setShowDelete(false)
        }}
        onCancel={() => setShowDelete(false)}
        confirmBtnText={'Confirm'}
        cancelBtnBsStyle='light'
        show={showDelete}
        showCancel={true}
        showCloseButton
      >
        <span>
          <p>Are you sure you want to delete participant?</p>
        </span>
      </SweetAlertWithProp>
    )
  }

  return (
    <>
      {renderModals()}
      {deleteModals()}
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
              </span>
              <input
                type='text'
                data-kt-notice-type-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Participants'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                {assignedToTenantId &&
                  user.tenant.tenantTypeId.toUpperCase() !== TenantTypeIds.Client && (
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#participants_modal'
                      onMouseOver={() => {
                        setMode('Add')
                        setViewOnly(false)
                      }}
                      onClick={() => {
                        setMode('Add')
                        setViewOnly(false)
                      }}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                      Add Participant
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'User', sort: 'user.name', width: '15'},
                  {name: 'Date Added', sort: 'created', width: '15'},
                  {name: 'Added By', sort: 'whoCreated', width: '15'},
                  {name: '', sort: '', static: true, width: '10'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    Participants.map((item) => (
                      <tr
                        key={item.id}
                        onMouseOver={() => {
                          setSelectedData(item)
                        }}
                      >
                        <td>
                          {item.user && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.user.name} {item.user.surname}
                            </a>
                          )}
                          {!item.user && <i>Not specified</i>}
                        </td>

                        <td>
                          {item.created && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                {item.created}
                              </Moment>
                            </a>
                          )}
                          {!item.created && <i>Not specified</i>}
                        </td>
                        <td>
                          {item.whoCreated && (
                            <a className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.whoCreated.name} {item.whoCreated.surname}
                            </a>
                          )}
                          {!item.whoCreated && <i>Not specified</i>}
                        </td>

                        <td className='text-end'>
                          {/* {(userActiveCheckIns?.find((checkIn: any) => {
                            if (checkIn.userId === item.userId) {
                              return false
                            }
                            return true
                          }) ||
                            userActiveCheckIns?.length === 0) && (
                            
                          )} */}
                          <button
                            type='button'
                            className='btn btn-sm btn-light-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#check_in_modal'
                            onMouseOver={() => {
                              setSelectedData(item)
                            }}
                            onClick={async () => {
                              setModalLoading(true)
                              var currentDateTime = new Date(
                                moment().format('YYYY-MM-DD')
                              ).toISOString()
                              var response = await CheckInsClient.Create(
                                workOrderId,
                                currentDateTime,
                                0,
                                null,
                                null,
                                null,
                                item.userId
                              )

                              // if (!response.successful) {
                              //   //show error
                              // } else {
                              //   setCheckIn(response.data)
                              // }
                              setCheckIn(response.data)

                              setModalLoading(false)
                              setShowCheckinModal(true)
                            }}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen013.svg'
                              className='svg-icon-3'
                            />
                            Check In
                          </button>
                          <button
                            type='button'
                            className='btn btn-sm btn-light-danger'
                            onMouseOver={() => {
                              setSelectedData(item)
                            }}
                            onClick={() => {
                              setSelectedData(item)
                              setShowDelete(true)
                            }}
                          >
                            <i className='bi bi-trash-fill'></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-notice-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticipantsList
