import { ApiClient } from '../../../setup/axios/SetupAxios';
import { ListQueryParams } from '../ApiClient';

export class QuoteStatusQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
}

export class QuoteStatusClient {
    static async List(params: QuoteStatusQueryParams) {
        return await ApiClient.Get('/quote-status' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/quote-status/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/quote-status/' + id);
    }

    static async Create(name: string, description: string, active: boolean, color: string) {
        return await ApiClient.Post('/quote-status/', {
            name: name,
            description: description,
            active: active,
            color: color,
        });
    }

    static async Update(
        id: string,
        name: string,
        description: string,
        active: boolean,
        color: string
    ) {
        return await ApiClient.Patch('/quote-status/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
            {
                op: 'replace',
                path: 'color',
                value: color,
            },
        ]);
    }
}
