import { ApiClient, PublicApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class IncidentsQueryParams extends ListQueryParams {
    WithEnabled(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithStatusId(value: string) {
        this.WithParam('statusId', value)
        return this
    }
    WithTypeId(value: string) {
        this.WithParam('typeId', value)
        return this
    }
    WithSiteId(value: string) {
        this.WithParam('siteId', value)
        return this
    }
}

export class IncidentsClient {
    static async List(params: IncidentsQueryParams) {
        return await ApiClient.Get('/incidents' + params.GenerateQueryString());
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/incidents/' + id);
    }

    static async Get(id: string) {
        return await ApiClient.Get('/incidents/' + id);
    }

    static async GetIncidentMobileInfo(userId: string, workOrderId: string) {
        return await PublicApiClient.Get(`/incidents/stand-alone-info?userId=${userId}&workOrderId=${workOrderId}`);
    }

    static async CreateStandAlone(
        clientId: string,
        siteId: string,
        closedOn: any,
        typeId: string,
        statusId: string,
        causeId: string,
        description: string,
        dateTime: any,
        location: string,
        treatment: string,
        createdById: string
    ) {
        return await PublicApiClient.Post('/incidents/stand-alone', {
            clientId,
            siteId,
            closedOn,
            typeId,
            // statusId,
            causeId,
            description,
            dateTime,
            location,
            treatment,
            createdById,
        });
    }

    static async GetActionStatusSummary(id: string) {
        return await ApiClient.Get('/incidents/' + id + '/action-status-summary');
    }

    static async Create(
        clientId: string,
        siteId: string,
        closedOn: any,
        typeId: string,
        statusId: string,
        causeId: string,
        description: string,
        dateTime: any,
        location: string,
        treatment: string
    ) {
        return await ApiClient.Post('/incidents', {
            clientId,
            siteId,
            closedOn,
            typeId,
            statusId,
            causeId,
            description,
            dateTime,
            location,
            treatment,
        });
    }

    static async Update(
        id: string,
        siteId: string,
        closedOn: any,
        typeId: string,
        statusId: string,
        causeId: string,
        description: string,
        dateTime: any,
        location: string,
        treatment: string
    ) {
        return await ApiClient.Patch('/incidents/' + id, [
            {
                op: 'replace',
                path: 'siteId',
                value: siteId,
            },
            {
                op: 'replace',
                path: 'closedOn',
                value: closedOn,
            },
            {
                op: 'replace',
                path: 'causeId',
                value: causeId,
            },
            {
                op: 'replace',
                path: 'typeId',
                value: typeId,
            },
            {
                op: 'replace',
                path: 'statusId',
                value: statusId,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'location',
                value: location,
            },
            {
                op: 'replace',
                path: 'dateTime',
                value: dateTime,
            },
            {
                op: 'replace',
                path: 'treatment',
                value: treatment,
            },
        ]);
    }
}
