import React, {useState, useEffect, useRef} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'

import Select from 'react-select'
import Dropzone from 'react-dropzone'
import {ProgressBar} from 'react-bootstrap-v5'
import _ from 'lodash'
import {CheckListItemTypes} from '../check-list-templates/enums'
import customStyles from '../../common/components/selectors/customStyles'
import {CheckListItemFileClient} from '../../infrastracture/api/CheckListItemFileClient'
import {CheckListItemClient} from '../../infrastracture/api/CheckListItemClient'
import {KTSVG} from '../../../_metronic/helpers'
import './styles.css'
import {CheckListClient} from '../../infrastracture/api/CheckListClient'
import SweetAlert from 'react-bootstrap-sweetalert'
import Moment from 'react-moment'
import {SweetAlertWithProp} from '../../../SweetAlertWithProp'

interface Props {
  items: any[]
  setParentIndex: (index: number) => void
  checkListId: string
}

const CheckListAnswered: React.FC<Props> = (props) => {
  const {items, setParentIndex, checkListId} = props
  const params: any = useParams()

  const [currentIndex, setCurrentIndex] = useState(0)
  const [checkListItems, setCheckListItems] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [showPreview, setShowPreview] = useState(false)
  const [selectedData, setSelectedData] = useState<any>({})

  const commentRef = useRef<any>(null)

  useEffect(() => {
    setParentIndex(currentIndex)
  }, [currentIndex])

  useEffect(() => {
    getCheckList()
  }, [items])

  const getCheckList = async () => {
    try {
      setLoading(true)
      var response: any

      if (checkListId) {
        response = await CheckListClient.Get(checkListId)
        if (!response.authenticated) {
        } else {
          const {data} = response
          setCheckListItems(data?.checkListItems)
        }
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const itemRenderer = (item: any, index: number) => {
    const {itemText, itemType, options, checkListTemplateId, id} = item

    switch (itemType) {
      case CheckListItemTypes.SectionHeader:
        return (
          <>
            <div className='form-label'>Section Header Text</div>
            <div className='text-gray'>{item.itemText}</div>
          </>
        )

      default:
        return (
          <>
            <div className='form-label'>Question</div>
            <div className='text-gray'>{item.itemText}</div>
            <br />
            <div className='form-label'>Answer</div>
            <div className='text-gray'>{item.value}</div>
          </>
        )
    }
  }

  const renderModals = () => {
    return (
      <SweetAlertWithProp
        show={showPreview}
        onConfirm={() => setShowPreview(false)}
        showCancel={false}
        title=''
        style={{width: '550px', height: '550px'}}
        confirmBtnText='Close'
      >
        <img
          src={selectedData.uri}
          alt='Image'
          style={{maxHeight: '450px', maxWidth: '450px', zIndex: 100000}}
        />
      </SweetAlertWithProp>
    )
  }

  if (loading)
    return (
      <div className='text-center d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        Loading...
      </div>
    )
  else
    return (
      <>
        {renderModals()}
        <div
          className='scroll-y me-n7 pe-7 roll-out'
          // id='kt_modal_add_customer_scroll'
          // data-kt-scroll='true'
          // style={{maxHeight: '450px'}}
          key={currentIndex}
        >
          <table className='scroll-y table table-row-dashed gy-5' id='kt_table_users_login_session'>
            <tbody className='fs-6 fw-bold text-gray-600'>
              {items &&
                items.length > 0 &&
                items.map((item, index) => {
                  return (
                    index === currentIndex && (
                      <>
                        <tr>
                          <td>
                            <div>
                              <table
                                className='w-100'
                                style={{
                                  borderBottom: '2px solid gray',
                                  marginBottom: '15px',
                                }}
                              >
                                <tr>
                                  <td className='w-50 text-dark fs-4'>{item.checkList?.name}</td>
                                  <td className='text-muted text-end w-50'>
                                    {currentIndex + 1} of {checkListItems.length}
                                  </td>
                                </tr>
                              </table>

                              {itemRenderer(item, index)}

                              {item.comment && (
                                <div>
                                  <label className='form-label'>Comment</label>
                                  <div className='text-gray'>{item.comment}</div>
                                </div>
                              )}
                              <br />
                              {item.files.length > 0 && (
                                <div style={{padding: '15px 0px'}}>
                                  <label className='form-label'>Files</label>
                                  {item.files.map(({fileName, uri}: any) => (
                                    <li key={fileName}>
                                      {fileName.match(/.(jpg|jpeg|png|gif)$/i) && (
                                        <span
                                          className='cursor-pointer hover-primary'
                                          onMouseOver={() => setSelectedData({uri})}
                                          onClick={() => {
                                            setSelectedData({uri})
                                            setShowPreview(true)
                                          }}
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/files/fil003.svg'
                                            className='svg-icon svg-icon-2tx'
                                          />
                                        </span>
                                      )}{' '}
                                      <span
                                        className='cursor-pointer hover-primary'
                                        onClick={() => {
                                          const link = document.createElement('a')

                                          link.href = uri
                                          link.download = fileName
                                          link.click()
                                        }}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/files/fil017.svg'
                                          className='svg-icon svg-icon-2tx'
                                        />
                                      </span>
                                    </li>
                                  ))}
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                        {item.answeredBy && (
                          <tr>
                            <table className='w-100'>
                              <tr>
                                <td className='w-50 text-muted fs-7'>
                                  <span className='fs-9'>Answered By</span> <br />
                                  {item.answeredBy?.name} {item.answeredBy?.surname} -{' '}
                                  <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                    {item.answeredBy?.created}
                                  </Moment>
                                </td>
                              </tr>
                            </table>
                          </tr>
                        )}
                      </>
                    )
                  )
                })}
            </tbody>
          </table>
          <div className='modal-footer'>
            {currentIndex > 0 && (
              <button
                type='button'
                onClick={() => {
                  setCurrentIndex(currentIndex - 1)
                }}
                className='btn btn-light'
              >
                Back
              </button>
            )}
            {currentIndex + 1 !== items.length && (
              <button
                type='button'
                onClick={async (e: any) => {
                  if (currentIndex + 1 !== items.length) {
                    setCurrentIndex(currentIndex + 1)
                  } else {
                    setCurrentIndex(0)
                  }
                }}
                className='btn btn-lg btn-primary'
              >
                Next
              </button>
            )}
          </div>
        </div>
      </>
    )
}

export default CheckListAnswered
