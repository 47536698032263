import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {QuoteQueryParams, QuotesClient} from '../../../infrastracture/api/QuotesClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'
import QuotesForParentWorkOrderModal from './QuotesForParentWorkOrderModal'

interface Props {
  workOrderId: string
  workOrderNumber: string
  title?: any
  refreshWO: () => void
  refreshData: () => void
  refreshList?: boolean
}
const QuotesForParentWorkOrderList: React.FC<Props> = (props) => {
  const {workOrderId, refreshWO, refreshList, refreshData, workOrderNumber} = props
  const [Quotes, setQuotes] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState('')
  const [viewOnly, setViewOnly] = useState(false)
  const [showQuoteModal, setShowQuoteModal] = useState(false)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'name',
    direction: 'desc',
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [workOrderId, refreshList])

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  async function fetchData() {
    setLoading(true)

    try {
      var query = new QuoteQueryParams()

      var response = await QuotesClient.GetQuotesForParentWorkOrder(workOrderId)

      if (!response.authenticated) {
      } else {
        const {data} = response
        var quotes = data

        setQuotes(quotes)

        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const renderModals = () => {
    return (
      <QuotesForParentWorkOrderModal
        viewOnly={viewOnly}
        selectedData={selectedData}
        workOrderId={workOrderId}
        handleOk={() => {
          fetchData()
          refreshWO()
          refreshData()
          setShowQuoteModal(false)
        }}
        handleClose={() => {
          setShowQuoteModal(false)
        }}
        show={showQuoteModal}
        workOrderNumber={workOrderNumber}
      />
    )
  }

  return (
    <>
      {renderModals()}
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
              </span>
              <input
                type='text'
                data-kt-notice-type-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Quotes'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'></div>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'Amount', sort: 'estimate', width: '10'},
                  {name: 'Status', sort: 'status', width: '10'},
                  {name: '', sort: '', static: true, width: '20'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    Quotes.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                            {toMoneyFormat(item.estimate)}
                          </span>
                        </td>

                        <td>
                          <div className='d-flex align-items-center rounded p-5'>
                            {item.quoteStatus?.color && (
                              <span
                                className={`bullet bullet-vertical h-30px `}
                                style={
                                  item.quoteStatus?.color && {
                                    background: item.quoteStatus?.color,
                                  }
                                }
                              ></span>
                            )}{' '}
                            <div
                              className='flex-grow-1 me-2'
                              style={
                                item.quoteStatus?.color && {
                                  paddingLeft: '5px',
                                }
                              }
                            >
                              <span className='fw-bolder text-gray-800 fs-6'>
                                {item.quoteStatus?.name}
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className='text-end'>
                          <button
                            type='button'
                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            onMouseOver={() => {
                              setSelectedData(item)
                              setViewOnly(true)
                              setMode('View')
                            }}
                            onClick={() => {
                              setSelectedData(item)
                              setViewOnly(true)
                              setMode('View')
                              setShowQuoteModal(true)
                            }}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-notice-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QuotesForParentWorkOrderList
