import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetFilter: '[Proposal SetFilter] Action',
    ResetFilter: '[Proposal ResetFilter] Action',
}

const initialProposalstate: IProposalstate = {
    filter: {
        workOrderStatusId: '',
        active: true,
        pagination: {
            currentPage: 1,
            totalRecords: 1,
            totalPages: 1,
            skip: 0,
            take: 50,
            reset: true,
            sort: 'title',
            direction: 'asc',
        },
    },
}

export interface IProposalstate {
    filter?: {
        workOrderStatusId: string
        active: boolean
        pagination: any
    }
}

export const reducer = persistReducer(
    { storage, key: 'v100-Proposal', whitelist: ['filter'] },
    (state: IProposalstate = initialProposalstate, action: ActionWithPayload<IProposalstate>) => {
        switch (action.type) {
            case actionTypes.ResetFilter: {
                return initialProposalstate
            }

            case actionTypes.SetFilter: {

                console.log(action.payload?.filter);

                const filter = action.payload?.filter
                return { ...state, filter }
            }

            default:
                return state
        }
    }
)

export const Actions = {
    resetFilter: () => ({ type: actionTypes.ResetFilter }),
    setFilter: (filter: object) => ({
        type: actionTypes.SetFilter,
        payload: { filter },
    }),
}

export function* saga() { }
