import { ApiClient } from '../../../setup/axios/SetupAxios';
import { ListQueryParams } from '../ApiClient';

export class DocumentTypesQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }

    WithTenantId(value: string) {
        this.WithParam('tenantId', value)
        return this
    }
}

export class DocumentTypesClient {
    static async List(params: DocumentTypesQueryParams) {
        return await ApiClient.Get('/document-types' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/document-types/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/document-types/' + id);
    }

    static async DeleteTenant(id: string, tid: string) {
        return await ApiClient.Delete('/document-types/' + id + '/tenants/' + tid);
    }

    static async AddTenant(id: string, tid: string) {
        return await ApiClient.Post('/document-types/' + id + '/tenants/' + tid);
    }

    static async Create(
        name: string,
        description: string,
        active: boolean,
        color: string,
        workSafe: boolean,
        defaultFileProvider: string,
        mFilesObjectClassId: number,
        mFilesDocumentSubTypeId: number
    ) {
        return await ApiClient.Post('/document-types/', {
            name: name,
            description: description,
            active: active,
            color: color,
            workSafe: workSafe,
            defaultFileProvider: defaultFileProvider,
            mFilesObjectClassId: mFilesObjectClassId,
            mFilesDocumentSubTypeId: mFilesDocumentSubTypeId,
        });
    }

    static async UpdateSpecificTenants(
        id: string,
        value: boolean
    ) {

        return await ApiClient.Patch('/document-types/' + id, [
            {
                op: 'replace',
                path: 'specificTenants',
                value: value,
            }
        ]);
    }

    static async Update(
        id: string,
        name: string,
        description: string,
        active: boolean,
        color: string,
        workSafe: boolean,
        defaultFileProvider: string,
        mFilesObjectClassId: number,
        mFilesDocumentSubTypeId: number
    ) {

        return await ApiClient.Patch('/document-types/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
            {
                op: 'replace',
                path: 'color',
                value: color,
            },
            {
                op: 'replace',
                path: 'workSafe',
                value: workSafe,
            },
            {
                op: 'replace',
                path: 'defaultFileProvider',
                value: defaultFileProvider,
            },
            {
                op: 'replace',
                path: 'mFilesObjectClassId',
                value: mFilesObjectClassId,
            },
            {
                op: 'replace',
                path: 'mFilesDocumentSubTypeId',
                value: mFilesDocumentSubTypeId,
            },
        ]);
    }
}
