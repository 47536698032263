import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class ChargesQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithWorkOrderId(value: string) {
        this.WithParam('workOrderId', value)
        return this
    }
    WithIsActive(value: boolean) {
        this.WithParam('isActive', value)
        return this
    }
    WithFeesOnly(value: boolean) {
        this.WithParam('feesOnly', value)
        return this
    }
    WithExcludeFeesAndDefaults(value: boolean) {
        this.WithParam('excludeFeesAndDefaults', value)
        return this
    }
}

export class ChargesClient {
    static async List(params: ChargesQueryParams) {
        return await ApiClient.Get('/charges' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/charges/' + id);
    }

    static async GetChargesForProjectWorkOrder(parentWorkOrderId: string) {
        return await ApiClient.Get('/charges/project-work-order/' + parentWorkOrderId);
    }

    static async GetShippingChargesForSingleWorkOrder(workOrderId: string) {
        return await ApiClient.Get('/charges/single-work-order/' + workOrderId + '/shipping');
    }

    static async GetShippingChargesForProjectWorkOrder(parentWorkOrderId: string) {
        return await ApiClient.Get('/charges/project-work-order/' + parentWorkOrderId + '/shipping');
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/charges/' + id);
    }

    static async DeleteAll(workOrderId: string) {
        return await ApiClient.Delete(`/charges/all?workOrderId=${workOrderId}`);
    }

    static async Create(
        name: string,
        workOrderId: string,
        chargeTypeId: string,
        value: string,
        excludeFees: boolean
    ) {
        return await ApiClient.Post('/charges/', {
            name,
            chargeTypeId,
            workOrderId,
            value,
            excludeFees,
        });
    }

    static async Update(
        id: string,
        name: string,
        chargeTypeId: string,
        value: string,
        excludeFees: boolean
    ) {
        return await ApiClient.Patch('/charges/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'chargeTypeId',
                value: chargeTypeId,
            },
            {
                op: 'replace',
                path: 'value',
                value: value,
            },
            {
                op: 'replace',
                path: 'excludeFees',
                value: excludeFees,
            },
        ]);
    }
}
