import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class WorkOrderQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }

    WithSiteId(value: string) {
        this.WithParam('siteId', value)
        return this
    }

    WithWorkOrderTypeId(value: string) {
        this.WithParam('workOrderTypeId', value)
        return this
    }

    WithWorkOrderStatusId(value: string) {
        this.WithParam('workOrderStatusId', value)
        return this
    }

    WithWorkOrderCategoryId(value: string) {
        this.WithParam('workOrderCategoryId', value)
        return this
    }

    WithTenantId(value: string) {
        this.WithParam('tenantId', value)
        return this
    }

    WithPriorityId(value: string) {
        this.WithParam('priorityId', value)
        return this
    }

    WithStatusChanged(value: boolean) {
        this.WithParam('statusChanged', value)
        return this
    }

    WithIgnoreCompleteOnly(value: boolean) {
        this.WithParam('ignoreComplete', false)
        this.WithParam('ignoreCompleteOnly', value)
        return this
    }

    WithAssignedToTenantId(value: string) {
        this.WithParam('assignedToTenantId', value)
        return this
    }
    WithAssignedTenantOrTenantId(value: string) {
        this.WithParam('assignedTenantOrTenantId', value)
        return this
    }

    WithExpectedOnSite(value: boolean) {
        this.WithParam('withExpectedOnSiteDate', value)
        return this
    }

    WithShowOperationalClients(value: boolean) {
        this.WithParam('showOperationalClientsOnly', value)
        return this
    }
    WithStartCreateDate(value: any) {
        this.WithParam('startCreateDate', value)
        return this
    }
    WithEndCreateDate(value: any) {
        this.WithParam('endCreateDate', value)
        return this
    }
    WithStartCompletedDate(value: any) {
        this.WithParam('startCompletedDate', value)
        return this
    }
    WithEndCompletedDate(value: any) {
        this.WithParam('endCompletedDate', value)
        return this
    }
    WithOnBehalfOfTenantId(value: any) {
        this.WithParam('onBehalfOfTenantId', value)
        return this
    }
    WithDepartmentId(value: any) {
        this.WithParam('departmentId', value)
        return this
    }
    WithGetTotalCost(value: boolean) {
        this.WithParam('getTotalCost', value)
        return this
    }
    WithParentWorkOrderId(value: string) {
        this.WithParam('parentWorkOrderId', value)
        return this
    }
    WithCreatedById(value: string) {
        this.WithParam('createdById', value)
        return this
    }
    WithWorkOrderFilterType(value: string) {
        this.WithParam('workOrderFilterType', value)
        return this
    }
    IsParent() {
        this.WithParam('workOrderFilterType', "projectWorkOrder")
        return this;
    }
}

export class WorkOrderClient {
    static async List(params: WorkOrderQueryParams) {
        return await ApiClient.Get('/work-orders' + params.GenerateQueryString());
    }

    static async CheckSupplierStatusInMyob(id: string) {
        return await ApiClient.Post('/integrations/myob/vendors/' + id + '/check-status');
    }

    static async Get(id: string) {
        return await ApiClient.Get('/work-orders/' + id);
    }

    static async GetHistory(id: string, params: WorkOrderQueryParams) {

        return await ApiClient.Get('/work-orders/' + id + '/history' + params.GenerateQueryString());
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/work-orders/' + id);
    }

    static async Create(
        name: any,
        description: any,
        invoiceNote: any,
        tenantId: any,
        siteId: any,
        workOrderTypeId: any,
        workOrderStatusId: any,
        dueDate: any,
        completeDate: any,
        expectedOnsite: any,
        priorityId: any,
        contact: any,
        contactNumber: any,
        workOrderCategoryId: any,
        workScopeId: any,
        parentWorkOrderId: any,
        purchaseOrder: string,
        siteContact: string,
        siteContactNumber: any,
        onBehalfOfTenantId: any,
        departmentId: any,
        statusFlowId: any,
        localOnly: boolean,
        contractorNotes: any,
        clientContact: any,
        suppliedPurchaseOrder: any,
        manufacturingPercentageFee: any,
        shippingPercentageFee: any,
        ownerForMyobUserId?: any,
        shippingType?: any,
        addressLine1?: any,
        addressLine2?: any,
        city?: any,
        country?: any,
        postalCode?: any,
        shippingSiteId?: any,
        shippingClientId?: any,
        shippingContractorId?: any,
        externalMyobProjectId?: any,
        shipToContact?: any,
        shipToContactEmail?: any
    ) {

        return await ApiClient.Post('/work-orders/', {
            name,
            description,
            tenantId,
            siteId,
            workOrderTypeId,
            workOrderStatusId,
            dueDate,
            completeDate,
            expectedOnsite,
            priorityId,
            contact,
            contactNumber,
            workOrderCategoryId,
            workScopeId,
            parentWorkOrderId,
            purchaseOrder,
            siteContact,
            siteContactNumber,
            onBehalfOfTenantId,
            departmentId,
            invoiceNote,
            statusFlowId,
            localOnly,
            contractorNotes,
            clientContact,
            suppliedPurchaseOrder,
            manufacturingPercentageFee,
            shippingPercentageFee,
            ownerForMyobUserId,
            shippingType,
            addressLine1,
            addressLine2,
            city,
            country,
            postalCode,
            shippingSiteId,
            shippingClientId,
            shippingContractorId,
            externalMyobProjectId,
            shipToContact,
            shipToContactEmail
        });

    }

    static async SetParent(id: string) {
        return await ApiClient.Post(`/work-orders/${id}/set-parent`, {});
    }

    static async AssignToProject(id: string, projectId: string) {
        return await ApiClient.Post(`/work-orders/${id}/assign-to-project/${projectId}`, {});
    }

    static async RemoveFromProject(id: string) {
        return await ApiClient.Post(`/work-orders/${id}/remove-from-project`, {});
    }

    static async UpdateField(id: string, field: string, value: any) {

        return await ApiClient.Patch('/work-orders/' + id, [
            {
                op: 'replace',
                path: field,
                value: value,
            },
        ]);

    }

    static async Update(
        id: string,
        name: any,
        description: any,
        invoiceNote: any,
        tenantId: any,
        siteId: any,
        workOrderTypeId: any,
        dueDate: any,
        completeDate: any,
        expectedOnsite: any,
        priorityId: any,
        contact: any,
        contactNumber: any,
        workOrderCategoryId: any,
        workScopeId: any,
        costEstimate: any,
        ocManaged: boolean,
        purchaseOrder: string,
        siteContact: string,
        siteContactNumber: any,
        onBehalfOfTenantId: any,
        departmentId: any,
        statusFlowId: any,
        contractorNotes: any,
        clientContact: any,
        suppliedPurchaseOrder: any,
        manufacturingPercentageFee: any,
        shippingPercentageFee: any,
        ownerForMyobUserId?: any,
        shippingType?: any,
        addressLine1?: any,
        addressLine2?: any,
        city?: any,
        postalCode?: any,
        shippingSiteId?: any,
        shippingClientId?: any,
        shippingContractorId?: any,
        externalMyobProjectId?: any,
        shipToContact?: any,
        shipToContactEmail?: any
    ) {


        return await ApiClient.Patch('/work-orders/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            }, {
                op: 'replace',
                path: 'shipToContact',
                value: shipToContact,
            }, {
                op: 'replace',
                path: 'shipToContactEmail',
                value: shipToContactEmail,
            }, {
                op: 'replace',
                path: 'externalMyobProjectId',
                value: externalMyobProjectId,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'invoiceNote',
                value: invoiceNote,
            },
            {
                op: 'replace',
                path: 'tenantId',
                value: tenantId,
            },
            {
                op: 'replace',
                path: 'siteId',
                value: siteId,
            },
            {
                op: 'replace',
                path: 'workOrderTypeId',
                value: workOrderTypeId,
            },
            {
                op: 'replace',
                path: 'dueDate',
                value: dueDate,
            },
            {
                op: 'replace',
                path: 'completeDate',
                value: completeDate,
            },
            {
                op: 'replace',
                path: 'expectedOnsite',
                value: expectedOnsite,
            },
            {
                op: 'replace',
                path: 'priorityId',
                value: priorityId,
            },
            {
                op: 'replace',
                path: 'contact',
                value: contact,
            },
            {
                op: 'replace',
                path: 'contactNumber',
                value: contactNumber,
            },
            {
                op: 'replace',
                path: 'workOrderCategoryId',
                value: workOrderCategoryId,
            },
            {
                op: 'replace',
                path: 'workScopeId',
                value: workScopeId,
            },
            {
                op: 'replace',
                path: 'costEstimate',
                value: costEstimate,
            },
            {
                op: 'replace',
                path: 'ocManaged',
                value: ocManaged,
            },
            {
                op: 'replace',
                path: 'purchaseOrder',
                value: purchaseOrder,
            },
            {
                op: 'replace',
                path: 'siteContact',
                value: siteContact,
            },
            {
                op: 'replace',
                path: 'siteContactNumber',
                value: siteContactNumber,
            },
            {
                op: 'replace',
                path: 'onBehalfOfTenantId',
                value: onBehalfOfTenantId,
            },
            {
                op: 'replace',
                path: 'departmentId',
                value: departmentId,
            },
            {
                op: 'replace',
                path: 'statusFlowId',
                value: statusFlowId,
            },
            {
                op: 'replace',
                path: 'contractorNotes',
                value: contractorNotes,
            },
            {
                op: 'replace',
                path: 'clientContact',
                value: clientContact,
            },
            {
                op: 'replace',
                path: 'suppliedPurchaseOrder',
                value: suppliedPurchaseOrder,
            },
            {
                op: 'replace',
                path: 'manufacturingPercentageFee',
                value: manufacturingPercentageFee,
            },
            {
                op: 'replace',
                path: 'shippingPercentageFee',
                value: shippingPercentageFee,
            },
            {
                op: 'replace',
                path: 'ownerForMyobUserId',
                value: ownerForMyobUserId,
            },
            {
                op: 'replace',
                path: 'shippingType',
                value: shippingType,
            },
            {
                op: 'replace',
                path: 'addressLine1',
                value: addressLine1,
            },
            {
                op: 'replace',
                path: 'addressLine2',
                value: addressLine2,
            },
            {
                op: 'replace',
                path: 'city',
                value: city,
            },
            {
                op: 'replace',
                path: 'postalCode',
                value: postalCode,
            },
            {
                op: 'replace',
                path: 'shippingSiteId',
                value: shippingSiteId,
            },
            {
                op: 'replace',
                path: 'shippingClientId',
                value: shippingClientId,
            },
            {
                op: 'replace',
                path: 'shippingContractorId',
                value: shippingContractorId,
            }
        ]);
    }

    static async AssignToTenant(id: string, assignedToTenantId: any) {

        return await ApiClient.Post(`/work-orders/${id}/assign-to`, {
            assignedToTenantId,
        });
    }

    static async SetStatus(id: string, statusId: any) {

        return await ApiClient.Post(`/work-orders/${id}/set-status`, {
            statusId,
        });
    }

    static async ForceSync(id: string) {
        return await ApiClient.Post(`/work-orders/${id}/myob/force-sync`);
    }

    static async ChangeContractor(id: string, assignedToTenantId: any) {
        return await ApiClient.Post(`/work-orders/${id}/change-contractor`, {
            assignedToTenantId,
        });
    }

    static async GetDashboardWorkOrderStatusView() {
        return await ApiClient.Get('/work-orders/dashboard/wo-status-view');
    }

    static async DashboardActiveWorkOrdersByCompany() {
        return await ApiClient.Get('/work-orders/dashboard/wo-by-company');
    }

    static async LinkAssets(id: string, assetIds: string[]) {
        return await ApiClient.Post(`/work-orders/${id}/assets`, assetIds);
    }
    static async DeleteAsset(id: string, assetId: string) {
        return await ApiClient.Delete(`/work-orders/${id}/assets/${assetId}`);
    }

    static async SendMessage(
        id: string,
        title: string,
        message: string,
        notification: boolean,
        email: boolean,
        userIds: string[]
    ) {
        return await ApiClient.Post(`/work-orders/${id}/send-message`, {
            title,
            message,
            notification,
            email,
            userIds,
            workOrderId: id,
        });
    }
}
