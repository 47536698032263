import React, {useState, useEffect, useRef} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory, useLocation} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Col} from 'reactstrap'
import Select from 'react-select'
import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import _ from 'lodash'
import IncidentTypeSelector from '../../../common/components/selectors/IncidentTypeSelector'
import IncidentStatusSelector from '../../../common/components/selectors/IncidentStatusSelector'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import SiteSelector from '../../../common/components/selectors/SiteSelector'
import qs from 'query-string'
import {IncidentsClient} from '../../../infrastracture/api/IncidentsClient'
import {StepperComponent} from '../../../../_metronic/assets/ts/components/_StepperComponent'
import {AttachmentsClient} from '../../../infrastracture/api/AttachmentsClient'
import IncidentCauseSelector from '../../../common/components/selectors/IncidentCauseSelector'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {PersonAffectedClient} from '../../../infrastracture/api/PersonAffectedClient'

interface Props {
  viewOnly?: boolean
}

const MobileIncidentForm: React.FC<Props> = (props) => {
  const {viewOnly} = props
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>(false)
  const history = useHistory()
  const params = useParams()
  const location = useLocation()

  const validationSchema = Yup.object().shape({
    typeId: Yup.string().required('This field is required'),
    statusId: Yup.string().required('This field is required'),
    description: Yup.string().required('This field is required'),
  })

  const initialState = {
    clientId: '',
    client: {} as any,
    siteId: '',
    site: {} as any,
    closedOn: null,
    typeId: '',
    type: {} as any,
    statusId: '',
    status: {} as any,
    causeId: '',
    cause: {} as any,
    description: '',
    dateTime: null,
    location: '',
    treatment: '',
    photos: [],
  }

  const [initialValues, setInitialValues] = useState(initialState)
  const [message, setMessage] = useState<any>({})

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null) as any

  const [currentStep, setCurrentStep] = useState(1)
  const [files, setFiles] = useState<any>([])
  const [persons, setPersons] = useState<any>([])
  const [success, setSuccess] = useState(false)

  const [isMobile, setIsMobile] = useState(false)

  const inputElement = React.useRef<any>()

  const [personModalOpen, setPersonModalOpen] = useState(false)
  const [personBeingAdded, setPersonBeingAdded] = useState({name: '', note: ''})
  const [personBeingError, setPersonBeingError] = useState({name: '', note: ''})

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    //check screen width on load
    handleResize()
  }, [])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    setCurrentStep(currentStep - 1)
    stepper.current.goPrev()
  }

  useEffect(() => {
    setLoading(true)
    const value = qs.parse(location.search)
    const {userId, workOrderId} = value

    if (userId && workOrderId) {
      getDetails(userId, workOrderId)
    } else {
      history.push('/incident-mobile-error')
      setLoading(false)
    }
  }, [location])

  const getDetails = async (userId: any, workOrderId: any) => {
    var response = await IncidentsClient.GetIncidentMobileInfo(userId, workOrderId)
    const {data} = response
    if (data?.user && data?.workOrder) {
      setData({...data, userId: userId, workOrderId: workOrderId})
      setInitialValues({
        ...initialValues,
        site: {value: data?.workOrder?.site?.id, label: data?.workOrder?.site?.name},
        siteId: data?.workOrder?.site?.id,
        client: {value: data?.workOrder?.tenant?.id, label: data?.workOrder?.tenant?.name},
        clientId: data?.workOrder?.tenant?.id,
      })
      setLoading(false)
    } else {
      history.push('/incident-mobile-error')
      setLoading(false)
    }
  }

  const renderModals = () => {
    return (
      <Modal isOpen={personModalOpen}>
        <ModalHeader>Add Person Affected </ModalHeader>
        <ModalBody>
          <table className='scroll-y table table-row-dashed gy-5' id='kt_table_users_login_session'>
            <tbody className='fs-6 fw-bold text-gray-600'>
              <tr>
                <td>
                  <label className='required form-label'>Name</label>
                  <input
                    name='name'
                    placeholder=''
                    type='text'
                    className='form-control form-control-solid'
                    id='name'
                    onChange={(e: any) => {
                      setPersonBeingAdded({...personBeingAdded, name: e.target.value})
                    }}
                  />
                </td>
              </tr>

              <tr>
                <td>
                  <label className='required form-label'>Note</label>

                  <textarea
                    name='note'
                    placeholder=''
                    className='form-control form-control-solid'
                    id='note'
                    onChange={(e) => {
                      var value = e.target.value
                      setPersonBeingAdded({...personBeingAdded, note: value})
                    }}
                    rows={3}
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <button
            className='btn btn-lg btn-secondary'
            onClick={(e: any) => {
              e.preventDefault()
              setPersonModalOpen(false)
            }}
          >
            Cancel{' '}
          </button>
          <button
            onClick={(e: any) => {
              e.preventDefault()
              setPersons([...persons, personBeingAdded])
              setPersonModalOpen(false)
              setPersonBeingAdded({name: '', note: ''})
            }}
            className='btn btn-lg btn-primary'
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <>
      {/* {renderModals()} */}
      {loading && (
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-lg-600px p-10 p-lg-15 mx-auto'>
              <div className='text-center d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
                Loading...
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
        style={
          !loading
            ? {}
            : {
                display: 'none',
              }
        }
      >
        <div className='d-flex flex-center flex-column flex-column-fluid'>
          <div
            className='bg-white rounded shadow-sm'
            style={isMobile ? {width: '95%', margin: '10px'} : {width: '600px', margin: '20px'}}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
                var response: any

                const {
                  clientId,
                  siteId,
                  closedOn,
                  causeId,
                  typeId,
                  statusId,
                  description,
                  location,
                  treatment,
                  dateTime,
                } = values

                response = await IncidentsClient.CreateStandAlone(
                  clientId,
                  siteId,
                  closedOn,
                  typeId,
                  statusId,
                  causeId,
                  description,
                  dateTime,
                  location,
                  treatment,
                  data.userId
                )

                if (!response.successful) {
                  //show error
                } else {
                  if (files.length > 0) {
                    files.forEach(async (file: any) => {
                      await AttachmentsClient.CreateStandAlone(
                        null,
                        response.data.id,
                        file.name,
                        file,
                        data.userId
                      )
                    })
                    setFiles([])
                  }

                  if (persons.length > 0) {
                    persons.forEach(async (person: any) => {
                      await PersonAffectedClient.CreateStandAlone(
                        person.name,
                        person.note,
                        response.data.id,
                        data.userId
                      )
                    })
                    setPersons([])
                  }

                  setSubmitting(false)

                  history.push('/incident-mobile-success')
                }
              }}
              enableReinitialize
            >
              {({
                errors,
                touched,
                isSubmitting,
                values,
                setFieldValue,
                resetForm,
                submitForm,
                validateForm,
                setFieldError,
              }: any) => (
                <>
                  <Form>
                    <div className='card shadow-sm'>
                      <div className='card-header'>
                        <h3 className='card-title'>
                          Add Incident
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </h3>
                        <div className='card-toolbar'></div>
                      </div>
                      <div className='card-body py-5'>
                        <div
                          ref={stepperRef}
                          className='stepper stepper-pills'
                          id='kt_incident_form_stepper'
                        >
                          <div
                            className={`stepper-nav stepper-nav  mb-10 ${
                              isMobile ? 'flex-wrap' : 'flex-center flex-wrap'
                            }`}
                          >
                            <div className='stepper-item current' data-kt-stepper-element='nav'>
                              <div className='stepper-line w-20px'></div>

                              <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>1</span>
                              </div>

                              <div className='stepper-label'>
                                <h3 className='stepper-title'>Details</h3>
                              </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                              <div className='stepper-line w-20px'></div>

                              <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>2</span>
                              </div>

                              <div className='stepper-label'>
                                <h3 className='stepper-title'>Persons Affected</h3>
                              </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                              <div className='stepper-line w-20px'></div>

                              <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>3</span>
                              </div>

                              <div className='stepper-label'>
                                <h3 className='stepper-title'>Photos</h3>
                              </div>
                            </div>
                          </div>

                          <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
                            <Form className='py-1 w-100 px-1' noValidate id='kt_incident_form_form'>
                              <div className='current' data-kt-stepper-element='content'>
                                <table className='scroll-y table table-row-dashed gy-5'>
                                  <tbody className='fw-bold text-gray-600 w-100'>
                                    <tr>
                                      <td>
                                        <div>
                                          <div className='form-label'>Client</div>
                                          <div className='text-dark-600 fw-bolder fs-5'>
                                            {values.client?.label}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div>
                                          <div className='form-label'>Site</div>
                                          <div className='text-dark-600 fw-bolder fs-5'>
                                            {values.site?.label}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label className='required form-label'> Type </label>
                                        <IncidentTypeSelector
                                          isPublic
                                          placeholder='Select Incident type'
                                          defaultValue={values.type}
                                          onChange={(name, value, label) => {
                                            setFieldValue('typeId', value)
                                            setFieldValue('type', {value, label})
                                          }}
                                        />
                                        {errors.type && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.type}
                                            </span>
                                          </div>
                                        )}
                                      </td>{' '}
                                    </tr>
                                    <tr>
                                      <td>
                                        <label className='required form-label'> Cause </label>
                                        <IncidentCauseSelector
                                          isPublic
                                          placeholder='Select Cause type'
                                          defaultValue={values.cause}
                                          onChange={(name, value, label) => {
                                            setFieldValue('causeId', value)
                                            setFieldValue('cause', {value, label})
                                          }}
                                        />
                                        {errors.cause && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.cause}
                                            </span>
                                          </div>
                                        )}
                                      </td>{' '}
                                    </tr>
                                    {/* <tr>
                                      <td>
                                        <label className='required form-label'> Status </label>
                                        <IncidentStatusSelector
                                          isPublic
                                          placeholder='Select Incident Status'
                                          defaultValue={values.status}
                                          onChange={(name, value, label) => {
                                            setFieldValue('statusId', value)
                                            setFieldValue('status', {value, label})
                                          }}
                                          setDefault={(name, value, label) => {
                                            setFieldValue('statusId', value)
                                            setFieldValue('status', {value, label})
                                          }}
                                        />
                                        {errors.status && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.status}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr> */}
                                    <tr>
                                      <td>
                                        <label className='required form-label'>Date Time</label>

                                        <input
                                          type='datetime-local'
                                          name='dateTime'
                                          placeholder=''
                                          className='form-control form-control-solid'
                                          id='dateTime'
                                          value={values.dateTime}
                                          defaultValue={values.dateTime}
                                          onChange={(e) => {
                                            var value = e.target.value
                                            setFieldValue('dateTime', value)
                                          }}
                                        />

                                        {errors.dateTime && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.dateTime}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <label className='required form-label'>Description</label>

                                        <textarea
                                          name='description'
                                          placeholder=''
                                          className='form-control form-control-solid'
                                          id='description'
                                          onChange={(e) => {
                                            var value = e.target.value
                                            setFieldValue('description', value)
                                          }}
                                          rows={5}
                                          defaultValue={values.description || ''}
                                        ></textarea>
                                        {errors.description && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.description}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <label className='form-label'> Location</label>
                                        <Field
                                          name='location'
                                          placeholder=''
                                          type='text'
                                          className='form-control form-control-solid'
                                          id='location'
                                        />
                                        {errors.location && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.location}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <label className='required form-label'>Treatment</label>

                                        <textarea
                                          name='treatment'
                                          placeholder=''
                                          className='form-control form-control-solid'
                                          id='treatment'
                                          onChange={(e) => {
                                            var value = e.target.value
                                            setFieldValue('treatment', value)
                                          }}
                                          rows={3}
                                          defaultValue={values.treatment || ''}
                                        ></textarea>
                                        {errors.treatment && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.treatment}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div data-kt-stepper-element='content'>
                                <table className='scroll-y table table-row-dashed gy-5'>
                                  <tbody className='fw-bold text-gray-600 w-100'>
                                    <tr>
                                      <td>
                                        <label className='required form-label'>Name</label>
                                        <input
                                          name='name'
                                          placeholder=''
                                          type='text'
                                          className='form-control form-control-solid'
                                          id='name'
                                          value={personBeingAdded.name}
                                          onChange={(e: any) => {
                                            setPersonBeingAdded({
                                              ...personBeingAdded,
                                              name: e.target.value,
                                            })
                                          }}
                                        />{' '}
                                        {personBeingError.name && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {personBeingError.name}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <label className='form-label'>Note</label>

                                        <textarea
                                          name='note'
                                          placeholder=''
                                          className='form-control form-control-solid'
                                          id='note'
                                          onChange={(e) => {
                                            var value = e.target.value
                                            setPersonBeingAdded({...personBeingAdded, note: value})
                                          }}
                                          value={personBeingAdded.note}
                                          rows={3}
                                        ></textarea>
                                        {personBeingError.note && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {personBeingError.note}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <input
                                          type='button'
                                          value='Add person'
                                          className='form-control form-control-solid'
                                          onClick={() => {
                                            if (_.isEmpty(personBeingAdded?.name)) {
                                              setPersonBeingError({
                                                ...personBeingError,
                                                name: 'Name is required',
                                              })
                                              return
                                            } else {
                                              setPersonBeingError({
                                                ...personBeingError,
                                                name: '',
                                              })
                                            }

                                            // if (_.isEmpty(personBeingAdded?.note)) {
                                            //   setPersonBeingError({
                                            //     ...personBeingError,
                                            //     note: 'Note is required',
                                            //   })
                                            //   return
                                            // } else {
                                            //   setPersonBeingError({
                                            //     ...personBeingError,
                                            //     note: '',
                                            //   })
                                            // }

                                            setPersons([...persons, personBeingAdded])
                                            setPersonBeingAdded({name: '', note: ''})
                                            setPersonBeingError({name: '', note: ''})
                                          }}
                                        />
                                      </td>
                                    </tr>

                                    {persons.length > 0 && (
                                      <tr>
                                        <td>
                                          <ul style={{listStyle: 'none'}}>
                                            {persons.map((person: any) => (
                                              <li>
                                                <KTSVG
                                                  path='/media/icons/duotune/communication/com006.svg'
                                                  className='svg-icon-4 me-1'
                                                />
                                                {person.name} {person.note && `- ${person.note}`}
                                              </li>
                                            ))}
                                          </ul>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              <div data-kt-stepper-element='content'>
                                <table className='scroll-y table table-row-dashed gy-5'>
                                  <tbody className='fw-bold text-gray-600 w-100'>
                                    <tr>
                                      <td>
                                        <input
                                          type='button'
                                          value='Add file'
                                          className='form-control form-control-solid'
                                          onClick={() => {
                                            inputElement.current.click()
                                          }}
                                        />
                                        <input
                                          type='file'
                                          id='selectedFile'
                                          className='form-control form-control-solid'
                                          onChange={(e) => {
                                            if (!e.target.files) return

                                            setFiles([...files, e.target.files[0]])
                                          }}
                                          accept={'image/*'}
                                          ref={inputElement}
                                          style={{display: 'none'}}
                                        />
                                        {touched.file && errors.file && (
                                          <div className='fv-plugins-message-container'>
                                            <span className='text-danger' role='alert'>
                                              {errors.file}
                                            </span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                    {files.length > 0 && (
                                      <tr>
                                        <td>
                                          <ul>
                                            {files.map((file: any) => (
                                              <li>{file.name}</li>
                                            ))}
                                          </ul>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className='d-flex flex-stack pt-10'>
                                <div className='mr-2'>
                                  <button
                                    onClick={prevStep}
                                    type='button'
                                    className='btn btn-lg btn-light-primary me-3'
                                    data-kt-stepper-action='previous'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/arrows/arr063.svg'
                                      className='svg-icon-4 me-1'
                                    />
                                    Back
                                  </button>
                                </div>
                                <div>
                                  <button
                                    type='submit'
                                    className='btn btn-lg btn-primary me-3'
                                    disabled={isSubmitting}
                                    onClick={async (e) => {
                                      e.preventDefault()

                                      var isValid = await validateForm()

                                      const {
                                        clientId,
                                        siteId,
                                        closedOn,
                                        causeId,
                                        typeId,
                                        statusId,
                                        description,
                                        location,
                                        treatment,
                                        dateTime,
                                      } = values

                                      if (!typeId) {
                                        setFieldError('type', 'This field is required')
                                        return
                                      }

                                      if (!dateTime) {
                                        setFieldError('dateTime', 'This field is required')
                                        return
                                      }

                                      // if (!statusId) {
                                      //   setFieldError('status', 'This field is required')
                                      //   return
                                      // }

                                      if (!description) {
                                        setFieldError('description', 'This field is required')
                                        return
                                      }
                                      if (!causeId) {
                                        setFieldError('cause', 'This field is required')
                                        return
                                      }
                                      if (!siteId) {
                                        setFieldError('site', 'This field is required')
                                        return
                                      }
                                      if (!treatment) {
                                        setFieldError('treatment', 'This field is required')
                                        return
                                      }

                                      if (currentStep !== 3) {
                                        setCurrentStep(currentStep + 1)
                                        stepper.current.goNext()
                                      } else {
                                        submitForm()
                                      }
                                    }}
                                  >
                                    {isSubmitting ? (
                                      <span
                                        className='indicator-progress'
                                        style={{display: 'block'}}
                                      >
                                        Please wait...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                      </span>
                                    ) : currentStep !== 3 ? (
                                      'Next'
                                    ) : (
                                      'Save'
                                    )}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                      <div className='card-footer'>
                        {/* <button
              type='button'
              onClick={() => {
                resetForm()
                history.goBack()
              }}
              className='btn btn-light'
              data-bs-dismiss='modal'
              id='rd_workOrder_modal_dismiss'
            >
              Discard
            </button>  */}
                        {/* {!viewOnly && (
              <button
                type='submit'
                id='rd_create_workOrder_submit'
                className='btn btn-lg btn-primary'
                disabled={isSubmitting}
              >
                {!isSubmitting && <span className='indicator-label'>Save</span>}
                {isSubmitting && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )} */}
                      </div>
                    </div>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default MobileIncidentForm
