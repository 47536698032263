import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserQueryParams, UsersClient} from '../../../infrastracture/api/UsersClient'
import {ChargesQueryParams, ChargesClient} from '../../../infrastracture/api/ChargesClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import ChargesModal from './ChargesModal'
import {ChargesModes} from '../chargesModes'
import Moment from 'react-moment'
import {capitalizeFirstChar, toMoneyFormat} from '../../../common/helpers/stringHelper'
import {OverlayTrigger, Tooltip, Popover} from 'react-bootstrap-v5'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import SweetAlert from 'react-bootstrap-sweetalert'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

const ChargesBreadCrumbs: Array<PageLink> = []

interface Props {
  workOrderId: string
  refreshWorkOrder: () => void
  refreshList?: boolean
}

const ChargesList: React.FC<Props> = (props) => {
  const {workOrderId, refreshWorkOrder, refreshList} = props
  const [charges, setCharges] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState(ChargesModes.View)
  const [viewOnly, setViewOnly] = useState(false)
  const [activeOnly, setActiveOnly] = useState(true)
  const [totalSum, setTotalSum] = useState(0)
  const [showDelete, setShowDelete] = useState(false)
  const [showSingleDelete, setShowSingleDelete] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 99999,
    reset: true,
    sort: 'created',
    direction: 'desc',
  })

  const [loading, setLoading] = useState(false)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  useEffect(() => {
    fetchData()
  }, [workOrderId, refreshList])

  useEffect(() => {
    fetchData()
  }, [activeOnly])

  async function fetchData() {
    setLoading(true)
    try {
      var query = new ChargesQueryParams()

      var response = await ChargesClient.List(
        query
          .Paginate(pagination.skip, pagination.take)
          .WithWorkOrderId(workOrderId)
          .WithIsActive(activeOnly)
          .Search(search)
          .Sort(pagination.sort, pagination.direction)
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setCharges(data?.data)

        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })

        if (data.data.length > 0) {
          var totalSum = data.data
            .map((item: any) => item.value)
            .reduce((prev: any, next: any) => prev + next)

          setTotalSum(totalSum)
        } else {
          setTotalSum(0)
        }
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const renderModals = () => {
    return (
      <>
        <ChargesModal
          viewOnly={viewOnly}
          mode={mode}
          selectedData={selectedData}
          handleOk={() => {
            fetchData()
            refreshWorkOrder()
          }}
          workOrderId={workOrderId}
        />
        <SweetAlertWithProp
          danger
          title='Warning'
          onConfirm={async () => {
            var response = await ChargesClient.DeleteAll(workOrderId)

            fetchData()
            refreshWorkOrder()
            setShowDelete(false)
          }}
          onCancel={() => setShowDelete(false)}
          confirmBtnText={'Confirm'}
          cancelBtnBsStyle='light'
          show={showDelete}
          showCancel={true}
          showCloseButton
        >
          <span>
            <p>Are you sure you want to clear all charges?</p>
          </span>
        </SweetAlertWithProp>

        <SweetAlertWithProp
          danger
          title='Warning'
          onConfirm={async () => {
            var response = await ChargesClient.Delete(selectedData.id)
            fetchData()
            refreshWorkOrder()
            setShowSingleDelete(false)
          }}
          onCancel={() => setShowSingleDelete(false)}
          confirmBtnText={'Confirm'}
          cancelBtnBsStyle='light'
          show={showSingleDelete}
          showCancel={true}
          showCloseButton
        >
          <span>
            <p>Are you sure you want to delete the selected charge?</p>
          </span>
        </SweetAlertWithProp>
      </>
    )
  }

  return (
    <>
      {renderModals()}
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
              </span>
              <input
                type='text'
                data-kt-notice-type-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Charges'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                onKeyPress={(e) => e.key === 'Enter' && fetchData()}
              />
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
              {!loading && totalSum > 0 && (
                <div className='text-dark fw-bolder fs-6'>
                  Total: {toMoneyFormat(totalSum)} (excl gst)
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <div
                className='form-check form-switch form-check-custom form-check-solid'
                style={{paddingRight: '15px'}}
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  id='flexSwitchChecked'
                  checked={activeOnly}
                  onChange={(e) => setActiveOnly(e.target.checked)}
                />
                <label className='form-check-label'>{activeOnly ? 'Active' : 'Inactive'}</label>
              </div>

              {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                <>
                  <button
                    type='button'
                    className='btn btn-sm btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#charges_modal'
                    onMouseOver={() => {
                      setMode(ChargesModes.Add)
                      setViewOnly(false)
                    }}
                    onClick={() => {
                      setMode(ChargesModes.Add)
                      setViewOnly(false)
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Add Charges
                  </button>
                  <span style={{paddingRight: '10px'}}></span>
                  {activeOnly && charges.length > 0 && (
                    <button
                      type='button'
                      className='btn btn-sm btn-danger'
                      onClick={() => {
                        setShowDelete(true)
                      }}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr015.svg' className='svg-icon-3' />{' '}
                      Clear All
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'Type', sort: 'chargeType.name', width: '15'},
                  {name: 'Amount', sort: 'value', width: '15'},
                  {name: 'Date', sort: 'created', width: '15'},
                  {name: 'Created By', sort: 'createdBy.name', width: '15'},
                  {name: '', sort: '', static: true, width: '10'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />

              {!loading && (
                <tbody>
                  {!loading &&
                    charges.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id='tooltip-disabled'>
                                <Popover id='chargePopover'>
                                  <Popover.Title as='h3'>
                                    <div className='text-dark'>{item.name}</div>
                                  </Popover.Title>
                                  <Popover.Content></Popover.Content>
                                </Popover>
                              </Tooltip>
                            }
                            placement='top'
                            trigger='hover'
                          >
                            <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                              {item.chargeType?.name}
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td>
                          <span
                            className='text-dark fw-bolder d-block mb-1 fs-6'
                            style={item.isActive ? {} : {textDecoration: 'line-through'}}
                          >
                            {toMoneyFormat(item.value)}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                            {item.created && (
                              <Moment utc local format='DD MMM, YYYY h:mm a'>
                                {item.created}
                              </Moment>
                            )}
                          </span>
                        </td>
                        <td>
                          <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                            {item.createdBy?.name} {item.createdBy?.surname}
                          </span>
                        </td>

                        <td className='text-end'>
                          <button
                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            data-bs-toggle='modal'
                            data-bs-target='#charges_modal'
                            onMouseOver={() => {
                              setSelectedData(item)
                              setViewOnly(true)
                              setMode(ChargesModes.View)
                            }}
                            onClick={() => {
                              setSelectedData(item)
                              setViewOnly(true)
                              setMode(ChargesModes.View)
                            }}
                          >
                            Details
                          </button>
                          {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner && (
                            <button
                              type='button'
                              className='btn btn-danger btn-sm'
                              onMouseOver={() => {
                                setSelectedData(item)
                              }}
                              onClick={() => {
                                setSelectedData(item)
                                setShowSingleDelete(true)
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-notice-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChargesList
