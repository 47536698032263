import { ApiClient, PublicApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class InviteQueryParams extends ListQueryParams {
    WithStatus(value: any) {
        this.WithParam('status', value)
        return this
    }

    WithFileUploadId(value: any) {
        this.WithParam('fileUploadId', value)
        return this
    }
}

export class InvitationsClient {
    static async List(params: InviteQueryParams) {
        return await ApiClient.Get('/invites' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/invites/' + id);
    }

    static async InviteUser(name: string, surname: string, email: string, roleId: string) {
        return await ApiClient.Post('/invites/user', {
            name: name,
            surname: surname,
            email: email,
            roleId: roleId,
        });
    }

    static async AcceptInvite(code: string, password: string) {

        return await PublicApiClient.Post('/invites/confirm/' + code, {
            password: password,
        });
    }

    static async ValidateCode(code: string) {
        return await PublicApiClient.Post('/invites/validate', {
            code: code,
        });
    }

    static async Cancel(id: string) {
        return await ApiClient.Post('/invites/cancel/' + id);
    }

    static async Decline(id: string) {
        return await PublicApiClient.Post('/invites/decline/' + id);
    }

    static async Resend(id: string) {
        return await PublicApiClient.Post('/invites/resend/' + id);
    }

    static async Update(id: string, name: string, surname: string) {

        return await ApiClient.Patch('/invites/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'surname',
                value: surname,
            },
        ]);
    }

    static async BulkSendEmails() {

        return await ApiClient.Get('/invites/bulk/send-emails');
    }
}
