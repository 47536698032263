/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { QuoteApprovalForm } from './components/QuoteApprovalForm'
import { WorkOrderQuoteApprovalForm } from './components/WorkOrderQuoteApprovalForm'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

function QuoteApproval() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid'>
              <Switch>
                  <Route path='/quote-approval/:id' component={QuoteApprovalForm} />
                  <Route path='/workorder-quote-approval/:id' component={WorkOrderQuoteApprovalForm} />
          <Redirect from='' exact={true} to='/login' />
        </Switch>
      </div>
    </div>
  )
}

export default QuoteApproval
