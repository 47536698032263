import {Form} from 'formik'
import React, {useState, useRef, useEffect} from 'react'
import {ProgressBar} from 'react-bootstrap-v5'
import Dropzone from 'react-dropzone'
import {KTSVG} from '../../../_metronic/helpers'
import {CheckListClient} from '../../infrastracture/api/CheckListClient'
import {CheckListItemFileClient} from '../../infrastracture/api/CheckListItemFileClient'
import {CheckListTemplateItemQueryParams} from '../../infrastracture/api/CheckListTemplateItemClient'
import CheckListItemRenderer from './CheckListItemRenderer'
import qs from 'query-string'
import {useLocation} from 'react-router-dom'
import './styles.css'
import CheckListAnswered from './CheckListAnswered'

interface Props {}

const CheckListStandAlone: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true)
  const [openComment, setOpenComment] = useState(false)
  const [openPhoto, setOpenPhoto] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<any[]>([])
  const [uploadedFileNames, setUploadedFileNames] = useState<any[]>([])
  const [fileNames, setFileNames] = useState<any[]>([])
  const [currentFile, setCurrentFile] = useState<any>(null)
  const [progress, setProgress] = useState(0)
  const [items, setItems] = useState<any[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [finished, setFinished] = useState(false)
  const [alreadyAnswered, setAlreadyAnswered] = useState(false)

  const location = useLocation()

  const [checkListId, setCheckList] = useState<any>('')
  const [userId, setUserId] = useState<any>('')

  useEffect(() => {
    const value = qs.parse(location.search)
    const {checkListId: q_checkListId, userId: q_userId} = value

    if (q_checkListId && q_userId) {
      setCheckList(q_checkListId)
      setUserId(q_userId)
    }
  }, [location])

  useEffect(() => {
    if (checkListId && userId) {
      fetchData()
    }
  }, [checkListId, userId])

  const fetchData = async () => {
    try {
      setLoading(true)
      var query = new CheckListTemplateItemQueryParams()

      var response: any

      if (checkListId) {
        response = await CheckListClient.Get(checkListId)
        if (!response.authenticated) {
        } else {
          const {data} = response

          setItems(data?.checkListItems)
        }
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (selectedFiles) selectedFiles.map((file: any) => upload(file))
  }, [selectedFiles])

  const handleDrop = (acceptedFiles: any) => {
    var additional = acceptedFiles.map((file: any) => file.name)
    setSelectedFiles(acceptedFiles)
    setFileNames([...fileNames, ...additional])
  }

  const upload = (file: any) => {
    let currentFile = selectedFiles![0]

    setProgress(0)
    setCurrentFile(currentFile)

    CheckListItemFileClient.Create(items[currentIndex].id, file.name, currentFile, (event: any) => {
      setProgress(Math.round((100 * event.loaded) / event.total))
    })
      .then((response) => {
        setUploadedFileNames([...uploadedFileNames, file.name])
        setCurrentFile(null)
        setSelectedFiles([])
      })
      .catch(() => {
        setProgress(0)
        setCurrentFile(null)
        setSelectedFiles([])
      })
  }

  const commentRef = useRef<any>(null)
  if (loading)
    return (
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <div className='w-lg-600px p-10 p-lg-15 mx-auto'>
            <div className='text-center d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
              Loading...
            </div>
          </div>
        </div>
      </div>
    )
  else if (!loading && finished)
    return (
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed roll-out'>
        <div className='w-lg-600px p-10 p-lg-15 mx-auto my-auto'>
          <div className='alert alert-success'>
            <div className='d-flex flex-column text-center'>
              <span>You have completed answering the check list</span>
            </div>
          </div>
        </div>
      </div>
    )
  else if (!loading && alreadyAnswered)
    return (
      <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
          <div className='w-100 w-sm-100 w-lg-600px'>
            <CheckListAnswered
              items={items}
              checkListId={checkListId}
              setParentIndex={(index: number) => setCurrentIndex(index)}
            />
          </div>
        </div>
      </div>
    )
  else
    return (
      <>
        <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
          <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-100 w-sm-100 w-lg-600px'>
              <CheckListItemRenderer
                items={items}
                setParentIndex={(index: number) => setCurrentIndex(index)}
                userId={userId}
                checkListId={checkListId}
                setFinished={(value: boolean) => setFinished(value)}
                setAlreadyAnswered={(value: boolean) => setAlreadyAnswered(value)}
              />
            </div>
          </div>
        </div>
      </>
    )
}

export default CheckListStandAlone
