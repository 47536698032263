/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, Dispatch, useState} from 'react'
import {DashboardClient, DashboardQueryParams} from '../../../infrastracture/api/DashboardClient'
import Moment from 'react-moment'
import moment from 'moment'
import {Link, useHistory} from 'react-router-dom'
import {Actions} from '../../../modules/work-order/redux/WorkOrderRedux'
import {RootState} from '../../../../setup'
import {connect} from 'react-redux'

type Props = {
  dispatch: Dispatch<any>
  workorder: any
  tenantId: string
}

const WorkOrderStatusView: React.FC<Props> = (props) => {
  const {workorder, dispatch, tenantId} = props
  const [statusViewList, setStatusviewList] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (tenantId) fetchData()

    //fetch data every 2 mins
    const interval = setInterval(() => {
      fetchData()
    }, 120000)

    return () => clearInterval(interval)
  }, [tenantId])

  const fetchData = async () => {
    setLoading(true)
    var query = new DashboardQueryParams()
    var response: any

    if (tenantId !== 'all') {
      response = await DashboardClient.GetWorkOrderStatusView(
        query.Paginate(0, 99999).WithAssignedTo(tenantId).WithTenantId(tenantId)
      )
    } else {
      response = await DashboardClient.GetWorkOrderStatusView(query.Paginate(0, 99999))
    }

    if (!response.authenticated) {
    } else {
      const {data} = response
      setStatusviewList(data.data)
    }
    setLoading(false)
  }

  return (
    <div className={`card `}>
      <div className={`card-header border-0 py-5`}>
        <h3 className='card-title fw-bolder'>Work Orders Status</h3>

        <div className='card-toolbar'>
          <span className='badge badge-primary'>
            {statusViewList.reduce((a, {count}) => a + count, 0)}
          </span>
        </div>
      </div>
      <div className='card-body pt-2' style={{height: '500px', overflowY: 'auto'}}>
        {loading && (
          <div className='d-flex align-items-center'>
            <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
          </div>
        )}
        {!loading && !statusViewList.length && (
          <div className='fw-bolder text-gray-800 ps-3 text-center'>No Data</div>
        )}
        {!loading &&
          statusViewList.length > 0 &&
          statusViewList.map((item: any) => (
            <div className='d-flex align-items-center mb-8'>
              <span
                className={`bullet bullet-vertical h-40px ${!item.color && 'bg-success'}`}
                style={item.color && {background: item.color}}
              ></span>
              <div className='flex-grow-1' style={{marginLeft: '15px'}}>
                <span
                  style={{cursor: 'pointer'}}
                  className='text-gray-800 text-hover-primary fw-bolder fs-6'
                  onClick={() => {
                    dispatch(
                      Actions.setFilter({
                          ...workorder.filter,
                          clientId: null,
                        workOrderStatusId: item.statusId,
                        ignoreComplete: false,
                      })
                    )
                    history.push('/work-orders/list')
                  }}
                >
                  {item.statusName}
                </span>
                <span className='text-muted fw-bold d-block'>
                  {item.count > 0 && `Oldest ${moment().diff(item.latestTimeStamp, 'days')} days`}
                </span>
              </div>
              <span
                className={`badge fs-8 fw-bolder ${!item.color && 'badge-light-success'}`}
                style={item.color && {background: item.color}}
              >
                {item.count}
              </span>
            </div>
          ))}
      </div>
    </div>
  )
}

export default connect(({workorder}: RootState) => ({
  workorder,
}))(WorkOrderStatusView)
