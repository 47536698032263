import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {NotesClient, NotesQueryParams} from '../../../infrastracture/api/NotesClient'
import {Col} from 'reactstrap'

import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import SweetAlert from 'react-bootstrap-sweetalert'
import NotesList from './NotesList'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

interface Props {
  handleOk: () => void
  selectedData: any
  mode: string
  viewOnly?: boolean
  workOrderId: string
}

const NotesModal: React.FC<Props> = (props) => {
  const {handleOk, selectedData, mode, viewOnly, workOrderId} = props
  const [createSuccess, setCreateSuccess] = useState(false)
  const history = useHistory()

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
  })

  const [initialValues, setInitialValues] = useState<any>({})

  useState()

  useEffect(() => {
    if (mode === 'Edit' || mode === 'View') setInitialValues(selectedData)
    else
      setInitialValues({
        description: '',
      })
  }, [selectedData, mode])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          var response: any

          if (mode === 'Edit') {
            response = await NotesClient.Update(selectedData.id, values.description)
          } else {
            response = await NotesClient.Create(values.description, workOrderId)
          }

          if (!response.successful) {
            //show error
          } else {
            setSubmitting(false)
            document.getElementById('rd_notes_modal_dismiss')!.click()
            setCreateSuccess(true)
          }
        }}
        enableReinitialize
      >
        {({errors, touched, isSubmitting, values, setFieldValue, resetForm}: any) => (
          <>
            <SweetAlertWithProp
              success
              title='Success'
              onConfirm={() => {
                setCreateSuccess(false)
                handleOk()
              }}
              confirmBtnText='Confirm'
              cancelBtnBsStyle='light'
              show={createSuccess}
              showCancel={false}
              showCloseButton
            >
              {mode} Notes success
            </SweetAlertWithProp>
            <div className='modal fade' tabIndex={-1} id='notes_modal'>
              <Form>
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>{mode} Note</h5>

                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <span className='svg-icon svg-icon-2x'></span>
                      </div>
                    </div>

                    <div className='modal-body'>
                      <div
                        className='scroll-y me-n7 pe-7'
                        id='kt_modal_add_customer_scroll'
                        data-kt-scroll='true'
                        style={{maxHeight: '850px'}}
                      >
                        <table
                          className='scroll-y table table-row-dashed gy-5'
                          id='kt_table_users_login_session'
                        >
                          <tbody className='fs-6 fw-bold text-gray-600'>
                            <tr>
                              <td>
                                <label className='required form-label'>Description</label>
                                <Field
                                  name='description'
                                  placeholder=''
                                  className='form-control form-control-solid'
                                  id='description'
                                  rows='10'
                                  component='textarea'
                                  disabled={viewOnly}
                                  defaultValue={values.description}
                                />
                                {touched.description && errors.description && (
                                  <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                      {errors.description}
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        onClick={() => {
                          resetForm()
                        }}
                        className='btn btn-light'
                        data-bs-dismiss='modal'
                        id='rd_notes_modal_dismiss'
                      >
                        Close
                      </button>
                      {!viewOnly && (
                        <button
                          type='submit'
                          id='rd_create_tenant_submit'
                          className='btn btn-lg btn-primary'
                          disabled={isSubmitting}
                        >
                          {!isSubmitting && <span className='indicator-label'>Save</span>}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </Formik>
    </>
  )
}

export default NotesModal
