import {Action} from '@reduxjs/toolkit'
import moment from 'moment'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Hazard SetFilter] Action',
  ResetFilter: '[Hazard ResetFilter] Action',
}

const initialHazardState: IHazardState = {
  filter: {
    statusId: '',
    typeId: '',
    clientId: '',
    siteId: '',
    start: '',
    end: '',
    pagination: {
      currentPage: 1,
      totalRecords: 1,
      totalPages: 1,
      skip: 0,
      take: 50,
      reset: true,
      sort: 'created',
      direction: 'desc',
    },
  },
}

export interface IHazardState {
  filter?: {
    statusId: string
    typeId: string
    clientId: string
    siteId: string
    start: any
    end: any
    pagination: any
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Hazard', whitelist: ['filter']},
  (state: IHazardState = initialHazardState, action: ActionWithPayload<IHazardState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialHazardState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
