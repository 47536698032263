import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

export function MenuInner() {
  const intl = useIntl()
  const permissions: any = useSelector<RootState>(
    ({auth}) => auth.userPermission,
    shallowEqual
  ) as any

  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      {permissions?.includes('workOrder.view.all') && (
        <MenuItem title='Work Orders' to='/work-orders' />
      )}
    </>
  )
}
