import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {
  WorkOrderStatusQueryParams,
  WorkOrderStatusClient,
} from '../../../infrastracture/api/WorkOrderStatusClient'

interface Props {
  workOrderId: string
  name?: string
  defaultValue?: any
  placeholder?: string
  disabled?: boolean
  onChange?: (value: any) => void
  statusFlowId?: string
}
const customStyles = {
  control: (provided: any) => ({
    ...provided,
    background: '#F1FAFF',
    color: '#009ef7',
    borderRadius: '5px',
    border: 'none',
    fontSize: '0.925rem',
    fontWeight: 500,
    minWidth: '150px',
  }),
  indicatorSeparator: (_: any) => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#009ef7',
  }),
  option: (styles: any) => ({
    ...styles,
  }),
  menuPortal: (base: any) => ({...base, zIndex: 9999}),
  singleValue: (provided: any) => ({...provided, background: '#f5f8fa', color: '#009ef7'}),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    background: '#f5f8fa',
    color: '#009ef7',
  }),
}

const WorkOrderStatusUpdateSelector: React.FC<Props> = (props: any) => {
  const {defaultValue, placeholder, disabled, name, onChange, statusFlowId} = props

  const [options, setOptions] = useState([])
  const [value, setValue] = useState<any>({})

  useEffect(() => {
    populateData()
  }, [statusFlowId])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const populateData = async () => {
    var query = new WorkOrderStatusQueryParams()

    if (statusFlowId) query.WithStatusFlowId(statusFlowId)

    //TODO: SUPPORT SEARCH FOR PERFORMANCE AGAINST LARGE COLLECTIONS
    var data = (
      await WorkOrderStatusClient.List(
        query.Paginate(0, 10000).WithActive(true).Sort('name', 'asc')
      )
    ).data

    if (data) {
      setOptions(
        data.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            isDisabled: item.disabled,
          }
        })
      )
    }
  }

  const handleChange = (values: any) => {
    const {value} = values
    setValue(values)
    onChange(value)
  }

  return (
    <Select
      onChange={handleChange}
      value={value}
      options={options}
      placeholder={placeholder}
      isDisabled={disabled}
      id={name}
      name={name}
      styles={customStyles}
      menuPortalTarget={document.body}
    />
  )
}

export default WorkOrderStatusUpdateSelector
