import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'
import { GetAPIDomain } from '../Helper'

export class AssetsQueryParams extends ListQueryParams {
    WithEnabled(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithContractId(value: any) {
        this.WithParam('contractId', value)
        return this
    }
    WithScheduleId(value: any) {
        this.WithParam('scheduleId', value)
        return this
    }
    WithSiteId(value: any) {
        this.WithParam('siteId', value)
        return this
    }
    WithClientId(value: any) {
        this.WithParam('clientId', value)
        return this
    }
    WithTypeId(value: any) {
        this.WithParam('typeId', value)
        return this
    }
    WithCategoryId(value: any) {
        this.WithParam('categoryId', value)
        return this
    }
    WithWorkOrderId(value: any) {
        this.WithParam('workOrderId', value)
        return this
    }
    WithPPM(value: boolean) {
        this.WithParam('ppm', value)
        return this
    }
    WithStatus(value: any) {
        this.WithParam('status', value)
        return this
    }
    WithWarranty(value: boolean) {
        this.WithParam('warranty', value)
        return this
    }
    WithFiftyLifeCycle(value: boolean) {
        this.WithParam('fiftyLifeCycle', value)
        return this
    }
    WithSeventyFiveLifeCycle(value: boolean) {
        this.WithParam('seventyFiveLifeCycle', value)
        return this
    }
    WithSeventyFivePlusLifeCycle(value: boolean) {
        this.WithParam('seventyFivePlusLifeCycle', value)
        return this
    }
}

export class AssetsClient {
    static async List(params: AssetsQueryParams) {
        return await ApiClient.Get('/assets' + params.GenerateQueryString());
    }

    static async Dashboard() {
        return await ApiClient.Get('/assets/dashboard');
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/assets/' + id);
    }

    static async Get(id: string) {
        return await ApiClient.Get('/assets/' + id);
    }

    static async Create(
        assetCategoryId: any,
        assetTypeId: any,
        name: any,
        make: any,
        model: any,
        description: any,
        details: any,
        serialNo: any,
        reference: any,
        status: any,
        siteId: any,
        location: any,
        warranty: any,
        warrantyTerm: any,
        expiryDate: any,
        ppm: any,
        renewalDate: any,
        commissionDate: any,
        purchaseValue: any,
        assetLifeCycle: any,
        currentValue: any,
        installContractorId: any,
        serviceContractorId: any,
        ppmAnnualValue: any,
        contractId: any,
        warrantyExpiry: any,
        clientId: any,
        privateValues: boolean,
        installContractorName: string,
        serviceContractorName: string
    ) {
        return await ApiClient.Post('/assets/', {
            assetCategoryId,
            assetTypeId,
            name,
            make,
            model,
            description,
            details,
            serialNo,
            reference,
            status,
            siteId,
            location,
            warranty,
            warrantyTerm,
            expiryDate,
            ppm,
            renewalDate,
            commissionDate,
            purchaseValue,
            assetLifeCycle,
            currentValue,
            installContractorId,
            serviceContractorId,
            ppmAnnualValue,
            contractId,
            warrantyExpiry,
            clientId,
            privateValues,
            installContractorName,
            serviceContractorName,
        });
    }

    static async BulkUpdate(file: any) {
        let formData = new FormData()
        formData.append('file', file)

        var accessToken = localStorage.getItem('acess-token');

        const response = await fetch((await GetAPIDomain()) + '/assets/bulk-update', {
            method: 'post',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
            body: formData,
        })

        return await response.json()
    }

    static async Update(
        id: string,
        assetCategoryId: any,
        assetTypeId: any,
        name: any,
        make: any,
        model: any,
        description: any,
        details: any,
        serialNo: any,
        reference: any,
        status: any,
        siteId: any,
        location: any,
        warranty: any,
        warrantyTerm: any,
        expiryDate: any,
        ppm: any,
        renewalDate: any,
        commissionDate: any,
        purchaseValue: any,
        assetLifeCycle: any,
        currentValue: any,
        installContractorId: any,
        serviceContractorId: any,
        ppmAnnualValue: any,
        contractId: any,
        warrantyExpiry: any,
        clientId: any,
        privateValues: boolean,
        installContractorName: string,
        serviceContractorName: string
    ) {


        return await ApiClient.Patch('/assets/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'assetCategoryId',
                value: assetCategoryId,
            },
            {
                op: 'replace',
                path: 'assetTypeId',
                value: assetTypeId,
            },
            {
                op: 'replace',
                path: 'make',
                value: make,
            },
            {
                op: 'replace',
                path: 'model',
                value: model,
            },
            {
                op: 'replace',
                path: 'details',
                value: details,
            },
            {
                op: 'replace',
                path: 'serialNo',
                value: serialNo,
            },
            {
                op: 'replace',
                path: 'reference',
                value: reference,
            },
            {
                op: 'replace',
                path: 'status',
                value: status,
            },
            {
                op: 'replace',
                path: 'siteId',
                value: siteId,
            },
            {
                op: 'replace',
                path: 'location',
                value: location,
            },
            {
                op: 'replace',
                path: 'warranty',
                value: warranty,
            },
            {
                op: 'replace',
                path: 'warrantyTerm',
                value: warrantyTerm,
            },
            {
                op: 'replace',
                path: 'expiryDate',
                value: expiryDate,
            },
            {
                op: 'replace',
                path: 'ppm',
                value: ppm,
            },
            {
                op: 'replace',
                path: 'renewalDate',
                value: renewalDate,
            },
            {
                op: 'replace',
                path: 'commissionDate',
                value: commissionDate,
            },
            {
                op: 'replace',
                path: 'purchaseValue',
                value: purchaseValue,
            },
            {
                op: 'replace',
                path: 'assetLifeCycle',
                value: assetLifeCycle,
            },
            {
                op: 'replace',
                path: 'currentValue',
                value: currentValue,
            },
            {
                op: 'replace',
                path: 'installContractorId',
                value: installContractorId,
            },
            {
                op: 'replace',
                path: 'serviceContractorId',
                value: serviceContractorId,
            },
            {
                op: 'replace',
                path: 'ppmAnnualValue',
                value: ppmAnnualValue,
            },
            {
                op: 'replace',
                path: 'contractId',
                value: contractId,
            },
            {
                op: 'replace',
                path: 'warrantyExpiry',
                value: warrantyExpiry,
            },
            {
                op: 'replace',
                path: 'clientId',
                value: clientId,
            },
            {
                op: 'replace',
                path: 'privateValues',
                value: privateValues,
            },
            {
                op: 'replace',
                path: 'installContractorName',
                value: installContractorName,
            },
            {
                op: 'replace',
                path: 'serviceContractorName',
                value: serviceContractorName,
            },
        ]);

    }
}
