import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

interface Props {
    selectedData: any
}

const AttachmentsViewModal: React.FC<Props> = (props) => {
    const { selectedData } = props

    const { fileName, uri, name,id } = selectedData
    const [createSuccess, setCreateSuccess] = useState(false)
    const params: any = useParams()

    return (
        <>
            <div className='modal fade' id='attachment_view_modal'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>{name}</h5>

                            <div
                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                data-bs-dismiss='modal'
                                aria-label='Close'
                            >
                                <span className='svg-icon svg-icon-2x'></span>
                            </div>
                        </div>

                        <div className='modal-body'>
                            <div
                                className='scroll-y me-n7 pe-7'
                                id='kt_modal_add_customer_scroll'
                                data-kt-scroll='true'
                                style={{ maxHeight: '450px' }}
                            >
                                <Zoom>
                                    <img id={id} src={uri} alt='Preview of the attachment' style={{ maxHeight: '450px', maxWidth: '450px' }} />
                                </Zoom>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-light'
                                data-bs-dismiss='modal'
                                id='attachment_view_modal_dismiss'
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AttachmentsViewModal
