import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserQueryParams, UsersClient} from '../../../infrastracture/api/UsersClient'
import {CheckListClient, CheckListQueryParams} from '../../../infrastracture/api/CheckListClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import Geocode from 'react-geocode'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import SweetAlert from 'react-bootstrap-sweetalert'
import Moment from 'react-moment'
import SiteCheckListModal from './SiteCheckListModal'
import CheckListWizard from '../../check-list-templates/components/CheckListWizard'
import CompletedCheckListModal from '../../check-lists/components/CompletedCheckListModal'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

const CheckListTemplateBreadCrumbs: Array<PageLink> = []

interface Props {
  siteId: string
  workOrderId: string
  refreshWorkOrder: () => void
}

const SiteCheckList: React.FC<Props> = ({siteId, workOrderId, refreshWorkOrder}) => {
  const [checkLists, setCheckLists] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)
  const [selectedData, setSelectedData] = useState<any>({})
  const [viewOnly, setViewOnly] = useState(false)
  const [resetFilter, setResetFilter] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showAdd, setShowAdd] = useState(false)
  const [showWizard, setShowWizard] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  const history = useHistory()

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'created',
    direction: 'desc',
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (pagination.reset && siteId) fetchData()
  }, [search, pagination])

  useEffect(() => {
    fetchData()
  }, [workOrderId])

  useEffect(() => {
    if (resetFilter && siteId) fetchData()
  }, [active, resetFilter])

  async function fetchData() {
    setLoading(true)
    try {
      var query = new CheckListQueryParams()

      var response = await CheckListClient.List(
        query
          .Paginate(pagination.skip, pagination.take)
          .Search(search)
          .WithActive(active)
          .WithWorkOrderId(workOrderId)
          .Sort(pagination.sort, pagination.direction)
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setCheckLists(data?.data)

        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })
      }
      setLoading(false)
      setResetFilter(false)
    } catch (e) {
      console.error(e)
    }
  }

  const permissions: any = useSelector<RootState>(
    ({auth}) => auth.userPermission,
    shallowEqual
  ) as any

  async function refresh() {
    setResetFilter(true)
  }

  async function reset() {
    setActive(true)
    setResetFilter(true)
  }

  const handleActiveChange = (e: any, value: any) => {
    var value: any = e.target.value === 'yes' ? true : false

    setActive(value)
  }
  const renderModals = () => {
    return (
      <>
        <SweetAlertWithProp
          danger
          title='Warning'
          onConfirm={async () => {
            var response = await CheckListClient.Delete(selectedData?.id)

            fetchData()
            refreshWorkOrder()
            setShowDelete(false)
          }}
          onCancel={() => setShowDelete(false)}
          confirmBtnText={'Confirm'}
          cancelBtnBsStyle='light'
          show={showDelete}
          showCancel={true}
          showCloseButton
        >
          <span>
            <p>
              Are you sure you want to delete <b>{selectedData?.name}</b>?
            </p>
          </span>
        </SweetAlertWithProp>
        <SiteCheckListModal
          handleOk={(item: any) => {
            setSelectedData(item)
            setShowAdd(false)
            fetchData()
          }}
          handleClose={() => {
            setShowAdd(false)
          }}
          workOrderId={workOrderId}
          show={showAdd}
          siteId={siteId}
        />
        <CheckListWizard
          checkListTemplateId={selectedData.checkListTemplateId}
          checkListId={selectedData.id}
          isTestMode={false}
          show={showWizard}
          handleOk={() => {
            setShowWizard(false)
            refreshWorkOrder()
            fetchData()
          }}
          handleClose={() => {
            setShowWizard(false)
            refreshWorkOrder()
            fetchData()
          }}
        />
        <CompletedCheckListModal
          checkListId={selectedData.id}
          handleClose={() => {
            setShowDetails(false)
          }}
          show={showDetails}
        />
      </>
    )
  }

  return (
    <>
      {renderModals()}
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
              </span>
              <input
                type='text'
                data-kt-site-type-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search check list'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                onKeyPress={(e) => e.key === 'Enter' && fetchData()}
              />
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <button
                type='button'
                className='btn btn-sm btn-light-primary me-3'
                onClick={() => {
                  setShowAdd(true)
                  setViewOnly(false)
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                Add Check List
              </button>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'Name', sort: 'checkListTemplate.name', width: '15'},
                  {name: 'Created Date', sort: 'created', width: '15'},
                  {name: 'Created By', sort: 'createdBy.name', width: '15'},
                  {name: 'Answered', static: true, width: '15'},
                  {name: '', sort: '', static: true, width: '10'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    checkLists.length > 0 &&
                    checkLists.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <a
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              if (item.answeredItems === item.checkListItems.length)
                                history.push(
                                  `/work-orders/details/${item.workOrderId}/check-list/${item.id}`
                                )
                              else
                                window.open(
                                  `/check-list-stand-alone?checkListId=${item.id}&userId=${user.id}`,
                                  '_blank'
                                )
                            }}
                            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                          >
                            {item.name}
                          </a>
                        </td>

                        <td>
                          {item.created && (
                            <Moment
                              utc
                              local
                              format='ddd DD MMM, YYYY h:mm a'
                              className='text-dark fw-bolder'
                            >
                              {item.created}
                            </Moment>
                          )}
                        </td>

                        <td>
                          {item.createdBy && (
                            <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                              {item.createdBy.name} {item.createdBy.surname}
                            </a>
                          )}
                          {!item.createdBy && <i>Not specified</i>}
                        </td>
                        <td>
                          <p
                            className={`fw-bolder d-block mb-1 fs-6 ${
                              item.answeredItems === item.checkListItems.length
                                ? 'text-success'
                                : 'text-danger'
                            }`}
                          >
                            {item.answeredItems} / {item.checkListItems.length}
                          </p>
                        </td>
                        <td className='text-end'>
                          {item.answeredItems === item.checkListItems.length ? (
                            <Link
                              className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                              to={`/work-orders/details/${item.workOrderId}/check-list/${item.id}`}
                            >
                              Details
                            </Link>
                          ) : (
                            <button
                              type='button'
                              className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                              onMouseOver={() => {
                                setSelectedData(item)
                              }}
                              onClick={() => {
                                window.open(
                                  `/check-list-stand-alone?checkListId=${item.id}&userId=${user.id}`,
                                  '_blank'
                                )
                              }}
                            >
                              Open
                            </button>
                          )}
                          {item.createdById === user.id && (
                            <>
                              <button
                                type='button'
                                className='btn btn-danger btn-sm px-4 me-2'
                                onMouseOver={() => {
                                  setSelectedData(item)
                                }}
                                onClick={() => {
                                  setShowDelete(true)
                                }}
                              >
                                Delete
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-site-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SiteCheckList
