import { ApiClient } from '../../../setup/axios/SetupAxios';
import { ListQueryParams } from '../ApiClient';

export class ProjectQueryParams extends ListQueryParams {
    WithCustomerId(value: string) {
        this.WithParam('customerId', value)
        return this
    }
}

export class ProjectsClient {
    static async List(params: ProjectQueryParams) {
        return await ApiClient.Get('/integrations/myob/projects' + params.GenerateQueryString());
    }

    static async Create(description: string, customerId: string, ownerId: string, salesPersonEmployeeId: string, projectType: string, wipSip: string, branch: string) {
        return await ApiClient.Post('/integrations/myob/projects', {
            description: description,
            customerId: customerId,
            ownerId: ownerId,
            salesPersonEmployeeId: salesPersonEmployeeId,
            projectType: projectType,
            wIPSIP: wipSip,
            branch: branch
        });
    }

    static async GetProjectId(tenantId?: string, onBehalfOfTenantId?: string, departmentId?: string, externalMYOBProjectId?: Boolean) {
        return await ApiClient.Post('/integrations/myob/projects/calculate', {
            tenantId: tenantId,
            onBehalfOfTenantId: onBehalfOfTenantId,
            departmentId: departmentId,
            externalMYOBProjectId: externalMYOBProjectId
        });
    }
}
