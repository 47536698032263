import React, {useState, useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Col, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  QuoteBreakdownClient,
  QuoteBreakdownQueryParams,
} from '../../../infrastracture/api/QuoteBreakdownClient'
import {toMoneyFormat} from '../../../common/helpers/stringHelper'
interface Props {
  handleOk: (quoteId: any) => void
  handleClose: (quoteId?: any) => void
  quoteId: string
  quotedValue: any
  show: boolean
}

const QuotesBreakdownModal: React.FC<Props> = (props) => {
  const {handleOk, show, handleClose, quoteId, quotedValue} = props

  const [loading, setLoading] = useState(false)
  const [reloadQuoteModal, setReloadQuoteModal] = useState(false)
  const [items, setItems] = useState<any>([])
  const [selectedData, setSelectedData] = useState<any>({})
  const [availableBalance, setAvailableBalance] = useState(0)

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const validationSchema = Yup.object().shape({})

  const initialValues = {
    cost: NaN,
    item: '',
    description: '',
  } as any

  useEffect(() => {
    if (quoteId && show) {
      getQuoteBreakdowns(quoteId)
      setReloadQuoteModal(false)
    }
  }, [quoteId, show])
  useEffect(() => {
    if (items && items.length > 0) {
      const itemsCost = items.reduce((n: any, {cost}: any) => n + cost, 0)
      setAvailableBalance(parseFloat(quotedValue) - parseFloat(itemsCost))
    }
  }, [items])

  const getQuoteBreakdowns = async (quoteId: string) => {
    setLoading(true)
    var query = new QuoteBreakdownQueryParams()

    var response = await QuoteBreakdownClient.List(query.WithQuoteId(quoteId))
    if (!response.authenticated) {
    } else {
      const {data} = response

      setItems(data)
      setLoading(false)
    }
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError, resetForm}) => {
          var response: any

          var totalCost = parseFloat(values.cost)

          var hasError = false

          if (Number.isNaN(values.cost) || values.cost <= 0) {
            setFieldError('cost', 'Cost is required')
            hasError = true
          }

          if (!values.item) {
            setFieldError('item', 'Item is required')
            hasError = true
          }
          if (!values.description) {
            setFieldError('description', 'Description is required')
            hasError = true
          }

          if (hasError) return

          if (items && items.length) {
            const itemsCost = items.reduce((n: any, {cost}: any) => n + cost, 0)

            totalCost += parseFloat(itemsCost)
          }

          if (totalCost > parseFloat(quotedValue)) {
            setFieldError('totalCost', 'Total cost cannot be greater than quoted value')
            return
          }

          response = await QuoteBreakdownClient.Create(
            values.cost,
            values.item,
            values.description,
            quoteId,
            quotedValue
          )

          if (response?.successful) {
            setSubmitting(false)
            resetForm(initialValues)
            getQuoteBreakdowns(quoteId)
            setReloadQuoteModal(true)
            // handleOk(quoteId)
          }
        }}
        enableReinitialize
      >
        {({errors, touched, isSubmitting, values, setFieldValue, resetForm, submitForm}: any) => (
          <>
            <>
              <Modal isOpen={show} onHide={() => {}} zIndex={1000} size='lg'>
                <Form>
                  <ModalHeader closeButton>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      <h5 className='modal-title'>Quote Breakdown</h5>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignContent: 'flex-end',
                          flexWrap: 'wrap',
                        }}
                      >
                        <table>
                          <tr>
                            <td style={{textAlign: 'right'}}>
                              <h6>Quoted: </h6>
                            </td>
                            <td>
                              <h6 style={{marginLeft: '5px'}}> {toMoneyFormat(quotedValue)}</h6>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h6>Available: </h6>
                            </td>
                            <td>
                              <h6 style={{marginLeft: '5px'}}>
                                {' '}
                                {toMoneyFormat(availableBalance)}
                              </h6>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </ModalHeader>
                  <ModalBody>
                    {' '}
                    <table style={{width: '100%'}}>
                      <tr>
                        <td width='20%' style={{verticalAlign: 'top'}}>
                          <label className='required form-label'>Cost</label>
                          <div className='input-group mb-5'>
                            <span className='input-group-text'>$</span>{' '}
                            <Field
                              type='number'
                              value={values.cost}
                              onChange={(e: any) => {
                                var value = e.target.value
                                value =
                                  value.indexOf('.') >= 0
                                    ? value.substr(0, value.indexOf('.')) +
                                      value.substr(value.indexOf('.'), 3)
                                    : value

                                setFieldValue('cost', value)
                              }}
                              className='form-control form-control-solid'
                              min='0'
                              name='cost'
                            />
                            {touched.cost && errors.cost && (
                              <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                  {errors.cost}
                                </span>
                              </div>
                            )}
                          </div>
                        </td>
                        <td width='30%' style={{verticalAlign: 'top'}}>
                          <label className='required form-label'>Item</label>
                          <Field
                            name='item'
                            placeholder=''
                            type='text'
                            className='form-control form-control-solid'
                            id='item'
                          />
                          {touched.item && errors.item && (
                            <div className='fv-plugins-message-container'>
                              <span className='text-danger' role='alert'>
                                {errors.item}
                              </span>
                            </div>
                          )}
                        </td>{' '}
                        <td width='50%' style={{verticalAlign: 'top'}}>
                          <label className='required form-label'>Description</label>
                          <div style={{display: 'flex'}}>
                            <Field
                              name='description'
                              placeholder=''
                              type='text'
                              className='form-control form-control-solid'
                              id='description'
                            />
                            <button
                              type='button'
                              className='btn btn-sm btn-light-primary'
                              onClick={() => {
                                submitForm()
                              }}
                              style={{marginLeft: '5px'}}
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr075.svg'
                                className='svg-icon-3'
                              />
                            </button>
                          </div>
                          {touched.description && errors.description && (
                            <div className='fv-plugins-message-container'>
                              <span className='text-danger' role='alert'>
                                {errors.description}
                              </span>
                            </div>
                          )}
                        </td>
                      </tr>
                      {errors.totalCost && (
                        <tr>
                          <td colSpan={3}>
                            <div className='fv-plugins-message-container'>
                              <span className='text-danger' role='alert'>
                                {errors.totalCost}
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                    </table>
                  </ModalBody>
                  <ModalFooter>
                    {loading && (
                      <div className='d-flex align-items-center'>
                        <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                      </div>
                    )}
                    <table style={{width: '100%'}}>
                      <td>
                        <div className='fs-6'>
                          <table style={{width: '100%'}}>
                            <tr className='bg-light'>
                              <th className='fw-bolder'>Cost</th>
                              <th className='fw-bolder'>Item</th>
                              <th className='fw-bolder'>Description</th>
                              <th className='fw-bolder'></th>
                            </tr>
                            {items &&
                              items.length > 0 &&
                              items.map((item: any) => (
                                <tr>
                                  <td>
                                    <div className='fs-6 text-gray-600'>
                                      {toMoneyFormat(item.cost)}
                                    </div>
                                  </td>
                                  <td>
                                    <div className='fs-6 text-gray-600'>{item.item}</div>
                                  </td>
                                  <td>
                                    <div className='fs-6 text-gray-600'>{item.description}</div>
                                  </td>
                                  <td width='5%' style={{textAlign: 'center'}}>
                                    <span
                                      style={{cursor: 'pointer'}}
                                      onMouseOver={() => {
                                        setSelectedData(item)
                                      }}
                                      onClick={async () => {
                                        setSelectedData(item)
                                        await QuoteBreakdownClient.Delete(selectedData.id)

                                        setReloadQuoteModal(true)
                                        getQuoteBreakdowns(quoteId)
                                      }}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/arrows/arr015.svg'
                                        className='svg-icon-2 svg-icon-danger'
                                      />
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </table>
                        </div>
                      </td>
                    </table>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                      <button
                        type='button'
                        onClick={() => {
                          resetForm()
                          if (reloadQuoteModal) handleClose(quoteId)
                          else handleClose()
                        }}
                        className='btn btn-light'
                      >
                        Close
                      </button>
                    </div>
                  </ModalFooter>
                </Form>
              </Modal>
            </>
          </>
        )}
      </Formik>
    </>
  )
}

export default QuotesBreakdownModal
