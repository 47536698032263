import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import _ from 'lodash'
import { ProgressBar } from 'react-bootstrap-v5'
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import { KTSVG } from '../../../_metronic/helpers'
import customStyles from '../../common/components/selectors/customStyles'
import { CheckListClient } from '../../infrastracture/api/CheckListClient'
import { CheckListItemClient } from '../../infrastracture/api/CheckListItemClient'
import { CheckListItemFileClient } from '../../infrastracture/api/CheckListItemFileClient'
import { CheckListItemTypes } from '../check-list-templates/enums'
import './styles.css'

interface Props {
    items: any[]
    setParentIndex: (index: number) => void
    setFinished: (value: boolean) => void
    setAlreadyAnswered: (value: boolean) => void
    checkListId: string
    userId: string
}

const CheckListItemRenderer: React.FC<Props> = (props) => {
    const { items, setParentIndex, checkListId, setFinished, userId, setAlreadyAnswered } = props
    const params: any = useParams()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [submitting, setSubmitting] = useState(false)
    const [openComment, setOpenComment] = useState(false)
    const [openPhoto, setOpenPhoto] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<any[]>([])
    const [uploadedFileNames, setUploadedFileNames] = useState<any[]>([])
    const [values, setValues] = useState<any[]>([])
    const [checkList, setCheckList] = useState<any>(null)
    const [checkListItems, setCheckListItems] = useState<any[]>([])
    const [answeredItems, setAnsweredItems] = useState<any[]>([])
    const [unansweredItems, setUnansweredItems] = useState<any[]>([])
    const [currentFile, setCurrentFile] = useState<any>(null)
    const [progress, setProgress] = useState(0)
    const [fileNames, setFileNames] = useState<any[]>([])
    const [currentItem, setCurrentItem] = useState<any>('')
    const [loading, setLoading] = useState(true)

    const commentRef = useRef<any>(null)

    useEffect(() => {
        setParentIndex(currentIndex)
    }, [currentIndex])

    useEffect(() => {
        getCheckList()

        const answered = items.filter((x: any) => x.value)
        setAnsweredItems(items.filter((x: any) => x.value))

        if (answered.length === items.length && items.length > 0) setAlreadyAnswered(true)
    }, [items])

    useEffect(() => {
        if (answeredItems.length > 0) setCurrentIndex(answeredItems.length)
    }, [answeredItems])

    const getCheckList = async () => {
        try {
            setLoading(true)
            var response: any

            if (checkListId) {
                response = await CheckListClient.Get(checkListId)
                if (!response.authenticated) {
                } else {
                    const { data } = response
                    setCheckList(data)
                    setCheckListItems(data?.checkListItems)

                    if (data.checkListItems) {
                        setAnsweredItems(checkListItems.filter((x:any) => x.value))
                    }
                }
            }
            setLoading(false)
        } catch (e) {
            console.error(e)
        }
    }

    const setInputValue = (value: any, id: any, index: number) => {
        setValues([
            ..._.filter(values, (o:any) => o.id !== id),
            {
                id: id,
                value: value,
                index,
            },
        ])
    }

    const itemRenderer = (item: any, index: number) => {
        const { itemText, itemType, options, checkListTemplateId, id } = item

        switch (itemType) {
            case CheckListItemTypes.TickBox:
                return (
                    <>
                        <label className='form-label text-dark fs-2'>{item.itemText}</label>
                        <br />
                        <div className='pb-3'></div>
                        <input
                            className='form-check-input'
                            type='radio'
                            name={`${index}-tickBox`}
                            onChange={(e) => {
                                setInputValue('Yes', `${index}-tickBox`, index)
                            }}
                        />{' '}
                        <label className='form-check-label'>Yes</label>{' '}
                        <input
                            className='form-check-input'
                            type='radio'
                            name={`${index}-tickBox`}
                            onChange={(e) => {
                                setInputValue('No', `${index}-tickBox`, index)
                            }}
                        />{' '}
                        <label className='form-check-label'>No</label>
                    </>
                )
            case CheckListItemTypes.DropList:
                const mappedOpt = options.map((item: any) => {
                    return {
                        value: item.id,
                        label: item.value,
                    }
                })

                return (
                    <>
                        <label className='form-label text-dark fs-2'>{item.itemText}</label>
                        <Select
                            isMulti={item.control === 'Multiselect' ? true : false}
                            styles={customStyles}
                            options={mappedOpt}
                            placeholder='Select an option'
                            key={`${index}-dropList`}
                            name={`${index}-dropList`}
                            id={`${index}-dropList`}
                            onChange={(e: any) => {
                                if (item.control === 'Multiselect') {
                                    const values = e.map((item: any) => item.label)
                                    setInputValue(values, `${index}-dropList`, index)
                                } else {
                                    setInputValue(e.label, `${index}-dropList`, index)
                                }
                            }}
                        />
                    </>
                )
            case CheckListItemTypes.TextInput:
                return (
                    <>
                        <label className='form-label text-dark fs-2'>{item.itemText}</label>
                        <input
                            type='text'
                            className='form-control'
                            key={`${index}-text`}
                            name={`${index}-text`}
                            id={`${index}-text`}
                            onChange={(e) => {
                                setInputValue(e.target.value, `${index}-text`, index)
                            }}
                        />
                    </>
                )
            case CheckListItemTypes.Radio:
                return (
                    <>
                        <label className='form-label text-dark fs-2'>{item.itemText}</label>
                        <div
                            id={`${index}-radio`}
                            onChange={(e: any) => {
                                setInputValue(e.target.value, `${index}-radio`, index)
                            }}
                        >
                            {options.map((o: any) => (
                                <>
                                    <div style={{ paddingTop: '5px' }}>
                                        <input
                                            className='form-check-input'
                                            id={o.value}
                                            value={o.value}
                                            type='radio'
                                            name='dynamicRadioBtn'
                                        />{' '}
                                        <label className='form-check-label'>{o.value}</label>
                                    </div>
                                </>
                            ))}
                        </div>
                    </>
                )
            case CheckListItemTypes.Range:
                const rangeOptions: any[] = []

                const start = options[0].value
                const end = options[1].value

                for (let i = start; i <= end; i++) {
                    rangeOptions.push({
                        value: i,
                        label: i,
                    })
                }

                return (
                    <>
                        <label className='form-label text-dark fs-2'>{item.itemText}</label>
                        <Select
                            styles={customStyles}
                            options={rangeOptions}
                            placeholder='Select an option'
                            key={`${index}-dropList`}
                            name={`${index}-dropList`}
                            id={`${index}-dropList`}
                            onChange={(e: any) => {
                                setInputValue(e.label, `${index}-range`, index)
                            }}
                        />
                    </>
                )
            // return (
            //   <Form>
            //     <label className='form-label'>{item.itemText}</label>
            //     <Form.Range
            //       min={parseInt(options[0].value)}
            //       max={parseInt(options[1].value)}
            //       key={`${index}-range`}
            //       name={`${index}-range`}
            //       id={`${index}-range`}
            //       onChange={(e) => {
            //         setInputValue(e.target.value, `${index}-range`, index)
            //       }}
            //     />

            //     <label className='form-label'>
            //       {_.find(values, (o) => o.id === `${index}-range`)?.value}
            //     </label>
            //   </Form>
            // )
            case CheckListItemTypes.SectionHeader:
                return (
                    <>
                        <label className='form-label text-dark fs-2'>{item.itemText}</label>
                        <p>{item.heading}</p>
                    </>
                )
            default:
            // code block
        }
    }

    useEffect(() => {
        if (selectedFiles) selectedFiles.map((file: any) => upload(file))
    }, [selectedFiles])

    const handleDrop = (acceptedFiles: any) => {
        var additional = acceptedFiles.map((file: any) => file.name)
        setSelectedFiles(acceptedFiles)
        setFileNames([...fileNames, ...additional])
    }

    const upload = (file: any) => {
        let currentFile = selectedFiles![0]

        setProgress(0)
        setCurrentFile(currentFile)

        CheckListItemFileClient.Create(items[currentIndex].id, file.name, currentFile, (event: any) => {
            setProgress(Math.round((100 * event.loaded) / event.total))
        })
            .then((response) => {
                setUploadedFileNames([...uploadedFileNames, file.name])
                setCurrentFile(null)
                setSelectedFiles([])
            })
            .catch(() => {
                setProgress(0)
                setCurrentFile(null)
                setSelectedFiles([])
            })
    }

    const submitItem = async (item: any) => {
        setIsSubmitting(true)
        const { id, itemText, itemType, options } = item
        const comment = commentRef.current?.value

        var objValue: any

        switch (itemType) {
            case CheckListItemTypes.TickBox:
                objValue = _.find(values, (o) => o.id === `${currentIndex}-tickBox`)

                await CheckListItemClient.Answer(
                    id,
                    objValue?.value ? String(objValue.value) : null,
                    comment,
                    userId
                )
                setIsSubmitting(false)
                return
            case CheckListItemTypes.DropList:
                objValue = _.find(values, (o) => o.id === `${currentIndex}-dropList`)

                await CheckListItemClient.Answer(
                    id,
                    objValue?.value ? String(objValue.value) : null,
                    comment,
                    userId
                )
                setIsSubmitting(false)
                return
            case CheckListItemTypes.TextInput:
                objValue = _.find(values, (o) => o.id === `${currentIndex}-text`)

                await CheckListItemClient.Answer(
                    id,
                    objValue?.value ? String(objValue.value) : null,
                    comment,
                    userId
                )
                setIsSubmitting(false)
                return
            case CheckListItemTypes.Radio:
                objValue = _.find(values, (o) => o.id === `${currentIndex}-radio`)

                await CheckListItemClient.Answer(
                    id,
                    objValue?.value ? String(objValue.value) : null,
                    comment,
                    userId
                )
                setIsSubmitting(false)
                return
            case CheckListItemTypes.Range:
                objValue = _.find(values, (o) => o.id === `${currentIndex}-range`)

                await CheckListItemClient.Answer(
                    id,
                    objValue?.value ? String(objValue.value) : null,
                    comment,
                    userId
                )
                setIsSubmitting(false)
                return
            case CheckListItemTypes.SectionHeader:
                await CheckListItemClient.Answer(id, 'viewed', null, userId)
                setIsSubmitting(false)
                return
        }
    }

    if (loading)
        return (
            <div className='text-center d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
                Loading...
            </div>
        )
    else
        return (
            <div
                className='scroll-y me-n7 pe-7 roll-out'
                // id='kt_modal_add_customer_scroll'
                // data-kt-scroll='true'
                // style={{maxHeight: '450px'}}
                key={currentIndex}
            >
                <table className='scroll-y table table-row-dashed gy-5' id='kt_table_users_login_session'>
                    <tbody className='fs-6 fw-bold text-gray-600'>
                        {items &&
                            items.length > 0 &&
                            items.map((item, index) => {
                                return (
                                    index === currentIndex && (
                                        <>
                                            <tr>
                                                <td>
                                                    <div>
                                                        <table
                                                            className='w-100'
                                                            style={{
                                                                borderBottom: '2px solid gray',
                                                                marginBottom: '15px',
                                                            }}
                                                        >
                                                            <tr>
                                                                <td className='w-50 text-dark fs-4'>{item.checkList?.name}</td>
                                                                <td className='text-muted text-end w-50'>
                                                                    {currentIndex + 1} of {checkListItems.length}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                        {itemRenderer(item, index)}
                                                        <br />
                                                        {(item.allowComment || item.allowPhoto) && (
                                                            <>
                                                                <div
                                                                    className='text-dark fw-bolder fs-5'
                                                                    style={{
                                                                        borderTop: '2px solid gray',
                                                                        marginTop: '15px',
                                                                        paddingTop: '15px',
                                                                        paddingBottom: '10px',
                                                                    }}
                                                                >
                                                                    Optional Input
                                                                </div>
                                                                <table style={{ width: '100%' }}>
                                                                    <tr>
                                                                        {item.allowComment && (
                                                                            <td>
                                                                                <div>
                                                                                    <span
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => setOpenComment(!openComment)}
                                                                                    >
                                                                                        Comment
                                                                                        {openComment ? (
                                                                                            <KTSVG
                                                                                                path='/media/icons/duotune/arrows/arr073.svg'
                                                                                                className='svg-icon-3'
                                                                                            />
                                                                                        ) : (
                                                                                            <KTSVG
                                                                                                path='/media/icons/duotune/arrows/arr072.svg'
                                                                                                className='svg-icon-3'
                                                                                            />
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                        )}
                                                                        {item.allowPhoto && (
                                                                            <td>
                                                                                <div className='text-end'>
                                                                                    <span
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => setOpenPhoto(!openPhoto)}
                                                                                    >
                                                                                        Photo
                                                                                        {openPhoto ? (
                                                                                            <KTSVG
                                                                                                path='/media/icons/duotune/arrows/arr073.svg'
                                                                                                className='svg-icon-3'
                                                                                            />
                                                                                        ) : (
                                                                                            <KTSVG
                                                                                                path='/media/icons/duotune/arrows/arr072.svg'
                                                                                                className='svg-icon-3'
                                                                                            />
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                </table>
                                                            </>
                                                        )}

                                                        {item.allowComment && openComment && (
                                                            <div>
                                                                <label className='form-label'>Comment</label>
                                                                <textarea
                                                                    name='comment'
                                                                    placeholder=''
                                                                    className='form-control'
                                                                    id='comment'
                                                                    rows={2}
                                                                    ref={commentRef}
                                                                />
                                                            </div>
                                                        )}
                                                        <br />
                                                        {item.allowPhoto && openPhoto && (
                                                            <div style={{ padding: '15px 0px' }}>
                                                                <Dropzone multiple={false} onDrop={handleDrop}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div {...getRootProps({ className: 'dropzone' })}>
                                                                            <input {...getInputProps()} />
                                                                            <a className='btn btn-sm btn-primary w-100'>Attach files</a>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                                {fileNames.map((fileName) => (
                                                                    <li key={fileName}>
                                                                        {fileName}{' '}
                                                                        {currentFile?.name === fileName && (
                                                                            <ProgressBar
                                                                                animated
                                                                                now={progress}
                                                                                style={{ marginTop: '2px' }}
                                                                            />
                                                                        )}
                                                                        {uploadedFileNames.includes(fileName) && (
                                                                            <KTSVG
                                                                                path='/media/icons/duotune/general/gen043.svg'
                                                                                className='svg-icon-4 svg-icon-success '
                                                                            />
                                                                        )}
                                                                    </li>
                                                                ))}
                                                            </div>

                                                            // <div>
                                                            //   <label className='form-label'>File</label>
                                                            //   <input
                                                            //     type='file'
                                                            //     className='form-control form-control-solid'
                                                            //     id='file'
                                                            //     name='file'
                                                            //     onChange={(e) => {
                                                            //       if (!e.target.files) return
                                                            //     }}
                                                            //   />
                                                            // </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                )
                            })}
                    </tbody>
                </table>
                <div className='modal-footer'>
                    {/* <button
          type='button'
          onClick={() => {
            setCurrentIndex(0)
          }}
          className='btn btn-light'
          data-bs-dismiss='modal'
          id='check_list_wizard_dismiss'
        >
          Close
        </button> */}
                    {/* {currentIndex > 0 && (
              <button
                type='button'
                onClick={() => {
                  setCurrentIndex(currentIndex - 1)
                }}
                className='btn btn-light'

                // data-bs-dismiss='modal'
                // id='check_list_wizard_dismiss'
              >
                Back
              </button>
            )} */}
                    <button
                        type='button'
                        onClick={async (e: any) => {
                            await submitItem(items[currentIndex])

                            setOpenPhoto(false)
                            setOpenComment(false)
                            setSelectedFiles([])
                            setUploadedFileNames([])
                            setCurrentFile(null)
                            setProgress(0)
                            setFileNames([])

                            if (currentIndex + 1 !== items.length) {
                                setCurrentIndex(currentIndex + 1)
                            } else {
                                setFinished(true)
                                setCurrentIndex(0)
                            }
                        }}
                        className='btn btn-lg btn-primary'
                        disabled={
                            !_.find(values, (o) => o.index === currentIndex) &&
                            items[currentIndex].itemType !== CheckListItemTypes.SectionHeader
                        }
                    >
                        {!isSubmitting && (
                            <span className='indicator-label'>
                                {currentIndex + 1 !== items.length ? 'Next' : 'Finish'}
                            </span>
                        )}
                        {isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        )
}

export default CheckListItemRenderer
