export enum LibraryModes {
  Add = 'Add',
  Edit = 'Edit',
  View = 'View',
}

export enum LibraryStatus {
  Active = 1,
  Expired = 2,
  Archived = 3,
}

export const LibraryStatusLabel = new Map<number, string>([
  [LibraryStatus.Active, 'Active'],
  [LibraryStatus.Expired, 'Expired'],
  [LibraryStatus.Archived, 'Archived'],
])

export const LibraryStatusId = new Map<number, number>([
  [LibraryStatus.Active, LibraryStatus.Active],
  [LibraryStatus.Expired, LibraryStatus.Expired],
  [LibraryStatus.Archived, LibraryStatus.Archived],
])
