import { ApiClient } from '../../../setup/axios/SetupAxios';
import { ListQueryParams } from '../ApiClient';

export class PermissionQueryParams extends ListQueryParams { }

export class PermissionsClient {
    static async List(params: PermissionQueryParams) {
        return await ApiClient.Get('/permissions' + params.GenerateQueryString());
    }

    static async ListGrouped(params: PermissionQueryParams) {
        return await ApiClient.Get('/permissions/grouped' + params.GenerateQueryString());
    }

    static async ForCurrentUser() {
        return await ApiClient.Get('/permissions/for/current-user');
    }
}
