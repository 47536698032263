import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class ReviewQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithWorkOrderId(value: string) {
        this.WithParam('workOrderId', value)
        return this
    }
}

export class ReviewsClient {
    static async List(params: ReviewQueryParams) {
        return await ApiClient.Get('/reviews' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/reviews/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/reviews/' + id);
    }

    static async Create(workOrderId: string, comment: string, rating: number) {
        return await ApiClient.Post('/reviews/', {
            workOrderId: workOrderId,
            comment: comment,
            rating: rating,
        });
    }

    static async Update(id: string, workOrderId: string, comment: string, rating: number) {
        return await ApiClient.Patch('/reviews/' + id, [
            {
                op: 'replace',
                path: 'comment',
                value: comment,
            },
            {
                op: 'replace',
                path: 'workOrderId',
                value: workOrderId,
            },
            {
                op: 'replace',
                path: 'rating',
                value: rating,
            },
        ]);
    }
}
