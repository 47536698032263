import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {LibraryClient, LibraryQueryParams} from '../../../infrastracture/api/LibraryClient'
import {Col, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Moment from 'react-moment'
import * as Yup from 'yup'
import SweetAlert from 'react-bootstrap-sweetalert'
import DocumentTypeSelector from '../../../common/components/selectors/DocumentTypeSelector'
import LibraryStatusSelector from '../../../common/components/selectors/LibraryStatusSelector'

import moment from 'moment'
import {CheckListClient} from '../../../infrastracture/api/CheckListClient'
import {SitesClient} from '../../../infrastracture/api/SitesClient'

interface Props {
  handleOk: (item: any) => void
  handleClose: () => void
  show: boolean
  siteId: string
  workOrderId: string
}

const SiteCheckListModal: React.FC<Props> = (props) => {
  const {handleClose, handleOk, show, siteId, workOrderId} = props
  const [createSuccess, setCreateSuccess] = useState(false)
  const params: any = useParams()
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [submittingIndex, setSubmittingIndex] = useState<any>(null)
  const [itemsList, setItemsList] = useState<Array<any>>([])

  const initialState = {}

  const fetchData = async () => {
    setLoading(true)

    if (!siteId) return

    try {
      var query = new LibraryQueryParams()

      var response = await await SitesClient.CheckLists(siteId)

      if (!response.authenticated) {
      } else {
        const {data} = response
        setItemsList(data)
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Modal isOpen={show} onHide={() => {}} size='lg' zIndex={9999}>
        <ModalHeader closeButton>
          <h5 className='modal-title'>
            Check Lists
            {loading && (
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            )}
          </h5>
        </ModalHeader>
        <ModalBody>
          <div
            className='scroll-y me-n7 pe-7'
            id='kt_modal_add_customer_scroll'
            data-kt-scroll='true'
            style={{maxHeight: '450px'}}
          >
            <table className='scroll-y table gx-2 gy-3' id='kt_table_users_login_session'>
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th>Name</th>
                  <th>Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='fs-6 fw-bold text-gray-600'>
                {!loading &&
                  itemsList &&
                  itemsList.map((item: any, index: number) => (
                    <>
                      <tr
                        key={item.id}
                        style={(index + 1) % 2 == 0 ? {backgroundColor: '#f2f2f2'} : {}}
                      >
                        <td>
                          {item.checkListTemplate && (
                            <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                              {item.checkListTemplate?.name}
                            </a>
                          )}
                          {!item.checkListTemplate && <i>Not specified</i>}
                        </td>

                        <td>
                          {item.created && (
                            <Moment
                              utc
                              local
                              format='ddd DD MMM, YYYY h:mm a'
                              className='text-dark fw-bolder'
                            >
                              {item.created}
                            </Moment>
                          )}
                        </td>

                        <td rowSpan={2} className='text-end' style={{verticalAlign: 'middle'}}>
                          <button
                            className='btn btn-primary w-100'
                            disabled={isSubmitting}
                            onClick={async () => {
                              setSubmitting(true)

                              const response = await CheckListClient.Create(
                                workOrderId,
                                item.checkListTemplateId
                              )
                              const {data} = response
                              setSubmitting(false)
                              handleOk(data)
                            }}
                          >
                            {(!isSubmitting || submittingIndex !== index) && (
                              <span className='indicator-label'>Add</span>
                            )}
                            {isSubmitting && submittingIndex === index && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Adding...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </td>
                      </tr>
                      <tr style={(index + 1) % 2 == 0 ? {backgroundColor: '#f2f2f2'} : {}}>
                        <td colSpan={2}>{item.checkListTemplate.description || ''}</td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            onClick={() => {
              handleClose()
            }}
            className='btn btn-light'
            data-bs-dismiss='modal'
            id='rd_Library_modal_dismiss'
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
      {/* <div className='modal fade' tabIndex={-1} id='library_modal'>
              <Form>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      
                    </div>

                    <div className='modal-body'>
                      
                    </div>
                    <div className='modal-footer'>
                    </div>
                  </div>
                </div>
              </Form>
            </div> */}
    </>
  )
}

export default SiteCheckListModal
