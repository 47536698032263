import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {LibraryStatus} from '../enums'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Library SetFilter] Action',
  ResetFilter: '[Library ResetFilter] Action',
}

const initialLibraryState: ILibraryState = {
  filter: {
    status: LibraryStatus.Active,
    documentType: '',
  },
}

export interface ILibraryState {
  filter?: {
    status: any
    documentType: string
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Library', whitelist: ['filter']},
  (state: ILibraryState = initialLibraryState, action: ActionWithPayload<ILibraryState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialLibraryState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
