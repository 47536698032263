import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {Card, Col} from 'reactstrap'

import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'

import {
  CheckListTemplateItemClient,
  CheckListTemplateItemQueryParams,
} from '../../../infrastracture/api/CheckListTemplateItemClient'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {CheckListClient} from '../../../infrastracture/api/CheckListClient'
import {TableHeader} from '../../../common/components/TableHeader'
import {CheckListItemTypes} from '../../check-list-templates/enums'

interface Props {
  checkListId: any
  show: boolean
  handleClose: () => void
}

const CompletedCheckListModal: React.FC<Props> = (props) => {
  const {checkListId, show, handleClose} = props
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<any[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  })

  useEffect(() => {
    if (checkListId) fetchData()
  }, [checkListId])

  const [initialValues, setInitialValues] = useState<any>({})

  const fetchData = async () => {
    try {
      setLoading(true)
      var query = new CheckListTemplateItemQueryParams()

      var response: any

      response = await CheckListClient.Get(checkListId)
      if (!response.authenticated) {
      } else {
        const {data} = response

        setItems(
          data?.checkListItems?.filter((x: any) => x.itemType !== CheckListItemTypes.SectionHeader)
        )
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Modal isOpen={show} size='lg' zIndex={9999}>
        <ModalHeader closeButton tag='div' cssModule={{width: '100%'}}>
          <h5>Check List Details </h5>{' '}
        </ModalHeader>
        <ModalBody>
          <table className='table align-middle gs-0 gy-4'>
            <TableHeader
              sort={'id'}
              direction={'asc'}
              columns={[
                {name: 'Item', static: true, width: '15'},
                {name: 'Answer', static: true, width: '15'},
                {name: 'Comment', static: true, width: '15'},
                {name: 'Files', sort: '', static: true, width: '10'},
              ]}
            />
            {/* end::Table head */}
            {/* begin::Table body */}

            {!loading && (
              <tbody>
                {!loading &&
                  items.map((item) => (
                    <tr key={item.id}>
                      <td>
                        {item.itemText && (
                          <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                            {item.itemText}
                          </span>
                        )}
                        {!item.itemText && <i>Not specified</i>}
                      </td>

                      <td>
                        {item.value && (
                          <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                            {item.value}
                          </span>
                        )}
                        {!item.value && <i>Not specified</i>}
                      </td>

                      <td>
                        {item.comment && (
                          <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                            {item.comment}
                          </span>
                        )}
                        {!item.comment && <i>Not specified</i>}
                      </td>
                      <td></td>
                    </tr>
                  ))}
              </tbody>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Loading...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </table>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            onClick={() => {
              handleClose()
            }}
            className='btn btn-light'
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CompletedCheckListModal
