import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import moment from 'moment'

interface Props {
  notices: any[]
  minutes: any
  files: any[]
  defaultWorkSafeFiles: any[]
  additionalWorkSafeFiles: any[]
  expectedOut: any
  safetyConsideration: string
}

const Step5: React.FC<Props> = (props) => {
  const {
    notices,
    minutes,
    files,
    expectedOut,
    defaultWorkSafeFiles,
    additionalWorkSafeFiles,
    safetyConsideration,
  } = props
  const today = moment()

  return (
    <div className='w-100'>
      <div>
        {/* <h2 className='fw-bolder text-dark'>Confirm Check In</h2> */}
        <h3 className='fw-bolder text-dark'>Acknowledged Notices</h3>

        <ul style={{listStyle: 'none'}}>
          {notices.length === 0 && (
            <li className='text-dark '>No notices available for this site</li>
          )}
          {notices.length > 0 &&
            notices.map((item, index) => {
              return (
                <>
                  <li>
                    <span style={{paddingRight: '5px'}}>
                      {item.notice?.noticeType?.name.toUpperCase()}
                    </span>
                    - <span style={{paddingRight: '5px'}}>{item.notice?.name.toUpperCase()}</span>
                    <KTSVG
                      path='/media/icons/duotune/general/gen043.svg'
                      className='svg-icon-4 svg-icon-success '
                    />
                  </li>
                </>
              )
            })}
        </ul>

        <h3 className='fw-bolder text-dark'>Time On Site</h3>
        <ul style={{listStyle: 'none'}}>
          <li>
            <label> {minutes < 60 ? `${minutes} minutes` : `${minutes / 60} hour(s)`} </label>
          </li>
          <li>
            Expected out:
            {moment(moment().add(parseInt(minutes), 'minutes')).isSame(today, 'day')
              ? `Today at ${moment().add(parseInt(minutes), 'minutes').format('h:mm a')}`
              : `Tomorrow at ${moment().add(parseInt(minutes), 'minutes').format('h:mm a')}`}
          </li>
        </ul>

        <h3 className='fw-bolder text-dark'>SWM / SSSP or TA</h3>
        <ul>
          {files.length > 0 &&
            files.map((item, index) => {
              return (
                <>
                  <li>
                    <span style={{paddingRight: '5px'}}>{item}</span>
                  </li>
                </>
              )
            })}
          {defaultWorkSafeFiles.length > 0 &&
            defaultWorkSafeFiles.map((item, index) => {
              return (
                <>
                  <li>
                    <span style={{paddingRight: '5px'}}>
                      {item.name} ({item.library?.documentType?.name})
                    </span>
                  </li>
                </>
              )
            })}
          {additionalWorkSafeFiles.length > 0 &&
            additionalWorkSafeFiles.map((item, index) => {
              return (
                <>
                  <li>
                    <span style={{paddingRight: '5px'}}>
                      {item.name} ({item.documentType?.name})
                    </span>
                  </li>
                </>
              )
            })}
        </ul>
        {safetyConsideration && (
          <>
            <h3 className='fw-bolder text-dark'>Safety Considerations</h3>{' '}
            <ul style={{listStyle: 'none'}}>
              <li>{safetyConsideration}</li>
            </ul>
          </>
        )}
      </div>
    </div>
  )
}

export {Step5}
