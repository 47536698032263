import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {CheckInsQueryParams, CheckInsClient} from '../../../infrastracture/api/CheckInsClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import CheckInsModal from './CheckInsModal'
import Moment from 'react-moment'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import SweetAlert from 'react-bootstrap-sweetalert'
import moment from 'moment'
import {
  ParticipantQueryParams,
  ParticipantsClient,
} from '../../../infrastracture/api/ParticipantsClient'
import {SweetAlertRenderProps} from 'react-bootstrap-sweetalert/dist/types'
import LeaveSiteReasonSelector from '../../../common/components/selectors/LeaveSiteReasonSelector'
import {UserCheckInFilters} from '../../../common/components/filter-options/UserCheckInFilters'
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap-v5'
import ExtendCheckinModal from './ExtendCheckinModal'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'
import {Permissions} from '../../../common/constants/permissions'
import EditCheckinModal from './EditCheckinModal'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {minutesToHourFormat} from '../../../common/helpers/stringHelper'
const CheckInsBreadCrumbs: Array<PageLink> = []

interface Props {
  workOrderId: string
  siteId: string
  workOrder: any
  userId?: any
  fromUserDetail?: boolean
  refreshWorkOrder?: () => void
  fromUsersList?: boolean
  getAll?: boolean
}

const CheckInsList: React.FC<Props> = (props) => {
  const {
    workOrderId,
    siteId,
    workOrder,
    refreshWorkOrder,
    userId,
    fromUserDetail,
    fromUsersList,
    getAll,
  } = props
  const [checkInsList, setCheckInsList] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [showCheckOutModal, setShowCheckOutModal] = useState(false)
  const [selectedData, setSelectedData] = useState<any>({})
  const [mode, setMode] = useState('')
  const [viewOnly, setViewOnly] = useState(false)
  const [checkIn, setCheckIn] = useState('')
  const [modalLoading, setModalLoading] = useState(false)
  const [userActiveCheckIns, setUserActiveCheckIns] = useState<Array<any>>([])
  const [participants, setParticipants] = useState<Array<any>>([])
  const [loading, setLoading] = useState(false)
  const [showIncompleteCheckOutModal, setShowIncompleteCheckOutModal] = useState(false)
  const [showSuccessCheckOut, setShowSuccessCheckOut] = useState(false)
  const [leaveSiteReason, setLeaveSiteReason] = useState<any>({})
  const [isExportClicked, setIsExportClicked] = useState(false)
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [resetFilter, setResetFilter] = useState(false)
  const [showExtendModal, setShowExtendModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [totalTime, setTotalTime] = useState(0)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'timeStamp',
    direction: 'desc',
  })

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const permissions: any = useSelector<RootState>(
    ({auth}) => auth.userPermission,
    shallowEqual
  ) as any

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  useEffect(() => {
    fetchData()
  }, [workOrderId])

  useEffect(() => {
    if (resetFilter) fetchData()
  }, [endDate, startDate, resetFilter])

  async function getuserActiveCheckIns() {
    try {
      var query = new CheckInsQueryParams()

      var response = await CheckInsClient.List(
        query
          .Paginate(pagination.skip, pagination.take)
          .Search(search)
          .WithWorkOrderId(workOrderId)
          .WithUserId(user.id)
          .WithActiveOnly(true)
          .Sort(pagination.sort, pagination.direction)
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setUserActiveCheckIns(data?.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  async function getParticipants() {
    var query = new ParticipantQueryParams()

    var response = await ParticipantsClient.List(
      query
        .Paginate(pagination.skip, pagination.take)
        .WithActive(true)
        .WithUserId(user.id)
        .WithWorkOrderId(workOrderId)
        .Sort('id', pagination.direction)
    )

    if (!response.authenticated) {
    } else {
      const {data} = response
      setParticipants(data?.data)
    }
  }

  async function fetchData() {
    setLoading(true)
    try {
      getuserActiveCheckIns()
      getParticipants()
      var query = new CheckInsQueryParams()

      var response: any

      if (!getAll) {
        response = await CheckInsClient.List(
          query
            .Paginate(pagination.skip, pagination.take)
            .Search(search)
            .WithWorkOrderId(workOrderId)
            .WithUserId(userId)
            .WithStart(new Date(startDate).toISOString())
            .WithEnd(new Date(endDate).toISOString())
            .Sort(pagination.sort, pagination.direction)
        )
      } else {
        response = await CheckInsClient.List(
          query
            .Paginate(pagination.skip, pagination.take)
            .Search(search)
            .WithWorkOrderId(workOrderId)
            .WithUserId(userId)
            .Sort(pagination.sort, pagination.direction)
        )
      }

      if (!response.authenticated) {
      } else {
        const {data} = response
        setCheckInsList(data?.data)

        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })
      }

      if (workOrderId) {
        var totalTime = await CheckInsClient.GetWorkOrderCheckInTotalTime(workOrderId)

        setTotalTime(totalTime.data)
      }

      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  // const renderModals = () => {
  //   return (
  //     <CheckInsModal
  //       workOrderId={workOrderId}
  //       siteId={siteId}
  //       viewOnly={viewOnly}
  //       workOrder={workOrder}
  //       checkIn={checkIn}
  //       handleOk={() => fetchData()}
  //       loading={modalLoading}
  //     />
  //   )
  // }

  const checkOut = async (woComplete: boolean) => {
    setShowCheckOutModal(false)
    setShowIncompleteCheckOutModal(false)
    setShowSuccessCheckOut(true)
    await CheckInsClient.CheckOut(selectedData.id, woComplete, leaveSiteReason?.value)
    if (refreshWorkOrder) refreshWorkOrder()
    fetchData()
  }

  const renderModals = () => (
    <>
      <SweetAlertWithProp
        success
        showCancel={false}
        title='Success!'
        onConfirm={() => {
          setShowSuccessCheckOut(false)
        }}
        focusCancelBtn
        cancelBtnBsStyle='light'
        show={showSuccessCheckOut}
        confirmBtnText='Yes'
        cancelBtnText='No'
      >
        Check out success!
      </SweetAlertWithProp>

      <SweetAlertWithProp
        info
        showCancel
        title='Is the Work Order Complete?'
        onConfirm={() => {
          checkOut(true)
        }}
        onCancel={() => {
          setShowCheckOutModal(false)
          setShowIncompleteCheckOutModal(true)
        }}
        focusCancelBtn
        cancelBtnBsStyle='light'
        show={showCheckOutModal}
        confirmBtnText='Yes'
        cancelBtnText='No'
      ></SweetAlertWithProp>

      <SweetAlertWithProp
        title={'Leave Site Reason'}
        onConfirm={() => {
          checkOut(false)
        }}
        onCancel={() => {
          setLeaveSiteReason({})
          setShowIncompleteCheckOutModal(false)
        }}
        type={'controlled'}
        dependencies={[leaveSiteReason]}
        show={showIncompleteCheckOutModal}
        required
        showConfirm={leaveSiteReason?.value ? true : false}
        confirmBtnText='Check Out'
        showCancel
        cancelBtnBsStyle='light'
      >
        {(renderProps: SweetAlertRenderProps) => (
          <form>
            <LeaveSiteReasonSelector
              placeholder='Select Leave Site Reason'
              defaultValue={leaveSiteReason}
              onChange={(name: any, value: any, label: any) => {
                setLeaveSiteReason({value, label})
              }}
              id='leaveSiteReasonId'
            />
            <br />
          </form>
        )}
      </SweetAlertWithProp>

      <ExtendCheckinModal
        handleOk={() => {
          setShowExtendModal(false)
          fetchData()
        }}
        handleClose={() => {
          setShowExtendModal(false)
        }}
        show={showExtendModal}
        checkIn={selectedData}
      />

      <EditCheckinModal
        handleOk={() => {
          setSelectedData({})
          setShowEditModal(false)
          fetchData()
        }}
        handleClose={() => {
          setShowEditModal(false)
        }}
        show={showEditModal}
        checkIn={selectedData}
      />
    </>
  )

  const ExportCheckIns = async () => {
    setIsExportClicked(true)
    await CheckInsClient.ExportCheckins(userId)
    setIsExportClicked(false)
  }

  async function refresh() {
    setResetFilter(true)
  }

  async function reset() {
    setStartDate(moment().format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
    setResetFilter(true)
  }

  const handleStartDateChange = (e: any, value: any) => {
    var value: any = e.target.value
    setStartDate(value)
  }

  const handleEndDateChange = (e: any, value: any) => {
    var value: any = e.target.value
    setEndDate(value)
  }

  return (
    <>
      {renderModals()}
      {/* {renderModals()} */}
      <div className={`card d-flex flex-row-fluid flex-start`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
              </span>
              <input
                type='text'
                data-kt-notice-type-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search CheckIn'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            {workOrderId && (
              <span className='badge badge-square badge-primary me-3' style={{padding: '0px 5px'}}>
                {minutesToHourFormat(totalTime)}
              </span>
            )}
            <span className='badge badge-square badge-primary me-3'>{pagination.totalRecords}</span>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              {/* {userActiveCheckIns.length === 0 && participants.length > 0 && (
                <button
                  disabled={!(userActiveCheckIns.length === 0) || !(participants.length > 0)}
                  type='button'
                  className='btn btn-sm btn-light-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#check_in_modal'
                  onClick={async () => {
                    setModalLoading(true)
                    var currentDateTime = new Date(moment().format('YYYY-MM-DD')).toISOString()
                    var response = await CheckInsClient.Create(
                      workOrderId,
                      currentDateTime,
                      0,
                      null,
                      null,
                      null,
                    )

                    if (!response.successful) {
                      //show error
                    } else {
                      setCheckIn(response.data)
                    }
                    setModalLoading(false)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/general/gen013.svg' className='svg-icon-3' />
                  Check In
                </button>
              )} */}
              {fromUserDetail && (
                <a
                  id='rd_create_tenant_submit'
                  className='btn btn-sm btn-light-primary me-3'
                  onClick={ExportCheckIns}
                >
                  {!isExportClicked && <span className='indicator-label'>Export</span>}
                  {isExportClicked && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </a>
              )}
              {!getAll && (
                <>
                  <a
                    type='button'
                    className='btn btn-sm btn-light-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <span className='svg-icon svg-icon-2'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <path
                          d='M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z'
                          fill='black'
                        ></path>
                      </svg>
                    </span>
                  </a>
                  <UserCheckInFilters
                    startDate={startDate}
                    endDate={endDate}
                    handleStartDateChange={handleStartDateChange}
                    handleEndDateChange={handleEndDateChange}
                    refresh={refresh}
                    reset={reset}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              {!fromUsersList && (
                <TableHeader
                  sort={pagination.sort}
                  direction={pagination.direction}
                  columns={[
                    {name: 'Full Name', sort: 'fileName'},
                    {name: 'Checked In', sort: 'name'},
                    {name: 'Due Out', sort: 'createdBy.name'},
                    {name: 'Checked Out', sort: 'checkOut'},
                    {name: 'Duration', static: true},
                    {name: 'Leave Reason', sort: 'leaveSite'},
                    {name: '', sort: '', static: true, width: 15},
                  ]}
                  onUpdate={(sort: any, direction: any) => {
                    setPagination({
                      ...pagination,
                      sort,
                      direction,
                      reset: true,
                    })
                  }}
                />
              )}

              {fromUsersList && (
                <TableHeader
                  sort={pagination.sort}
                  direction={pagination.direction}
                  columns={[
                    {name: 'Site', sort: 'workorder.site.name'},
                    {name: 'Checked In', sort: 'name'},
                    {name: 'Checked Out', sort: 'checkOut'},
                    {name: 'Duration', static: true},
                    {name: 'Leave Reason', sort: 'leaveSite'},
                    {name: '', sort: '', static: true, width: 30},
                  ]}
                  onUpdate={(sort: any, direction: any) => {
                    setPagination({
                      ...pagination,
                      sort,
                      direction,
                      reset: true,
                    })
                  }}
                />
              )}

              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    checkInsList.map((item) => (
                      <tr key={item.id}>
                        {fromUsersList && (
                          <td>
                            {item.workOrder?.site && (
                              <a className='text-gray-600 fw-bolder d-block mb-1 fs-6 ml-2'>
                                {item.workOrder?.site?.name}
                              </a>
                            )}
                          </td>
                        )}
                        {!fromUsersList && (
                          <td>
                            {item.user && (
                              <Link
                                className='text-gray-600 fw-bolder d-block mb-1 fs-6 ml-2'
                                to={`/check-ins/details/` + item?.id}
                              >
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id='tooltip-disabled'>
                                      <Popover id='checkinlistpopover'>
                                        <Popover.Title as='h3'>
                                          <div className='text-dark'>
                                            {item.comment && item.comment}{' '}
                                            {!item.comment && <i>No Safety Considerations</i>}
                                          </div>
                                        </Popover.Title>
                                        <Popover.Content></Popover.Content>
                                      </Popover>
                                    </Tooltip>
                                  }
                                  placement='top'
                                  trigger='hover'
                                >
                                  <span className='text-dark fw-bolder d-block mb-1 fs-6'>
                                    {item.user?.name} {item.user?.surname}
                                  </span>
                                </OverlayTrigger>
                              </Link>
                            )}
                          </td>
                        )}

                        <td>
                          <a className='text-gray-600 fw-bolder  d-block mb-1 fs-6'>
                            {item.checkedIn && (
                              <Moment utc local format='DD MM, YY h:mm a'>
                                {item.checkedIn}
                              </Moment>
                            )}
                          </a>
                        </td>
                        {!fromUsersList && (
                          <td>
                            <a className='text-gray-600 fw-bolder  d-block mb-1 fs-6'>
                              {item.expectedCheckOut && (
                                <Moment utc local format='DD MM, YY h:mm a'>
                                  {item.expectedCheckOut}
                                </Moment>
                              )}
                            </a>
                          </td>
                        )}

                        <td>
                          <a className='text-gray-600 fw-bolder  d-block mb-1 fs-6'>
                            {item.checkOut && (
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id='tooltip-disabled'>
                                    <Popover id='checkinlistpopover'>
                                      <Popover.Title as='h3'>
                                        <div className='text-dark' style={{minWidth: '150px'}}>
                                          Checked Out By:
                                        </div>
                                      </Popover.Title>
                                      <Popover.Content>
                                        {item.checkedOutBy?.name} {item.checkedOutBy?.surname}
                                      </Popover.Content>
                                    </Popover>
                                  </Tooltip>
                                }
                                placement='top'
                                trigger='hover'
                              >
                                <Moment utc local format='DD MM, YY h:mm a'>
                                  {item.checkOut}
                                </Moment>
                              </OverlayTrigger>
                            )}
                          </a>
                        </td>

                        <td>
                          <a className='text-gray-600 fw-bolder  d-block mb-1 fs-6'>
                            {item.timeOnSite && minutesToHourFormat(item.timeOnSite)}
                          </a>
                        </td>

                        <td>
                          <a className='text-gray-600 fw-bolder  d-block mb-1 fs-6'>
                            {item.leaveSiteReason?.name}
                          </a>
                        </td>

                        <td className='text-end'>
                          {!fromUserDetail && !item.checkOut && (
                            <>
                              <button
                                type='button'
                                className='btn btn-bg-light btn-color-primary btn-active-color-primary btn-sm'
                                onMouseOver={() => {
                                  setSelectedData(item)
                                }}
                                onClick={() => {
                                  setSelectedData(item)
                                  setShowCheckOutModal(true)
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr042.svg'
                                  className='svg-icon-3'
                                />
                              </button>

                              <button
                                type='button'
                                className='btn btn-bg-light btn-color-primary btn-active-color-primary btn-sm'
                                onMouseOver={() => {
                                  setSelectedData(item)
                                }}
                                onClick={() => {
                                  setSelectedData(item)
                                  setShowExtendModal(true)
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen041.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </>
                          )}

                          {item.checkOut &&
                            permissions?.includes(Permissions.MANAGE_CHECKINS) &&
                            user.tenant.tenantTypeId.toUpperCase() === TenantTypeIds.Owner && (
                              <button
                                type='button'
                                className='btn btn-bg-light btn-color-primary btn-active-color-primary btn-sm'
                                onMouseOver={() => {
                                  setSelectedData(item)
                                }}
                                onClick={() => {
                                  setSelectedData(item)
                                  setShowEditModal(true)
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen055.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-notice-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckInsList
