import { ApiClient, PublicApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class CheckListQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithWorkOrderId(value: string) {
        this.WithParam('workOrderId', value)
        return this
    }
}

export class CheckListClient {
    static async List(params: CheckListQueryParams) {
        return await ApiClient.Get('/checklist' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await PublicApiClient.Get('/checklist/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/checklist/' + id);

    }

    static async Create(workOrderId: string, checkListTemplateId: boolean) {
        return await ApiClient.Post('/checklist/', {
            workOrderId,
            checkListTemplateId,
        });
    }

    static async Update(
        id: string,
        name: string,
        description: string,
        active: boolean,
        singleOnly: boolean
    ) {
        return await ApiClient.Patch('/checklist-/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
            {
                op: 'replace',
                path: 'singleOnly',
                value: singleOnly,
            },
        ]);
    }

    static async ListCheckListSite(id: string) {
        return await ApiClient.Get(`/checklist-/${id}/sites`);
    }

    static async UpdateSites(id: string, assignedSites: any) {
        return await ApiClient.Post(`/checklist-/${id}/sites`, assignedSites);
    }
}
