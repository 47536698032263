import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class NoticeSitesQueryParams extends ListQueryParams {
    WithSiteId(value: string) {
        this.WithParam('siteId', value)
        return this
    }
    WithNoticeId(value: string) {
        this.WithParam('noticeId', value)
        return this
    }
}

export class NoticeSitesClient {
    static async List(params: NoticeSitesQueryParams) {
        return await ApiClient.Get('/notice-sites' + params.GenerateQueryString());
    }
}
