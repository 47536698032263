import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import {
    AttachmentsClient
} from '../../../infrastracture/api/AttachmentsClient'

import { Field, Form, Formik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { SweetAlertWithProp } from '../../../../SweetAlertWithProp'
import DocumentTypeSelector from '../../../common/components/selectors/DocumentTypeSelector'
import { AttachmentModes } from '../attachmentModes'
interface Props {
    handleOk: () => void
    selectedData: any
    mode: AttachmentModes
    workOrderId: any
    proposalId: any
    assetId: any
    contractId: any
    hazardId: any
    incidentId: any
    incidentActionId: any
    viewOnly?: boolean
    imagesOnly?: boolean
}

const AttachmentsModal: React.FC<Props> = (props) => {
    const {
        handleOk,
        selectedData,
        mode,
        viewOnly,
        workOrderId,
        proposalId,
        assetId,
        contractId,
        hazardId,
        incidentId,
        incidentActionId,
        imagesOnly,
    } = props
    const [createSuccess, setCreateSuccess] = useState(false)
    const params: any = useParams()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
    })

    const initialState = {
        name: '',
        file: '',
        documentType: '',
        expiryDate: null,
        visibility: null,
        private: false,
    }

    const [initialValues, setInitialValues] = useState<any>(initialState)

    useEffect(() => {
        if (mode === AttachmentModes.Add) {
            setInitialValues(initialState)
        } else {
            const { documentTypeId, documentType, expiryDate, visibility } = selectedData
            setInitialValues({
                ...initialValues,
                name: selectedData.name,
                documentType: documentTypeId ? { value: documentTypeId, label: documentType?.name } : {},
                expiryDate,
                visibility,
            })
        }
    }, [selectedData, mode])

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setStatus, setSubmitting, setFieldError }) => {
                    var response: any

                    if (!values.file && mode === AttachmentModes.Add) {
                        setFieldError('file', 'File is required')
                        return
                    }

                    if (mode === AttachmentModes.Edit) {
                        response = await AttachmentsClient.Update(
                            selectedData.id,
                            values.name,
                            values.private,
                            values?.documentType?.value || null,
                            values.expiryDate,
                            values.visibility
                        )
                    } else {
                        response = await AttachmentsClient.Create(
                            workOrderId,
                            assetId,
                            contractId,
                            hazardId,
                            incidentId,
                            incidentActionId,
                            proposalId,
                            values.name,
                            values.file,
                            null,
                            null,
                            values.private,
                            values?.documentType?.value,
                            values.expiryDate,
                            values.visibility
                        )
                    }

                    if (!response) {
                        //show error
                    } else {
                        setSubmitting(false)
                        document.getElementById('rd_attachment_modal_dismiss')!.click()

                        if (mode === AttachmentModes.Add) {
                            let fileInput = document.getElementById('file') as HTMLInputElement
                            if (fileInput.value) fileInput.value = ''
                        }

                        handleOk()
                    }
                }}
                enableReinitialize
            >
                {({ errors, touched, isSubmitting, values, setFieldValue, resetForm }: any) => (
                    <>
                        <SweetAlertWithProp
                            success
                            title='Success'
                            onConfirm={() => {
                                setCreateSuccess(false)
                            }}
                            confirmBtnText='Confirm'
                            cancelBtnBsStyle='light'
                            show={createSuccess}
                            showCancel={false}
                            showCloseButton
                        >
                            {mode} File success
                        </SweetAlertWithProp>
                        <div className='modal fade' tabIndex={-1} id='attachment_modal'>
                            <Form>
                                <div className='modal-dialog modal-dialog-centered'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <h5 className='modal-title'>{mode} File</h5>

                                            <div
                                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                                data-bs-dismiss='modal'
                                                aria-label='Close'
                                            >
                                                <span className='svg-icon svg-icon-2x'></span>
                                            </div>
                                        </div>

                                        <div className='modal-body'>
                                            <div
                                                className='scroll-y me-n7 pe-7'
                                                id='kt_modal_add_customer_scroll'
                                                data-kt-scroll='true'
                                                style={{ maxHeight: '550px' }}
                                            >
                                                <table
                                                    className='scroll-y table table-row-dashed gy-5'
                                                    id='kt_table_users_login_session'
                                                >
                                                    <tbody className='fs-6 fw-bold text-gray-600'>
                                                        <tr>
                                                            <td>
                                                                <label className='required form-label'>Name</label>
                                                                <Field
                                                                    name='name'
                                                                    placeholder=''
                                                                    type='text'
                                                                    className='form-control form-control-solid'
                                                                    id='name'
                                                                    disabled={viewOnly}
                                                                />
                                                                {touched.name && errors.name && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <span className='text-danger' role='alert'>
                                                                            {errors.name}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        {mode === AttachmentModes.Add && (
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <label className='required form-label'>File</label>
                                                                        <input
                                                                            type='file'
                                                                            className='form-control form-control-solid'
                                                                            id='file'
                                                                            name='file'
                                                                            onChange={(e) => {
                                                                                if (!e.target.files) return
                                                                                setFieldValue('file', e.target.files[0])
                                                                            }}
                                                                            accept={imagesOnly ? 'image/*' : ''}
                                                                        />
                                                                        {touched.file && errors.file && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <span className='text-danger' role='alert'>
                                                                                    {errors.file}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}
                                                        {!hazardId && !incidentId && !incidentActionId && (
                                                            <tr>
                                                                <td>
                                                                    <label className='form-label'>Document Type</label>
                                                                    <DocumentTypeSelector
                                                                        disabled={viewOnly}
                                                                        placeholder='Select Document Type'
                                                                        defaultValue={values.documentType}
                                                                        onChange={(name: any, value: any) => {
                                                                            setFieldValue(name, value)
                                                                        }}
                                                                    />
                                                                    {touched.documentType && errors.documentType && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <span className='text-danger' role='alert'>
                                                                                {errors.documentType}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {(assetId || contractId) && (
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <label className=' form-label'>Expiry Date </label>
                                                                        <Field
                                                                            name='expiryDate'
                                                                            type='date'
                                                                            placeholder='expiryDate'
                                                                            value={moment(values.expiryDate).format('YYYY-MM-DD')}
                                                                            className={
                                                                                'form-control form-control-solid' +
                                                                                (errors.expiryDate && touched.expiryDate
                                                                                    ? ' is-invalid'
                                                                                    : '')
                                                                            }
                                                                        />
                                                                        {touched.expiryDate && errors.expiryDate && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <span className='text-danger' role='alert'>
                                                                                    {errors.expiryDate}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div className='form-check form-switch form-check-custom form-check-solid'>
                                                                            <label className='form-check-label pe-2'>Visibility</label>
                                                                            <Field
                                                                                className='form-check-input pe-2'
                                                                                type='checkbox'
                                                                                value=''
                                                                                id='visibility'
                                                                                name='visibility'
                                                                                checked={values.visibility}
                                                                                disabled={viewOnly}
                                                                                onChange={(e: any) => {
                                                                                    var checked = e.target.checked
                                                                                    setFieldValue('visibility', checked)
                                                                                }}
                                                                            />
                                                                            <span className='ps-2'>
                                                                                {values.visibility ? 'Yes' : 'No'}
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}
                                                        {assetId && (
                                                            <tr>
                                                                <td>
                                                                    <div className='form-check form-switch form-check-custom form-check-solid'>
                                                                        <label className='form-check-label pe-2'>Private</label>
                                                                        <Field
                                                                            className='form-check-input pe-2'
                                                                            type='checkbox'
                                                                            value=''
                                                                            id='private'
                                                                            name='private'
                                                                            checked={values.private}
                                                                            disabled={viewOnly}
                                                                            onChange={(e: any) => {
                                                                                var checked = e.target.checked
                                                                                setFieldValue('private', checked)
                                                                            }}
                                                                        />
                                                                        <span className='ps-2'>{values.private ? 'Yes' : 'No'}</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {mode === AttachmentModes.View && (
                                                            <tr>
                                                                <td>
                                                                    <label className='required form-label'>File</label>
                                                                    <div className='input-group input-group-solid'>
                                                                        {/* <span className='input-group-text'>$</span> */}
                                                                        <input
                                                                            readOnly
                                                                            type='text'
                                                                            className='form-control form-control-solid'
                                                                            value={selectedData.fileName}
                                                                        />
                                                                        <span
                                                                            className='input-group-text'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                const link = document.createElement('a')
                                                                                link.href = selectedData.uri
                                                                                link.download = selectedData.name
                                                                                link.click()
                                                                            }}
                                                                        >
                                                                            <KTSVG
                                                                                path='/media/icons/duotune/arrows/arr004.svg'
                                                                                className='svg-icon-3 svg-icon-dark'
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    resetForm()
                                                }}
                                                className='btn btn-light'
                                                data-bs-dismiss='modal'
                                                id='rd_attachment_modal_dismiss'
                                            >
                                                Close
                                            </button>
                                            {!viewOnly && (
                                                <button
                                                    type='submit'
                                                    id='rd_create_tenant_submit'
                                                    className='btn btn-lg btn-primary'
                                                    disabled={isSubmitting}
                                                >
                                                    {!isSubmitting && <span className='indicator-label'>Save</span>}
                                                    {isSubmitting && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default AttachmentsModal
