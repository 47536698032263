import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { TenantTypeIds } from '../../../common/constants/tenantTypeIds'
import { WorkOrderClient, WorkOrderQueryParams } from '../../../infrastracture/api/WorkOrderClient'
import _ from 'lodash'
import { QuoteStatus } from '../constants/quoteStatus'
import {
    ClientPrioritySettingsClient,
    ClientPrioritySettingsQueryParams,
} from '../../../infrastracture/api/ClientPrioritySettingsClient'
import { ChargesClient, ChargesQueryParams } from '../../../infrastracture/api/ChargesClient'

export const getCharges = async (
    quotesDetails: any,
    wo: any,
    tenant: any,
    user: any // should be coming from redux
) => {

    var costConfirmed = 0
    var isManufacturing = wo.statusFlow.myobStatusFlowType === 'salesOrder' ? true : false;
    var percentageFee = isManufacturing ? (wo.manufacturingPercentageFee != null ? wo.manufacturingPercentageFee / 100 : 0) : (tenant.percentageFee != null ? tenant.percentageFee / 100 : 0);

    var estimate = parseFloat(quotesDetails.estimate)

    var percentageFeeAmount = percentageFee > 0 ? estimate * percentageFee : 0

    var priorityQuery = await ClientPrioritySettingsClient.List(
        new ClientPrioritySettingsQueryParams()
            .Paginate(0, 10)
            .WithClientId(tenant.id)
            .WithPriorityId(wo.priorityId)
            .Sort('id', 'asc')
    )

    var priority = priorityQuery?.data?.data[0] ?? null

    var priorityFee = priority && !wo.workOrderId ? priority.fee : 0 //dont apply priority fee when child

    var baseCost = (estimate ?? 0) + percentageFeeAmount + priorityFee

    var adminFee = tenant.adminFee

    if (wo.isParent || wo.workOrderId) {
        adminFee = 0
    }

    var charges = []

    var totalProjectCost = estimate

    if (
        baseCost != null &&
        baseCost !== 0 &&
        user?.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor &&
        wo.ocManaged === false
    ) {

        costConfirmed = baseCost

        if (baseCost < tenant.thresholdMoney || tenant.applyAdminFeeWhenOverThreshold === true) {
            costConfirmed = baseCost + adminFee;
        }

        //add percentage fee if OC managed = false
        if (percentageFee !== 0 && percentageFee != null) {
            charges.push({ value: percentageFeeAmount, chargeType: { name: isManufacturing ? 'Manufacturing Fee' : 'Percentage Fee' } })
        }

        if (wo.isParent || wo.workOrderId) {
            var parentWorkOrderId = wo.isParent ? wo.id : wo.workOrderId
            var parentWo = await WorkOrderClient.Get(parentWorkOrderId)
            var childrenWo = await WorkOrderClient.List(
                new WorkOrderQueryParams()
                    .Paginate(0, 99999)
                    .WithParentWorkOrderId(parentWorkOrderId)
                    .Sort('id', 'asc')
            )

            var projectWos = [parentWo.data, ...childrenWo.data.data]

            var quotes: any = []

            quotes.push(projectWos.map((x) => x.quotes))

            quotes = _.flattenDeep(quotes)

            // if (quotesDetails.quoteStatus?.label !== QuoteStatus.Approved)
            //   quotes = quotes.filter((y: any) => y.quoteStatus.name === QuoteStatus.Submitted)

            var sumOfQuotes = _.sumBy(quotes, function (o: any) {
                return parseFloat(o.estimate)
            })

            if (tenant.thresholdMoney > sumOfQuotes || tenant.applyAdminFeeWhenOverThreshold === true) {
                totalProjectCost = sumOfQuotes

                if (wo.isParent) adminFee = tenant.adminFee
            }
        }

        if ((adminFee ?? 0) > 0 && !wo.workOrderId) {

            if (baseCost < tenant.thresholdMoney || tenant.applyAdminFeeWhenOverThreshold === true) {

                charges.push({ value: adminFee, chargeType: { name: 'Admin Fee' } })
            }
        }
    }

    if (!wo.workOrderId && priorityFee && priorityFee > 0)
        charges.push({ value: priorityFee, chargeType: { name: 'Priority Fee' } })

    if (!wo.workOrderId && !wo.isParent) {
        var shippingCharges = await ChargesClient.GetShippingChargesForSingleWorkOrder(wo.id);
        const { data } = shippingCharges


        for (var i = 0; i < data.length; i++) {
            costConfirmed += data[i].value;
            charges.push(data[i]);
        }
    }
    

    console.log("TC");
    console.log(costConfirmed);

    return { ...quotesDetails, charges, totalCost: costConfirmed }
}

export const getCurrentCharges = async (
    quotesDetails: any,
    wo: any,
    tenant: any,
    user: any // should be coming from redux
) => {

    var isManufacturing = wo.statusFlow.myobStatusFlowType === 'salesOrder' ? true : false;
    var percentageFee = isManufacturing ? (wo.manufacturingPercentageFee != null ? wo.manufacturingPercentageFee / 100 : 0) : (tenant.percentageFee != null ? tenant.percentageFee / 100 : 0);

    var estimate = parseFloat(quotesDetails.estimate)

    var percentageFeeAmount = percentageFee > 0 ? estimate * percentageFee : 0

    var baseCost = (estimate ?? 0) + percentageFeeAmount

    var charges = []


    if (baseCost != null &&
        baseCost !== 0 &&
        user?.tenant?.tenantTypeId?.toUpperCase() !== TenantTypeIds.Contractor &&
        wo.ocManaged === false) {

        //add percentage fee if OC managed = false
        if (percentageFee !== 0 && percentageFee != null) {
            charges.push({ value: percentageFeeAmount, chargeType: { name: isManufacturing ? 'Manufacturing Fee' : 'Percentage Fee' } })
        }
    }

    return { ...quotesDetails, charges, totalCost: baseCost, quoteChanged: wo.quoteChanged }
}

export const getProjectCharges = async (
    estimate: any,
    wo: any,
    tenant: any
) => {

    var priorityQuery = await ClientPrioritySettingsClient.List(
        new ClientPrioritySettingsQueryParams()
            .Paginate(0, 10)
            .WithClientId(tenant.id)
            .WithPriorityId(wo.priorityId)
            .Sort('id', 'asc')
    )

    var priority = priorityQuery?.data?.data[0] ?? null

    var priorityFee = priority ? priority.fee : 0

    var adminFee = tenant.adminFee


    var charges = []

    if ((adminFee ?? 0) > 0) {
        if (estimate < tenant.thresholdMoney || tenant.applyAdminFeeWhenOverThreshold === true) {
            charges.push({ value: adminFee, chargeType: { name: 'Admin Fee' } })
        } 
    }

    if (priorityFee && priorityFee > 0)
        charges.push({ value: priorityFee, chargeType: { name: 'Priority Fee' } })

    return charges;
}
