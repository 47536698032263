import {FC} from 'react'
import {Redirect, Switch, Route, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {CheckInvitationCode} from '../modules/invites/components/CheckInvitationCode'
import CheckListStandAlone from '../modules/check-list-standalone/CheckListStandAlone'
import QuoteApproval from '../modules/quote-approval/QuoteApproval'
import queryString from 'query-string'
import MobileHazardForm from '../modules/hazards/components/MobileHazardForm'
import MobileHazardSuccess from '../modules/hazards/components/MobileHazardSuccess'
import MobileHazardError from '../modules/hazards/components/MobileHazardError'
import MobileIncidentForm from '../modules/incidents/components/MobileIncidentForm'
import MobileIncidentError from '../modules/incidents/components/MobileIncidentError'
import MobileIncidentSuccess from '../modules/incidents/components/MobileIncidentSuccess'

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const location = useLocation()

  let params: any = queryString.parse(location.search)

  return (
    <Switch>
      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />
      <Route exact path='/invite/accept/:id' component={CheckInvitationCode} />
          <Route path='/check-list-stand-alone' component={CheckListStandAlone} />
          <Route exact path='/quote-approval/:id' component={QuoteApproval} />
          <Route exact path='/workorder-quote-approval/:id' component={QuoteApproval} />
      <Route exact path='/hazard-mobile' component={MobileHazardForm} />
      <Route exact path='/hazard-mobile-error' component={MobileHazardError} />
      <Route exact path='/hazard-mobile-success' component={MobileHazardSuccess} />
      <Route exact path='/incident-mobile' component={MobileIncidentForm} />
      <Route exact path='/incident-mobile-error' component={MobileIncidentError} />
      <Route exact path='/incident-mobile-success' component={MobileIncidentSuccess} />

      {!isAuthorized && !location.pathname.includes('quote-approval') ? (
        <Route>
          <AuthPage />
        </Route>
      ) : !isAuthorized && location.pathname.includes('quote-approval') ? (
        <Redirect to={`/login?redirect=${location.pathname}`} />
      ) : params?.redirect ? (
        <Redirect to={`${params?.redirect}`} />
      ) : (
        <Redirect from='/login' to='/dashboard' />
      )}

      {!isAuthorized ? (
        <Redirect to='/login' />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
