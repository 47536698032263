import {ApiClient} from '../../../setup/axios/SetupAxios'
import {ListQueryParams} from '../ApiClient'

export class QuoteBreakdownQueryParams extends ListQueryParams {
  WithQuoteId(value: string) {
    this.WithParam('quoteId', value)
    return this
  }
}

export class QuoteBreakdownClient {
  static async List(params: QuoteBreakdownQueryParams) {
    return await ApiClient.Get('/quote-breakdowns' + params.GenerateQueryString())
  }

  static async Get(id: string) {
    return await ApiClient.Get('/quote-breakdowns/' + id)
  }

  static async Delete(id: string) {
    return await ApiClient.Delete('/quote-breakdowns/' + id)
  }

  static async Create(
    cost: number,
    item: string,
    description: string,
    quoteId: string,
    totalCost?: any
  ) {
    return await ApiClient.Post('/quote-breakdowns/', {
      cost,
      item,
      description,
      quoteId,
      totalCost,
    })
  }
}
