import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserQueryParams, UsersClient} from '../../../infrastracture/api/UsersClient'
import {ReviewQueryParams, ReviewsClient} from '../../../infrastracture/api/ReviewsClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import ReviewsModal from './ReviewsModal'
import Moment from 'react-moment'
import {Rating, RatingView} from 'react-simple-star-rating'
import AttachmentsViewModal from '../../attachments/components/AttachmentsViewModal'

const ReviewsBreadCrumbs: Array<PageLink> = []
interface Props {
  workOrderId: string
  title?: any
  refreshWO: () => void
}
const ReviewsList: React.FC<Props> = (props) => {
  const {workOrderId, refreshWO} = props
  const [Reviews, setReviews] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [active, setActive] = useState(true)
  const [selectedData, setSelectedData] = useState<any>({})
  const [selectedAttachment, setSelectedAttachment] = useState<any>({})
  const [mode, setMode] = useState('')
  const [viewOnly, setViewOnly] = useState(false)

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalRecords: 1,
    totalPages: 1,
    skip: 0,
    take: 50,
    reset: true,
    sort: 'rating',
    direction: 'desc',
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (pagination.reset) fetchData()
  }, [search, pagination])

  useEffect(() => {
    fetchData()
  }, [workOrderId])

  async function fetchData() {
    setLoading(true)
    try {
      var query = new ReviewQueryParams()

      var response = await ReviewsClient.List(
        query
          .Paginate(pagination.skip, pagination.take)
          .Search(search)
          .WithActive(active)
          .WithWorkOrderId(workOrderId)
          .Sort(pagination.sort, pagination.direction)
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setReviews(data?.data)

        const totalPages = Math.ceil(data.total / pagination.take)

        setPagination({
          ...pagination,
          totalPages,
          totalRecords: data.total,
          reset: false,
        })
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  const renderModals = () => {
    return (
      <>
        <ReviewsModal
          viewOnly={viewOnly}
          mode={mode}
          selectedData={selectedData}
          workOrderId={workOrderId}
          handleOk={() => {
            fetchData()
            refreshWO()
          }}
        />
        <AttachmentsViewModal selectedData={selectedAttachment} />
      </>
    )
  }

  return (
    <>
      {renderModals()}
      <div className={`card`}>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                <img src={toAbsoluteUrl('/media/icons/duotune/general/gen004.svg')} />
              </span>
              <input
                type='text'
                data-kt-notice-type-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Reviews'
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
              {loading && (
                <div className='d-flex align-items-center'>
                  <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                </div>
              )}
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#Reviews_modal'
                  onMouseOver={() => {
                    setMode('Add')
                    setViewOnly(false)
                  }}
                  onClick={() => {
                    setMode('Add')
                    setViewOnly(false)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                  Add Review
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <TableHeader
                sort={pagination.sort}
                direction={pagination.direction}
                columns={[
                  {name: 'Comment', sort: 'comment', width: '15'},
                  {name: 'Rating', sort: 'rating', width: '10'},
                  {name: 'Created', sort: 'created', width: '10'},
                  {name: 'Created By', sort: 'created', width: '10'},
                  {name: '', sort: '', static: true, width: '10'},
                ]}
                onUpdate={(sort: any, direction: any) => {
                  setPagination({
                    ...pagination,
                    sort,
                    direction,
                    reset: true,
                  })
                }}
              />
              {/* end::Table head */}
              {/* begin::Table body */}

              {!loading && (
                <tbody>
                  {!loading &&
                    Reviews.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {item.comment && (
                            <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                              {item.comment.length >= 30 && (
                                <span>{item.comment.substring(0, 30)}...</span>
                              )}
                              {item.comment.length < 30 && item.comment}
                            </a>
                          )}
                          {!item.comment && <i>Not specified</i>}
                        </td>
                        <td>
                          {item.rating && <RatingView ratingValue={item.rating} />}
                          {!item.rating && <i>Not specified</i>}
                        </td>

                        <td>
                          {item.created && (
                            <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                              <Moment utc local format='ddd DD MMM, YYYY h:mm a'>
                                {item.created}
                              </Moment>
                            </a>
                          )}
                          {!item.created && <i>Not specified</i>}
                        </td>
                        <td>
                          {item.whoCreated && (
                            <a className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'>
                              {item.whoCreated.name} {item.whoCreated.surname}
                            </a>
                          )}
                          {!item.whoCreated && <i>Not specified</i>}
                        </td>
                        <td className='text-end'>
                          {item.attachments &&
                            item.attachments[0].fileName.match(/.(jpg|jpeg|png|gif)$/i) && (
                              <button
                                type='button'
                                className='btn btn-bg-light btn-color-primary btn-active-color-primary btn-sm'
                                data-bs-toggle='modal'
                                data-bs-target='#attachment_view_modal'
                                onMouseOver={() => {
                                  if (item.attachments) {
                                    setSelectedAttachment(item.attachments[0])
                                  }
                                }}
                                onClick={() => {
                                  if (item.attachments) {
                                    setSelectedAttachment(item.attachments[0])
                                  }
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen045.svg'
                                  className='svg-icon-3 m-0'
                                />
                              </button>
                            )}
                          <button
                            type='button'
                            className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            data-bs-toggle='modal'
                            data-bs-target='#Reviews_modal'
                            onMouseOver={() => {
                              setSelectedData(item)
                              setViewOnly(true)
                              setMode('View')
                            }}
                            onClick={() => {
                              setSelectedData(item)
                              setViewOnly(true)
                              setMode('View')
                            }}
                          >
                            Details
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>

          <div className='card-footer px-1 py-4'>
            <div className='d-flex justify-content-between'>
              <div style={{minWidth: '200px'}}>
                <RecordPerPageSelector
                  placeholder='Rows per page'
                  onChange={(name, value) => {
                    setPagination({
                      ...pagination,
                      take: value,
                      reset: true,
                      currentPage: 1,
                    })
                  }}
                />
              </div>
              <div data-kt-notice-type-table-toolbar='base'>
                <Pagination
                  current={pagination.currentPage}
                  total={pagination.totalPages}
                  onPageChange={(page) => {
                    setPagination({
                      ...pagination,
                      currentPage: page,
                      skip: page === 1 ? 0 : (page - 1) * pagination.take,
                      reset: true,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewsList
