export const capitalizeFirstChar = (string: string) => {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const toMoneyFormat = (string: any) => {
  if (string || string === 0) return `$${Number(string).toFixed(2)}`
}

export const minutesToHourFormat = (totalMinutes: any) => {
  var hours = Math.floor(totalMinutes / 60)
  var minutes = totalMinutes % 60

  if (minutes > 0 && hours > 0)
    return `${hours}${hours > 1 ? 'hrs' : 'hr'} ${minutes}${minutes > 1 ? 'mins' : 'min'} `
  else if (minutes > 0 && hours <= 0) return `${minutes}${minutes > 1 ? 'mins' : 'min'} `
  else return `${hours}${hours > 1 ? 'hrs' : 'hr'} `
}
