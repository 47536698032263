import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class MessageQueryParams extends ListQueryParams {
    WithUserId(value: string) {
        this.WithParam('userId', value)
        return this
    }
    WithSiteId(value: string) {
        this.WithParam('teamId', value)
        return this
    }
    WithTeamId(value: string) {
        this.WithParam('teamId', value)
        return this
    }
    WithWorkOrderId(value: string) {
        this.WithParam('workOrderId', value)
        return this
    }
}

export class MessagesClient {
    static async List(params: MessageQueryParams) {
        return await ApiClient.Get('/messages' + params.GenerateQueryString());
    }

    static async Create(
        title: string,
        content: string,
        userIds: [],
        scheduled?: Date,
        deviceToken?: string
    ) {

        return await ApiClient.Post('/messages', {
            title: title,
            content: content,
            userIds: userIds,
            scheduled: scheduled,
            deviceToken: deviceToken,
        });
    }

    static async Get(id: string) {
        return await ApiClient.Get('/messages/' + id);
    }
}
