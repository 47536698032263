import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import {Item1} from '../../../../../_metronic/partials/content/activity/Item1'
import Dropzone from 'react-dropzone'
import {ProgressBar} from 'react-bootstrap-v5'
import {CheckInsClient} from '../../../../infrastracture/api/CheckInsClient'
import {KTSVG} from '../../../../../_metronic/helpers'
import {
  AttachmentsQueryParams,
  AttachmentsClient,
} from '../../../../infrastracture/api/AttachmentsClient'
import UploadFromLibraryModal from '../../../library/components/UploadFromLibraryModal'

interface Props {
  user: any
  handleOk: (item: any) => void
  acknowledgeSWMS: (value: boolean) => void
  setDefaultWorkSafeFiles: (item: any) => void
  updateAddiotnalWorkSafeFiles: (item: any) => void
  checkInId: any
  workOrderId: any
}

const Step3: React.FC<Props> = (props) => {
  const {
    user,
    checkInId,
    workOrderId,
    handleOk,
    acknowledgeSWMS,
    setDefaultWorkSafeFiles,
    updateAddiotnalWorkSafeFiles,
  } = props

  let files: any[] = []
  const [fileNames, setFileNames] = useState<any[]>([])
  const [uploadedFileNames, setUploadedFileNames] = useState<any[]>([])

  const [currentFile, setCurrentFile] = useState<any>(null)
  const [progress, setProgress] = useState(0)

  const [selectedFiles, setSelectedFiles] = useState<any[]>([])
  const [workSafeFiles, setWorkSafeFiles] = useState<any[]>([])
  const [additionalWorkSafeFiles, setAdditionalWorkSafeFiles] = useState<any[]>([])
  const [showFromLibraryModal, setShowFromLibraryModal] = useState(false)

  useEffect(() => {
    getWorkSafeItems()
  }, [checkInId])

  useEffect(() => {
    handleOk(fileNames)
  }, [fileNames])

  useEffect(() => {
    if (selectedFiles) selectedFiles.map((file: any) => upload(file))
  }, [selectedFiles])

  const getWorkSafeItems = async () => {
    var query = new AttachmentsQueryParams()

    var response = await AttachmentsClient.List(
      query
        .Paginate(0, 99999)
        .WithWorkOrderId(workOrderId)
        .WithWorkSafeOnly(true)
        .Sort('name', 'asc')
    )

    if (!response.authenticated) {
    } else {
      const {data} = response

      setDefaultWorkSafeFiles(data?.data)
      setWorkSafeFiles(data?.data)
    }
  }

  const handleDrop = (acceptedFiles: any) => {
    var additional = acceptedFiles.map((file: any) => file.name)
    setSelectedFiles(acceptedFiles)
    setFileNames([...fileNames, ...additional])
  }

  const upload = (file: any) => {
    let currentFile = selectedFiles![0]

    setProgress(0)
    setCurrentFile(currentFile)

    CheckInsClient.UploadSWMS(checkInId, workOrderId, file.name, currentFile, (event: any) => {
      setProgress(Math.round((100 * event.loaded) / event.total))
    })
      .then((response) => {
        setUploadedFileNames([...uploadedFileNames, file.name])
        setCurrentFile(null)
        setSelectedFiles([])
      })
      .catch(() => {
        setProgress(0)
        setCurrentFile(null)
        setSelectedFiles([])
      })
  }

  const renderModal = () => (
    <UploadFromLibraryModal
      user={user}
      workSafeOnly
      handleClose={() => {
        setShowFromLibraryModal(false)
      }}
      handleOk={() => {
        setShowFromLibraryModal(false)
      }}
      show={showFromLibraryModal}
      workOrderId={workOrderId}
      uploadFile={async (item: any) => {
        setAdditionalWorkSafeFiles([...additionalWorkSafeFiles, item])
        updateAddiotnalWorkSafeFiles(item)
        await CheckInsClient.UploadLibraryToCheckIn(checkInId, item.id, workOrderId)
      }}
    />
  )

  return (
    <>
      {renderModal()}
      <div className='w-100'>
        <div>
          <h2 className='fw-bolder text-dark'>Attach SWM / SSSP or TA as appropriate</h2>
          <div className='form-group'>
            <div style={{padding: '15px 0px'}}>
              <button
                onClick={(e: any) => {
                  e.preventDefault()
                  setShowFromLibraryModal(true)
                }}
                className='btn btn-sm btn-primary w-100'
              >
                Upload from Library
              </button>
            </div>
            <div style={{padding: '15px 0px'}}>
              <Dropzone multiple={false} onDrop={handleDrop}>
                {({getRootProps, getInputProps}) => (
                  <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <a className='btn btn-sm btn-primary w-100'>Attach files</a>
                  </div>
                )}
              </Dropzone>
            </div>
            <div>
              <strong>Files:</strong>
              <ul>
                {workSafeFiles.map((item: any) => (
                  <li key={item.id}>
                    {item.name} ({item.library?.documentType?.name})
                  </li>
                ))}
                {fileNames.map((fileName) => (
                  <li key={fileName}>
                    {fileName}{' '}
                    {currentFile?.name === fileName && (
                      <ProgressBar animated now={progress} style={{marginTop: '2px'}} />
                    )}
                    {uploadedFileNames.includes(fileName) && (
                      <KTSVG
                        path='/media/icons/duotune/general/gen043.svg'
                        className='svg-icon-4 svg-icon-success '
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
            {additionalWorkSafeFiles.length > 0 && (
              <div>
                <strong>Additional Files:</strong>
                <ul>
                  {additionalWorkSafeFiles.map((item: any) => (
                    <li key={item.id}>
                      {item.name} {item.documentType?.name && `(${item.documentType.name})`}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div>
              <input
                className='form-check-input'
                type='checkbox'
                onChange={(e) => acknowledgeSWMS(e.target.checked)}
                name='workSafe'
              />{' '}
              <label className='form-check-label'>
                <b>
                  I acknowledge and understand the requirements of the attached SWM / SSSP or TA
                </b>
              </label>
            </div>
          </div>
          {/* <label className='required form-label'>File</label>
        <input
          type='file'
          className='form-control form-control-solid'
          multiple
          id='file'
          name='file'
          onChange={(e) => {
            var tempFiles: any = []

            if (e.target.files) {
              Array.from(e.target.files).forEach((file: any) => tempFiles.push(file))
            }

            setFiles(tempFiles)
          }}
        /> */}
          {/* {files.length > 0 && Array.from(files).forEach((file: any) => <p>{file.name}</p>)} */}
        </div>
      </div>
    </>
  )
}

export {Step3}
