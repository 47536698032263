import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class CategoryWorkScopesQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithWorkOrderCategoryId(value: string) {
        this.WithParam('workOrderCategoryId', value)
        return this
    }
    WithWorkScopeId(value: string) {
        this.WithParam('workScopeId', value)
        return this
    }
}

export class CategoryWorkScopesClient {
    static async List(params: CategoryWorkScopesQueryParams) {
        return await ApiClient.Get('/category-work-scopes' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/category-work-scopes/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/category-work-scopes/' + id);
    }

    static async Create(workOrderCategoryId: string, workScopeId: string, description: string) {
        return await ApiClient.Post('/category-work-scopes', {
            workOrderCategoryId,
            workScopeId,
            description,
        });
    }

    static async Update(
        id: string,
        workOrderCategoryId: string,
        workScopeId: string,
        description: string
    ) {
        return await ApiClient.Patch('/category-work-scopes/' + id, [
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
        ]);
    }
}
