import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {Form} from 'react-bootstrap-v5'
import moment from 'moment'
import Select from 'react-select'
import customStyles from '../../../../common/components/selectors/customStyles'
import {checkInTimeOptions} from '../options'
interface Props {
  handleOk: (hrs: any, expectedOut: any) => void
  checkIn: any
}

const Step2: React.FC<Props> = (props) => {
  const {handleOk, checkIn} = props

  // const [hrs, setHrs] = useState<any>(1)
  const [minutes, setMinutes] = useState<any>(30)
  const today = moment()

  useEffect(() => {
    // setHrs(1)
    setMinutes(30)
  }, [checkIn])

  return (
    <div className='w-100'>
      <div>
        <h2 className='fw-bolder text-dark'>Time On Site</h2>
        <Select
          onChange={(values: any) => {
            const {label, value} = values
            setMinutes(value)

            handleOk(value, moment().add(parseInt(minutes), 'minutes'))
          }}
          defaultValue={{value: 30, label: '30 minutes'}}
          options={checkInTimeOptions}
          placeholder={'Time On Site'}
          styles={customStyles}
          menuPortalTarget={document.body}
        />
        {/* <Form>
          <Form.Label>{hrs} Hour(s)</Form.Label>
          <Form.Range
            value={hrs}
            onChange={(e) => {
              setHrs(e.target.value)
              handleOk(e.target.value, moment().add(parseInt(hrs), 'hours'))
            }}
            min={1}
            max={12}
          />
        </Form> */}
        <div className='d-flex flex-stack pr-2'>
          <div></div>

          <div>
            <Form.Label>
              Expected out:
              {moment(moment().add(parseInt(minutes), 'minutes')).isSame(today, 'day')
                ? `Today at ${moment().add(parseInt(minutes), 'minutes').format('h:mm a')}`
                : `Tomorrow at ${moment().add(parseInt(minutes), 'minutes').format('h:mm a')}`}
            </Form.Label>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step2}
