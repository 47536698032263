import React, {useEffect, useState} from 'react'
import Select from 'react-select'

interface Props {
  name?: string
  defaultValue?: any
  placeholder?: string
  disabled?: boolean
  onChange?: (name: any, value: any) => void
}

const styles = {
  control: (provided: any) => ({
    ...provided,
    background: '#f5f8fa',
    borderRadius: '5px',
    width: '155px',
  }),
  indicatorSeparator: (_: any) => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
  }),
  option: (styles: any) => {
    return {
      ...styles,
    }
  },
  menuPortal: (base: any) => ({...base, zIndex: 9999}),
  singleValue: (provided: any) => ({...provided, background: '#f5f8fa'}),
  placeholder: (defaultStyles: any) => ({...defaultStyles, background: '#f5f8fa'}),
}

const RecordPerPageSelector: React.FC<Props> = (props: any) => {
  const {defaultValue, placeholder, disabled, name} = props

  const [value, setValue] = useState(null)

  const defaultId = 'recordperpage'

  const options = [
    {value: 10, label: '10'},
    {value: 20, label: '20'},
    {value: 30, label: '30'},
    {value: 40, label: '40'},
    {value: 50, label: '50'},
    {value: 100, label: '100'},
    {value: 200, label: '200'},
  ]

  useEffect(() => {
    if (defaultValue?.value) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const handleChange = (values: any) => {
    const {onChange} = props
    const {label, value} = values
    setValue(values)
    onChange(defaultId, value)
  }

  return (
    <Select
      onChange={handleChange}
      value={value}
      options={options}
      placeholder={'Rows per page'}
      isDisabled={disabled}
      id={name ? name : defaultId}
      name={name ? name : defaultId}
      styles={styles}
      menuPortalTarget={document.body}
    />
  )
}

export default RecordPerPageSelector
