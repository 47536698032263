import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class NotesQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithWorkOrderId(value: string) {
        this.WithParam('workOrderId', value)
        return this
    }
}

export class NotesClient {
    static async List(params: NotesQueryParams) {
        return await ApiClient.Get('/notes' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/notes/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/notes/' + id);
    }

    static async Create(description: string, workOrderId: string) {
        return await ApiClient.Post('/notes/', {
            description: description,
            workOrderId: workOrderId,
        });
    }

    static async Update(id: string, description: string) {
        return await ApiClient.Patch('/notes/' + id, [
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
        ]);
    }
}
