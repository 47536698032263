import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[RateCardType SetFilter] Action',
  ResetFilter: '[RateCardType ResetFilter] Action',
}

const initialRateCardTypeState: IRateCardTypeState = {
  filter: {
    status: 'active',
    active: true,
  },
}

export interface IRateCardTypeState {
  filter?: {
    status: string
    active: boolean
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-RateCardType', whitelist: ['filter']},
  (state: IRateCardTypeState = initialRateCardTypeState, action: ActionWithPayload<IRateCardTypeState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialRateCardTypeState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
