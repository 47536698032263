import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {MessagesClient, MessageQueryParams} from '../../../infrastracture/api/MessagesClient'
import {UsersClient, UserQueryParams} from '../../../infrastracture/api/UsersClient'
import {SitesClient, SiteQueryParams} from '../../../infrastracture/api/SitesClient'
import {Col} from 'reactstrap'
import Select from 'react-select'
import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import SweetAlert from 'react-bootstrap-sweetalert'
import MessagesList from './MessagesList'
import UserSelector from '../../../common/components/selectors/UserSelector'
import SiteSelector from '../../../common/components/selectors/SiteSelector'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

interface Props {
  handleOk: () => void
  selectedData: any
  mode: string
  viewOnly?: boolean
}

const MessagesModal: React.FC<Props> = (props) => {
  const {handleOk, selectedData, mode, viewOnly} = props
  const [createSuccess, setCreateSuccess] = useState(false)
  const [users, setUsers] = useState<Array<any>>([])
  const [sites, setSites] = useState<Array<any>>([])
  const [selectedUsers, setSelectedUsers] = useState<Array<any>>([])
  const [selectedSites, setSelectedSites] = useState<Array<any>>([])
  const [selectedAudience, setSelectedAudience] = useState<any>({
    value: 'users',
    label: 'Users',
  })
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    content: Yup.string().required('Content is required'),
  })

  const [initialValues, setInitialValues] = useState<any>({})

  useEffect(() => {
    if (mode === 'Edit' || mode === 'View') setInitialValues(selectedData)
    else
      setInitialValues({
        title: '',
        content: '',
        scheduled: null,
        selectedSites: [],
        selectedUsers: [],
      })
  }, [selectedData, mode])

  useEffect(() => {
    fetchUsersData()
    // fetchSitesData()
  }, [])

  async function fetchUsersData() {
    setLoading(true)
    try {
      var query = new UserQueryParams()
      var response = await UsersClient.List(query.Paginate(0, 99999).Search('').Sort('name', 'asc'))
      if (response.data?.data) {
        var data = response.data.data.map(function (item: any) {
          return {value: item.id, label: item.name + ' ' + item.surname}
        })
        setUsers(data)
        setLoading(false)
      }
    } catch (e) {
      console.error(e)
    }
  }

  // async function fetchSitesData() {
  //   setLoading(true)
  //   try {
  //     var query = new SiteQueryParams()
  //     var response = await SitesClient.List(query.Paginate(0, 99999).Search('').Sort('name', 'asc'))
  //     var data = response.data.data.map(function (item: any) {
  //       return {value: item.id, label: item.name}
  //     })
  //     setSites(data)
  //     setLoading(false)
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          debugger
          var response: any

          if (values.scheduled) values.scheduled = new Date(values.scheduled).toISOString()

          // if (selectedSites.length > 0)
          //   values.selectedSites = selectedSites.map(function (item) {
          //     return item.value
          //   })

          if (selectedUsers.length > 0)
            values.selectedUsers = selectedUsers.map(function (item) {
              return item.value
            })
          if (mode === 'Edit') {
            // response = await MessagesClient.Update(
            //   selectedData.id,
            //   values.name,
            //   values.description,
            //   values.active
            // )
          } else {
            response = await MessagesClient.Create(
              values.title,
              values.content,
              values.selectedUsers,
              values.scheduled
            )
          }

          if (!response.successful) {
            //show error
          } else {
            setSubmitting(false)
            document.getElementById('rd_messages_modal_dismiss')!.click()
            setCreateSuccess(true)
          }
        }}
        enableReinitialize
      >
        {({errors, touched, isSubmitting, values, setFieldValue, resetForm}: any) => (
          <>
            <SweetAlertWithProp
              success
              title='Success'
              onConfirm={() => {
                setCreateSuccess(false)
                handleOk()
              }}
              confirmBtnText='Confirm'
              cancelBtnBsStyle='light'
              show={createSuccess}
              showCancel={false}
              showCloseButton
            >
              {mode} Messages success
            </SweetAlertWithProp>
            <div className='modal fade' tabIndex={-1} id='messages_modal'>
              <Form>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>{mode} Messages</h5>

                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <span className='svg-icon svg-icon-2x'></span>
                      </div>
                    </div>

                    <div className='modal-body'>
                      <div
                        className='scroll-y me-n7 pe-7'
                        id='kt_modal_add_customer_scroll'
                        data-kt-scroll='true'
                        style={{maxHeight: '450px'}}
                      >
                        <table
                          className='scroll-y table table-row-dashed gy-5'
                          id='kt_table_users_login_session'
                        >
                          <tbody className='fs-6 fw-bold text-gray-600'>
                            <tr>
                              <td>
                                <label className='required form-label'>Title</label>
                                <Field
                                  name='title'
                                  placeholder='Title'
                                  type='text'
                                  className='form-control form-control-solid'
                                  id='title'
                                  disabled={viewOnly}
                                />
                                {touched.name && errors.title && (
                                  <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                      {errors.title}
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className='required form-label'>Content</label>
                                <Field
                                  name='content'
                                  placeholder='Content'
                                  type='text'
                                  className='form-control form-control-solid'
                                  id='content'
                                  rows='5'
                                  disabled={viewOnly}
                                />
                                {touched.name && errors.content && (
                                  <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                      {errors.content}
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className='form-label'>Scheduled</label>
                                <Field
                                  name='scheduled'
                                  type='datetime-local'
                                  className='form-control form-control-solid'
                                  id='scheduled'
                                />
                              </td>
                            </tr>
                            {/* <tr>
                              <td>
                                <label className='required form-label'>Type of audience</label>
                                <Select
                                  options={[
                                    {value: 'sites', label: 'Sites'},
                                    {value: 'users', label: 'Users'},
                                  ]}
                                  defaultValue={selectedAudience}
                                  placeholder='Select Type of Audience'
                                  onChange={(e: any) => {
                                    setSelectedAudience(e)
                                  }}
                                />
                              </td>
                            </tr> */}
                            {/* {selectedAudience.value === 'users' && ( */}
                            <tr>
                              <td>
                                <label className='required form-label'>Recipients</label>
                                <Select
                                  isMulti
                                  options={users}
                                  defaultValue={selectedUsers}
                                  placeholder='Select Recipients'
                                  onChange={(selectedOptions: any) => {
                                    setSelectedUsers(selectedOptions)
                                  }}
                                />
                              </td>
                            </tr>
                            {/* )} */}
                            {/* {selectedAudience.value === 'sites' && (
                              <tr>
                                <td>
                                  <label className='required form-label'>Sites</label>
                                  <Select
                                    isMulti
                                    options={sites}
                                    defaultValue={selectedSites}
                                    placeholder='Select Sites'
                                    onChange={(selectedOptions: any) => {
                                      setSelectedSites(selectedOptions)
                                    }}
                                  />
                                </td>
                                <td></td>
                              </tr>
                            )} */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        onClick={() => {
                          resetForm()
                        }}
                        className='btn btn-light'
                        data-bs-dismiss='modal'
                        id='rd_messages_modal_dismiss'
                      >
                        Close
                      </button>
                      {!viewOnly && (
                        <button
                          type='submit'
                          id='rd_create_tenant_submit'
                          className='btn btn-lg btn-primary'
                          disabled={isSubmitting}
                        >
                          {!isSubmitting && <span className='indicator-label'>Save</span>}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </Formik>
    </>
  )
}

export default MessagesModal
