import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  ParticipantsClient,
  ParticipantQueryParams,
} from '../../../infrastracture/api/ParticipantsClient'
import {Col} from 'reactstrap'
import Select from 'react-select'
import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import SweetAlert from 'react-bootstrap-sweetalert'
import ParticipantsList from './ParticipantsList'
import {Rating} from 'react-simple-star-rating'
import UserSelector from '../../../common/components/selectors/UserSelector'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

interface Props {
  handleOk: () => void
  selectedData: any
  mode: string
  viewOnly?: boolean
  workOrderId: string
}

const ParticipantsModal: React.FC<Props> = (props) => {
  const {handleOk, selectedData, mode, viewOnly, workOrderId} = props
  const [createSuccess, setCreateSuccess] = useState(false)
  const [rating, setRating] = useState(0)
  const [participants, setParticipants] = useState<Array<any>>([])
  const [selectedParticipants, setSelectedParticipants] = useState<Array<any>>([])
  // const [userId, setUserId] = useState('')
  const history = useHistory()

  const initialState = {
    userId: null,
    user: {} as any,
    selectedParticipants: [] as any,
  }

  const [initialValues, setInitialValues] = useState<any>({})

  useEffect(() => {
    if (mode === 'Edit' || mode === 'View') {
      const {user} = selectedData

      setInitialValues({
        ...selectedData,
        user: {value: user?.id, label: user?.name},
      })
    } else
      setInitialValues({
        userId: null,
        user: {} as any,
      })
  }, [selectedData, mode])

  useEffect(() => {
    fetchAvailableParticipants()
  }, [])

  async function fetchAvailableParticipants() {
    var query = new ParticipantQueryParams()
    var response = await ParticipantsClient.ListAvailableParticipants(
      query.Paginate(0, 99999).WithWorkOrderId(workOrderId).WithActive(true).Sort('name', 'asc')
    )

    if (response.authenticated) {
      var data = response.data.data.map(function (item: any) {
        return {value: item.id, label: item.name + ' ' + item.surname}
      })
      setParticipants(data)
    }
  }

  const handleRating = (rate: any) => {
    setRating(rate)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          var response: any

          if (selectedParticipants.length > 0)
            values.selectedParticipants = selectedParticipants.map(function (item) {
              return item.value
            })

          if (mode === 'Edit') {
            response = await ParticipantsClient.Update(selectedData.id, workOrderId, values.userId)
          } else {
            response = await ParticipantsClient.Create(workOrderId, values.selectedParticipants)
          }

          if (!response.successful) {
            //show error
          } else {
            setSubmitting(false)
            document.getElementById('rd_Participants_modal_dismiss')!.click()
            fetchAvailableParticipants()
            setSelectedParticipants([])
            setCreateSuccess(true)
          }
        }}
        enableReinitialize
      >
        {({errors, touched, isSubmitting, values, setFieldValue, resetForm}: any) => (
          <>
            <SweetAlertWithProp
              success
              title='Success'
              onConfirm={() => {
                setCreateSuccess(false)
                handleOk()
              }}
              confirmBtnText='Confirm'
              cancelBtnBsStyle='light'
              show={createSuccess}
              showCancel={false}
              showCloseButton
            >
              {mode} participant success
            </SweetAlertWithProp>
            <div className='modal fade' tabIndex={-1} id='participants_modal'>
              <Form>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>{mode} Participant</h5>

                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <span className='svg-icon svg-icon-2x'></span>
                      </div>
                    </div>

                    <div className='modal-body'>
                      <div className='me-n7 pe-7' style={{maxHeight: '450px'}}>
                        <table
                          className='table table-row-dashed gy-5'
                          id='kt_table_users_login_session'
                        >
                          <tbody className='fs-6 fw-bold text-gray-600'>
                            <tr>
                              <td>
                                <label className='form-label'>Users</label>
                                <Select
                                  isMulti
                                  value={selectedParticipants}
                                  options={participants}
                                  placeholder='Select Participants'
                                  onChange={(selectedOptions: any) => {
                                    setSelectedParticipants(selectedOptions)
                                  }}
                                />
                                {/* <UserSelector
                                  placeholder='Select User'
                                  defaultValue={values.user}
                                  onChange={setFieldValue}
                                  id='userId'
                                />

                                {touched.user && errors.user && (
                                  <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                      {errors.user}
                                    </span>
                                  </div>
                                )} */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        onClick={() => {
                          resetForm()
                        }}
                        className='btn btn-light'
                        data-bs-dismiss='modal'
                        id='rd_Participants_modal_dismiss'
                      >
                        Close
                      </button>
                      {!viewOnly && (
                        <button
                          type='submit'
                          id='rd_create_tenant_submit'
                          className='btn btn-lg btn-primary'
                          disabled={isSubmitting}
                        >
                          {!isSubmitting && <span className='indicator-label'>Save</span>}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </Formik>
    </>
  )
}

export default ParticipantsModal
