import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { UserQueryParams, UsersClient } from '../../../infrastracture/api/UsersClient'
import customStyles from './customStyles'

interface Props {
    name?: string
    defaultValue?: any
    placeholder?: string
    disabled?: boolean
    onChange?: (name: any, value: any, label?: any, mobile?: any) => void
    id?: string
    tenantId?: string
    isMulti?: boolean
    tenantTypeId?: string
    isMyobOwner?: boolean
    isMyobSupplyChain?: boolean
    hasEmployeeId?: boolean
    hasOwnerId?: boolean
    excludeInvolvedWithProposalId?: string
}


const UserSelector: React.FC<Props> = (props: any) => {

    const { excludeInvolvedWithProposalId, tenantId, id, defaultValue, placeholder, disabled, name, onChange, isMulti, tenantTypeId, isMyobOwner, isMyobSupplyChain, hasEmployeeId, hasOwnerId } =
        props

    const [options, setOptions] = useState([])
    const [value, setValue] = useState<any>({})

    const defaultId = id || 'userId'

    useEffect(() => {
        populateData()
    }, [])

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue])

    const populateData = async () => {
        var query = new UserQueryParams()
        var data: any

        if (tenantId) query.WithTenantId(tenantId)
        if (tenantTypeId) query.WithTenantTypeId(tenantTypeId)
        if (isMyobOwner) query.WithIsMyobOwner(isMyobOwner)
        if (isMyobSupplyChain) query.WithIsMyobSupplyChain(isMyobSupplyChain)
        if (hasEmployeeId) query.WithHasEmployeeId(hasEmployeeId)
        if (hasOwnerId) query.WithHasOwnerId(hasOwnerId)
        if (excludeInvolvedWithProposalId) query.WithParam('excludeInvolvedWithProposalId', excludeInvolvedWithProposalId)

        data = (
            await UsersClient.List(query.Sort('name', 'asc').Paginate(0, 10000).WithShowSuspended(false))
        ).data

        if (data) {
            setOptions(
                data.data.map((item: any) => {
                    return {
                        value: item.id,
                        label: `${item.name} ${item.surname}`,
                        mobile: item.mobile,
                    }
                })
            )
        }
    }

    const handleChange = (values: any) => {
        const { value, label, mobile } = values
        setValue(values)
        if (isMulti) onChange(defaultId, values)
        else onChange(defaultId, value, label, mobile)
    }

    return (
        <Select
            onChange={handleChange}
            value={value}
            options={options}
            placeholder={placeholder}
            isDisabled={disabled}
            id={name ? name : defaultId}
            name={name ? name : defaultId}
            styles={customStyles}
            menuPortalTarget={document.body}
            isMulti={isMulti}
        />
    )
}

export default UserSelector
