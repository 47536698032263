import React, {useState, useEffect, useRef} from 'react'

interface Props {}

const MobileHazardSuccess: React.FC<Props> = (props) => {
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-600px p-10 p-lg-15 mx-auto'>
          <div className='text-center d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
            New Hazard Successfully Added!
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileHazardSuccess
