import React, {useState, useEffect, useRef} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CheckListItemFileClient} from '../../../infrastracture/api/CheckListItemFileClient'
import {CheckListItemClient} from '../../../infrastracture/api/CheckListItemClient'
import {CheckListClient} from '../../../infrastracture/api/CheckListClient'

import Moment from 'react-moment'
import * as Yup from 'yup'
import {Formik, Field, FormikHelpers, useFormik, ErrorMessage} from 'formik'
import SweetAlert from 'react-bootstrap-sweetalert'
import {DragDropContext} from 'react-beautiful-dnd'
import CheckInsList from '../../check-ins/components/CheckInsList'
import {CheckListItemTypes, CheckListItemTypesLabel} from '../enums'
import {Col, Form, Row} from 'react-bootstrap-v5'
import Select from 'react-select'
import customStyles from '../../../common/components/selectors/customStyles'
import Dropzone from 'react-dropzone'
import {ProgressBar} from 'react-bootstrap-v5'
import _ from 'lodash'

interface Props {
  items: any[]
  setParentIndex: (index: number) => void
  closeModal: () => void
  isTestMode: boolean
  checkListId?: string
}

const CheckListItemRenderer: React.FC<Props> = (props) => {
  const {items, setParentIndex, closeModal, checkListId, isTestMode} = props
  const params: any = useParams()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [openComment, setOpenComment] = useState(false)
  const [openPhoto, setOpenPhoto] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<any[]>([])
  const [uploadedFileNames, setUploadedFileNames] = useState<any[]>([])
  const [values, setValues] = useState<any[]>([])
  const [checkList, setCheckList] = useState<any>(null)
  const [checkListItems, setCheckListItems] = useState<any[]>([])
  const [answeredItems, setAnsweredItems] = useState<any[]>([])
  const [unansweredItems, setUnansweredItems] = useState<any[]>([])
  const [currentFile, setCurrentFile] = useState<any>(null)
  const [progress, setProgress] = useState(0)
  const [fileNames, setFileNames] = useState<any[]>([])
  const [currentItem, setCurrentItem] = useState<any>('')

  const commentRef = useRef<any>(null)

  useEffect(() => {
    setParentIndex(currentIndex)
  }, [currentIndex])

  useEffect(() => {
    // getCheckList()
    setAnsweredItems(items.filter((x:any) => x.value))
  }, [items])

  useEffect(() => {
    if (answeredItems.length > 0) setCurrentIndex(answeredItems.length)
  }, [answeredItems])

  // const getCheckList = async () => {
  //   try {
  //     var response: any

  //     if (checkListId) {
  //       response = await CheckListClient.Get(checkListId)
  //       if (!response.authenticated) {
  //       } else {
  //         const {data} = response
  //         setCheckList(data)
  //         setCheckListItems(data?.checkListItems)

  //         if (data.checkListItems) {
  //           setAnsweredItems(checkListItems.filter((x) => x.value))
  //         }
  //       }
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  const setInputValue = (value: any, id: any, index: number) => {
    setValues([
      ..._.filter(values, (o:any) => o.id !== id),
      {
        id: id,
        value: value,
        index,
      },
    ])
  }

  const itemRenderer = (item: any, index: number) => {
    const {itemText, itemType, options, checkListTemplateId, id} = item

    switch (itemType) {
      case CheckListItemTypes.TickBox:
        return (
          <>
            {item.itemText} <br />
            <input
              className='form-check-input'
              type='radio'
              name={`${index}-tickBox`}
              onChange={(e) => {
                setInputValue('Yes', `${index}-tickBox`, index)
              }}
            />{' '}
            <label className='form-check-label'>Yes</label>{' '}
            <input
              className='form-check-input'
              type='radio'
              name={`${index}-tickBox`}
              onChange={(e) => {
                setInputValue('No', `${index}-tickBox`, index)
              }}
            />{' '}
            <label className='form-check-label'>No</label>
          </>
        )
      case CheckListItemTypes.DropList:
        const mappedOpt = options.map((item: any) => {
          return {
            value: item.id,
            label: item.value,
          }
        })

        return (
          <>
            <label className='form-label'>{item.itemText}</label>
            <Select
              isMulti={item.control === 'Multiselect' ? true : false}
              styles={customStyles}
              options={mappedOpt}
              placeholder='Select an option'
              key={`${index}-dropList`}
              name={`${index}-dropList`}
              id={`${index}-dropList`}
              onChange={(e: any) => {
                if (item.control === 'Multiselect') {
                  const values = e.map((item: any) => item.label)
                  setInputValue(values, `${index}-dropList`, index)
                } else {
                  setInputValue(e.label, `${index}-dropList`, index)
                }
              }}
            />
          </>
        )
      case CheckListItemTypes.TextInput:
        return (
          <>
            <label className='form-label'>{item.itemText}</label>
            <input
              type='text'
              className='form-control form-control-solid'
              key={`${index}-text`}
              name={`${index}-text`}
              id={`${index}-text`}
              onChange={(e) => {
                setInputValue(e.target.value, `${index}-text`, index)
              }}
            />
          </>
        )
      case CheckListItemTypes.Radio:
        return (
          <>
            <label className='form-label'>{item.itemText}</label>
            <div
              id={`${index}-radio`}
              onChange={(e: any) => {
                setInputValue(e.target.value, `${index}-radio`, index)
              }}
            >
              {options.map((o: any) => (
                <>
                  <div style={{paddingTop: '5px'}}>
                    <input
                      className='form-check-input'
                      id={o.value}
                      value={o.value}
                      type='radio'
                      name='dynamicRadioBtn'
                    />{' '}
                    <label className='form-check-label'>{o.value}</label>
                  </div>
                </>
              ))}
            </div>
          </>
        )
      case CheckListItemTypes.Range:
        const rangeOptions: any[] = []

        const start = options[0].value
        const end = options[1].value

        for (let i = start; i <= end; i++) {
          rangeOptions.push({
            value: i,
            label: i,
          })
        }

        return (
          <>
            <label className='form-label'>{item.itemText}</label>
            <Select
              styles={customStyles}
              options={rangeOptions}
              placeholder='Select an option'
              key={`${index}-dropList`}
              name={`${index}-dropList`}
              id={`${index}-dropList`}
              onChange={(e: any) => {
                setInputValue(e.label, `${index}-range`, index)
              }}
              menuPortalTarget={document.body}
            />
          </>
        )
      // return (
      //   <Form>
      //     <label className='form-label'>{item.itemText}</label>
      //     <Form.Range
      //       min={parseInt(options[0].value)}
      //       max={parseInt(options[1].value)}
      //       key={`${index}-range`}
      //       name={`${index}-range`}
      //       id={`${index}-range`}
      //       onChange={(e) => {
      //         setInputValue(e.target.value, `${index}-range`, index)
      //       }}
      //     />

      //     <label className='form-label'>
      //       {_.find(values, (o) => o.id === `${index}-range`)?.value}
      //     </label>
      //   </Form>
      // )
      case CheckListItemTypes.SectionHeader:
        return (
          <>
            <label className='form-label fs-5'>{item.itemText}</label>
            <p>{item.heading}</p>
          </>
        )
      default:
      // code block
    }
  }

  useEffect(() => {
    if (selectedFiles) selectedFiles.map((file: any) => upload(file))
  }, [selectedFiles])

  const handleDrop = (acceptedFiles: any) => {
    var additional = acceptedFiles.map((file: any) => file.name)
    setSelectedFiles(acceptedFiles)
    setFileNames([...fileNames, ...additional])
  }

  const upload = (file: any) => {
    let currentFile = selectedFiles![0]

    setProgress(0)
    setCurrentFile(currentFile)

    CheckListItemFileClient.Create(items[currentIndex].id, file.name, currentFile, (event: any) => {
      setProgress(Math.round((100 * event.loaded) / event.total))
    })
      .then((response) => {
        setUploadedFileNames([...uploadedFileNames, file.name])
        setCurrentFile(null)
        setSelectedFiles([])
      })
      .catch(() => {
        setProgress(0)
        setCurrentFile(null)
        setSelectedFiles([])
      })
  }

  const submitItem = async (item: any) => {
    setIsSubmitting(true)
    const {id, itemText, itemType, options} = item
    const comment = commentRef.current?.value

    var objValue: any

    switch (itemType) {
      case CheckListItemTypes.TickBox:
        objValue = _.find(values, (o) => o.id === `${currentIndex}-tickBox`)

        await CheckListItemClient.Answer(
          id,
          objValue?.value ? String(objValue.value) : null,
          comment
        )
        setIsSubmitting(false)
        return
      case CheckListItemTypes.DropList:
        objValue = _.find(values, (o) => o.id === `${currentIndex}-dropList`)

        await CheckListItemClient.Answer(
          id,
          objValue?.value ? String(objValue.value) : null,
          comment
        )
        setIsSubmitting(false)
        return
      case CheckListItemTypes.TextInput:
        objValue = _.find(values, (o) => o.id === `${currentIndex}-text`)

        await CheckListItemClient.Answer(
          id,
          objValue?.value ? String(objValue.value) : null,
          comment
        )
        setIsSubmitting(false)
        return
      case CheckListItemTypes.Radio:
        objValue = _.find(values, (o) => o.id === `${currentIndex}-radio`)

        await CheckListItemClient.Answer(
          id,
          objValue?.value ? String(objValue.value) : null,
          comment
        )
        setIsSubmitting(false)
        return
      case CheckListItemTypes.Range:
        objValue = _.find(values, (o) => o.id === `${currentIndex}-range`)

        await CheckListItemClient.Answer(
          id,
          objValue?.value ? String(objValue.value) : null,
          comment
        )
        setIsSubmitting(false)
        return
      case CheckListItemTypes.SectionHeader:
        await CheckListItemClient.Answer(id, 'viewed', null)
        setIsSubmitting(false)
        return
    }
  }

  return (
    <div
      className='scroll-y me-n7 pe-7'
      id='kt_modal_add_customer_scroll'
      data-kt-scroll='true'
      style={{maxHeight: '450px'}}
    >
      <table className='scroll-y table table-row-dashed gy-5' id='kt_table_users_login_session'>
        <tbody className='fs-6 fw-bold text-gray-600'>
          {items.length > 0 &&
            items.map((item, index) => {
              return (
                index === currentIndex && (
                  <tr>
                    <td>
                      <div>
                        {itemRenderer(item, index)}
                        <br />
                        {(item.allowComment || item.allowPhoto) && (
                          <>
                            <div
                              className='text-dark fw-bolder fs-5'
                              style={{
                                borderTop: '2px solid gray',
                                marginTop: '15px',
                                paddingTop: '15px',
                                paddingBottom: '10px',
                              }}
                            >
                              Optional Input
                            </div>
                            <table style={{width: '100%'}}>
                              <tr>
                                {item.allowComment && (
                                  <td>
                                    <div>
                                      <span
                                        style={{cursor: 'pointer'}}
                                        onClick={() => setOpenComment(!openComment)}
                                      >
                                        Comment
                                        {openComment ? (
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr073.svg'
                                            className='svg-icon-3'
                                          />
                                        ) : (
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr072.svg'
                                            className='svg-icon-3'
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </td>
                                )}
                                {item.allowPhoto && (
                                  <td>
                                    <div className='text-end'>
                                      <span
                                        style={{cursor: 'pointer'}}
                                        onClick={() => setOpenPhoto(!openPhoto)}
                                      >
                                        Photo
                                        {openPhoto ? (
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr073.svg'
                                            className='svg-icon-3'
                                          />
                                        ) : (
                                          <KTSVG
                                            path='/media/icons/duotune/arrows/arr072.svg'
                                            className='svg-icon-3'
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </td>
                                )}
                              </tr>
                            </table>
                          </>
                        )}

                        {item.allowComment && openComment && (
                          <div>
                            <label className='form-label'>Comment</label>
                            <textarea
                              name='comment'
                              placeholder=''
                              className='form-control form-control-solid'
                              id='comment'
                              rows={2}
                              ref={commentRef}
                            />
                          </div>
                        )}
                        <br />
                        {item.allowPhoto && openPhoto && (
                          <div style={{padding: '15px 0px'}}>
                            <Dropzone multiple={false} onDrop={handleDrop}>
                              {({getRootProps, getInputProps}) => (
                                <div {...getRootProps({className: 'dropzone'})}>
                                  <input {...getInputProps()} />
                                  <a className='btn btn-sm btn-primary w-100'>Attach files</a>
                                </div>
                              )}
                            </Dropzone>
                            {fileNames.map((fileName) => (
                              <li key={fileName}>
                                {fileName}{' '}
                                {currentFile?.name === fileName && (
                                  <ProgressBar animated now={progress} style={{marginTop: '2px'}} />
                                )}
                                {uploadedFileNames.includes(fileName) && (
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen043.svg'
                                    className='svg-icon-4 svg-icon-success '
                                  />
                                )}
                              </li>
                            ))}
                          </div>

                          // <div>
                          //   <label className='form-label'>File</label>
                          //   <input
                          //     type='file'
                          //     className='form-control form-control-solid'
                          //     id='file'
                          //     name='file'
                          //     onChange={(e) => {
                          //       if (!e.target.files) return
                          //     }}
                          //   />
                          // </div>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              )
            })}
        </tbody>
      </table>
      <div className='modal-footer'>
        <button
          type='button'
          onClick={() => {
            setCurrentIndex(0)
            closeModal()
          }}
          className='btn btn-light'
          data-bs-dismiss='modal'
          id='check_list_wizard_dismiss'
        >
          Close
        </button>
        {/* {currentIndex > 0 && (
              <button
                type='button'
                onClick={() => {
                  setCurrentIndex(currentIndex - 1)
                }}
                className='btn btn-light'

                // data-bs-dismiss='modal'
                // id='check_list_wizard_dismiss'
              >
                Back
              </button>
            )} */}
        <button
          type='button'
          onClick={async (e: any) => {
            if (!isTestMode) {
              await submitItem(items[currentIndex])
            }

            setOpenPhoto(false)
            setOpenComment(false)
            setSelectedFiles([])
            setUploadedFileNames([])
            setCurrentFile(null)
            setProgress(0)
            setFileNames([])

            if (currentIndex + 1 !== items.length) {
              setCurrentIndex(currentIndex + 1)
            } else {
              setCurrentIndex(0)
              closeModal()
            }
          }}
          className='btn btn-lg btn-primary'
          disabled={
            !_.find(values, (o) => o.index === currentIndex) &&
            items[currentIndex].itemType !== CheckListItemTypes.SectionHeader
          }
        >
          {!isSubmitting && (
            <span className='indicator-label'>
              {currentIndex + 1 !== items.length ? 'Next' : 'Finish'}
            </span>
          )}
          {isSubmitting && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default CheckListItemRenderer
