/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'

export function UserCheckInFilters(props: any) {
  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fs-5 fw-bold mb-3'>StartDate</label>
          <div>
            <input
              name='startDate'
              type='datetime-local'
              className='form-control form-control-solid'
              id='startDate'
              onChange={props.handleStartDateChange}
              value={props.startDate}
            />
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-label fs-5 fw-bold mb-3'>End Date</label>
          <div>
            <input
              name='endDate'
              type='datetime-local'
              className='form-control form-control-solid'
              id='endDate'
              onChange={props.handleEndDateChange}
              value={props.endDate}
            />
          </div>
        </div>

        <div className='d-flex justify-content-end'>
          <a
            type='reset'
            className='btn btn-sm btn-white btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            onClick={props.reset}
          >
            Reset
          </a>

          <a
            onClick={props.refresh}
            type='submit'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
          >
            Apply
          </a>
        </div>
      </div>
    </div>
  )
}
