import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetFilter: '[BatchJob SetFilter] Action',
    ResetFilter: '[BatchJob ResetFilter] Action',
}

const initialBatchJobState: IBatchJobState = {
    filter: {
        status: '',
        type: ''
    },
}

export interface IBatchJobState {
    filter?: {
        status: string
        type: string
    }
}

export const reducer = persistReducer(
    { storage, key: 'v100-BatchJob', whitelist: ['filter'] },
    (
        state: IBatchJobState = initialBatchJobState,
        action: ActionWithPayload<IBatchJobState>
    ) => {
        switch (action.type) {
            case actionTypes.ResetFilter: {
                return initialBatchJobState
            }

            case actionTypes.SetFilter: {
                const filter = action.payload?.filter
                return { ...state, filter }
            }

            default:
                return state
        }
    }
)

export const Actions = {
    resetFilter: () => ({ type: actionTypes.ResetFilter }),
    setFilter: (filter: object) => ({
        type: actionTypes.SetFilter,
        payload: { filter },
    }),
}

export function* saga() { }
