//TO DO: Add other permissions to avoid just string comparison on components
export enum Permissions {
    VIEW_MENU_WORKORDERS = 'workOrder.view.menu',
    VIEW_MENU_MESSAGES = 'messages.view.menu',
    VIEW_MENU_CHECKINS = 'checkIns.view.menu',
    VIEW_MENU_LIBRARY = 'library.view.menu',
    VIEW_MENU_SITES = 'sites.view.menu',
    VIEW_MENU_USERS = 'users.view.menu',
    VIEW_MENU_NOTICES = 'notices.view.menu',
    VIEW_MENU_INVITATIONS = 'invitations.view.menu',
    VIEW_MENU_CLIENTS = 'clients.view.menu',
    VIEW_MENU_CONTRACTORS = 'contractors.view.menu',
    VIEW_MENU_ASSETS = 'assets.view.menu',
    VIEW_MENU_CONTRACTS = 'contracts.view.menu',
    VIEW_MENU_SCHEDULES = 'schedules.view.menu',
    VIEW_MENU_ASSET_CATEGORIES = 'assetCategories.view.menu',
    VIEW_MENU_CONTRACT_CATEGORIES = 'contractCategories.view.menu',
    FM_MODULE_MENU = 'fmModule.menu',
    VIEW_MENU_ROLES = 'roles.view.menu',
    VIEW_MENU_ACCOUNT_MAPPINGS = 'accountMappings.view.menu',
    VIEW_MENU_CHECKLIST_TEMPLATES = 'checkListTemplates.view.menu',
    VIEW_MENU_BUSINESS_TYPES = 'businessTypes.view.menu',
    VIEW_MENU_DOCUMENT_TYPES = 'documentTypes.view.menu',
    VIEW_MENU_SITE_TYPES = 'siteTypes.view.menu',
    VIEW_MENU_NOTICE_TYPES = 'noticeTypes.view.menu',
    VIEW_MENU_LEAVE_SITE_REASON = 'leaveSiteReason.view.menu',
    VIEW_MENU_WORK_ORDER_STATUS = 'workOrderStatus.view.menu',
    VIEW_MENU_STATUS_FLOW = 'statusFlow.view.menu',
    VIEW_MENU_QUOTE_STATUS = 'quoteStatus.view.menu',
    VIEW_MENU_PROPOSAL = 'proposal.view.menu',
    VIEW_MENU_PROPOSAL_SECTION = 'proposal.section.view.menu',
    VIEW_MENU_ACCOUNT = 'account.view.menu',
    VIEW_MENU_WORK_ORDER_TYPES = 'workOrderTypes.view.menu',
    VIEW_MENU_PRIORITIES = 'priorities.view.menu',
    VIEW_MENU_WORKSCOPE = 'workScope.view.menu',
    VIEW_MENU_CHARGE_TYPES = 'chargeTypes.view.menu',
    VIEW_MENU_DASHBOARD = 'dashboard.view.menu',
    VIEW_MENU_CALENDAR = 'calendar.view.menu',
    VIEW_MENU_BATCH_JOBS = 'batch.jobs.view.menu',
    MANAGE_WORKORDER_MESSAGE = 'workOrder.message',
    VIEW_MENU_HAZARD_STATUS = 'hazardStatus.view.menu',
    VIEW_MENU_HAZARD_TYPE = 'hazardType.view.menu',
    VIEW_MENU_EXCLUSIONS = 'exclusions.view.menu',

    VIEW_ALL_HAZARD = 'hazards.view.all',
    MANAGE_HAZARD = 'hazards.modify',
    VIEW_MENU_HAZARD = 'hazards.view.menu',

    VIEW_ALL_INCIDENT = 'incidents.view.all',
    MANAGE_INCIDENT = 'incidents.modify',
    VIEW_MENU_INCIDENT = 'incidents.view.menu',

    VIEW_ALL_INCIDENT_STATUS = 'incidentStatus.view.all',
    MANAGE_INCIDENT_STATUS = 'incidentStatus.modify',
    VIEW_MENU_INCIDENT_STATUS = 'incidentStatus.view.menu',
    VIEW_ALL_INCIDENT_CAUSE = 'incidentCause.view.all',
    MANAGE_INCIDENT_CAUSE = 'incidentCause.modify',
    VIEW_MENU_INCIDENT_CAUSE = 'incidentCause.view.menu',
    VIEW_MENU_INCIDENT_TYPE = 'incidentType.view.menu',
    VIEW_ALL_INCIDENT_TYPE = 'incidentType.view.all',
    MANAGE_INCIDENT_TYPE = 'incidentType.modify',

    VIEW_MENU_ACTION_TYPE = 'actionType.view.menu',
    VIEW_ALL_ACTION_TYPE = 'actionType.view.all',
    MANAGE_ACTION_TYPE = 'actionType.modify',

    VIEW_ALL_ACTION_STATUS = 'actionStatus.view.all',
    MANAGE_ACTION_STATUS = 'actionStatus.modify',
    VIEW_MENU_ACTION_STATUS = 'actionStatus.view.menu',

    VIEW_ALL_RATECARDS = 'rateCards.view.all',
    MANAGE_RATECARDS = 'rateCards.modify',
    VIEW_MENU_RATECARDS = 'rateCards.view.menu',
    VIEW_MENU_INVOLVEMENTTYPES = 'involvement-types.view.menu',

    MANAGE_CHECKINS = 'checkIns.modify',
}
