import React, {FC, useEffect, useState} from 'react'
import Moment from 'react-moment'
import {CheckInsClient, CheckInsQueryParams} from '../../../../infrastracture/api/CheckInsClient'

interface Props {
  toggleChecked: (e: boolean) => void
  onCommentChanged: (e: string) => void
  workOrderId: string
  checkInId: string
}

const Step4: React.FC<Props> = ({toggleChecked, onCommentChanged, workOrderId, checkInId}) => {
  const [checked, setChecked] = useState(false)
  const [checkIns, setCheckIns] = useState([])

  useEffect(() => {
    if (checkInId) fetchCheckIns()
  }, [checkInId])

  const fetchCheckIns = async () => {
    var query = new CheckInsQueryParams()

    var response = await CheckInsClient.List(
      query
        .Paginate(0, 99999)
        .WithWorkOrderId(workOrderId)
        .WithCommentsOnly(true)
        .Sort('timeStamp', 'desc')
    )

    if (!response.authenticated) {
    } else {
      const {data} = response
      setCheckIns(data?.data)
    }
  }

  return (
    <>
      <div className='w-100'>
        <div>
          {/* <h2 className='fw-bolder text-dark'>Confirm Check In</h2> */}
          <h3 className='fw-bolder text-dark'>
            Any other safety considerations identified prior to commencing work?
          </h3>

          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input pe-2'
              type='checkbox'
              value=''
              id='checked'
              name='checked'
              checked={checked}
              onChange={(e: any) => {
                const checked = e.target.checked
                setChecked(checked)
                toggleChecked(checked)

                if (!checked) onCommentChanged('')
              }}
            />
            <label className='form-check-label pe-2'>{checked ? 'Yes' : 'No'}</label>
          </div>
          {checked && (
            <>
              <label className='form-label pt-3'>Comment</label>
              <textarea
                name='comment'
                placeholder=''
                className='form-control form-control-solid'
                id='comment'
                rows={3}
                onChange={(e: any) => onCommentChanged(e.target.value)}
              />
            </>
          )}
          {checkIns.length > 0 && (
            <h5 className='fw-bolder text-dark pt-3'>Other safety considerations</h5>
          )}
          <ul>
            {checkIns.length > 0 &&
              checkIns.map((item: any) => {
                return (
                  <li key={item.id}>
                    <b>
                      {item.user?.name} {item.user?.surname}{' '}
                    </b>
                    <i>
                      <Moment utc local format='DD MMM, YYYY h:mm a'>
                        {item.timeStamp}
                      </Moment>
                    </i>
                    <br /> - "{item.comment}"
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </>
  )
}

export {Step4}
