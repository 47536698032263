import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class DashboardQueryParams extends ListQueryParams {
    WithDate(value: any) {
        this.WithParam('date', value)
        return this
    }
    WithSiteId(value: any) {
        this.WithParam('siteId', value)
        return this
    }
    WithClientId(value: any) {
        this.WithParam('clientId', value)
        return this
    }
    WithAssignedTo(value: any) {
        this.WithParam('assignedToId', value)
        return this
    }
    WithTenantId(value: any) {
        this.WithParam('tenantId', value)
        return this
    }
    WithCurrent(value: any) {
        this.WithParam('current', value)
        return this
    }
}

export class DashboardClient {
    static async GetWorkOrderStatusView(params: DashboardQueryParams) {
        return await ApiClient.Get('/dashboard/wo-status-view' + params.GenerateQueryString());
    }

    static async GetActiveWorkOrdersByCompany(params: DashboardQueryParams) {
        return await ApiClient.Get('/dashboard/wo-by-company' + params.GenerateQueryString());
    }

    static async GetCheckInTimeline(params: DashboardQueryParams) {
        return await ApiClient.Get('/dashboard/check-in-timeline' + params.GenerateQueryString());
    }

    static async GetCheckInRealtime(params: DashboardQueryParams) {
        return await ApiClient.Get('/dashboard/check-in-realtime' + params.GenerateQueryString());
    }

    static async GetWorkOrdersDueDateConflict(params: DashboardQueryParams) {
        return await ApiClient.Get('/dashboard/wo-duedate-conflict' + params.GenerateQueryString());
    }
}
