import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class CheckInNoticesQueryParams extends ListQueryParams {
    WithCheckInId(value: any) {
        this.WithParam('checkInId', value)
        return this
    }
    WithNoticeId(value: any) {
        this.WithParam('noticeId', value)
        return this
    }
}

export class CheckInNoticesClient {
    static async List(params: CheckInNoticesQueryParams) {
        return await ApiClient.Get('/check-in-notices' + params.GenerateQueryString());

    }

    static async Create(checkInId: string, noticeId: string) {
        return await ApiClient.Post('/check-in-notices/', {
            checkInId,
            noticeId,
        });
    }
}
