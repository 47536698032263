import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { ReviewsClient } from '../../../infrastracture/api/ReviewsClient'

import {
    AttachmentsClient
} from '../../../infrastracture/api/AttachmentsClient'

import { Field, Form, Formik } from 'formik'
import { Rating } from 'react-simple-star-rating'
import * as Yup from 'yup'
import { SweetAlertWithProp } from '../../../../SweetAlertWithProp'
interface Props {
    handleOk: () => void
    selectedData: any
    mode: string
    viewOnly?: boolean
    workOrderId: string
}

const ReviewsModal: React.FC<Props> = (props) => {
    const { handleOk, selectedData, mode, viewOnly, workOrderId } = props
    console.log(selectedData)
    const [createSuccess, setCreateSuccess] = useState(false)
    const [rating, setRating] = useState(0)
    const params: any = useParams()

    const validationSchema = Yup.object().shape({
        comment: Yup.string().required('Comment is required'),
    })

    const [initialValues, setInitialValues] = useState<any>({})
    useState()

    useEffect(() => {
        if (mode === 'Edit' || mode === 'View') {
            setInitialValues(selectedData)
            setRating(selectedData.rating)
        } else
            setInitialValues({
                comment: '',
                rating: 0,
                file: '',
                fileName: '',
            })
    }, [selectedData, mode])

    const handleRating = (rate: any) => {
        setRating(rate)
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setStatus, setSubmitting, setFieldError }) => {
                    var response: any

                    if (mode === 'Edit') {
                        response = await ReviewsClient.Update(
                            selectedData.id,
                            workOrderId,
                            values.comment,
                            rating
                        )
                    } else {
                        response = await ReviewsClient.Create(workOrderId, values.comment, rating)
                    }

                    if (values.file && values.fileName)
                        await AttachmentsClient.Create(
                            params.id,
                            null,
                            null,
                            null,
                            null,
                            null,
                            values.fileName,
                            values.file,
                            null,
                            response.data.id,
                            false,
                            false
                        )

                    if (!response.successful) {
                        //show error
                    } else {
                        setSubmitting(false)
                        document.getElementById('rd_Reviews_modal_dismiss')!.click()
                        setCreateSuccess(true)
                    }
                }}
                enableReinitialize
            >
                {({ errors, touched, isSubmitting, values, setFieldValue, resetForm }: any) => (
                    <>
                        <SweetAlertWithProp
                            success
                            title='Success'
                            onConfirm={() => {
                                setCreateSuccess(false)
                                handleOk()
                            }}
                            confirmBtnText='Confirm'
                            cancelBtnBsStyle='light'
                            show={createSuccess}
                            showCancel={false}
                            showCloseButton
                        >
                            {mode} Reviews success
                        </SweetAlertWithProp>
                        <div className='modal fade' tabIndex={-1} id='Reviews_modal'>
                            <Form>
                                <div className='modal-dialog modal-lg modal-dialog-centered'>
                                    <div className='modal-content'>
                                        <div className='modal-header'>
                                            <h5 className='modal-title'>{mode} Review</h5>

                                            <div
                                                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                                data-bs-dismiss='modal'
                                                aria-label='Close'
                                            >
                                                <span className='svg-icon svg-icon-2x'></span>
                                            </div>
                                        </div>

                                        <div className='modal-body'>
                                            <div
                                                className='scroll-y me-n7 pe-7'
                                                id='kt_modal_add_customer_scroll'
                                                data-kt-scroll='true'
                                                style={{ maxHeight: '450px' }}
                                            >
                                                <table
                                                    className='scroll-y table table-row-dashed gy-5'
                                                    id='kt_table_users_login_session'
                                                >
                                                    <tbody className='fs-6 fw-bold text-gray-600'>
                                                        <tr>
                                                            <td>
                                                                <label className='required form-label'>Comment</label>
                                                                <Field
                                                                    name='comment'
                                                                    placeholder=''
                                                                    className='form-control form-control-solid'
                                                                    id='comment'
                                                                    rows='5'
                                                                    component='textarea'
                                                                    disabled={viewOnly}
                                                                />
                                                                {touched.name && errors.name && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <span className='text-danger' role='alert'>
                                                                            {errors.name}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <label className='required form-label'>Rating</label> <br />
                                                                <Rating
                                                                    onClick={handleRating}
                                                                    ratingValue={rating} /* Rating Props */
                                                                />
                                                            </td>
                                                        </tr>
                                                        <br />
                                                        Attachments
                                                        {(mode === 'View' || mode === 'Edit') &&
                                                            selectedData.attachments &&
                                                            selectedData.attachments.map((item: any) => (
                                                                <>
                                                                    {item.fileName.match(/.(jpg|jpeg|png|gif)$/i) ? (
                                                                        <tr>
                                                                            <td>
                                                                                <div style={{ marginBottom: '5px', textAlign: 'center' }}>
                                                                                    <img
                                                                                        src={item.uri}
                                                                                        alt='Image'
                                                                                        style={{ maxHeight: '450px', maxWidth: '450px' }}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        <tr>
                                                                            <td>
                                                                                <div
                                                                                    className='input-group input-group-solid'
                                                                                    style={{ marginBottom: '5px' }}
                                                                                >
                                                                                    <span style={{ margin: 'auto' }}>
                                                                                        <KTSVG
                                                                                            path='/media/icons/duotune/files/fil003.svg'
                                                                                            className='svg-icon svg-icon-2x svg-icon-primary me-1'
                                                                                        />
                                                                                    </span>
                                                                                    <input
                                                                                        readOnly
                                                                                        type='text'
                                                                                        className='form-control form-control-solid'
                                                                                        value={item.name}
                                                                                    />
                                                                                    <span
                                                                                        className='input-group-text'
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => {
                                                                                            const link = document.createElement('a')
                                                                                            link.href = item.Uri
                                                                                            link.download = item.name
                                                                                            link.click()
                                                                                        }}
                                                                                    >
                                                                                        <KTSVG
                                                                                            path='/media/icons/duotune/arrows/arr004.svg'
                                                                                            className='svg-icon-3 svg-icon-dark'
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </>
                                                            ))}
                                                        {(mode === 'View' || mode === 'Edit') && !selectedData.attachments && (
                                                            <>No Attachments</>
                                                        )}
                                                        {mode === 'Add' && (
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        <label className='form-label'>File</label>
                                                                        <input
                                                                            type='file'
                                                                            className='form-control form-control-solid'
                                                                            id='file'
                                                                            name='file'
                                                                            onChange={(e) => {
                                                                                if (!e.target.files) return
                                                                                setFieldValue('file', e.target.files[0])
                                                                            }}
                                                                        />
                                                                        {touched.file && errors.file && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <span className='text-danger' role='alert'>
                                                                                    {errors.file}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <label className='form-label'>Name</label>
                                                                        <Field
                                                                            name='fileName'
                                                                            placeholder=''
                                                                            type='text'
                                                                            className='form-control form-control-solid'
                                                                            id='fileName'
                                                                            disabled={viewOnly}
                                                                        />
                                                                        {touched.fileName && errors.fileName && (
                                                                            <div className='fv-plugins-message-container'>
                                                                                <span className='text-danger' role='alert'>
                                                                                    {errors.fileName}
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    resetForm()
                                                }}
                                                className='btn btn-light'
                                                data-bs-dismiss='modal'
                                                id='rd_Reviews_modal_dismiss'
                                            >
                                                Close
                                            </button>
                                            {!viewOnly && (
                                                <button
                                                    type='submit'
                                                    id='rd_create_tenant_submit'
                                                    className='btn btn-lg btn-primary'
                                                    disabled={isSubmitting}
                                                >
                                                    {!isSubmitting && <span className='indicator-label'>Save</span>}
                                                    {isSubmitting && (
                                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                                            Please wait...
                                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                        </span>
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )
}

export default ReviewsModal
