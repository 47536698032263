/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, Dispatch, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {DashboardClient, DashboardQueryParams} from '../../../infrastracture/api/DashboardClient'
import Moment from 'react-moment'
import moment from 'moment'
import {Link, useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import {RootState} from '../../../../setup'
import {Actions} from '../../../modules/work-order/redux/WorkOrderRedux'

type Props = {
  dispatch: Dispatch<any>
  workorder: any
  tenantId: string
}

const WorkOrderDueDateConflict: React.FC<Props> = (props) => {
  const {workorder, dispatch, tenantId} = props
  const [itemsList, setItemsList] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    if (tenantId) fetchData()

    //fetch data every 2 mins
    const interval = setInterval(() => {
      fetchData()
    }, 120000)

    return () => clearInterval(interval)
  }, [tenantId])

  const fetchData = async () => {
    setLoading(true)
    var query = new DashboardQueryParams()

    var response: any

    if (tenantId !== 'all') {
      response = await DashboardClient.GetWorkOrdersDueDateConflict(
        query.Paginate(0, 99999).WithAssignedTo(tenantId).WithTenantId(tenantId)
      )
    } else {
      response = await DashboardClient.GetWorkOrdersDueDateConflict(query.Paginate(0, 99999))
    }

    if (!response.authenticated) {
    } else {
      const {data} = response
      setItemsList(data.data)
    }
    setLoading(false)
  }

  const addDefaultSrc = (ev: any) => {
    ev.target.src = toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm004.svg')
  }

  return (
    <div className={`card `}>
      <div className={`card-header border-0 py-5`}>
        <h3 className='card-title fw-bolder'>WO Due Date Conflicts</h3>

        <div className='card-toolbar'>
          <span className='badge badge-primary'>{itemsList.length}</span>
        </div>
      </div>

      <div className='card-body pt-2' style={{height: '500px', overflowY: 'auto'}}>
        {loading && (
          <div className='d-flex align-items-center'>
            <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
          </div>
        )}
        {!loading && !itemsList.length && (
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <tbody>
              <tr>
                <td colSpan={3}>
                  <div className='fw-bolder text-gray-800 ps-3 text-center'>No Data</div>
                </td>
              </tr>
            </tbody>
          </table>
        )}

        {!loading && itemsList.length > 0 && (
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th>WO #</th>
                <th>Due Date</th>
                <th>Date on Site</th>
              </tr>
            </thead>

            {itemsList.length > 0 &&
              itemsList.map((item: any) => (
                <tbody>
                  <tr>
                    <td>
                      <Link
                        className='text-dark fw-bolder d-block fs-7'
                        to={`/work-orders/details/` + item?.id}
                      >
                        {` ${item.workOrderNumberId}`}
                      </Link>
                    </td>

                    <td>
                      <span className='text-dark fw-bold d-block fs-7'>
                        {item.dueDate && (
                          <Moment utc local format='DD MM YYYY h:mm a'>
                            {item.dueDate}
                          </Moment>
                        )}

                        {!item.dueDate && <i className='fw-bold d-block fs-7'>Not Specified</i>}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold d-block fs-7'>
                        {item.expectedOnSite && (
                          <Moment utc local format='DD MM YYYY h:mm a'>
                            {item.expectedOnSite}
                          </Moment>
                        )}
                        {!item.expectedOnSite && (
                          <i className='fw-bold d-block fs-7'>Not Specified</i>
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        )}
      </div>
    </div>
  )
}

export default connect(({workorder}: RootState) => ({
  workorder,
}))(WorkOrderDueDateConflict)
