import React, { useState } from 'react'

import { toAbsoluteUrl } from '../../../_metronic/helpers'

interface Props {
    sort: string
    direction: string
    columns?: any
    onUpdate?: (sort: string, direction: string) => void
}

const TableHeader: React.FC<Props> = (props: any) => {
    const { sort: propsSort, direction: propsDirection } = props

    const [sort, setSort] = useState(propsSort)
    const [direction, setDirection] = useState(propsDirection)

    const headerClicked = (sort: any) => {
        const { onUpdate } = props

        var newDirection = direction === 'desc' ? 'asc' : 'desc'
        setDirection(newDirection)
        setSort(sort)

        if (onUpdate) onUpdate(sort, newDirection)
    }

    const { columns } = props

    return (
        <thead>
            <tr className='fw-bolder text-muted bg-light'>
                {columns
                    .filter((f: any) => !f.disabled)
                    .map((item: any, idx: any) => (
                        <th
                            key={item.name + '-' + idx}
                            className={item.static ? '' : 'clickable'}
                            style={
                                item.width ? { width: `${item.width}%`, borderTop: 'none' } : { borderTop: 'none' }
                            }
                            onClick={() => {
                                if (!item.static) headerClicked(item.sort)
                            }}
                        >
                            {item.name}
                            {!item.static && item.sort === sort && (
                                <span>
                                    &nbsp;
                                    {direction === 'desc' && (
                                        <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr072.svg')} alt="Direction descending indicator" />
                                    )}
                                    {direction !== 'desc' && (
                                        <img src={toAbsoluteUrl('/media/icons/duotune/arrows/arr073.svg')} alt="Direction ascending indicator" />
                                    )}
                                </span>
                            )}
                        </th>
                    ))}
            </tr>
        </thead>
    )
}

export { TableHeader }
