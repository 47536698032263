import React, {useState, useEffect} from 'react'
import {Col, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Moment from 'react-moment'
import * as Yup from 'yup'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'
import {Field, Formik, Form} from 'formik'
import UserSelector from '../../../common/components/selectors/UserSelector'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {WorkOrderClient} from '../../../infrastracture/api/WorkOrderClient'
import _ from 'lodash'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

interface Props {
  handleOk: () => void
  handleClose: () => void
  show: boolean
  workOrder: any
}

const WorkOrderMessageModal: React.FC<Props> = (props) => {
  const {handleClose, handleOk, show, workOrder} = props

  const [loading, setLoading] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [selectedData, setSelectedData] = useState<any>({})

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This field is required'),
    message: Yup.string().required('This field is required'),
  })

  const initialState = {
    title: '',
    message: '',
    notification: true,
    email: false,
    tenantId: null,
    tenant: {} as any,
    clientUsers: null,
    contractorUsers: null,
  }

  const [initialValues, setInitialValues] = useState<any>(initialState)
  const [showSuccess, setShowSuccess] = useState(false)
  return (
    <>
      <SweetAlertWithProp
        success
        title='Success'
        onConfirm={() => {
          handleOk()
          setShowSuccess(false)
        }}
        confirmBtnText='Confirm'
        cancelBtnBsStyle='light'
        show={showSuccess}
        showCancel={false}
        showCloseButton
      >
        Send message success!
      </SweetAlertWithProp>
      <Modal isOpen={show} onHide={() => {}} zIndex={1000} size='lg'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: any, {setStatus, setSubmitting, setFieldError}: any) => {
            console.log({values})
            const {message, title, notification, email, clientUsers, contractorUsers} = values
            debugger
            var userIds: any = []

            if (clientUsers) {
              clientUsers.map((user: any) => userIds.push(user.value))
            }

            if (contractorUsers) {
              contractorUsers.map((user: any) => userIds.push(user.value))
            }

            var response = await WorkOrderClient.SendMessage(
              workOrder.id,
              title,
              message,
              notification,
              email,
              _.uniq(userIds)
            )

            if (!response.successful) {
              //show error
            } else {
              setShowSuccess(true)
            }
          }}
          enableReinitialize
        >
          {({errors, touched, isSubmitting, values, setFieldValue}: any) => (
            <>
              <Form>
                <ModalHeader closeButton>
                  <h5 className='modal-title'>Message</h5>
                </ModalHeader>
                <ModalBody>
                  <div className='card-body'>
                    <table className='table align-middle gs-0 gy-4'>
                      {!loading && (
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <label className='required form-label'>Title</label>
                              <Field
                                name='title'
                                placeholder='Title'
                                type='text'
                                className='form-control form-control-solid'
                                id='title'
                              />
                              {touched.title && errors.title && (
                                <div className='fv-plugins-message-container'>
                                  <span className='text-danger' role='alert'>
                                    {errors.title}
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <label className='required form-label'>Message</label>

                              <textarea
                                name='message'
                                placeholder=''
                                className='form-control form-control-solid'
                                id='message'
                                onChange={(e) => {
                                  var value = e.target.value
                                  setFieldValue('message', value)
                                }}
                                rows={5}
                                defaultValue={values.message || ''}
                              ></textarea>
                              {touched.message && errors.message && (
                                <div className='fv-plugins-message-container'>
                                  <span className='text-danger' role='alert'>
                                    {errors.message}
                                  </span>
                                </div>
                              )}
                            </td>
                          </tr>{' '}
                          <tr>
                            <td>
                              <div className='form-check form-switch form-check-custom form-check-solid'>
                                <label className='form-label pe-2'>Notification</label>
                                <Field
                                  className='form-check-input pe-2'
                                  type='checkbox'
                                  value=''
                                  id='notification'
                                  name='notification'
                                  checked={values.notification}
                                />
                                <span className='ps-2'>{values.notification ? 'Yes' : 'No'}</span>
                              </div>
                            </td>
                            <td>
                              <div className='form-check form-switch form-check-custom form-check-solid'>
                                <label className='form-label pe-2'>Email</label>
                                <Field
                                  className='form-check-input pe-2'
                                  type='checkbox'
                                  value=''
                                  id='email'
                                  name='email'
                                  checked={values.email}
                                />
                                <span className='ps-2'>{values.email ? 'Yes' : 'No'}</span>
                              </div>
                            </td>
                          </tr>
                          {/* <tr>
                          <td colSpan={2}>
                            <label className='required form-label'>Audience</label>

                            <TenantSelector
                              placeholder='Select Tenant'
                              defaultValue={values.tenant}
                              onChange={(defaultId: any, value: any, label: any) => {
                                setFieldValue('tenantId', value)
                                setFieldValue('tenant', {value, label})
                              }}
                              id='tenantId'
                              tenantTypeIds={[TenantTypeIds.Client, TenantTypeIds.Contractor]}
                            />

                            {touched.tenantId && errors.tenantId && (
                              <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>
                                  {errors.tenantId}
                                </span>
                              </div>
                            )}
                          </td>
                        </tr> */}
                          {workOrder.tenantId &&
                            user.tenant.tenantTypeId.toUpperCase() !== TenantTypeIds.Contractor && (
                              <tr>
                                <td colSpan={2}>
                                  <label className='form-label'>Client Users</label>

                                  <UserSelector
                                    placeholder='Select User'
                                    defaultValue={values.clientUsers}
                                    onChange={(name: any, value: any) => {
                                      setFieldValue('clientUsers', value)
                                    }}
                                    tenantId={workOrder.tenantId}
                                    isMulti
                                  />

                                  {touched.clientUsers && errors.clientUsers && (
                                    <div className='fv-plugins-message-container'>
                                      <span className='text-danger' role='alert'>
                                        {errors.clientUsers}
                                      </span>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            )}
                          {workOrder.assignedToTenantId && (
                            <tr>
                              <td colSpan={2}>
                                <label className='form-label'>Contractor Users</label>

                                <UserSelector
                                  placeholder='Select User'
                                  defaultValue={values.contractorUsers}
                                  onChange={(name: any, value: any) => {
                                    setFieldValue('contractorUsers', value)
                                  }}
                                  tenantId={workOrder.assignedToTenantId}
                                  isMulti
                                />

                                {touched.contractorUsers && errors.contractorUsers && (
                                  <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                      {errors.contractorUsers}
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    type='button'
                    onClick={() => {
                      handleClose()
                    }}
                    className='btn btn-light'
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary'
                    disabled={
                      isSubmitting ||
                      (!values.notification && !values.email) ||
                      (!values.contractorUsers && !values.clientUsers)
                    }
                  >
                    {!isSubmitting && <span className='indicator-label'>Send</span>}
                    {isSubmitting && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </ModalFooter>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default WorkOrderMessageModal
