import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class WorkOrderStatusQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithExcludeStatusFlowId(value: string) {
        this.WithParam('excludeStatusFlowId', value)
        return this
    }
    WithStatusFlowId(value: string) {
        this.WithParam('statusFlowId', value)
        return this
    }
}

export class WorkOrderStatusClient {
    static async List(params: WorkOrderStatusQueryParams) {
        return await ApiClient.Get('/work-order-status' + params.GenerateQueryString());

    }

    static async Get(id: string) {
        return await ApiClient.Get('/work-order-status/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/work-order-status/' + id);
    }

    static async Create(
        name: string,
        description: string,
        active: boolean,
        isDefault: boolean,
        required: boolean,
        color?: string
    ) {
        return await ApiClient.Post('/work-order-status/', {
            name: name,
            description: description,
            active: active,
            default: isDefault,
            color: color,
            required,
        });
    }

    static async Update(
        id: string,
        name: string,
        description: string,
        active: boolean,
        isDefault: boolean,
        required: boolean,
        color?: string
    ) {

        return await ApiClient.Patch('/work-order-status/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
            {
                op: 'replace',
                path: 'default',
                value: isDefault,
            },
            {
                op: 'replace',
                path: 'color',
                value: color,
            },
            {
                op: 'replace',
                path: 'required',
                value: required,
            },
        ]);
    }
}
