import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class ClientPrioritySettingsQueryParams extends ListQueryParams {
    WithClientId(value: string) {
        this.WithParam('clientId', value)
        return this
    }
    WithPriorityId(value: string) {
        this.WithParam('priorityId', value)
        return this
    }
}

export class ClientPrioritySettingsClient {
    static async List(params: ClientPrioritySettingsQueryParams) {
        return await ApiClient.Get('/client-priority-setting' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/client-priority-setting/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/client-priority-setting/' + id);
    }

    static async Create(days: number, fee: any, clientId: string, priorityId: string) {
        return await ApiClient.Post('/client-priority-setting/', {
            days,
            fee,
            clientId,
            priorityId,
        });
    }

    static async Update(id: string, days: number, fee: any) {
        return await ApiClient.Patch('/client-priority-setting/' + id, [
            {
                op: 'replace',
                path: 'days',
                value: days,
            },
            {
                op: 'replace',
                path: 'fee',
                value: fee,
            },
        ]);
    }
}
