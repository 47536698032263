import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'

export class StatusFlowsQueryParams extends ListQueryParams {
    WithActive(value: boolean) {
        this.WithParam('active', value)
        return this
    }
    WithStatusFlowId(value: string) {
        this.WithParam('statusFlowId', value)
        return this
    }
    WithWorkOrderTypeId(value: string) {
        this.WithParam('workOrderTypeId', value)
        return this
    }
    WithIsDefault(value: boolean) {
        this.WithParam('isDefault', value)
        return this
    }
}

export class StatusFlowsClient {
    static async List(params: StatusFlowsQueryParams) {
        return await ApiClient.Get('/status-flows' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/status-flows/' + id);
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/status-flows/' + id);
    }

    static async Create(name: string, description: string, active: boolean, isdefault: boolean,
        myobType: string,
        weight: number,
        defaultAssignedToTenantId?: string,
        defaultOwnerId?: string,
        defaultStatusFlowId?: string,
        clientReadyStatusFlowId?: string,
        pricingReceivedStatusFlowId?: string) {


        return await ApiClient.Post('/status-flows/', {
            name: name,
            description: description,
            active: active,
            default: isdefault,
            myobStatusFlowType: myobType,
            weight: weight,
            defaultAssignedToTenantId: defaultAssignedToTenantId,
            defaultOwnerForMyobUserId: defaultOwnerId,
            defaultStatusFlowId: defaultStatusFlowId,
            clientReadyStatusFlowId: clientReadyStatusFlowId,
            pricingReceivedStatusFlowId: pricingReceivedStatusFlowId
        });
    }

    static async Update(
        id: string,
        name: string,
        description: string,
        active: boolean,
        isdefault: boolean,
        myobType: string,
        weight: number,
        defaultAssignedToTenantId?: string,
        defaultOwnerId?: string,
        defaultStatusFlowId?: string,
        clientReadyStatusFlowId?: string,
        pricingReceivedStatusFlowId?: string
    ) {

        return await ApiClient.Patch('/status-flows/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'description',
                value: description,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
            {
                op: 'replace',
                path: 'default',
                value: isdefault,
            }, {
                op: 'replace',
                path: 'myobStatusFlowType',
                value: myobType,
            },
            {
                op: 'replace',
                path: 'weight',
                value: weight,
            },
            {
                op: 'replace',
                path: 'defaultAssignedToTenantId',
                value: defaultAssignedToTenantId,
            },
            {
                op: 'replace',
                path: 'defaultOwnerForMyobUserId',
                value: defaultOwnerId,
            },
            {
                op: 'replace',
                path: 'defaultStatusFlowId',
                value: defaultStatusFlowId,
            },
            {
                op: 'replace',
                path: 'clientReadyStatusFlowId',
                value: clientReadyStatusFlowId,
            },
            {
                op: 'replace',
                path: 'pricingReceivedStatusFlowId',
                value: pricingReceivedStatusFlowId,
            }
        ]);
    }

    static async ListStatus(params: StatusFlowsQueryParams) {
        return await ApiClient.Get('/status-flows/status' + params.GenerateQueryString());
    }

    static async CreateStatusFlowStatus(statusFlowId: string, workOrderStatusId: string) {
        return await ApiClient.Post('/status-flows/status', {
            statusFlowId,
            workOrderStatusId,
        });
    }

    static async UpdateStatusFlowStatusField(id: string, field: string, value: any) {
        return await ApiClient.Patch('/status-flows/status/' + id, [
            {
                op: 'replace',
                path: field,
                value: value,
            },
        ]);
    }

    static async ReOrderStatusFlowStatus(items: any[]) {
        return await ApiClient.Post('/status-flows/status/re-order', items);
    }

    static async DeleteStatusFlowStatus(id: string) {
        return await ApiClient.Delete('/status-flows/status/' + id);
    }

    static async ListType(params: StatusFlowsQueryParams) {
        return await ApiClient.Get('/status-flows/type' + params.GenerateQueryString());
    }

    static async CreateStatusFlowType(statusFlowId: string, workOrderTypeId: string) {
        return await ApiClient.Post('/status-flows/type', {
            statusFlowId,
            workOrderTypeId,
        });
    }

    static async DeleteStatusFlowType(id: string) {
        return await ApiClient.Delete('/status-flows/type/' + id);
    }
}
