import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ChargesClient, ChargesQueryParams} from '../../../infrastracture/api/ChargesClient'
import {Col} from 'reactstrap'

import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import SweetAlert from 'react-bootstrap-sweetalert'
import ChargesList from './ChargesList'
import ChargeTypeSelector from '../../../common/components/selectors/ChargeTypeSelector'
import {ChargesModes} from '../chargesModes'
import {capitalizeFirstChar} from '../../../common/helpers/stringHelper'
import {TenantTypeIds} from '../../../common/constants/tenantTypeIds'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {SweetAlertWithProp} from '../../../../SweetAlertWithProp'

interface Props {
  handleOk: () => void
  selectedData: any
  mode: ChargesModes
  workOrderId: string
  viewOnly?: boolean
}

const ChargesModal: React.FC<Props> = (props) => {
  const {handleOk, workOrderId, selectedData, mode, viewOnly} = props
  const [createSuccess, setCreateSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
  const [isFee, setIsFee] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    value: Yup.string().required('Value is required'),
  })

  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const initialState = {
    name: '',
    value: '',
    chargeTypeId: '',
    chargeType: {} as any,
    excludeFees: true,
  }
  const [initialValues, setInitialValues] = useState(initialState)

  useEffect(() => {
    if (mode === ChargesModes.Edit || mode === ChargesModes.View) {
      setInitialValues({
        ...selectedData,
        chargeType: {
          value: selectedData.chargeTypeId,
          label: selectedData.chargeType?.name,
        },
      })
    } else if (mode === ChargesModes.Add) setInitialValues(initialState)
  }, [selectedData, mode])

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          if (!values.chargeTypeId) {
            setFieldError('chargeType', 'Charge Type is required')
            return
          }
          var response: any

          if (mode === ChargesModes.Edit) {
            response = await ChargesClient.Update(
              selectedData.id,
              values.name,
              values.chargeTypeId,
              values.value,
              values.excludeFees
            )
          } else {
            response = await ChargesClient.Create(
              values.name,
              workOrderId,
              values.chargeTypeId,
              values.value,
              values.excludeFees
            )
          }

          if (!response) {
            //show error
          } else {
            setSubmitting(false)
            document.getElementById('rd_charges_modal_dismiss')!.click()
            setCreateSuccess(true)
          }
        }}
        enableReinitialize
      >
        {({errors, touched, isSubmitting, values, setFieldValue, resetForm}: any) => (
          <>
            <SweetAlertWithProp
              success
              title='Success'
              onConfirm={() => {
                setCreateSuccess(false)
                handleOk()
              }}
              confirmBtnText='Confirm'
              cancelBtnBsStyle='light'
              show={createSuccess}
              showCancel={false}
              showCloseButton
            >
              {mode} Charge Sucess
            </SweetAlertWithProp>

            <SweetAlertWithProp
              warning
              showCancel
              title='Are you sure you want to delete this charge?'
              onConfirm={async () => {
                await ChargesClient.Delete(selectedData.id)

                setShowDelete(false)
                setShowDeleteSuccess(true)
              }}
              onCancel={() => {
                setShowDelete(false)
              }}
              focusCancelBtn
              cancelBtnBsStyle='light'
              show={showDelete}
              confirmBtnText='Yes'
              cancelBtnText='No'
            ></SweetAlertWithProp>

            <SweetAlertWithProp
              success
              showCancel={false}
              title='Success!'
              onConfirm={() => {
                setShowDeleteSuccess(false)
                handleOk()
              }}
              focusCancelBtn
              cancelBtnBsStyle='light'
              show={showDeleteSuccess}
              confirmBtnText='Yes'
              cancelBtnText='No'
            >
              Delete success!
            </SweetAlertWithProp>
            <div className='modal fade' tabIndex={-1} id='charges_modal'>
              <Form>
                <div className='modal-dialog modal-dialog-centered'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title'>{mode} Charge</h5>

                      <div
                        className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      >
                        <span className='svg-icon svg-icon-2x'></span>
                      </div>
                    </div>

                    <div className='modal-body'>
                      <div
                        className='scroll-y me-n7 pe-7'
                        id='kt_modal_add_customer_scroll'
                        data-kt-scroll='true'
                        style={{maxHeight: '450px'}}
                      >
                        <table
                          className='scroll-y table table-row-dashed gy-5'
                          id='kt_table_users_login_session'
                        >
                          <tbody className='fs-6 fw-bold text-gray-600'>
                            <tr>
                              <td>
                                <label className='required form-label'>Charge Type</label>

                                <ChargeTypeSelector
                                  placeholder='Charge Type'
                                  defaultValue={values.chargeType}
                                  onChange={(name: any, value: any, label?: any, fee?: any) => {
                                    setFieldValue('chargeTypeId', value)

                                    if (fee) {
                                      setIsFee(fee)
                                      setFieldValue('excludeFees', true)
                                    } else {
                                      setIsFee(false)
                                    }
                                  }}
                                  disabled={viewOnly}
                                />

                                {touched.chargeType && errors.chargeType && (
                                  <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                      {errors.chargeType}
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className='required form-label'>Description</label>
                                <Field
                                  name='name'
                                  placeholder=''
                                  type='text'
                                  className='form-control form-control-solid'
                                  id='name'
                                  disabled={viewOnly}
                                />
                                {touched.name && errors.name && (
                                  <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                      {errors.name}
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label className='required form-label'>Value (excl gst)</label>
                                <div className='input-group mb-5'>
                                  <span className='input-group-text'>$</span>{' '}
                                  <Field
                                    type='number'
                                    defaultValue={values.value}
                                    value={values.value}
                                    onChange={(e: any) => {
                                      var value = e.target.value
                                      value =
                                        value.indexOf('.') >= 0
                                          ? value.substr(0, value.indexOf('.')) +
                                            value.substr(value.indexOf('.'), 3)
                                          : value

                                      setFieldValue('value', value)
                                    }}
                                    className='form-control form-control-solid'
                                    name='value'
                                    data-kt-element='price'
                                    disabled={viewOnly}
                                  />
                                </div>

                                {touched.value && errors.value && (
                                  <div className='fv-plugins-message-container'>
                                    <span className='text-danger' role='alert'>
                                      {errors.value}
                                    </span>
                                  </div>
                                )}
                              </td>
                            </tr>
                            {user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner &&
                              !isFee && (
                                <tr>
                                  <td>
                                    <div
                                      className='form-check form-switch form-check-custom form-check-solid'
                                      style={{paddingRight: '15px'}}
                                    >
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='excludeFees'
                                        checked={values.excludeFees}
                                        onChange={(e) =>
                                          setFieldValue('excludeFees', e.target.checked)
                                        }
                                        disabled={viewOnly}
                                      />
                                      <label className='form-check-label'>
                                        Exclude Threshold Fees
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>

                        {errorMessage && (
                          <div className='fv-plugins-message-container'>
                            <span className='text-danger' role='alert'>
                              {errorMessage}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='modal-footer'>
                      {viewOnly && !selectedData.chargeType?.isDefault && (
                        <button
                          type='button'
                          onClick={() => {
                            setShowDelete(true)
                          }}
                          className='btn btn-danger'
                          data-bs-dismiss='modal'
                          id='rd_charges_modal_delete'
                        >
                          Delete
                        </button>
                      )}

                      <button
                        type='button'
                        onClick={() => {
                          setErrorMessage('')
                          setInitialValues(initialState)
                          resetForm()
                        }}
                        className='btn btn-light'
                        data-bs-dismiss='modal'
                        id='rd_charges_modal_dismiss'
                      >
                        Close
                      </button>
                      {!viewOnly && (
                        <button
                          type='submit'
                          id='rd_create_tenant_submit'
                          className='btn btn-lg btn-primary'
                          disabled={isSubmitting}
                        >
                          {!isSubmitting && <span className='indicator-label'>Save</span>}
                          {isSubmitting && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}
      </Formik>
    </>
  )
}

export default ChargesModal
