import React, {useState, useEffect} from 'react'
import {Redirect, Route, Switch, Link, useParams, useHistory} from 'react-router-dom'
import {Card, Col} from 'reactstrap'

import * as Yup from 'yup'
import {Formik, Field, Form, FormikHelpers, useFormik, ErrorMessage, yupToFormErrors} from 'formik'
import CheckListItemRenderer from './CheckListItemRenderer'
import {
  CheckListTemplateItemClient,
  CheckListTemplateItemQueryParams,
} from '../../../infrastracture/api/CheckListTemplateItemClient'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {CheckListClient} from '../../../infrastracture/api/CheckListClient'
import {CheckListItemClient} from '../../../infrastracture/api/CheckListItemClient'

interface Props {
  checkListTemplateId?: any
  checkListId?: any //CAN BE USED TO RETRIEVE USER ANSWERS
  isTestMode: boolean
  show: boolean
  handleOk: () => void
  handleClose: () => void
}

const CheckListWizard: React.FC<Props> = (props) => {
  const {checkListTemplateId, checkListId, isTestMode, show, handleClose} = props
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<any[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  })

  useEffect(() => {
    if (show) fetchData()
  }, [checkListTemplateId, checkListId, show])

  const [initialValues, setInitialValues] = useState<any>({})

  const fetchData = async () => {
    try {
      setLoading(true)
      var query = new CheckListTemplateItemQueryParams()

      var response: any

      if (checkListId) {
        response = await CheckListClient.Get(checkListId)
        if (!response.authenticated) {
        } else {
          const {data} = response

          setItems(data?.checkListItems)
        }
      } else if (checkListTemplateId && isTestMode) {
        response = await CheckListTemplateItemClient.List(
          query
            .Paginate(0, 999999)
            .WithCheckListTemplateId(checkListTemplateId)
            .Sort('order', 'asc')
        )
        if (!response.authenticated) {
        } else {
          const {data} = response
          setItems(data?.data)
        }
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, {setStatus, setSubmitting, setFieldError}) => {
          //   var response: any
          //   if (mode === 'Edit') {
          //     response = await ChargeTypesClient.Update(
          //       selectedData.id,
          //       values.name,
          //       values.description,
          //       values.active,
          //       values.singleOnly
          //     )
          //   } else {
          //     response = await ChargeTypesClient.Create(
          //       values.name,
          //       values.description,
          //       values.active,
          //       values.singleOnly
          //     )
          //   }
          //   if (!response.successful) {
          //     //show error
          //   } else {
          //     setSubmitting(false)
          //     document.getElementById('rd_charge_types_modal_dismiss')!.click()
          //     setCreateSuccess(true)
          //   }
        }}
        enableReinitialize
      >
        {({errors, touched, isSubmitting, values, setFieldValue, resetForm}) => (
          <>
            <Modal isOpen={show} onHide={() => {}} zIndex={9999}>
              <ModalHeader closeButton tag='div' cssModule={{width: '100%'}}>
                <table className='w-100'>
                  <tr>
                    <td>
                      <h5>Check List Wizard {isTestMode ? '(Test Mode)' : ''}</h5>
                    </td>
                    <td>
                      {!loading && (
                        <div className='form-label text-end'>
                          {currentIndex + 1} of {items.length}
                        </div>
                      )}
                    </td>
                  </tr>
                </table>
              </ModalHeader>
              <ModalBody>
                {loading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Loading...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : (
                  <Form>
                    <CheckListItemRenderer
                      items={items}
                      setParentIndex={(index: number) => setCurrentIndex(index)}
                      closeModal={handleClose}
                      checkListId={checkListId}
                      isTestMode={isTestMode}
                    />
                  </Form>
                )}
              </ModalBody>
              {/* <ModalFooter>
          <button
            type='button'
            onClick={() => {
              handleClose()
            }}
            className='btn btn-light'
            data-bs-dismiss='modal'
            id='rd_Library_modal_dismiss'
          >
            Close
          </button>
        </ModalFooter> */}
            </Modal>
          </>
        )}
      </Formik>
    </>
  )
}

export default CheckListWizard
