import React, {useEffect, useState} from 'react'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {UserQueryParams, UsersClient} from '../../../infrastracture/api/UsersClient'
import {TableHeader} from '../../../common/components/TableHeader'
import Pagination from 'react-responsive-pagination'
import RecordPerPageSelector from '../../../common/components/selectors/RecordPerPageSelector'
import {
  NoticeSitesClient,
  NoticeSitesQueryParams,
} from '../../../infrastracture/api/NoticeSitesClient'
import ReactMarkdown from 'react-markdown'

interface Props {
  siteId: any
}

const NoticeSitesList: React.FC<Props> = (props) => {
  const {siteId} = props
  const [notices, setNotices] = useState<Array<any>>([])
  const [search, setSearch] = useState('')
  const [selectedData, setSelectedData] = useState<any>(null)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [siteId])

  async function fetchData() {
    setLoading(true)
    try {
      var query = new NoticeSitesQueryParams()

      var response = await NoticeSitesClient.List(
        query.Paginate(0, 99999).Search(search).WithSiteId(siteId).Sort('notice.name', 'asc')
      )

      if (!response.authenticated) {
      } else {
        const {data} = response
        setNotices(data?.data)
      }
      setLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <table width='100%'>
        <tbody>
          <tr>
            <td width={selectedData ? '50%' : '100%'} style={{verticalAlign: 'top'}}>
              <div className={`card`}>
                <div className='card-header'>
                  <div className='card-title'>
                    <div className='d-flex align-items-center position-relative my-1'>
                      <h2 className='text-gray-600'>Notices</h2>
                      {loading && (
                        <div className='d-flex align-items-center'>
                          <span className='spinner-border spinner-border-lg align-middle ms-2'></span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='card-body py-3'>
                  <div className='table-responsive '>
                    <table className='table align-middle gs-0 gy-4'>
                      <TableHeader
                        columns={[
                          {name: 'Notice', sort: 'notice.name', width: '50'},
                          {name: 'Type', sort: 'notice.noticeType.name', width: '50'},
                        ]}
                        sort={'notice.name'}
                        direction={'asc'}
                      />
                      {/* end::Table head */}
                      {/* begin::Table body */}

                      {!loading && (
                        <tbody>
                          {!loading &&
                            notices.map((item) => (
                              <tr
                                key={item.id}
                                onClick={() => {
                                  setSelectedData(item)
                                }}
                                style={{cursor: 'pointer'}}
                              >
                                <td>
                                  {item.notice && (
                                    <a className='text-gray-600 fw-bolder d-block mb-1 fs-6'>
                                      {item.notice?.name}
                                    </a>
                                  )}
                                </td>
                                <td>
                                  <div className='d-flex align-items-center rounded p-5'>
                                    {item.notice?.noticeType?.color && (
                                      <span
                                        className={`bullet bullet-vertical h-30px `}
                                        style={
                                          item.notice.noticeType?.color && {
                                            background: item.notice?.noticeType?.color,
                                          }
                                        }
                                      ></span>
                                    )}{' '}
                                    <div
                                      className='flex-grow-1 me-2'
                                      style={
                                        item.notice?.noticeType?.color && {
                                          paddingLeft: '5px',
                                        }
                                      }
                                    >
                                      <span className='fw-bolder text-gray-800 fs-6'>
                                        {item.notice?.noticeType?.name}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </td>

            <td width={selectedData ? '50%' : '0%'} style={{verticalAlign: 'top'}}>
              <div className='card' style={selectedData ? {} : {display: 'none'}}>
                <div className='card-header'>
                  <h3 className='card-title'>
                    <span style={{paddingRight: '5px'}}>
                      {selectedData?.notice?.noticeType?.name.toUpperCase()}
                    </span>
                  </h3>
                </div>
                <div className='card-body card-scroll'>
                  <h3>{selectedData?.notice?.name.toUpperCase()}</h3>

                  <ReactMarkdown>{selectedData?.notice?.body}</ReactMarkdown>
                  <span className='text-danger mt-2'>
                    <h3 className='text-danger'>{selectedData?.notice && 'IMPORTANT'}</h3>
                    <ReactMarkdown>{selectedData?.notice?.warning}</ReactMarkdown>
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default NoticeSitesList
