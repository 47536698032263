import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SetFilter: '[Assets SetFilter] Action',
  ResetFilter: '[Assets ResetFilter] Action',
}

const initialAssetsState: IAssetsState = {
  filter: {
    clientId: '',
    siteId: '',
    categoryId: '',
    typeId: '',
    ppm: true,
    warranty: null,
    status: 0,
    fiftyLifeCycle: null,
    seventyFiveLifeCycle: null,
    seventyFivePlusLifeCycle: null,
  },
}

export interface IAssetsState {
  filter?: {
    clientId: string
    siteId: string
    categoryId: string
    typeId: string
    ppm: boolean
    warranty: any
    status: any
    fiftyLifeCycle: any
    seventyFiveLifeCycle: any
    seventyFivePlusLifeCycle: any
  }
}

export const reducer = persistReducer(
  {storage, key: 'v100-Assets', whitelist: ['filter']},
  (state: IAssetsState = initialAssetsState, action: ActionWithPayload<IAssetsState>) => {
    switch (action.type) {
      case actionTypes.ResetFilter: {
        return initialAssetsState
      }

      case actionTypes.SetFilter: {
        const filter = action.payload?.filter
        return {...state, filter}
      }

      default:
        return state
    }
  }
)

export const Actions = {
  resetFilter: () => ({type: actionTypes.ResetFilter}),
  setFilter: (filter: object) => ({
    type: actionTypes.SetFilter,
    payload: {filter},
  }),
}

export function* saga() {}
