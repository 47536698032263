import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {
  ChargeTypesClient,
  ChargeTypesQueryParams,
} from '../../../infrastracture/api/ChargeTypesClient'
import customStyles from './customStyles'

interface Props {
  name?: string
  defaultValue?: any
  placeholder?: string
  disabled?: boolean
  onChange?: (name: any, value: any, label?: any, fee?: any) => void
  feesOnly?: boolean
  nonFeesOnly?: boolean
}

const ChargeTypeSelector: React.FC<Props> = (props: any) => {
  const {defaultValue, placeholder, disabled, name, onChange, feesOnly, nonFeesOnly} = props

  const [options, setOptions] = useState<any>([])
  const [value, setValue] = useState<any>({})

  const defaultId = 'chargeTypeId'

  useEffect(() => {
    populateData()
  }, [])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const populateData = async () => {
    var query = new ChargeTypesQueryParams()

    if (feesOnly) {
      query.WithFeesOnly(true)
    } else if (nonFeesOnly) {
      query.WithNonFeesOnly(true)
    }
    //TODO: SUPPORT SEARCH FOR PERFORMANCE AGAINST LARGE COLLECTIONS
    var data = (
      await ChargeTypesClient.List(query.Paginate(0, 10000).Sort('name', 'asc').WithActive(true))
    ).data

    if (data) {
      setOptions(
        data.data
          // .filter((x: any) => !x.isDefault)
          .map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              fee: item.fee,
            }
          })
      )
    }
  }

  const handleChange = (values: any) => {
    const {value, label, fee} = values
    setValue(values)
    onChange(defaultId, value, label, fee)
  }

  return (
    <Select
      onChange={handleChange}
      value={value}
      options={options}
      placeholder={placeholder}
      isDisabled={disabled}
      id={name ? name : defaultId}
      name={name ? name : defaultId}
      styles={customStyles}
      menuPortalTarget={document.body}
    />
  )
}

export default ChargeTypeSelector
