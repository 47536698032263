import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {HazardTypeClient, HazardTypeQueryParams} from '../../../infrastracture/api/HazardTypeClient'
import customStyles from './customStyles'

interface Props {
  name?: string
  defaultValue?: any
  placeholder?: string
  disabled?: boolean
  onChange?: (name: any, value: any, label: any) => void
  isPublic?: boolean
}

const HazardTypeSelector: React.FC<Props> = (props: any) => {
  const {defaultValue, placeholder, disabled, name, isPublic} = props
  const [options, setOptions] = useState([])
  const [value, setValue] = useState<any>({})

  const defaultId = 'hazardTypeId'

  useEffect(() => {
    populateData()
  }, [])

  useEffect(() => {
    if (value != defaultValue) setValue(defaultValue)
  }, [defaultValue])

  const populateData = async () => {
    var query = new HazardTypeQueryParams()
    if (isPublic) {
      var data = (
        await HazardTypeClient.PublicList(
          query.Paginate(0, 10000).Sort('name', 'asc').WithEnabled(true)
        )
      ).data

      if (data) {
        setOptions(
          data.data.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            }
          })
        )
      }
    } else {
      //TODO: SUPPORT SEARCH FOR PERFORMANCE AGAINST LARGE COLLECTIONS
      var data = (
        await HazardTypeClient.List(query.Paginate(0, 10000).Sort('name', 'asc').WithEnabled(true))
      ).data

      if (data) {
        setOptions(
          data.data.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            }
          })
        )
      }
    }
  }

  const handleChange = (values: any) => {
    const {onChange} = props
    const {label, value} = values
    setValue(values)
    onChange(defaultId, value, label)
  }

  return (
    <Select
      onChange={handleChange}
      // onInputChange={(event: any) => {
      //   event.preventDefault()
      // }}
      value={value}
      options={options}
      placeholder={placeholder}
      isDisabled={disabled}
      id={name ? name : defaultId}
      name={name ? name : defaultId}
      menuPortalTarget={document.body}
      styles={customStyles}
      // menuPosition={'fixed'}
    />
  )
}

export default HazardTypeSelector
